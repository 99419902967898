import { Box, Typography } from "@mui/material";
import { NewThemeStyledLink } from "components/NewThemeStyledLink";
import { supportMail } from "../../../constants";
import { useTranslation } from "react-i18next";

export const ResetPasswordFooter: React.FC<{ isLogin?: boolean }> = ({
  isLogin,
}) => {
  const { t } = useTranslation();

  const handleGetHelpClick = () => {
    window.open(`mailto:${supportMail}`);
  };

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Typography variant="p2" color="grey.700" fontWeight={400} mr={1}>
        {isLogin
          ? t("Login.labels.havingTrouble")
          : t("common.labels.stillNeedHelp")}
      </Typography>
      <NewThemeStyledLink onClick={handleGetHelpClick}>
        <Typography
          variant="p2"
          color="grey.700"
          sx={{ textDecoration: "underline" }}
        >
          {isLogin ? t("Login.labels.getHelp") : t("common.labels.contactUs")}
        </Typography>
      </NewThemeStyledLink>
    </Box>
  );
};
