import { Typography } from "@mui/material";
import { ActionsDialogProps } from "components/ActionsDialog";
import { BasicModal } from "components/BasicModal/BasicModal";
import { IconContainer } from "components/IconContainer";
import { FormPublicApi } from "decl";
import {
  AddDailyDiaryDelayRecordInput,
  DailyDiaryPresetSection,
  DailyDiaryDelayRecord,
  EditDailyDiaryDelayRecordInput,
  AttachmentInput,
} from "generated/graphql";
import { Plus } from "phosphor-react";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { RecordModalFooter } from "../../RecordModalFooter";
import { DelayOptionalColumn } from "../DelayTable/DelayTable";
import { DelayLookups, DelayRecordForm } from "./DelayRecordForm";
import { useDisableBackdropClick } from "../../../hooks/useDisableBackdropClick";

export type DelayRecordModalProps = {
  delayRecord?: DailyDiaryDelayRecord;
  delayLookups: DelayLookups;
  section: DailyDiaryPresetSection;
  optionalColumns: DelayOptionalColumn[];
  onAttachmentsUpdated: (attachments: AttachmentInput[]) => void;
  onPrimaryClick: (
    delayRecord: AddDailyDiaryDelayRecordInput | EditDailyDiaryDelayRecordInput,
    closeModal: boolean
  ) => void;
  onSecondaryClick: () => void;
} & Omit<ActionsDialogProps, "onPrimaryClick" | "onSecondaryClick">;

export const DelayRecordModal: React.FC<DelayRecordModalProps> = ({
  delayRecord,
  delayLookups,
  optionalColumns,
  section,
  onAttachmentsUpdated,
  onPrimaryClick,
  onSecondaryClick,
  onClose,
  primaryBtnCaption,
  secondaryBtnCaption,
  primaryBtnLoading,
  secondaryBtnLoading,
  primaryBtnDisabled,
  ...restDialogProps
}) => {
  const { t } = useTranslation();
  const formApiRef = useRef<FormPublicApi>(null);
  const { handleClose } = useDisableBackdropClick(onClose);

  const [addAnotherRecord, setAddAnotherRecord] = useState(false);
  const [modalFooterKey, setModalFooterKey] = useState(0); // TODO: remove this when updating useAttachments module or creating a new one specific for modals

  const delayRecordRef = useRef<
    AddDailyDiaryDelayRecordInput | EditDailyDiaryDelayRecordInput
  >();

  const handleDelayRecordChange = (
    updatedDelayRecord:
      | EditDailyDiaryDelayRecordInput
      | AddDailyDiaryDelayRecordInput
  ) => {
    delayRecordRef.current = updatedDelayRecord;
  };

  const handlePrimaryBtnClick = () => {
    if (formApiRef.current?.validate()) {
      if (delayRecord) {
        // edit
        onPrimaryClick(delayRecordRef.current!, true);
      } else {
        // add
        const { id, ...addRecordInput } =
          delayRecordRef.current! as EditDailyDiaryDelayRecordInput;
        onPrimaryClick(addRecordInput, !addAnotherRecord);

        if (addAnotherRecord) {
          // clear modal
          formApiRef.current.reset();
          setModalFooterKey((crtKey) => crtKey + 1);
        }
      }
    }
  };

  return (
    <BasicModal
      headerIcon={
        <IconContainer greyBackground>
          <Plus size={24} />
        </IconContainer>
      }
      title={
        <Typography
          variant="h3"
          fontWeight={600}
          fontSize="20px"
          color="grey.800"
        >
          {t(
            `Projects.DailyDiaries.DelayModal.${
              delayRecord ? "editDelayRecord" : "addDelayRecord"
            }`
          )}
        </Typography>
      }
      fullWidth
      onClose={handleClose}
      maxWidth="sm"
      {...restDialogProps}
    >
      <DelayRecordForm
        apiRef={formApiRef}
        delayLookups={delayLookups}
        optionalColumns={optionalColumns}
        section={section}
        onChange={handleDelayRecordChange}
        delayRecord={delayRecord}
      />
      <RecordModalFooter
        mt={4}
        key={modalFooterKey}
        onAddAnotherRecordChange={setAddAnotherRecord}
        showAddAnotherRecord={!delayRecord}
        addAnotherRecord={addAnotherRecord}
        primaryBtnCaption={
          primaryBtnCaption ??
          (delayRecord ? t("common.labels.saveChanges") : undefined)
        }
        primaryBtnLoading={primaryBtnLoading}
        primaryBtnDisabled={primaryBtnDisabled}
        secondaryBtnCaption={secondaryBtnCaption}
        secondaryBtnLoading={secondaryBtnLoading}
        attachments={delayRecord?.attachments ?? []}
        onAttachmentsUpdated={onAttachmentsUpdated}
        onPrimaryClick={handlePrimaryBtnClick}
        onSecondaryClick={onSecondaryClick}
      />
    </BasicModal>
  );
};
