import { OnMutationDone } from "decl";
import {
  ChangeProductInstanceStatusMutation,
  ChangeProductInstanceStatusMutationVariables,
  ProductInstance,
  ProductInstanceStatus,
} from "generated/graphql";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { changeProductInstanceStatusMutation } from "./ContractProductInstances.query";

export type useContractProductInstancesProps = {
  onStatusChange: OnMutationDone;
};

export const useContractProductInstances = ({
  onStatusChange,
}: useContractProductInstancesProps) => {
  const { t } = useTranslation();

  const [changeProductInstanceStatus, { loading: changeStatusLoading }] =
    useGraphMutation<
      ChangeProductInstanceStatusMutation,
      ChangeProductInstanceStatusMutationVariables
    >(
      changeProductInstanceStatusMutation,
      {
        update: onStatusChange,
      },
      t("common.successMessages.entityUpdated", {
        entity: t("common.labels.productInstance"),
      })
    );

  const handleChangeStatus = useCallback(
    async (row: ProductInstance, newStatus: ProductInstanceStatus) => {
      await changeProductInstanceStatus({
        variables: {
          id: row.id,
          status: newStatus,
        },
      });
    },
    [changeProductInstanceStatus]
  );

  return {
    changeStatus: handleChangeStatus,
    changeStatusLoading,
  };
};
