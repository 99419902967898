import { ProductInstance, ProductInstanceStatus } from "generated/graphql";
import { Box, Collapse, useTheme } from "@mui/material";
import {
  GridCellParams,
  GridEventListener,
  GridFilterModel,
  GridLogicOperator,
  GridRowParams,
  GridRowSelectionModel,
  GridSortModel,
} from "@mui/x-data-grid-pro";
import { CollapsibleHeader } from "components/CollapsibleHeader";
import { StatusOption } from "components/StatusTag/StatusTag";
import { StyledDataGrid } from "components/StyledDataGrid";
import { exportToExcel } from "helpers/exportToExcel";
import { useDataGridVisibleRows } from "hooks/useDataGridVisibleRows";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useContractStatusOptions } from "components/StatusTag/useContractStatusOptions";
import { useColumns } from "./ContractProductInstances.constants";
import { Browsers } from "phosphor-react";
import { NewAppPaths } from "helpers/paths/paths";
import { getUserName } from "helpers/miscelaneous";

export type ContractProductInstancesProps = {
  productInstances: ProductInstance[];
  loading?: boolean;
  projectFriendlyName?: string;
  contractFriendlyName?: string;
  onProductInstanceStatusChange: (
    row: ProductInstance,
    newStatus: ProductInstanceStatus
  ) => void;
};

export const ContractProductInstances: React.FC<
  ContractProductInstancesProps
> = ({
  productInstances,
  loading,
  projectFriendlyName,
  contractFriendlyName,
  onProductInstanceStatusChange,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { projectId, contractId } = useParams();
  const navigate = useNavigate();
  const statusOptions =
    useContractStatusOptions() as StatusOption<ProductInstanceStatus>[];

  const { visibleRowsCount, gridApiRef } = useDataGridVisibleRows();
  const [gridVisibility, setGridVisibility] = useState(true);
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>();
  const [sortingModel, setSortingModel] = useState<GridSortModel>([
    { field: "productName", sort: "asc" },
    { field: "description", sort: "asc" },
  ]);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [
      {
        field: "status",
        operator: "isAnyOf",
        value: [
          ProductInstanceStatus.Active,
          ProductInstanceStatus.Offline,
          ProductInstanceStatus.Closed,
        ],
      },
    ],
    logicOperator: GridLogicOperator.And,
    quickFilterLogicOperator: GridLogicOperator.And,
    quickFilterValues: [],
  });

  const handleExportToExcel = () => {
    const columns = [
      {
        header: t("common.labels.product"),
        key: "productName",
        width: 20,
      },
      {
        header: t("AdminConsole.ProductInstances.labels.instance"),
        key: "description",
        width: 20,
      },
      { header: t("common.labels.status"), key: "status", width: 20 },
      {
        header: t("common.labels.config"),
        key: "productSchemaId",
        width: 20,
      },
      {
        header: t("AdminConsole.ProductInstances.labels.mode"),
        key: "soloModeSupported",
        width: 20,
      },
      {
        header: t("common.labels.dateCreated"),
        key: "dateCreated",
        width: 20,
      },
      {
        header: t("common.labels.createdBy"),
        key: "creator",
        width: 20,
      },
    ];

    const rows = productInstances
      .filter(
        (productInstance) =>
          (selectionModel || []).indexOf(productInstance.id) >= 0
      )
      .map((productInstance) => ({
        ...productInstance,
        productName: productInstance.product.name,
        soloModeSupported: t(
          `AdminConsole.ProductInstances.labels.${
            productInstance.soloModeSupported ? "solo" : "multi"
          }`
        ),
        creator: getUserName(productInstance.creator),
        dateCreated: productInstance.dateCreated
          ? new Date(productInstance.dateCreated)
          : "",
      }));

    exportToExcel(
      `${projectFriendlyName}-${contractFriendlyName}-${t(
        "AdminConsole.ProductInstances.labels.productInstances"
      )}`,
      columns,
      rows
    );
  };

  const handleNewProductInstance = useCallback(() => {
    navigate(
      NewAppPaths.authorized.AdminConsole.children.Projects.children.ContractDetails.children.NewProductInstance.pathConstructor(
        projectId!,
        contractId!
      )
    );
  }, [navigate, projectId, contractId]);

  const handleRowClick: GridEventListener<"rowClick"> | undefined = useCallback(
    (rowData: GridRowParams<ProductInstance>) => {
      const selection = window.getSelection()?.toString();
      if (!selection) {
        navigate(
          NewAppPaths.authorized.AdminConsole.children.Projects.children.ContractDetails.children.ProductInstanceDetails.pathConstructor(
            projectId!,
            contractId!,
            rowData.row.id
          )
        );
      }
    },
    [navigate, projectId, contractId]
  );

  const columns = useColumns(statusOptions, onProductInstanceStatusChange);

  return (
    <Box>
      <CollapsibleHeader
        title={t("AdminConsole.ProductInstances.labels.productInstances")}
        actionButtonCaption={t("common.buttons.addEntity", {
          entity: t("common.labels.productInstance").toLowerCase(),
        })}
        visibleRowsCount={visibleRowsCount || 0}
        selectedCount={selectionModel?.length || 0}
        withShadow={false}
        onExportToExcel={handleExportToExcel}
        onActionButtonClick={handleNewProductInstance}
        icon={
          <Browsers
            size={22}
            weight="fill"
            color={theme.palette.primary.main}
          />
        }
        onToggleCollapse={() => setGridVisibility((state) => !state)}
        collapsed={!gridVisibility}
      />
      <Collapse in={gridVisibility}>
        <Box sx={{ maxHeight: 600, width: "100%", overflowY: "auto" }}>
          <StyledDataGrid
            apiRef={gridApiRef}
            rows={productInstances}
            columns={columns}
            getRowId={(rowData: ProductInstance) => rowData.id}
            onRowSelectionModelChange={setSelectionModel}
            loading={loading}
            sortingMode="client"
            sortModel={sortingModel}
            onSortModelChange={setSortingModel}
            filterMode="client"
            filterModel={filterModel}
            onFilterModelChange={setFilterModel}
            onRowClick={handleRowClick}
            getCellClassName={(
              params: GridCellParams<any, ProductInstance, any>
            ) => {
              return params.row.status === ProductInstanceStatus.Removed
                ? "greyed-out"
                : "";
            }}
            checkboxSelection
            disableRowSelectionOnClick
            autoHeight
          />
        </Box>
      </Collapse>
    </Box>
  );
};
