import { gql } from "@apollo/client";

export const notificationsQuery = gql`
  query notifications($limit: Int!, $nextToken: String) {
    notifications(limit: $limit, nextToken: $nextToken) {
      nextToken
      items {
        id
        itemId
        project {
          id
          name
        }
        contract {
          id
          name
          contractType {
            id
            description
            subType
            version
          }
        }
        productInstanceId
        productType
        versionId
        number
        details {
          __typename
          title
          ... on AttachmentAddedDetails {
            attachments {
              fileName
              description
            }
          }
          ... on AttachmentDescriptionAddedDetails {
            attachmentChanges {
              fileName
              oldDescription
              newDescription
            }
          }
          ... on AttachmentDescriptionEditedDetails {
            attachmentChanges {
              fileName
              oldDescription
              newDescription
            }
          }
          ... on AttachmentRemovedDetails {
            attachments {
              fileName
              description
            }
          }
          ... on CommentRepliedDetails {
            content
          }
          ... on CompEventConfirmationRecordedDetails {
            attachments {
              fileName
              description
            }
          }
          ... on CompEventConfirmationSentDetails {
            attachments {
              fileName
              description
            }
          }
          ... on CompEventNoticeRecordedDetails {
            attachments {
              fileName
              description
            }
            notifiedByParty
          }
          ... on CompEventNoticeRequestQuotationRecordedDetails {
            attachments {
              fileName
              description
            }
            notifiedByParty
          }
          ... on CompEventNoticeRequestQuotationSentDetails {
            attachments {
              fileName
              description
            }
            notifiedByParty
          }
          ... on CompEventNoticeSentDetails {
            attachments {
              fileName
              description
            }
            notifiedByParty
          }
          ... on CompEventOwnAssessmentNoticeRecordedDetails {
            attachments {
              fileName
              description
            }
          }
          ... on CompEventOwnAssessmentNoticeSentDetails {
            attachments {
              fileName
              description
            }
          }
          ... on CompEventOwnAssessmentRecordedDetails {
            attachments {
              fileName
              description
            }
          }
          ... on CompEventOwnAssessmentSentDetails {
            attachments {
              fileName
              description
            }
          }
          ... on CompEventQuotationAcceptanceRecordedDetails {
            attachments {
              fileName
              description
            }
          }
          ... on CompEventQuotationAcceptanceSentDetails {
            regardingType
            regardingProductInstanceId
            attachments {
              fileName
              description
            }
          }
          ... on CompEventQuotationRecordedDetails {
            attachments {
              fileName
              description
            }
          }
          ... on CompEventQuotationRevisionRequestRecordedDetails {
            attachments {
              fileName
              description
            }
          }
          ... on CompEventQuotationRevisionRequestSentDetails {
            attachments {
              fileName
              description
            }
          }
          ... on CompEventQuotationSentDetails {
            attachments {
              fileName
              description
            }
          }
          ... on CompEventRejectionRecordedDetails {
            attachments {
              fileName
              description
            }
          }
          ... on CompEventRejectionSentDetails {
            attachments {
              fileName
              description
            }
          }
          ... on EventAddedDetails {
            attachments {
              fileName
              description
            }
          }
          ... on EventEditedDetails {
            fieldChanges {
              fieldName
              oldValue
              newValue
            }
          }
          ... on EventOwnerChangedDetails {
            oldOwner
            newOwner
          }
          ... on EventStatusChangedDetails {
            oldStatus
            newStatus
            reason
            details
          }
          ... on RiskEditedDetails {
            fieldChanges {
              fieldName
              oldValue
              newValue
            }
          }
          ... on EwEditedDetails {
            fieldChanges {
              fieldName
              oldValue
              newValue
            }
          }
          ... on EwOwnerChangedDetails {
            oldOwner
            newOwner
          }
          ... on EwRecordedDetails {
            attachments {
              fileName
              description
            }
          }
          ... on EwSentDetails {
            attachments {
              fileName
              description
            }
          }
          ... on EwStatusChangedDetails {
            oldStatus
            newStatus
            reason
            details
          }
          ... on InstructionEditedDetails {
            fieldChanges {
              fieldName
              oldValue
              newValue
            }
          }
          ... on InstructionOwnerChangedDetails {
            oldOwner
            newOwner
          }
          ... on InstructionRecordedDetails {
            attachments {
              fileName
              description
            }
          }
          ... on InstructionSentDetails {
            attachments {
              fileName
              description
            }
          }
          ... on InstructionStatusChangedDetails {
            oldStatus
            newStatus
            reason
            details
          }
          ... on NewCommentAddedDetails {
            content
          }
          ... on RiskAddedDetails {
            attachments {
              fileName
              description
            }
          }
          ... on RiskEditedDetails {
            fieldChanges {
              fieldName
              oldValue
              newValue
            }
          }
          ... on RiskOwnerChangedDetails {
            oldOwner
            newOwner
          }
          ... on RiskStatusChangedDetails {
            oldStatus
            newStatus
            reason
            details
          }
          ... on UserMentionedDetails {
            content
          }
          ... on ActionAuthorizedDetails {
            productDetails {
              regardingType
              regardingItemId
            }
            actionName
            authorizationAuditId
          }
          ... on ActionPreventedDetails {
            productDetails {
              regardingType
              regardingItemId
            }
            actionName
            authorizationAuditId
          }
          ... on AuthorizationRequiredDetails {
            productDetails {
              regardingType
              regardingItemId
            }
            actionName
            initiatorName
            authorizationAuditId
          }
        }
        status
        creatorId
        creator {
          id
          firstname
          surname
          profilePicture
        }
        dateCreated
        recipientId
        recipient {
          id
          firstname
          surname
        }
        dateModified
      }
    }
  }
`;

export const newNotificationsQuery = gql`
  query newNotifications($lastChecked: AWSDateTime!) {
    newNotifications(lastChecked: $lastChecked)
  }
`;

export const unreadNotificationsQuery = gql`
  query unreadNotifications {
    unreadNotifications
  }
`;

export const markNotificationMutation = gql`
  mutation markNotification($id: ID!, $status: NotificationStatus!) {
    markNotification(id: $id, status: $status) {
      id
      status
    }
  }
`;

export const markAllNotificationsAsReadMutation = gql`
  mutation markAllNotificationsAsRead {
    markAllNotificationsAsRead
  }
`;

export const compEventItemQuery = gql`
  query compEventItem($id: ID!) {
    compEventItem(id: $id) {
      __typename
      id
      productInstanceId
      regardingId
      regardingType
      status
      regardingEvent {
        id
        title
        productInstanceId
        number

        productInstance {
          id
          product {
            id
          }
        }
      }
      regardingInstruction {
        id
        title
        number
        productInstanceId
        productInstance {
          id
          product {
            id
          }
        }
      }
    }
  }
`;

export const claimItemQuery = gql`
  query claimItem($id: ID!) {
    claimItem(id: $id) {
      __typename
      id
      productInstanceId
      regardingId
      regardingType
      status
      regardingEvent {
        id
        title
        productInstanceId
        number

        productInstance {
          id
          product {
            id
          }
        }
      }
      regardingInstruction {
        id
        title
        number
        productInstanceId
        productInstance {
          id
          product {
            id
          }
        }
      }
    }
  }
`;

export const variationItemQuery = gql`
  query variationItem($id: ID!) {
    variationItem(id: $id) {
      __typename
      id
      productInstanceId
      regardingId
      status
      regardingInstruction {
        id
        title
        number
        productInstanceId
        productInstance {
          id
          product {
            id
          }
        }
      }
    }
  }
`;
