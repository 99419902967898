import { useCallback, useContext, useEffect, useState } from "react";
import { ClaimWidgetContext } from "../../ClaimWidget/ClaimWidget.context";
import {
  GenerateRecordClaimDeterminationNoticePreviewMutation,
  GenerateRecordClaimDeterminationNoticePreviewMutationVariables,
  RecordClaimDeterminationNoticePreview,
} from "generated/graphql";
import { useGraphMutation } from "hooks/useGraphMutation";
import { debounce } from "@mui/material";
import { eventDebounceDuration } from "../../../../../../constants";
import { generateRecordClaimDeterminationNoticePreviewMutation } from "./NotifyClaimDeterminationAction.query";

export const useRecordClaimDeterminationNoticeModal = (
  draftClaimDeterminationId: string
) => {
  const { claim, contract } = useContext(ClaimWidgetContext);

  const [claimPreview, setClaimPreview] =
    useState<RecordClaimDeterminationNoticePreview>();

  const [generateClaimPreview] = useGraphMutation<
    GenerateRecordClaimDeterminationNoticePreviewMutation,
    GenerateRecordClaimDeterminationNoticePreviewMutationVariables
  >(generateRecordClaimDeterminationNoticePreviewMutation, {}, null);

  const loadPreview = debounce(
    useCallback(async () => {
      const { data } = await generateClaimPreview({
        variables: {
          input: {
            claimId: claim!.id,
            draftClaimDeterminationId,
          },
        },
      });

      if (data) {
        setClaimPreview(data.generateRecordClaimDeterminationNoticePreview);
      }
    }, [generateClaimPreview, claim, draftClaimDeterminationId]),
    eventDebounceDuration
  );

  useEffect(() => {
    loadPreview();
    // eslint-disable-next-line
  }, []);

  return {
    claimPreview,
    contract,
  };
};
