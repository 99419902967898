import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { AuthorizationWorkflowAudit } from "generated/graphql";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  authorizationAuditStatusOptions,
  useCommonAuthorizationColumns,
} from "../Authorizations.constants";
import { statusComparatorFunction } from "helpers/dataGrid.helpers";
import { StatusTagNew } from "components/StatusTag/StatusTagNew";

export const useColumns = () => {
  const { t } = useTranslation();

  const commonAuthColumns = useCommonAuthorizationColumns();

  return useMemo((): GridColDef<AuthorizationWorkflowAudit>[] => {
    return [
      ...commonAuthColumns,
      {
        field: "status",
        headerName: t("common.labels.status"),
        flex: 0.1,
        minWidth: 120,
        maxWidth: 130,
        resizable: true,
        sortComparator: statusComparatorFunction,
        valueGetter: (params) => params.row.status,
        renderCell: (
          params: GridRenderCellParams<any, AuthorizationWorkflowAudit, any>
        ) => {
          return (
            <StatusTagNew
              selectedOptionId={params.row.status}
              disabled
              options={authorizationAuditStatusOptions}
            />
          );
        },
      },
    ];
  }, [t, commonAuthColumns]);
};
