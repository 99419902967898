import { Box, CircularProgress, Grid, ThemeProvider } from "@mui/material";
import { NewPageContentContainer } from "components/NewPageContentContainer";
import { Attachments } from "containers/Projects/components/Attachments/Attachments";
import { useAttachments } from "containers/Projects/components/Attachments/hooks/useAttachments";
import { useImagePreviewModal } from "containers/Projects/components/Attachments/hooks/useImagePreviewModal";
import {
  Changelog,
  ChangelogPublicAPI,
} from "containers/Projects/components/Changelog/Changelog";
import { Comments } from "containers/Projects/components/Comments/Comments";
import {
  ExplorerContext,
  ExplorerState,
} from "containers/Projects/components/Explorer/Explorer.context";
import { PhotoAttachmentPreviewModal } from "containers/Projects/components/PhotoAttachmentPreviewModal/PhotoAttachmentPreviewModal";
import { SchemaInterpretor } from "containers/Projects/components/SchemaInterpretor/SchemaInterpretor";
import { CollapsibleSectionContainer } from "containers/Projects/components/SchemaInterpretor/Section/Section";
import {
  Attachment,
  AttachmentInput,
  AttachmentStatus,
  ChangeRiskItemStatusMutation,
  ChangeRiskItemStatusMutationVariables,
  EditRiskItemMutation,
  EditRiskItemMutationVariables,
  ItemStatusOption,
  ProductSchema,
  ProductType,
  RiskItemQuery,
  RiskItemQueryVariables,
  User,
} from "generated/graphql";
import { changeRiskItemStatusMutation } from "graphql/mutations/changeRiskStatus";
import { editRiskItemMutation } from "graphql/mutations/editRisk";
import { NewAppPaths } from "helpers/paths/paths";
import { PermissionEnum } from "helpers/Permissions/Permissions.constants";
import { ErrorHandlingType, useGraphMutation } from "hooks/useGraphMutation";
import { useGraphQuery } from "hooks/useGraphQuery";
import { useHasAccess } from "hooks/useHasAccess";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { GlobalContext } from "state-management/globalContext/Global.context";
import { extendedTheme } from "theme/extendedTheme";
import { RiskEventDetailsHeader } from "./components/RiskEventDetailsHeader/RiskEventDetailsHeader";
import { riskItemQuery } from "./RiskDetails.query";
import { SectionContainer } from "components/miscellaneous/SectionContainer";
import { useNavigateToRegisterPage } from "containers/Projects/hooks/useNavigateToRegisterPage";
import { NotifiedUsersWidgetHeader } from "containers/Projects/components/NotifiedUsers/NotifiedUsersWidgetHeader";
import { NotifiedUsers } from "containers/Projects/components/NotifiedUsers/NotifiedUsers";

export const RiskItemDetails = () => {
  const { productInstanceId, riskItemId } = useParams();
  const navigate = useNavigate();
  const handleNavigateToRisksRegister = useNavigateToRegisterPage();
  const { t } = useTranslation();
  const location = useLocation();
  const explorerStateRef = useRef<ExplorerState | undefined>();
  const { authenticatedUser } = useContext(GlobalContext);
  const {
    changeExplorerEntities,
    clear: clearExplorerData,
    setLoading: setExplorerDataLoading,
  } = useContext(ExplorerContext);

  const [changelogApiRef, setChangelogApiRef] = useState<ChangelogPublicAPI>();

  const {
    data: riskItemData,
    refetch: refetchRiskData,
    loading: riskItemDataLoading,
    error,
  } = useGraphQuery<RiskItemQuery, RiskItemQueryVariables>(
    riskItemQuery,
    {
      variables: { id: riskItemId! },
      errorPolicy: "none",
    },
    {
      type: ErrorHandlingType.None,
    }
  );

  const [changeRiskItemStatus, { loading: changeRiskItemStatusLoading }] =
    useGraphMutation<
      ChangeRiskItemStatusMutation,
      ChangeRiskItemStatusMutationVariables
    >(
      changeRiskItemStatusMutation,
      {
        update: (_cache) => {
          refetchRiskData();

          // cache.evict({ id: "ROOT_QUERY", fieldName: "riskItems" });
          // cache.gc();
          reloadChangelog();
        },
      },
      t("common.successMessages.entityUpdated", {
        entity: t("Projects.Risks.riskItem"),
      })
    );

  const [doEditRisk] = useGraphMutation<
    EditRiskItemMutation,
    EditRiskItemMutationVariables
  >(
    editRiskItemMutation,
    {
      update: () => {
        refetchRiskData();
        reloadChangelog();
      },
    },
    t("common.successMessages.entityUpdated", {
      entity: t("Projects.Risks.riskItem"),
    })
  );

  const handleAttachmentsUpdated = async (
    attachmentsUpdated: AttachmentInput[]
  ) => {
    await doEditRisk({
      variables: {
        input: {
          id: riskItemId!,
          data: riskItemData!.riskItem.data,
          attachments: attachmentsUpdated ?? riskItemData!.riskItem.attachments,
        },
      },
    });
  };

  const {
    allAttachments,
    addAttachments,
    removeAttachment,
    updateAttachment,
    downloadAttachment,
  } = useAttachments(
    ((riskItemData?.riskItem.attachments as Attachment[]) ?? []).filter(
      (attach) => attach.status === AttachmentStatus.Active
    ) || [],
    handleAttachmentsUpdated
  );

  const {
    imageAttachmentPreviewModalVisible,
    imagePreviewData,
    previewUrl,
    handleAttachmentClick,
    closeModal: closeImagePreviewModal,
  } = useImagePreviewModal(downloadAttachment);

  const triggerEditRiskFlow = () => {
    navigate(
      NewAppPaths.authorized.Projects.children.EditRisk.pathConstructor(
        riskItemData!.riskItem.productInstanceId,
        riskItemData!.riskItem.id
      ),
      {
        state: explorerStateRef.current,
      }
    );
  };

  const handleChangeRiskStatus = async (
    newStatus: ItemStatusOption,
    reasonId: string,
    remarks?: string
  ) => {
    await changeRiskItemStatus({
      variables: {
        input: {
          id: riskItemData!.riskItem.id,
          newStatusId: newStatus.id,
          reasonId,
          remarks,
        },
      },
    });
  };

  const reloadChangelog = useCallback(() => {
    changelogApiRef?.reload();
  }, [changelogApiRef]);

  useEffect(() => {
    if (riskItemData) {
      const explorerState = {
        projectId: riskItemData.riskItem.productInstance.contract.project.id,
        contractId: riskItemData.riskItem.productInstance.contract.id,
        productId: riskItemData.riskItem.productInstance.product.id,
        productInstanceId: riskItemData.riskItem.productInstanceId,
      };
      explorerStateRef.current = explorerState;
      changeExplorerEntities(explorerState);
    }
  }, [changeExplorerEntities, riskItemData]);

  useEffect(() => {
    if (location.state) {
      explorerStateRef.current = location.state as ExplorerState;
      changeExplorerEntities(location.state as ExplorerState);
    } else {
      // state not preset, needs fetching
      setExplorerDataLoading(true);
    }
  }, [setExplorerDataLoading, location, changeExplorerEntities]);

  useEffect(() => {
    if (riskItemData) {
      setExplorerDataLoading(false);
    }
  }, [setExplorerDataLoading, riskItemData]);

  useEffect(() => {
    if (changelogApiRef) {
      reloadChangelog();
    }
  }, [changelogApiRef, reloadChangelog]);

  useEffect(() => {
    if (error && !riskItemData) {
      clearExplorerData();
      navigate(NewAppPaths.authorized.NotFound);
    }
  }, [error, riskItemData, navigate, clearExplorerData]);

  const isCurrentUserRiskOwner =
    riskItemData?.riskItem.ownerId === authenticatedUser?.id;
  const canChangeStatus =
    useHasAccess(
      undefined,
      [PermissionEnum.ChangeStatus],
      productInstanceId ?? undefined
    ) || isCurrentUserRiskOwner;
  const canManageAttachments =
    useHasAccess(
      undefined,
      [PermissionEnum.ManageAttachments],
      productInstanceId!
    ) || isCurrentUserRiskOwner;

  return (
    <ThemeProvider
      theme={(outerTheme) => ({
        ...outerTheme,
        ...extendedTheme,
      })}
    >
      <PhotoAttachmentPreviewModal
        open={imageAttachmentPreviewModalVisible}
        attachment={imagePreviewData?.attachment}
        creatorName={imagePreviewData?.creatorName}
        creatorCompany={imagePreviewData?.creatorCompany}
        previewUrl={previewUrl}
        onClose={closeImagePreviewModal}
        onDownload={downloadAttachment}
      />
      <NewPageContentContainer>
        <Box height="100%" width="100%">
          <RiskEventDetailsHeader
            title={riskItemData?.riskItem.title || ""}
            titleSuffix={`(${riskItemData?.riskItem.number})`}
            statusOptionId={riskItemData?.riskItem.statusOptionId || ""}
            productType={ProductType.RisksRegister}
            dataLoading={riskItemDataLoading || changeRiskItemStatusLoading}
            statusOptions={
              (riskItemData?.riskItem.productInstance.statusCollection
                .statusOptions.items as ItemStatusOption[]) ?? []
            }
            isCurrentUserProductItemOwner={isCurrentUserRiskOwner}
            onStatusChange={
              canChangeStatus ? handleChangeRiskStatus : undefined
            }
            onEdit={triggerEditRiskFlow}
            onBack={handleNavigateToRisksRegister}
          />
          <Box mt={3}>
            {riskItemDataLoading || !riskItemData ? (
              <Box display="flex" alignItems="center" justifyContent="center">
                <CircularProgress />
              </Box>
            ) : (
              <SchemaInterpretor
                editMode={false}
                productItemType={ProductType.RisksRegister}
                schema={
                  riskItemData!.riskItem.productInstance
                    .productSchema as ProductSchema
                }
                metadata={{
                  owner: riskItemData!.riskItem.owner as User,
                  creator: riskItemData!.riskItem.creator as User,
                  dateModified: riskItemData!.riskItem.dateModified,
                  dateCreated: riskItemData!.riskItem.dateCreated,
                  productItemId: riskItemData!.riskItem.id,
                }}
                schemaValues={riskItemData!.riskItem.data}
                contractCurrency={
                  riskItemData!.riskItem.productInstance.contract
                    .valueCurrency ?? ""
                }
                contractTimezone={
                  riskItemData!.riskItem.productInstance.contract.timeZone ?? ""
                }
                productInstanceId={riskItemData!.riskItem.productInstanceId}
                onOwnerChange={reloadChangelog}
                mainColumnExtraWidgets={
                  <>
                    <Grid item xs={12} key={"attachments"} position="relative">
                      <SectionContainer>
                        <Attachments
                          editMode={canManageAttachments === true}
                          attachments={allAttachments}
                          onAttachmentsAdd={addAttachments}
                          onAttachmentRemove={removeAttachment}
                          onAttachmentUpdate={updateAttachment}
                          onAttachmentClick={handleAttachmentClick}
                        />
                      </SectionContainer>
                    </Grid>
                    <Grid item xs={12} key={"comments"} position="relative">
                      <SectionContainer>
                        <Comments
                          productType={ProductType.RisksRegister}
                          productItemId={riskItemId!}
                          productInstanceId={productInstanceId!}
                          onCommentAdded={reloadChangelog}
                        />
                      </SectionContainer>
                    </Grid>
                  </>
                }
                secondaryColumnExtraWidgets={
                  <>
                    {riskItemData.riskItem.notificationRecipients.length >
                      0 && (
                      <Box
                        width="100%"
                        key="notified-users"
                        position="relative"
                      >
                        <CollapsibleSectionContainer
                          collapsible
                          title={
                            <NotifiedUsersWidgetHeader
                              recipientsCount={
                                riskItemData.riskItem.notificationRecipients
                                  .length
                              }
                            />
                          }
                        >
                          <NotifiedUsers
                            recipients={
                              riskItemData.riskItem.notificationRecipients
                            }
                          />
                        </CollapsibleSectionContainer>
                      </Box>
                    )}
                    <Box width="100%" key="changelog" position="relative">
                      <CollapsibleSectionContainer
                        collapsible
                        title="Changelog"
                        maxHeight="600px"
                        overflow="auto"
                      >
                        <Changelog
                          productItemId={riskItemData!.riskItem.id}
                          productType={ProductType.RisksRegister}
                          productInstanceId={productInstanceId!}
                          contractTimezone={
                            riskItemData?.riskItem.productInstance.contract
                              .timeZone ?? ""
                          }
                          ref={(apiRef) => {
                            setChangelogApiRef(apiRef!);
                          }}
                        />
                      </CollapsibleSectionContainer>
                    </Box>
                  </>
                }
              />
            )}
          </Box>
        </Box>
      </NewPageContentContainer>
    </ThemeProvider>
  );
};
