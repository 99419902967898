import { SchemaSection } from "generated/graphql";
import { temporaryRowId } from "../../../../../../../../../constants";

export const sortSections = (sections: SchemaSection[]): SchemaSection[] => {
  const sectionsCopy = JSON.parse(JSON.stringify(sections)) as SchemaSection[];

  return sectionsCopy.sort(
    (section1, section2) => section1.displayOrder - section2.displayOrder
  );
};

export const rowsContainTemporaryRecord = (rows: SchemaSection[]): boolean => {
  return !!rows.find((row) => row.id === temporaryRowId);
};
