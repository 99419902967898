import { Box, Collapse, useTheme } from "@mui/material";
import { CollapsibleHeader } from "components/CollapsibleHeader";
import { DetailsList } from "components/DetailsList";
import { PageContentContainer } from "components/PageContentContainer";
import { UserProfileButton } from "components/UserProfileButton/UserProfileButton";
import { dateISOFormat } from "../../../../../../constants";
import { OnMutationDone } from "decl";
import {
  ChangeUserStatusMutation,
  ChangeUserStatusMutationVariables,
  EditUserInput,
  EditUserMutationMutation,
  EditUserMutationMutationVariables,
  GetCompanyLiteByIdQuery,
  GetCompanyLiteByIdQueryVariables,
  GetUserByIdQuery,
  GetUserByIdQueryVariables,
  User,
  UserStatus,
} from "generated/graphql";
import { editUserMutation } from "graphql/mutations/editUserMutation";
import { getCompanyLiteByIdQuery } from "graphql/queries/companyByIdLite.query";
import { NewAppPaths } from "helpers/paths/paths";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useGraphQuery } from "hooks/useGraphQuery";
import moment from "moment";
import { UserCircle } from "phosphor-react";
import { useMemo, useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { changeUserStatusMutation } from "../CompanyDetails/CompanyDetails.query";
import { getUserByIdQuery } from "./UserDetails.query";
import { UserDetailsHeader } from "./UserDetailsHeader";
import { UserForm, UserFormPublicApi } from "./UserForm";
import { UserRoles } from "./UserRoles/UserRoles";
import { useUserRoles } from "./UserRoles/useUserRoles";
import { noop } from "helpers/miscelaneous";
import { CenteredLoadingIndicator } from "components/CenteredLoadingIndicator";

export const UserDetails = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const { companyId, userId } = useParams();
  const userFormRef = useRef<UserFormPublicApi>(null);

  const [showDetails, setShowDetails] = useState(true);
  const [readOnly, setReadOnly] = useState(true);
  const updatedUserDetails = useRef<EditUserInput>();

  const {
    data: userData,
    refetch: reloadUserData,
    loading: getUserDataLoading,
    error: userDataError,
  } = useGraphQuery<GetUserByIdQuery, GetUserByIdQueryVariables>(
    getUserByIdQuery,
    {
      variables: { id: userId! },
    }
  );

  if (userDataError && userDataError.message.indexOf("was not found") >= 0) {
    // Admin accessed the link of a non existing user (deleted / not registered)
    navigate(NewAppPaths.authorized.Home);
  }

  const handleUserRolesUpdates: OnMutationDone = (cache) => {
    reloadUserData();
    cache.evict({ id: "ROOT_QUERY", fieldName: "user" });
    cache.gc();
  };

  const {
    loading: userRolesLoading,
    projects,
    roles,
    addUserRole,
    addUserRoleLoading,
    editUserRole,
    editUserRoleLoading,
    changeUserRoleStatus,
    changeUserRoleStatusLoading,
  } = useUserRoles({
    userId: userId!,
    callbacks: {
      onAdd: handleUserRolesUpdates,
      onEdit: handleUserRolesUpdates,
      onStatusChange: handleUserRolesUpdates,
    },
  });

  const { data: companyData, loading: getCompanyDataLoading } = useGraphQuery<
    GetCompanyLiteByIdQuery,
    GetCompanyLiteByIdQueryVariables
  >(getCompanyLiteByIdQuery, { variables: { id: companyId! } });

  const [changeUserStatus, { loading: changeUserStatusLoading }] =
    useGraphMutation<
      ChangeUserStatusMutation,
      ChangeUserStatusMutationVariables
    >(
      changeUserStatusMutation,
      {
        update: (cache) => {
          reloadUserData();

          cache.evict({ id: "ROOT_QUERY", fieldName: "company" });
          cache.gc();
        },
      },
      t("common.successMessages.entityUpdated", {
        entity: t("common.labels.user"),
      })
    );

  const [editUser, { loading: editUserLoading }] = useGraphMutation<
    EditUserMutationMutation,
    EditUserMutationMutationVariables
  >(
    editUserMutation,
    {
      update: (cache) => {
        reloadUserData();

        cache.evict({ id: "ROOT_QUERY", fieldName: "company" });
        cache.gc();
      },
    },
    t("common.successMessages.entityUpdated", {
      entity: t("common.labels.user"),
    })
  );

  const handleCancel = () => {
    userFormRef.current?.reset();
    setReadOnly(true);
  };

  const handleChangeUserStatus = useCallback(
    async (newStatus: UserStatus) => {
      await changeUserStatus({
        variables: {
          id: userData?.user?.id!,
          status: newStatus,
        },
      });
    },
    [userData?.user?.id, changeUserStatus]
  );

  const handleUserUpdate = useCallback((updatedUser: EditUserInput) => {
    updatedUserDetails.current = updatedUser;
  }, []);

  const handleSaveUserUpdates = useCallback(async () => {
    if (!updatedUserDetails.current || !userFormRef.current?.validate()) {
      return;
    }

    const { errors } = await editUser({
      variables: {
        input: updatedUserDetails.current,
      },
    });

    if (!errors) {
      setReadOnly(true);
    }
  }, [editUser, updatedUserDetails]);

  const userEntityObj = useMemo(() => {
    if (!userData || !userData.user || !companyData?.company) {
      return [];
    }

    return [
      {
        id: "email",
        label: t("common.labels.email"),
        value: userData.user.email,
      },
      {
        id: "company",
        label: t("common.labels.company"),
        value: companyData.company.registeredName,
      },
      {
        id: "firstName",
        label: t("common.labels.firstName"),
        value: userData.user.firstname,
      },
      {
        id: "surname",
        label: t("common.labels.surname"),
        value: userData.user.surname,
      },
      {
        id: "jobTitle",
        label: t("Register.labels.jobTitle"),
        value: userData.user.jobTitle,
      },
      {
        id: "country",
        label: t("AdminConsole.Companies.labels.country"),
        value: userData.user.country,
      },
      {
        id: "mobileNumber",
        label: t("common.labels.mobileNumber"),
        value: userData.user.mobileNumber,
      },
      {
        id: "alternateNumber",
        label: t("common.labels.alternativeNumber"),
        value: userData.user.alternateNumber,
      },
      {
        id: "dateOfBirth",
        label: t("common.labels.dateOfBirth"),
        value: userData.user.dateOfBirth
          ? moment(userData.user.dateOfBirth).format(dateISOFormat)
          : "",
      },
      {
        id: "profilePicture",
        label: t("AdminConsole.Users.labels.profilePicture"),
        value: (
          <UserProfileButton url={userData.user.profilePicture ?? undefined} />
        ),
      },
    ];
  }, [userData, companyData, t]);

  return (
    <Box display="flex" flexDirection="column" position="relative">
      <UserDetailsHeader
        user={(userData?.user as User) ?? undefined}
        companyName={companyData?.company?.registeredName}
        generalLoading={
          getUserDataLoading ||
          getCompanyDataLoading ||
          changeUserStatusLoading ||
          editUserLoading
        }
        onStatusChange={handleChangeUserStatus}
        onResendRegisterInvitation={noop}
      />
      <CollapsibleHeader
        title={t("AdminConsole.Users.labels.userDetails")}
        collapsed={!showDetails}
        icon={
          <UserCircle
            size={22}
            weight="fill"
            color={theme.palette.primary.main}
          />
        }
        onToggleCollapse={() => setShowDetails((state) => !state)}
        withShadow={false}
        editable
        editMode={!readOnly}
        onCancel={handleCancel}
        onEdit={() => setReadOnly(false)}
        onSaveActionLoading={editUserLoading}
        onSave={handleSaveUserUpdates}
      />
      <Collapse in={showDetails}>
        <PageContentContainer>
          {readOnly ? (
            <DetailsList
              loading={getUserDataLoading || getCompanyDataLoading}
              entity={userEntityObj}
            />
          ) : getUserDataLoading || getCompanyDataLoading ? null : (
            <UserForm
              readOnly={readOnly || !userData?.user?.registered}
              companyName={companyData?.company?.registeredName}
              user={(userData?.user as User) ?? undefined}
              onChange={handleUserUpdate}
              loading={
                getUserDataLoading ||
                getCompanyDataLoading ||
                changeUserStatusLoading ||
                editUserLoading
              }
              ref={userFormRef}
            />
          )}
        </PageContentContainer>
      </Collapse>
      {projects && roles ? (
        <UserRoles
          roles={roles}
          projects={projects}
          user={userData?.user as User}
          loading={
            userRolesLoading ||
            addUserRoleLoading ||
            editUserRoleLoading ||
            changeUserRoleStatusLoading
          }
          onRoleStatusChange={changeUserRoleStatus}
          onRoleAdd={addUserRole}
          onRoleEdit={editUserRole}
        />
      ) : (
        <Box mt={6}>
          <CenteredLoadingIndicator />
        </Box>
      )}
    </Box>
  );
};
