import { Stack, Typography, useTheme } from "@mui/material";
import { Attachments } from "containers/Projects/components/Attachments/Attachments";
import {
  AttachmentsDencity,
  EnhancedAttachment,
} from "containers/Projects/components/Attachments/Attachments.decl";
import { useAttachments } from "containers/Projects/components/Attachments/hooks/useAttachments";
import { useImagePreviewModal } from "containers/Projects/components/Attachments/hooks/useImagePreviewModal";
import { ReasonSummary } from "containers/Projects/components/ActionModal/ReasonSummary";
import { PhotoAttachmentPreviewModal } from "containers/Projects/components/PhotoAttachmentPreviewModal/PhotoAttachmentPreviewModal";
import { useTranslation } from "react-i18next";
import { Warning } from "phosphor-react";

type InstructWorkToCommenceSummaryProps = {
  text: string;
  noAgreement?: boolean;
  pastWording?: boolean;
  attachments: EnhancedAttachment[];
};

export const InstructWorkToCommenceSummary: React.FC<
  InstructWorkToCommenceSummaryProps
> = ({ text, noAgreement, pastWording, attachments }) => {
  const { downloadAttachment } = useAttachments(attachments);
  const { t } = useTranslation();
  const theme = useTheme();

  const {
    imageAttachmentPreviewModalVisible,
    imagePreviewData,
    previewUrl,
    handleAttachmentClick,
    closeModal: closeImagePreviewModal,
  } = useImagePreviewModal(downloadAttachment);
  return (
    <>
      <PhotoAttachmentPreviewModal
        open={imageAttachmentPreviewModalVisible}
        attachment={imagePreviewData?.attachment}
        creatorName={imagePreviewData?.creatorName}
        creatorCompany={imagePreviewData?.creatorCompany}
        previewUrl={previewUrl}
        onClose={closeImagePreviewModal}
        onDownload={downloadAttachment}
      />
      <Stack spacing={1}>
        {(pastWording || noAgreement) && (
          <Stack direction="row" spacing={0.5} alignItems="center">
            <Warning size={16} color={theme.palette.warning.main} />
            <Typography variant="caption" color={theme.palette.grey[600]}>
              {pastWording
                ? t("Projects.Variations.noAgreementForVOPast", {
                    value: t(`common.labels.${noAgreement ? "no" : "yes"}`),
                  })
                : t("Projects.Variations.noAgreementForVO")}
            </Typography>
          </Stack>
        )}

        <ReasonSummary reason={text} label={t("common.labels.remarks")} />
        {attachments && attachments.length > 0 && (
          <Stack>
            <Typography variant="p2" color="grey.800" fontWeight={600}>
              {t("Attachments.attachments")}
            </Typography>
            <Attachments
              editMode={false}
              showTabs={false}
              hideTitle
              hideHeader
              dencity={AttachmentsDencity.Compact}
              attachments={attachments}
              onAttachmentClick={handleAttachmentClick}
            />
          </Stack>
        )}
      </Stack>
    </>
  );
};
