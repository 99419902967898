import {
  Box,
  BoxProps,
  css,
  IconButton,
  IconButtonProps,
  styled,
  Typography,
  useTheme,
} from "@mui/material";
import { ItemTag } from "components/ItemTag";
import { dateISOFormat } from "../../../../../constants";
import { ProductType } from "generated/graphql";
import moment from "moment";
import { CaretLeft, CaretRight } from "phosphor-react";
import { dailyDiaryDateFormat } from "../DailyDiary.constants";

export type DailyDiaryNavigatorProps = {
  date: string;
  onPreviousClick: () => void;
  onNextClick: () => void;
};

const NavigateButton: React.FC<
  { direction: "next" | "previous" } & IconButtonProps
> = ({ direction, onClick, disabled, ...btnProps }) => {
  const theme = useTheme();

  return (
    <IconButton
      onClick={onClick}
      disabled={disabled}
      style={{
        background: "transparent",
        cursor: "pointer",
        border: `1px solid ${theme.palette.grey[100]}`,
        borderRadius: "8px",
      }}
      {...btnProps}
      size="small"
    >
      {direction === "next" ? (
        <CaretRight
          size={22}
          color={
            disabled ? theme.palette.action.disabled : theme.palette.grey[800]
          }
        />
      ) : (
        <CaretLeft
          size={22}
          color={
            disabled ? theme.palette.action.disabled : theme.palette.grey[800]
          }
        />
      )}
    </IconButton>
  );
};

const DateContainer = styled(Box)(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    padding: ${theme.spacing(1)} ${theme.spacing(2)};
    border: 1px solid ${theme.palette.grey[200]};
    border-radius: 8px;
  `
);

export const DailyDiaryNavigator: React.FC<
  DailyDiaryNavigatorProps & BoxProps
> = ({ date, onPreviousClick, onNextClick, ...restBoxProps }) => {
  const today = moment(new Date()).format(dateISOFormat);
  const ddDate = moment(date).format(dateISOFormat);

  return (
    <Box display="flex" alignItems="center" {...restBoxProps}>
      <NavigateButton direction="previous" onClick={onPreviousClick} />
      <DateContainer mx={2}>
        <ItemTag type={ProductType.DailyDiary} />
        <Typography
          variant="h3"
          fontSize="20px"
          fontWeight={600}
          color="grey.800"
          lineHeight="28px"
          ml={2}
        >
          {moment(date).format(dailyDiaryDateFormat)}
        </Typography>
      </DateContainer>
      <NavigateButton
        direction="next"
        onClick={onNextClick}
        disabled={today === ddDate}
      />
    </Box>
  );
};
