import {
  Tooltip,
  TooltipProps,
  Typography,
  TypographyProps,
} from "@mui/material";
import { tooltipEnterDelay } from "../constants";
import React, { useRef, useState, useLayoutEffect } from "react";

export type OverflowTooltipProps = {
  typographyProps?: TypographyProps;
} & Omit<TooltipProps, "children">;

export const OverflowTooltip: React.FC<OverflowTooltipProps> = ({
  typographyProps,
  title,
  ...tooltipProps
}) => {
  const [isOverflowed, setIsOverflow] = useState(false);
  const textElementRef = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    setIsOverflow(
      Number(textElementRef.current?.scrollWidth) >
        Number(textElementRef.current?.clientWidth)
    );
  }, []);

  return (
    <Tooltip
      disableHoverListener={!isOverflowed}
      enterDelay={tooltipEnterDelay}
      PopperProps={{ style: { zIndex: 9999999 } }}
      title={
        typeof title === "string" ? (
          <Typography variant="p2">{title}</Typography>
        ) : (
          title
        )
      }
      {...tooltipProps}
    >
      <Typography
        ref={textElementRef}
        {...typographyProps}
        textOverflow="ellipsis"
        overflow="hidden"
        noWrap
      >
        {title}
      </Typography>
    </Tooltip>
  );
};
