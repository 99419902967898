import { Box, Collapse } from "@mui/material";
import {
  GridCellParams,
  GridFilterModel,
  GridLogicOperator,
  GridRowSelectionModel,
  GridSortModel,
} from "@mui/x-data-grid-pro";
import { StyledDataGrid } from "components/StyledDataGrid";
import { useContractStatusOptions } from "components/StatusTag/useContractStatusOptions";
import { Contract, ContractStatus } from "generated/graphql";
import { exportToExcel } from "helpers/exportToExcel";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useColumns } from "./CompanyContracts.constants";
import { CollapsibleHeader } from "components/CollapsibleHeader";
import { ContractsIcon } from "components/Icons/ContractsIcon";
import { useDataGridVisibleRows } from "hooks/useDataGridVisibleRows";
import { StatusOption } from "components/StatusTag/StatusTag";

export type CompanyContractsProps = {
  contracts: Contract[];
  companyName?: string;
  loading?: boolean;
  onContractStatusChange: (row: Contract, newStatus: ContractStatus) => void;
};

export const CompanyContracts: React.FC<CompanyContractsProps> = ({
  contracts,
  companyName,
  loading,
  onContractStatusChange,
}) => {
  const { t } = useTranslation();
  const contractStatusOptions =
    useContractStatusOptions() as StatusOption<ContractStatus>[];

  const { visibleRowsCount, gridApiRef } = useDataGridVisibleRows();
  const [showContracts, setShowContracts] = useState(false);
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>();
  const [sortingModel, setSortingModel] = useState<GridSortModel>([
    { field: "friendlyName", sort: "asc" },
  ]);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [
      {
        field: "status",
        operator: "isAnyOf",
        value: [
          ContractStatus.Active,
          ContractStatus.Closed,
          ContractStatus.Offline,
        ],
      },
    ],
    logicOperator: GridLogicOperator.And,
    quickFilterLogicOperator: GridLogicOperator.And,
    quickFilterValues: [],
  });

  const handleGridRowSelectionModelChange = useCallback(
    (selectionModel: GridRowSelectionModel) => {
      setSelectionModel(selectionModel);
    },
    []
  );

  const handleExportToExcel = () => {
    const columns = [
      {
        header: t("AdminConsole.Contracts.labels.contract"),
        key: "friendlyName",
        width: 20,
      },
      { header: t("common.labels.status"), key: "status", width: 20 },
      {
        header: t("AdminConsole.Projects.labels.project"),
        key: "project",
        width: 20,
      },
      { header: t("common.labels.dateCreated"), key: "dateCreated", width: 20 },
    ];

    const rows = contracts
      .filter((contract) => (selectionModel || []).indexOf(contract.id) >= 0)
      .map((contract) => ({
        ...contract,
        project: contract.project.friendlyName,
        dateCreated: contract.dateCreated ? new Date(contract.dateCreated) : "",
      }));

    exportToExcel(
      `${companyName}-${t("AdminConsole.Contracts.labels.contracts")}`,
      columns,
      rows
    );
  };

  const columns = useColumns(contractStatusOptions, onContractStatusChange);

  return (
    <Box>
      <CollapsibleHeader
        title={t("AdminConsole.Contracts.labels.contracts")}
        visibleRowsCount={visibleRowsCount || 0}
        selectedCount={selectionModel?.length || 0}
        onExportToExcel={handleExportToExcel}
        onToggleCollapse={() => setShowContracts((state) => !state)}
        collapsed={!showContracts}
        icon={<ContractsIcon />}
      />
      <Collapse in={showContracts}>
        <Box sx={{ maxHeight: 600, width: "100%", overflowY: "auto" }}>
          <StyledDataGrid
            apiRef={gridApiRef}
            rows={contracts}
            columns={columns}
            getRowId={(rowData: Contract) => rowData.id}
            onRowSelectionModelChange={handleGridRowSelectionModelChange}
            loading={loading}
            sortingMode="client"
            sortModel={sortingModel}
            onSortModelChange={setSortingModel}
            filterMode="client"
            filterModel={filterModel}
            onFilterModelChange={setFilterModel}
            getCellClassName={(params: GridCellParams<any, Contract, any>) => {
              return [ContractStatus.Removed, ContractStatus.Closed].indexOf(
                params.row.status
              ) >= 0
                ? "greyed-out"
                : "";
            }}
            checkboxSelection
            disableRowSelectionOnClick
            autoHeight
          />
        </Box>
      </Collapse>
    </Box>
  );
};
