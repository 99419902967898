import { ActionsDialogProps } from "components/ActionsDialog";
import { RecordProductItemExtraData } from "containers/Projects/components/RecordProductItemForm";
import { ClaimActionType } from "generated/graphql";
import { RecordAction } from "../components/RecordAction";
import { useRecordClaimNoAgreementNoticeModal } from "./useRecordClaimNoAgreementNoticeModal";
import { ReasonsAttachmentsSummary } from "../../../ActionModal/ReasonsAttachmentsSummary";
import { EnhancedAttachment } from "containers/Projects/components/Attachments/Attachments.decl";

export type RecordClaimNoAgreementNoticeModalProps = {
  remarks: string;
  attachments?: EnhancedAttachment[];
  onPrimaryClick: (extraData: RecordProductItemExtraData) => void;
} & Omit<ActionsDialogProps, "onPrimaryClick">;

export const RecordClaimNoAgreementNoticeModal: React.FC<
  RecordClaimNoAgreementNoticeModalProps
> = ({ remarks, attachments = [], ...restDialogProps }) => {
  const { claimPreview } = useRecordClaimNoAgreementNoticeModal(remarks);
  return (
    <RecordAction
      actionType={ClaimActionType.NotifyAgreementClaimNotReached}
      claimPreview={claimPreview}
      summaryEl={
        <ReasonsAttachmentsSummary text={remarks} attachments={attachments} />
      }
      {...restDialogProps}
    />
  );
};
