import { ThemeProvider } from "@mui/material";
import { CenteredLoadingIndicator } from "components/CenteredLoadingIndicator";
import { NewPageContentContainer } from "components/NewPageContentContainer";
import {
  EarlyWarningItem,
  EventItem,
  GetProductInstanceStatusCollectionQuery,
  GetProductInstanceStatusCollectionQueryVariables,
  InstructionItem,
  ItemStatusOption,
  ProductType,
  RiskItem,
} from "generated/graphql";
import { getNewProductItemPath } from "helpers/paths/pathConstructors";
import { NewAppPaths } from "helpers/paths/paths";
import { useGraphLazyQuery } from "hooks/useGraphLazyQuery";
import {
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo,
  useContext,
} from "react";
import { matchPath, Outlet, useNavigate } from "react-router-dom";
import { extendedTheme } from "theme/extendedTheme";
import { CompEventsView } from "./components/CompEvents/CompEventsView/CompEventsView";
import { DailyDiariesView } from "./components/DailyDiariesView/DailyDiariesView";
import { DeleteProductItemDraftConfirmModal } from "./components/DeleteProductItemDraftConfirmModal/DeleteProductItemDraftConfirmModal";
import { useDeleteProductItemDraftConfirmModal } from "./components/DeleteProductItemDraftConfirmModal/useDeleteProductItemDraftConfirmModal";
import { EmptyView } from "./components/EmptyView";
import { Explorer } from "./components/Explorer/Explorer";
import { ProductItemsView } from "./components/ProductItemsView/ProductItemsView";
import { useCompEventsRegister } from "./hooks/useCompEventsRegister";
import { useEarlyWarningsRegister } from "./hooks/useEarlyWarningsRegister";
import { useEventsRegister } from "./hooks/useEventsRegister";
import { useInstructionsRegister } from "./hooks/useInstructionsRegister";
import { useRisksRegister } from "./hooks/useRisksRegister";
import { ProductTypenameToProduct } from "./Projects.constants";
import { ProductItem } from "./Projects.decl";
import { getProductInstanceStatusCollectionQuery } from "./Projects.query";
import { ExplorerContainer } from "./Projects.styled";
import {
  ExplorerContext,
  ExplorerContextProvider,
} from "./components/Explorer/Explorer.context";
import { ClaimsView } from "./components/Claims/ClaimsView/ClaimsView";
import { useClaimsRegister } from "./hooks/useClaimsRegister/useClaimsRegister";
import { VariationsView } from "./components/Variations/VariationsView/VariationsView";
import { useVariationsRegister } from "./hooks/useVariationsRegister/useVariationsRegister";

export const ProjectsInner = () => {
  const navigate = useNavigate();
  const draftIdToDelete = useRef("");

  const isProjectsHome = !!matchPath(
    NewAppPaths.authorized.Projects,
    window.location.pathname
  );

  const {
    projectId: selectedProjectId,
    contractId: selectedContractId,
    productId: selectedProductId,
    productInstanceId: selectedProductInstanceId,
    loading: explorerDataLoading,
    changeProject: handleProjectSelectionChange,
    changeContract: handleContractSelectionChange,
    changeProduct: handleProductSelectionChange,
    changeProductInstance: handleProductInstanceSelectionChange,
  } = useContext(ExplorerContext);

  const [productItemType, setProductItemType] = useState<ProductType>();

  const {
    riskItemsData,
    draftRiskItemsData,
    risksLoading,
    fetchRiskItemsData,
    fetchDraftRiskItemsData,
  } = useRisksRegister();

  const {
    ewItemsData,
    draftEWItemsData,
    ewsLoading,
    fetchEWItemsData,
    fetchDraftEWItemsData,
  } = useEarlyWarningsRegister();

  const {
    eventItemsData,
    draftEventItemsData,
    eventsLoading,
    fetchEventItemsData,
    fetchDraftEventItemsData,
  } = useEventsRegister();

  const {
    instructionItemsData,
    draftInstructionItemsData,
    instructionsLoading,
    fetchInstructionItemsData,
    fetchDraftInstructionItemsData,
  } = useInstructionsRegister();

  const {
    compEventItemsData,
    compEventItemsDataLoading,
    fetchCompEventItemsData,
  } = useCompEventsRegister();

  const { claimItemsData, claimItemsDataLoading, fetchClaimItemsData } =
    useClaimsRegister();

  const {
    variationItemsData,
    variationItemsDataLoading,
    fetchVariationItemsData,
  } = useVariationsRegister();

  const [
    fetchProductInstanceStatusCollectionData,
    {
      data: productInstanceStatusCollectionData,
      loading: productInstanceStatusCollectionDataLoading,
    },
  ] = useGraphLazyQuery<
    GetProductInstanceStatusCollectionQuery,
    GetProductInstanceStatusCollectionQueryVariables
  >(getProductInstanceStatusCollectionQuery);

  const {
    modalVisible: deleteProductItemDraftModalVisible,
    setModalVisible: setDeleteProductItemDraftModalVisible,
    closeModal: closeDeleteProductItemDraftModal,
    handleCancel: handleDeleteProductItemDraftModalCancel,
    handleConfirm: handleDeleteProductItemDraftModalConfirm,
    loading: deleteProductItemDraftLoading,
  } = useDeleteProductItemDraftConfirmModal();

  const handleAddNewProductItem = () => {
    if (
      selectedProjectId &&
      selectedContractId &&
      selectedProductInstanceId &&
      selectedProductId
    ) {
      navigate(
        getNewProductItemPath({
          projectId: selectedProjectId,
          contractId: selectedContractId,
          productId: selectedProductId,
          productInstanceId: selectedProductInstanceId,
          productType: productItemType!,
        })
      );
    }
  };

  const handleDeleteProductItemDraft = async (draftId: string) => {
    draftIdToDelete.current = draftId;
    setDeleteProductItemDraftModalVisible(true);
  };

  const handleDraftProductItemNavigation = (draftId: string) => {
    if (
      selectedProjectId &&
      selectedContractId &&
      selectedProductInstanceId &&
      selectedProductId
    ) {
      navigate(
        getNewProductItemPath({
          projectId: selectedProjectId,
          contractId: selectedContractId,
          productId: selectedProductId,
          productInstanceId: selectedProductInstanceId,
          productType: productItemType!,
          draftId,
        })
      );
    }
  };

  const fetchTableData = useCallback(
    async (selectedProductInstanceId: string) => {
      if (!isProjectsHome) {
        // the user is not on any register page, thus no need to fetch registers' data
        return;
      }

      const { data } = await fetchProductInstanceStatusCollectionData({
        variables: {
          id: selectedProductInstanceId,
        },
      });

      const productItemType =
        ProductTypenameToProduct[data!.productInstance.product.__typename!];
      setProductItemType(productItemType);

      switch (productItemType) {
        case ProductType.RisksRegister: {
          fetchRiskItemsData({
            variables: {
              productInstanceId: selectedProductInstanceId,
            },
          });
          fetchDraftRiskItemsData({
            variables: {
              productInstanceId: selectedProductInstanceId,
            },
          });
          break;
        }
        case ProductType.EarlyWarnings: {
          fetchEWItemsData({
            variables: {
              productInstanceId: selectedProductInstanceId,
            },
          });
          fetchDraftEWItemsData({
            variables: {
              productInstanceId: selectedProductInstanceId,
            },
          });
          break;
        }
        case ProductType.Events: {
          fetchEventItemsData({
            variables: {
              productInstanceId: selectedProductInstanceId,
            },
          });
          fetchDraftEventItemsData({
            variables: {
              productInstanceId: selectedProductInstanceId,
            },
          });
          break;
        }
        case ProductType.Instructions: {
          fetchInstructionItemsData({
            variables: {
              productInstanceId: selectedProductInstanceId,
            },
          });
          fetchDraftInstructionItemsData({
            variables: {
              productInstanceId: selectedProductInstanceId,
            },
          });
          break;
        }
        case ProductType.CompEvents: {
          fetchCompEventItemsData({
            variables: { productInstanceId: selectedProductInstanceId },
          });
          break;
        }
        case ProductType.Claims: {
          fetchClaimItemsData({
            variables: { productInstanceId: selectedProductInstanceId },
          });
          break;
        }
        case ProductType.Variations: {
          fetchVariationItemsData({
            variables: { productInstanceId: selectedProductInstanceId },
          });
          break;
        }
      }
    },
    [
      isProjectsHome,
      fetchProductInstanceStatusCollectionData,
      fetchRiskItemsData,
      fetchDraftRiskItemsData,
      fetchEWItemsData,
      fetchDraftEWItemsData,
      fetchEventItemsData,
      fetchDraftEventItemsData,
      fetchInstructionItemsData,
      fetchDraftInstructionItemsData,
      fetchCompEventItemsData,
      fetchClaimItemsData,
      fetchVariationItemsData,
    ]
  );

  const productItems = useMemo((): ProductItem[] => {
    switch (productItemType) {
      case ProductType.RisksRegister:
        return (riskItemsData?.riskItems.items as RiskItem[]) || [];
      case ProductType.EarlyWarnings:
        return (
          (ewItemsData?.earlyWarningItems.items as EarlyWarningItem[]) || []
        );
      case ProductType.Events:
        return (eventItemsData?.eventItems.items as EventItem[]) || [];
      case ProductType.Instructions:
      default:
        return (
          (instructionItemsData?.instructionItems.items as InstructionItem[]) ||
          []
        );
    }
  }, [
    riskItemsData,
    eventItemsData,
    ewItemsData,
    instructionItemsData,
    productItemType,
  ]);

  const draftProductItems = useMemo((): ProductItem[] => {
    if (productItemType === ProductType.RisksRegister) {
      return (draftRiskItemsData?.draftRiskItems.items as RiskItem[]) || [];
    } else if (productItemType === ProductType.EarlyWarnings) {
      return (
        (draftEWItemsData?.draftEarlyWarningItems
          .items as EarlyWarningItem[]) || []
      );
    } else if (productItemType === ProductType.Events) {
      return (draftEventItemsData?.draftEventItems.items as EventItem[]) || [];
    }

    return (
      (draftInstructionItemsData?.draftInstructionItems
        .items as InstructionItem[]) || []
    );
  }, [
    draftRiskItemsData,
    draftEWItemsData,
    draftEventItemsData,
    draftInstructionItemsData,
    productItemType,
  ]);

  useEffect(() => {
    if (selectedProductInstanceId) {
      fetchTableData(selectedProductInstanceId);
    }
    setProductItemType(undefined);
  }, [selectedProductInstanceId, fetchTableData]);

  const loading = useMemo(
    () =>
      productInstanceStatusCollectionDataLoading ||
      risksLoading ||
      ewsLoading ||
      eventsLoading ||
      instructionsLoading ||
      deleteProductItemDraftLoading,
    [
      productInstanceStatusCollectionDataLoading,
      risksLoading,
      ewsLoading,
      eventsLoading,
      instructionsLoading,
      deleteProductItemDraftLoading,
    ]
  );

  return (
    <ThemeProvider
      theme={(outerTheme) => ({
        ...outerTheme,
        ...extendedTheme,
      })}
    >
      <DeleteProductItemDraftConfirmModal
        open={deleteProductItemDraftModalVisible}
        onClose={closeDeleteProductItemDraftModal}
        onPrimaryClick={() =>
          handleDeleteProductItemDraftModalConfirm(
            draftIdToDelete.current,
            productItemType!
          )
        }
        onSecondaryClick={handleDeleteProductItemDraftModalCancel}
        primaryBtnLoading={deleteProductItemDraftLoading}
      />
      <ExplorerContainer>
        <Explorer
          selectedProjectId={selectedProjectId}
          selectedContractId={selectedContractId}
          selectedProductId={selectedProductId}
          selectedProductInstanceId={selectedProductInstanceId}
          loading={explorerDataLoading}
          onProjectSelectionChange={handleProjectSelectionChange}
          onContractSelectionChange={handleContractSelectionChange}
          onProductSelectionChange={handleProductSelectionChange}
          onProductInstanceSelectionChange={
            handleProductInstanceSelectionChange
          }
        />
      </ExplorerContainer>
      <Outlet />
      {isProjectsHome && (
        <NewPageContentContainer
          overflow={
            productItemType === ProductType.DailyDiary ? "hidden" : "unset"
          }
        >
          {selectedProductInstanceId ? (
            productItemType ? (
              productItemType === ProductType.CompEvents ? (
                <CompEventsView
                  compEvents={compEventItemsData}
                  loading={compEventItemsDataLoading}
                />
              ) : productItemType === ProductType.DailyDiary ? (
                <DailyDiariesView />
              ) : productItemType === ProductType.Claims ? (
                <ClaimsView
                  claims={claimItemsData}
                  loading={claimItemsDataLoading}
                />
              ) : productItemType === ProductType.Variations ? (
                <VariationsView
                  variations={variationItemsData}
                  loading={variationItemsDataLoading}
                />
              ) : (
                <ProductItemsView
                  productItems={productItems}
                  draftProductItems={draftProductItems}
                  statusOptions={
                    (productInstanceStatusCollectionData?.productInstance
                      .statusCollection.statusOptions
                      .items as ItemStatusOption[]) || []
                  }
                  loading={loading}
                  productType={productItemType}
                  onAddNewProductItem={handleAddNewProductItem}
                  onDeleteDraft={handleDeleteProductItemDraft}
                  onNavigateToProductItemDraft={
                    handleDraftProductItemNavigation
                  }
                />
              )
            ) : (
              <CenteredLoadingIndicator />
            )
          ) : (
            <EmptyView />
          )}
        </NewPageContentContainer>
      )}
    </ThemeProvider>
  );
};

export const Projects: React.FC = () => (
  <ExplorerContextProvider>
    <ProjectsInner />
  </ExplorerContextProvider>
);
