import {
  ClaimFurtherParticularsRequestPromptQuery,
  ClaimFurtherParticularsRequestPromptQueryVariables,
  RecordClaimFurtherParticularsRequestMutation,
  RecordClaimFurtherParticularsRequestMutationVariables,
  SendClaimFurtherParticularsRequestMutation,
  SendClaimFurtherParticularsRequestMutationVariables,
} from "generated/graphql";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { ClaimWidgetContext } from "../../ClaimWidget/ClaimWidget.context";
import {
  claimFurtherParticularsRequestPromptQuery,
  recordClaimFurtherParticularsRequestMutation,
  sendClaimFurtherParticularsRequestMutation,
} from "./RequestFurtherParticularsAction.query";
import { OnMutationDone } from "decl";
import { useGraphQuery } from "hooks/useGraphQuery";

export const useRequestFurtherParticularsAction = () => {
  const { t } = useTranslation();
  const { onChange, claim, isFIDIC99RedYellow } =
    useContext(ClaimWidgetContext);

  const handleOnUpdate: OnMutationDone = (cache) => {
    cache.evict({
      id: "ROOT_QUERY",
      fieldName: "claimItems",
    });
    cache.evict({
      id: "ROOT_QUERY",
      fieldName: "claimHistoryItems",
      args: { claimId: claim!.id },
    });
    cache.evict({
      id: "ROOT_QUERY",
      fieldName: "claimDetailsExplorerItems",
      args: { claimId: claim!.id },
    });
    cache.evict({
      id: "ROOT_QUERY",
      fieldName: "claimAction",
    });
    cache.gc();
    onChange?.();
  };

  const { data: promptData, loading } = useGraphQuery<
    ClaimFurtherParticularsRequestPromptQuery,
    ClaimFurtherParticularsRequestPromptQueryVariables
  >(claimFurtherParticularsRequestPromptQuery, {
    variables: {
      input: {
        claimId: claim!.id,
      },
    },
    skip: !claim,
  });

  const [
    sendFurtherParticularsRequest,
    { loading: sendFurtherParticularsRequestLoading },
  ] = useGraphMutation<
    SendClaimFurtherParticularsRequestMutation,
    SendClaimFurtherParticularsRequestMutationVariables
  >(
    sendClaimFurtherParticularsRequestMutation,
    {
      update: handleOnUpdate,
    },
    t(
      `Projects.Claims.successMessages.additionalParticularsRequestSent${
        isFIDIC99RedYellow ? "99RY" : ""
      }`
    )
  );

  const [
    recordFurtherParticularsRequest,
    { loading: recordFurtherParticularsRequestLoading },
  ] = useGraphMutation<
    RecordClaimFurtherParticularsRequestMutation,
    RecordClaimFurtherParticularsRequestMutationVariables
  >(
    recordClaimFurtherParticularsRequestMutation,
    {
      update: handleOnUpdate,
    },
    t(
      `Projects.Claims.successMessages.additionalParticularsRequestRecord${
        isFIDIC99RedYellow ? "99RY" : ""
      }`
    )
  );

  return {
    reasons: promptData?.claimFurtherParticularsRequestPrompt.reasons,
    loading,
    actionLoading:
      sendFurtherParticularsRequestLoading ||
      recordFurtherParticularsRequestLoading,
    sendFurtherParticularsRequest,
    recordFurtherParticularsRequest,
  };
};
