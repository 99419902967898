import {
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { FormPublicApi } from "decl";
import { AddLookupCollectionInput, LookupCollection } from "generated/graphql";
import { validateData } from "helpers/validators";
import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { dataValidators } from "./LookupCollection.constants";

export type LookupCollectionFormProps = {
  lookupCollection?: LookupCollection;
  onChange?: (
    lookupCollectionData: AddLookupCollectionInput | LookupCollection
  ) => void;
};

export const defaultLookupCollection: AddLookupCollectionInput = {
  name: "",
  optionsLockedForUsers: true,
  optionsCharLengthLimit: "" as any,
};

export const LookupCollectionForm = React.forwardRef(
  (
    { lookupCollection, onChange }: LookupCollectionFormProps,
    ref: React.Ref<FormPublicApi>
  ) => {
    const { t } = useTranslation();

    const [formData, setFormData] = useState<
      AddLookupCollectionInput | LookupCollection
    >(lookupCollection || defaultLookupCollection);
    const [formDataErrors, setFormDataErrors] = useState<{
      [key: string]: string;
    }>({});

    const handleTextFieldChange: React.ChangeEventHandler<
      HTMLTextAreaElement | HTMLInputElement
    > = (evt) => {
      setFormData((curData) => ({
        ...curData,
        [evt.target.name]: evt.target.value,
      }));

      setFormDataErrors((curFormDataErrs) => {
        const { [evt.target.name]: _, ...rest } = curFormDataErrs;

        return rest;
      });
    };

    const handleCheckboxChange = (
      evt: React.ChangeEvent<HTMLInputElement>,
      checked: boolean
    ) => {
      setFormData((curData) => ({
        ...curData,
        [evt.target.name]: checked,
      }));
    };

    const validateForm = useCallback(
      (formData: AddLookupCollectionInput | LookupCollection) => {
        const validationResult = validateData(formData, dataValidators);

        if (validationResult.valid) {
          setFormDataErrors({});
          return true;
        }
        setFormDataErrors(validationResult.errors);
        return false;
      },
      []
    );

    const reset = useCallback(() => {
      setFormData(defaultLookupCollection);
    }, []);

    useEffect(() => {
      onChange?.({
        ...formData,
        optionsCharLengthLimit: formData.optionsCharLengthLimit || undefined,
      });
    }, [formData, onChange]);

    useEffect(() => {
      setFormData(lookupCollection || defaultLookupCollection);
    }, [lookupCollection]);

    useImperativeHandle(
      ref,
      () => ({
        validate: () => validateForm(formData),
        reset,
      }),
      [validateForm, formData, reset]
    );

    return (
      <>
        <Grid container spacing={6}>
          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              name="name"
              value={formData.name}
              onChange={handleTextFieldChange}
              type="text"
              label={t("common.labels.name")}
              variant="standard"
              error={!!formDataErrors.name}
              helperText={formDataErrors.name}
              InputLabelProps={{ shrink: true }}
              required
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <FormControlLabel
              label={
                <Typography variant="caption">
                  {`${t("AdminConsole.Lookups.optionsLockedForUsers")}`}
                </Typography>
              }
              labelPlacement="end"
              control={
                <Checkbox
                  inputProps={{
                    "aria-label": `${t(
                      "AdminConsole.Lookups.optionsLockedForUsers"
                    )}`,
                  }}
                  name="optionsLockedForUsers"
                  onChange={handleCheckboxChange}
                  checked={!!formData.optionsLockedForUsers}
                  required
                />
              }
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              type="number"
              name="optionsCharLengthLimit"
              value={formData.optionsCharLengthLimit}
              onChange={handleTextFieldChange}
              label={t("AdminConsole.Lookups.optionsCharLengthLimit")}
              variant="standard"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>
      </>
    );
  }
);
