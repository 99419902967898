import { gql } from "@apollo/client";
import { attachmentLiteFields } from "./attachmentLite.fragment";
import { contractBindingTypeFields } from "./contractBindingType.fragment";
import { creatorFields } from "./creator.fragment";
import { authorizationWorkflowAuditFields } from "./authorizationWorkflowAudit.fragment";
import { variationProposalLiteFields } from "./variationProposalLite.fragment";
import { variationAgreementLiteFields } from "./variationAgreementLite.fragment";
import { variationDeterminationLiteFields } from "./variationDeterminationLite.fragment";
import { draftVariationDeterminationFields } from "./draftVariationDetermination.fragment";
import { draftVariationAgreementFields } from "./draftVariationAgreement.fragment";

export const variationHistoryItemFields = gql`
  ${creatorFields}
  ${contractBindingTypeFields}
  ${attachmentLiteFields}
  ${authorizationWorkflowAuditFields}
  ${variationProposalLiteFields}
  ${variationAgreementLiteFields}
  ${draftVariationDeterminationFields}
  ${draftVariationAgreementFields}
  ${variationDeterminationLiteFields}

  fragment VariationHistoryItemFields on VariationHistoryItem {
    id
    variationId
    actionType
    actionNumber
    answers {
      __typename
      ... on VariationHistoryProposalForProposedVariationAnswers {
        proposalId
        proposal {
          ...VariationProposalLiteFields
          attachments {
            ...AttachmentLiteFields
          }
        }
      }

      ... on VariationHistoryProposalForVariationAnswers {
        proposalId
        proposal {
          ...VariationProposalLiteFields
          attachments {
            ...AttachmentLiteFields
          }
        }
      }

      ... on VariationHistoryObjectionProposedVariationAnswers {
        reasons
      }
      ... on VariationHistoryObjectionVariationAnswers {
        reasons
      }
      ... on VariationHistoryConsentForProposedVariationAnswers {
        remarks
        proposalId
        proposal {
          ...VariationProposalLiteFields
          attachments {
            ...AttachmentLiteFields
          }
        }
      }

      ... on VariationHistoryAgreementAnswers {
        agreement {
          ...VariationAgreementLiteFields
          attachments {
            ...AttachmentLiteFields
          }
        }
        draftAgreementId
        draftAgreement {
          ...DraftVariationAgreementFields
          attachments {
            ...AttachmentLiteFields
          }
        }
      }

      ... on VariationHistoryNoAgreementReachedAnswers {
        remarks
      }
      ... on VariationHistoryRevisedProposalRequestAnswers {
        remarks
      }
      ... on VariationHistoryAbandonmentAnswers {
        remarks
      }
      ... on VariationHistoryRevocationVariationAnswers {
        remarks
      }
      ... on VariationHistoryConfirmationVariationAnswers {
        remarks
      }
      ... on VariationHistoryDeterminationAnswers {
        determination {
          ...VariationDeterminationLiteFields
          attachments {
            ...AttachmentLiteFields
          }
        }
        draftDeterminationId
        draftDetermination {
          ...DraftVariationDeterminationFields
          attachments {
            ...AttachmentLiteFields
          }
        }
      }
      ... on VariationHistoryInstructionCommenceWorkAnswers {
        remarks
        agreementSubmitted
      }
    }
    attachments {
      ...AttachmentLiteFields
    }
    recipients {
      userId
      name
      preference {
        inApp
        email
      }
    }
    dateCreated
    dateSent
    dueDate
    sentByUserId
    sentByPartyId
    offline
    daysLate
    isIncomplete
    authorizationWorkflowAudit {
      ...AuthorizationWorkflowAuditFields
    }
    sentByUser {
      ...CreatorFields
    }
    sentByParty {
      ...ContractBindingTypeFields
    }
  }
`;
