import { gql } from "@apollo/client";
import { creatorFields } from "graphql/fragments/creator.fragment";
import { itemStatusOptionFields } from "graphql/fragments/itemStatusOption.fragment";
import { itemDataFields } from "graphql/fragments/itemData.fragment";

export const riskItemsQuery = gql`
  ${itemDataFields}
  ${itemStatusOptionFields}
  ${creatorFields}

  query riskItems($productInstanceId: ID!) {
    riskItems(productInstanceId: $productInstanceId) {
      items {
        id
        title
        number
        productInstanceId
        productInstance {
          contract {
            valueCurrency
          }
        }
        ownerId
        statusOptionId
        severity
        data {
          ...ItemDataFields
        }
        dateCreated
        dateModified
        creatorId
        creator {
          ...CreatorFields
        }
        statusOption {
          ...ItemStatusOptionFields
        }
        owner {
          ...CreatorFields
        }
      }
    }
  }
`;

export const getProductInstanceStatusCollectionQuery = gql`
  ${itemStatusOptionFields}

  query getProductInstanceStatusCollection($id: ID!) {
    productInstance(id: $id) {
      id
      description
      product {
        id
        name
      }
      statusCollection {
        id
        name
        status
        statusOptions {
          items {
            ...ItemStatusOptionFields
          }
        }
      }
    }
  }
`;

export const earlyWarningItemsQuery = gql`
  ${itemStatusOptionFields}
  ${creatorFields}
  ${itemDataFields}

  query earlyWarningItems($productInstanceId: ID!) {
    earlyWarningItems(productInstanceId: $productInstanceId) {
      items {
        id
        title
        severity
        data {
          ...ItemDataFields
        }
        number
        productInstanceId
        ownerId
        statusOptionId
        dateCreated
        creatorId
        # TODO: why creator necessary on the Register view?
        creator {
          ...CreatorFields
        }
        statusOption {
          ...ItemStatusOptionFields
        }
        owner {
          ...CreatorFields
        }
        # TODO: why currency necessary on the Register view?
        productInstance {
          contract {
            valueCurrency
          }
        }
        dateModified
        offline
        givenById
        dateSent
      }
    }
  }
`;

export const draftRiskItemsQuery = gql`
  ${itemStatusOptionFields}
  ${creatorFields}
  ${itemDataFields}

  query draftRiskItems($productInstanceId: ID!) {
    draftRiskItems(productInstanceId: $productInstanceId) {
      items {
        id
        title
        number
        productInstanceId
        ownerId
        statusOptionId
        severity
        data {
          ...ItemDataFields
        }
        dateCreated
        dateModified
        creatorId
        creator {
          ...CreatorFields
        }
        statusOption {
          ...ItemStatusOptionFields
        }
        owner {
          ...CreatorFields
        }
      }
    }
  }
`;

export const draftEarlyWarningItemsQuery = gql`
  ${itemStatusOptionFields}
  ${creatorFields}

  query draftEarlyWarningItems($productInstanceId: ID!) {
    draftEarlyWarningItems(productInstanceId: $productInstanceId) {
      items {
        id
        title
        severity
        data {
          sections {
            name
            entries {
              name
              value
            }
          }
        }
        number
        productInstanceId
        ownerId
        statusOptionId
        dateCreated
        creatorId
        creator {
          ...CreatorFields
        }
        statusOption {
          ...ItemStatusOptionFields
        }
        owner {
          ...CreatorFields
        }
        productInstance {
          contract {
            valueCurrency
          }
        }
        dateModified
        offline
        givenById
      }
    }
  }
`;
