import { useCallback, useContext, useEffect, useState } from "react";
import {
  GenerateRecordVariationProposalAbandonmentPreviewMutation,
  GenerateRecordVariationProposalAbandonmentPreviewMutationVariables,
  RecordVariationProposalAbandonmentPreview,
} from "generated/graphql";
import { useGraphMutation } from "hooks/useGraphMutation";
import { debounce } from "@mui/material";
import { eventDebounceDuration } from "../../../../../../constants";
import { VariationWidgetContext } from "../../VariationWidget/VariationWidget.context";
import { generateRecordVariationProposalAbandonmentPreviewMutation } from "./AbandonProposedVariationAction.query";

export const useRecordAbandonProposedVariationModal = (remarks: string) => {
  const { variation } = useContext(VariationWidgetContext);

  const [variationPreview, setVariationPreview] =
    useState<RecordVariationProposalAbandonmentPreview>();

  const [generatePreview] = useGraphMutation<
    GenerateRecordVariationProposalAbandonmentPreviewMutation,
    GenerateRecordVariationProposalAbandonmentPreviewMutationVariables
  >(generateRecordVariationProposalAbandonmentPreviewMutation, {}, null);

  const loadPreview = debounce(
    useCallback(async () => {
      const { data } = await generatePreview({
        variables: {
          input: {
            variationId: variation!.id,
            remarks,
          },
        },
      });

      if (data) {
        setVariationPreview(
          data.generateRecordVariationProposalAbandonmentPreview
        );
      }
    }, [generatePreview, variation, remarks]),
    eventDebounceDuration
  );

  useEffect(() => {
    loadPreview();
    // eslint-disable-next-line
  }, []);

  return {
    variationPreview,
  };
};
