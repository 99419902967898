import {
  ClaimItem,
  CompEventItem,
  InstructionItem,
  VariationItem,
} from "generated/graphql";
import { variationStatusToNextStepWordingMap } from "./VariationWidget/VariationWidget.constants";

export const isVariation = (
  productItem: CompEventItem | ClaimItem | VariationItem
): productItem is VariationItem =>
  !!((productItem as VariationItem).__typename === "VariationItem");

export const getNextStepText = (variation?: VariationItem) => {
  return variation?.nextStep
    ? variationStatusToNextStepWordingMap[variation.nextStep.actionType]
    : null;
};

export const getNextStepDueDate = (
  variation?: VariationItem,
  instructionItem?: InstructionItem
) => {
  return variation
    ? variation.nextStep?.dueDate
    : instructionItem?.notifyDueDate;
};
