import { ActionsDialogProps } from "components/ActionsDialog";
import { RecordProductItemExtraData } from "containers/Projects/components/RecordProductItemForm";
import { VariationActionType } from "generated/graphql";
import { RecordAction } from "../components/RecordAction";
import { useRecordInstructionOfVariationCancelationModal } from "./useRecordInstructionOfVariationCancelationModal";
import { useTranslation } from "react-i18next";
import { ReasonsAttachmentsSummary } from "containers/Projects/components/ActionModal/ReasonsAttachmentsSummary";
import { EnhancedAttachment } from "containers/Projects/components/Attachments/Attachments.decl";

export type RecordInstructionOfVariationCancelationModalProps = {
  remarks: string;
  attachments?: EnhancedAttachment[];
  onPrimaryClick: (extraData: RecordProductItemExtraData) => void;
} & Omit<ActionsDialogProps, "onPrimaryClick">;

export const RecordInstructionOfVariationCancelationModal: React.FC<
  RecordInstructionOfVariationCancelationModalProps
> = ({ remarks, attachments = [], ...restDialogProps }) => {
  const { t } = useTranslation();
  const { variationPreview } =
    useRecordInstructionOfVariationCancelationModal(remarks);

  return (
    <RecordAction
      actionType={VariationActionType.RevokeInstructionOfVariation}
      variationPreview={variationPreview}
      summaryEl={
        <ReasonsAttachmentsSummary
          text={remarks}
          label={t("common.labels.remarks")}
          attachments={attachments}
        />
      }
      {...restDialogProps}
    />
  );
};
