import moment from "moment";

/**
 * Taken from stackOverflow. It returns the offset in minutes for a IANA formated timezone
 * @param timeZoneIANA timezone in IANA format. i.e. 'Europe/Berlin'
 * @returns the offset in minutes for a IANA formated timezone
 */
export const getTimezoneOffset = (timeZoneIANA: string) => {
  const timeZoneName = Intl.DateTimeFormat("ia", {
    timeZoneName: "short",
    timeZone: timeZoneIANA,
  })
    .formatToParts()
    .find((i) => i.type === "timeZoneName")!.value;
  // const offset = timeZoneName.slice(3);
  // if (!offset) return 0;

  // const matchData = offset.match(/([+-])(\d+)(?::(\d+))?/);
  // if (!matchData) return `cannot parse timezone name: ${timeZoneName}`;

  // const [, sign, hour, minute] = matchData;
  // let result = parseInt(hour) * 60;
  // if (sign === "+") result *= -1;
  // if (minute) result += parseInt(minute);

  // return formatMinutesToUTCOffsetFormat(result);

  if (timeZoneName === "GMT") {
    return "UTC-0";
  }

  return timeZoneName.replace("GMT", "UTC");
};

// TODO remove if still not used. The same with the above commented code
/**
 * From -240 => UTC-04
 * @param minutes
 */
export const formatMinutesToUTCOffsetFormat = (minutes: number) => {
  const sign = minutes >= 0 ? "+" : "-";
  const hoursRaw = Math.abs(minutes) / 60;
  const remainingMinutesRaw = Math.abs(minutes) % 60;
  const hours = hoursRaw.toString().length === 2 ? hoursRaw : `0${hoursRaw}`;
  const remainingMinutes =
    remainingMinutesRaw.toString().length === 2
      ? remainingMinutesRaw
      : `0${remainingMinutesRaw}`;

  return `UTC${sign}${hours}:${remainingMinutes}`;
};

export const getTimezoneOffset2 = (timeZoneIANA: string) => {
  return `UTC ${moment.tz(timeZoneIANA).format("Z")}`;
};

export const getTimezoneNamesWithOffset = () => {
  const rawTimeZoneNames = moment.tz.names();
  return rawTimeZoneNames.map(
    // (tzName) => `${tzName} (${getTimezoneOffset(tzName)})`
    (tzName) => `${tzName} (${getTimezoneOffset2(tzName)})`
  );
};

export const stripTimezoneOffset = (timezone: string = "") => {
  const tzTokens = timezone.split(" (UTC ");

  return tzTokens[0];
};
