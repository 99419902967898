import { MainNavigation } from "components/MainNavigation/MainNavigation";
import { ThemeProvider } from "@mui/material";
import { extendedTheme } from "theme/extendedTheme";
import { Router } from "./Router";
import { useContext } from "react";
import { AuthContext } from "providers/auth.context";
import { GraphqlProvider } from "providers/graphqlProvider";
import { GlobalContextProvider } from "state-management/globalContext/Global.context";

export const App: React.FC = () => {
  const { isLoggedIn } = useContext(AuthContext);

  return (
    <>
      <GraphqlProvider>
        <GlobalContextProvider>
          <ThemeProvider
            theme={(outerTheme) => ({
              ...outerTheme,
              ...extendedTheme,
            })}
          >
            {isLoggedIn && <MainNavigation />}
          </ThemeProvider>
          <Router />
        </GlobalContextProvider>
      </GraphqlProvider>
    </>
  );
};
