import { read, write } from "helpers/localStorage";
import { ErrorsType } from "../types";
import { AUTH_SERVICE_URLS } from "../helpers/paths/paths";
import { AuthContextDataType, AuthLSDataType } from "../providers/auth.context";
import { ResetPasswordResponseType } from "containers/ResetPassword/ResetPassword.decl";
import { SetNewPasswordResponseType } from "containers/ResetPassword/SetNewPassword/SetNewPassword.decl";

export const login = async (
  REACT_APP_AUTH_BASE_URL: string,
  email: string,
  password: string,
  persistAuthDataAfterSessionEnded: boolean = true
): Promise<AuthContextDataType | ErrorsType> => {
  const data = {
    Username: email,
    Password: password,
  };

  const response = await fetch(
    `${REACT_APP_AUTH_BASE_URL}/${AUTH_SERVICE_URLS.Login}`,
    {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }
  );
  const result = (await response.json()) as AuthContextDataType;

  if (result.AccessToken) {
    const lsAuthData: AuthLSDataType = {
      tokenData: result,
      persist: persistAuthDataAfterSessionEnded,
    };
    write(lsAuthData);
  }

  return result;
};

export const refreshToken = async (
  REACT_APP_AUTH_BASE_URL: string,
  refreshTokenStr?: string,
  saveToLS: boolean = true
) => {
  const currentLSAuthData = read() as AuthLSDataType;
  const computedRefreshToken =
    refreshTokenStr || currentLSAuthData?.tokenData?.RefreshToken;

  if (!computedRefreshToken) {
    return {
      Success: false,
      Errors: ["Refresh token missing"],
    };
  }

  const data = {
    Token: computedRefreshToken,
  };

  const response = await fetch(
    `${REACT_APP_AUTH_BASE_URL}/${AUTH_SERVICE_URLS.RefreshToken}`,
    {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }
  );
  const result = (await response.json()) as { AccessToken: string };

  if (result.AccessToken && saveToLS) {
    currentLSAuthData.tokenData.AccessToken = result.AccessToken;
    write(currentLSAuthData);
  }

  return result;
};

export const resetPassword = async (
  REACT_APP_AUTH_BASE_URL: string,
  email: string
): Promise<ResetPasswordResponseType | ErrorsType> => {
  const data = {
    Username: email,
  };

  const response = await fetch(
    `${REACT_APP_AUTH_BASE_URL}/${AUTH_SERVICE_URLS.ResetPassword}`,
    {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }
  );
  const result = (await response.json()) as ResetPasswordResponseType;

  return result;
};

export const setNewPassword = async (
  REACT_APP_AUTH_BASE_URL: string,
  email: string,
  password: string,
  confirmationCode: string
): Promise<SetNewPasswordResponseType | ErrorsType> => {
  const data = {
    Username: email,
    Password: password,
    ConfirmationCode: confirmationCode,
  };

  const response = await fetch(
    `${REACT_APP_AUTH_BASE_URL}/${AUTH_SERVICE_URLS.SetNewPassword}`,
    {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }
  );
  const result = (await response.json()) as SetNewPasswordResponseType;

  return result;
};
