import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import MentionsPlugin from "./plugins/MentionsPlugin/MentionsPlugin";
import { MentionNode } from "./nodes/MentionNode";
import { EditorErrorBoundary } from "./EditorErrorBoundary";
import { Box } from "@mui/material";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { EditorToolbar } from "./EditorToolbar";
import {
  CLEAR_EDITOR_COMMAND,
  EditorState,
  LexicalEditor,
  $getRoot,
  $insertNodes,
} from "lexical";
import { $generateHtmlFromNodes, $generateNodesFromDOM } from "@lexical/html";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { ListItemNode, ListNode } from "@lexical/list";
import { useRef, useState, useEffect } from "react";
import { ClearEditorPlugin } from "@lexical/react/LexicalClearEditorPlugin";
import { UserMentionOption } from "./plugins/MentionsPlugin/useMentionLookupService";
import { EditorPlaceholder } from "./EditorPlaceholder";
import { EditorWrapper, StyledContentEditable } from "./Editor.styled";
import { TablePlugin } from "@lexical/react/LexicalTablePlugin";
import { TableContext } from "./plugins/TablePlugin/TablePlugin";
import TableCellResizer from "./plugins/TablePlugin/TableCellResizer";
import TableCellActionMenuPlugin from "./plugins/TablePlugin/TableActionMenuPlugin";
import { EditorTheme } from "./Editor.constants";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { v4 as uuidv4 } from "uuid";
import "./Editor.styles.css";
import { emptyRichTextHTML } from "../../../../../constants";

const editorConfig = {
  theme: EditorTheme,
  namespace: uuidv4(),
  onError(error: Error) {
    throw error;
  },
  nodes: [
    MentionNode,
    ListNode,
    ListItemNode,
    TableCellNode,
    TableNode,
    TableRowNode,
  ],
};

export type EditorProps = {
  disabled: boolean;
  typeaheadOptions: UserMentionOption[];
  initialValue?: string; // HTML DOM value
  placeholder?: string;
  hasError?: boolean;
  withTable?: boolean;
  toolbarFullWidth?: boolean;
  dataTestId?: string;
  onSend?: (htmlText: string) => void;
  onChange?: (htmlText: string) => void;
  onEditorDirtyChange?: (isDirty: boolean) => void;
  onClose?: () => void;
};

export const EditorInner: React.FC<EditorProps> = ({
  disabled,
  typeaheadOptions,
  initialValue,
  placeholder,
  hasError,
  withTable,
  toolbarFullWidth = true,
  dataTestId,
  onSend,
  onChange,
  onEditorDirtyChange,
  onClose,
}) => {
  const [activeEditor] = useLexicalComposerContext();
  const currentMsgHTML = useRef<string>();
  const editorInitialized = useRef(false);
  const [floatingAnchorElem, setFloatingAnchorElem] =
    useState<HTMLDivElement | null>(null);
  const [isDirty, setIsDirty] = useState(false);

  const handleChange = (_: EditorState, editor: LexicalEditor) => {
    editor.update(() => {
      const rawHTMLText = $generateHtmlFromNodes(editor, null);
      currentMsgHTML.current = rawHTMLText;
      setIsDirty(rawHTMLText !== emptyRichTextHTML);
      if (editorInitialized.current) {
        onChange?.(rawHTMLText);
      } else if (rawHTMLText !== emptyRichTextHTML) {
        onChange?.(rawHTMLText);
        editorInitialized.current = true;
      }
    });
  };

  const handleSubmitMessage = () => {
    if (isDirty) {
      onSend?.(currentMsgHTML.current ?? "");

      activeEditor?.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined);
    }
  };

  const onRef = (_floatingAnchorElem: HTMLDivElement | null) => {
    if (_floatingAnchorElem !== null) {
      setFloatingAnchorElem(_floatingAnchorElem);
    }
  };

  useEffect(() => {
    onEditorDirtyChange?.(isDirty);
  }, [isDirty, onEditorDirtyChange]);

  useEffect(() => {
    if (activeEditor) {
      if (initialValue && editorInitialized.current === false) {
        // In the browser you can use the native DOMParser API to parse the HTML string.
        const parser = new DOMParser();
        const dom = parser.parseFromString(initialValue, "text/html");

        activeEditor.update(() => {
          const nodes = $generateNodesFromDOM(activeEditor, dom);

          // Select the root
          $getRoot().select();

          // Insert them at a selection.
          $insertNodes(nodes);
          editorInitialized.current = true;

          setTimeout(() => {
            // otherwise blur does not work
            activeEditor.blur();
          });
        });
      }
    }
  }, [initialValue, activeEditor]);

  return (
    <TableContext>
      <EditorWrapper
        className="editor-container"
        disabled={disabled}
        hasError={hasError}
      >
        <TablePlugin />
        <TableCellResizer />
        <RichTextPlugin
          contentEditable={
            <div className="editor-input">
              <div className="editor" ref={onRef}>
                <StyledContentEditable
                  className="ContentEditable__root"
                  data-testid={dataTestId}
                />
              </div>
            </div>
          }
          placeholder={
            <EditorPlaceholder disabled={disabled} placeholder={placeholder} />
          }
          ErrorBoundary={EditorErrorBoundary}
        />

        <MentionsPlugin mentionsList={typeaheadOptions} />
        <EditorToolbar
          onSend={onSend ? handleSubmitMessage : undefined}
          onCancel={onClose}
          withTable={withTable}
          sendBtnDisabled={!isDirty}
          fullWidth={toolbarFullWidth}
        />
        <ListPlugin />
        <ClearEditorPlugin />
        <OnChangePlugin onChange={handleChange} />
        <Box sx={{ height: "70px", width: "100%" }}>
          {/* just to push the editable area to the top */}
        </Box>

        {floatingAnchorElem && (
          <>
            <TableCellActionMenuPlugin anchorElem={floatingAnchorElem} />
          </>
        )}
      </EditorWrapper>
    </TableContext>
  );
};

export const Editor: React.FC<EditorProps> = (props) => {
  return (
    <LexicalComposer initialConfig={editorConfig}>
      <EditorInner {...props} />
    </LexicalComposer>
  );
};
