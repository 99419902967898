import {
  Contract,
  ContractLiteQuery,
  ContractLiteQueryVariables,
  ProductInstanceSchemaQuery,
  ProductInstanceSchemaQueryVariables,
} from "generated/graphql";
import { contractLiteQuery } from "graphql/queries/contractLite.query";
import { productInstanceSchemaQuery } from "graphql/queries/productInstanceSchema.query";
import { useGraphQuery } from "./useGraphQuery";
import { useProjectLite } from "../containers/AdminConsole/hooks/useProjectLite";
import { isNECContractType } from "containers/Projects/Projects.utils";

export const useProductItemContext = (
  projectId: string,
  contractId: string,
  productInstanceId: string
) => {
  const { data: contractLiteData, loading: getContractLiteDataLoading } =
    useGraphQuery<ContractLiteQuery, ContractLiteQueryVariables>(
      contractLiteQuery,
      {
        variables: { id: contractId },
      }
    );

  const { projectDataLite, loading: projectDataLoading } =
    useProjectLite(projectId);

  const {
    data: productInstanceSchemaData,
    loading: getProductInstanceSchemaDataLoading,
  } = useGraphQuery<
    ProductInstanceSchemaQuery,
    ProductInstanceSchemaQueryVariables
  >(productInstanceSchemaQuery, {
    variables: { id: productInstanceId },
    fetchPolicy: "cache-first",
  });

  return {
    contractDataLite: contractLiteData?.contract,
    contractDataLoading: getContractLiteDataLoading,
    isNECContractType: isNECContractType(
      contractLiteData?.contract as Contract
    ),
    contractTypeId: contractLiteData?.contract.contractTypeId,
    projectDataLite,
    projectDataLoading,
    productInstanceData: productInstanceSchemaData?.productInstance,
    productInstanceDataLoading: getProductInstanceSchemaDataLoading,
  };
};
