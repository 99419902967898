import {
  ClaimNoticePromptQuery,
  ClaimNoticePromptQueryVariables,
  ListItem,
  RecordClaimNoticeMutation,
  RecordClaimNoticeMutationVariables,
  SendClaimNoticeMutation,
  SendClaimNoticeMutationVariables,
} from "generated/graphql";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useGraphQuery } from "hooks/useGraphQuery";
import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ClaimWidgetContext } from "../../ClaimWidget/ClaimWidget.context";
import {
  claimNoticePromptQuery,
  recordClaimNoticeMutation,
  sendClaimNoticeMutation,
} from "./NotifyClaimAction.query";
import { customSort } from "containers/Projects/Projects.utils";
import { OnMutationDone } from "decl";

export const useNotifyClaimAction = () => {
  const { t } = useTranslation();
  const { onChange, productInstanceId } = useContext(ClaimWidgetContext);

  const { data, loading } = useGraphQuery<
    ClaimNoticePromptQuery,
    ClaimNoticePromptQueryVariables
  >(claimNoticePromptQuery, {
    variables: {
      input: {
        productInstanceId: productInstanceId!,
      },
    },
    skip: !productInstanceId,
  });

  const handleOnUpdate: OnMutationDone = (cache) => {
    cache.evict({ id: "ROOT_QUERY", fieldName: "claimItems" });
    cache.evict({
      id: "ROOT_QUERY",
      fieldName: "claimAction",
    });
    cache.gc();
    onChange?.();
  };

  const [sendClaimNotice, { loading: sendClaimNoticeLoading }] =
    useGraphMutation<SendClaimNoticeMutation, SendClaimNoticeMutationVariables>(
      sendClaimNoticeMutation,
      {
        update: handleOnUpdate,
      },
      t("common.successMessages.entitySent", {
        entity: t("Projects.Claims.claim"),
      })
    );

  const [recordClaimNotice, { loading: recordClaimNoticeLoading }] =
    useGraphMutation<
      RecordClaimNoticeMutation,
      RecordClaimNoticeMutationVariables
    >(
      recordClaimNoticeMutation,
      {
        update: handleOnUpdate,
      },
      t("common.successMessages.entityRecorded", {
        entity: t("Projects.Claims.claim"),
      })
    );

  const orderedClaimTypes = useMemo(
    () =>
      (data?.claimNoticePrompt.claimTypes
        .slice()
        .sort((claim1, claim2) => claim1.name.localeCompare(claim2.name))
        .sort(customSort) ?? []) as ListItem[],
    [data]
  );

  return {
    claimTypes: orderedClaimTypes,
    advanceWarnings: (data?.claimNoticePrompt.advanceWarnings ??
      []) as ListItem[],
    loading,
    actionLoading: sendClaimNoticeLoading || recordClaimNoticeLoading,
    sendClaimNotice,
    recordClaimNotice,
  };
};
