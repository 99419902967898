import { EFFECT_SECTION_NAME } from "../../../../constants";
import { ItemDataInput } from "generated/graphql";

export const doesSchemaValuesContainEffect = (
  schemaValues: ItemDataInput
): boolean => {
  return !!schemaValues.sections.find(
    (section) => section.name === EFFECT_SECTION_NAME
  );
};
