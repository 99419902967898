import { DailyDiaryItem, DailyDiaryItemMixed } from "generated/graphql";

export const isDailyDiaryItem = (
  mixedDailyDiary: DailyDiaryItemMixed
): mixedDailyDiary is DailyDiaryItem => {
  return !!(mixedDailyDiary as DailyDiaryItem).id;
};

export const addDefaultMinutes00 = (
  evt: InputEvent,
  condition: boolean,
  onUpdateValue: (newDate: Date) => void
) => {
  const value = (evt.target as HTMLInputElement).value as string;
  if (value.includes(":mm")) {
    // if date is invalid
    const hours = value.substring(0, 1);
    if (condition) {
      const newDate = new Date("2020-01-01");
      newDate.setHours(Number.parseInt(hours), 0, 0);

      setTimeout(() => {
        // needed for the cmp to update the value correctly
        onUpdateValue(newDate);
      });
    }
  }
};
