import { OnMutationDone } from "decl";
import {
  AddDocumentTemplateRouteOverrideInput,
  AddDocumentTemplateRouteOverrideMutation,
  AddDocumentTemplateRouteOverrideMutationVariables,
  EditDocumentTemplateRouteOverrideInput,
  EditDocumentTemplateRouteOverrideMutation,
  EditDocumentTemplateRouteOverrideMutationVariables,
  RemoveDocumentTemplateRouteOverrideMutation,
  RemoveDocumentTemplateRouteOverrideMutationVariables,
} from "generated/graphql";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
  addDocumentTemplateRouteOverrideMutation,
  editDocumentTemplateRouteOverrideMutation,
  removeDocumentTemplateRouteOverrideMutation,
} from "./DocumentTemplateRoutes.query";

export type useDocumentTemplateOverridesArgs = {
  onAdd: OnMutationDone;
  onEdit: OnMutationDone;
  onRemove: OnMutationDone;
};

export const useDocumentTemplateOverrides = ({
  onAdd,
  onEdit,
  onRemove,
}: useDocumentTemplateOverridesArgs) => {
  const { t } = useTranslation();
  const entityName = t(
    "AdminConsole.Templates.labels.documentTemplateOverride"
  );

  const [
    doAddDocumentTemplateRouteOverride,
    { loading: addDocumentTemplateRouteOverrideLoading },
  ] = useGraphMutation<
    AddDocumentTemplateRouteOverrideMutation,
    AddDocumentTemplateRouteOverrideMutationVariables
  >(
    addDocumentTemplateRouteOverrideMutation,
    {
      update: onAdd,
    },
    t("common.successMessages.entityCreated", {
      entity: entityName,
    })
  );

  const [
    doEditDocumentTemplateRouteOverride,
    { loading: editDocumentTemplateRouteOverrideLoading },
  ] = useGraphMutation<
    EditDocumentTemplateRouteOverrideMutation,
    EditDocumentTemplateRouteOverrideMutationVariables
  >(
    editDocumentTemplateRouteOverrideMutation,
    {
      update: onEdit,
    },
    t("common.successMessages.entityUpdated", {
      entity: entityName,
    })
  );

  const [
    doRemoveDocumentTemplateRouteOverride,
    { loading: removeDocumentTemplateRouteOverrideLoading },
  ] = useGraphMutation<
    RemoveDocumentTemplateRouteOverrideMutation,
    RemoveDocumentTemplateRouteOverrideMutationVariables
  >(
    removeDocumentTemplateRouteOverrideMutation,
    {
      update: onRemove,
    },
    t("common.successMessages.entityDeleted", {
      entity: entityName,
    })
  );

  const addDocumentTemplateRouteOverride = useCallback(
    (input: AddDocumentTemplateRouteOverrideInput) => {
      return doAddDocumentTemplateRouteOverride({ variables: { input } });
    },
    [doAddDocumentTemplateRouteOverride]
  );

  const editDocumentTemplateRouteOverride = useCallback(
    (input: EditDocumentTemplateRouteOverrideInput) => {
      return doEditDocumentTemplateRouteOverride({ variables: { input } });
    },
    [doEditDocumentTemplateRouteOverride]
  );

  const removeDocumentTemplateRouteOverride = useCallback(
    (routeId: string) => {
      return doRemoveDocumentTemplateRouteOverride({
        variables: { id: routeId },
      });
    },
    [doRemoveDocumentTemplateRouteOverride]
  );

  return {
    addDocumentTemplateRouteOverride,
    addDocumentTemplateRouteOverrideLoading,
    editDocumentTemplateRouteOverride,
    editDocumentTemplateRouteOverrideLoading,
    removeDocumentTemplateRouteOverride,
    removeDocumentTemplateRouteOverrideLoading,
  };
};
