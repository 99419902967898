import { gql } from "@apollo/client";
import { compEventItemFields } from "graphql/fragments/compEventItem.fragment";
import { creatorFields } from "graphql/fragments/creator.fragment";
import { productItemProductInstanceFields } from "../RiskDetails/RiskDetails.query";
import { authorizationWorkflowActionMappingFields } from "graphql/fragments/authorizationWorkflowActionMapping.fragment";
import { authorizationWorkflowAuditTrailFields } from "graphql/fragments/authorizationWorkflowAuditTrail.fragment";
import { claimItemFields } from "graphql/fragments/claimItem.fragment";
import { authorizationWorkflowFields } from "graphql/fragments/authorizationWorkflow.fragment";
import { notificationRecipientFields } from "graphql/fragments/notificationRecipient.fragment";

const localEventAuthWorkflowAuditFields = gql`
  ${creatorFields}
  ${authorizationWorkflowActionMappingFields}
  ${authorizationWorkflowAuditTrailFields}
  ${authorizationWorkflowFields}

  fragment LocalEventAuthWorkflowAuditFields on AuthorizationWorkflowAudit {
    id
    initiatedById
    initiatedBy {
      ...CreatorFields
    }
    regardingProductItemId
    regardingProductType
    regardingItemHistoryId
    actionId
    action {
      ...AuthorizationWorkflowActionMappingFields
    }
    workflowId
    workflow {
      ...AuthorizationWorkflowFields
    }
    status
    dateCreated
    auditTrails {
      ...AuthorizationWorkflowAuditTrailFields
    }
  }
`;

export const eventItemQuery = gql`
  ${productItemProductInstanceFields}
  ${creatorFields}
  ${compEventItemFields}
  ${claimItemFields}
  ${localEventAuthWorkflowAuditFields}
  ${notificationRecipientFields}

  query eventItem($id: ID!) {
    eventItem(id: $id) {
      id
      title
      data {
        sections {
          name
          entries {
            name
            value
          }
        }
      }
      number
      productInstanceId
      productInstance {
        ...ProductInstanceFields
      }
      ownerId
      statusOptionId
      dateCreated
      creatorId
      creator {
        ...CreatorFields
      }
      statusOption {
        id
        description
        equatesToRemoved
        statusCollectionId
        isInternal
        status
      }
      owner {
        ...CreatorFields
      }
      dateModified
      notifyDueDate
      attachments {
        id
        fileName
        fileUrl
        mimeType
        description
        locked
        fileSize
        status
        dateCreated
        dateModified
        creatorId
        creator {
          ...CreatorFields
          company {
            registeredName
          }
        }
      }
      compEvent {
        ...CompEventItemFields
        ongoingAuthorizationAudit {
          ...LocalEventAuthWorkflowAuditFields
        }
      }
      claim {
        ...ClaimItemFields
        ongoingAuthorizationAudit {
          ...LocalEventAuthWorkflowAuditFields
        }
      }
      notificationRecipients {
        ...NotificationRecipientFields
      }
    }
  }
`;
