import {
  Box,
  Chip,
  css,
  hexToRgb,
  ListItem,
  PopperPlacementType,
  styled,
} from "@mui/material";

export const StyledChip = styled(Chip, {
  shouldForwardProp: (prop) => prop !== "bgColor",
})<{ bgColor: string }>(({ bgColor, theme }) => {
  const colorAsRgb = hexToRgb(bgColor);
  const tokens = colorAsRgb.split("rgb(");
  const tokens2 = tokens[1].split(",");

  return css`
    && {
      cursor: pointer;
      background: rgba(
        ${parseInt(tokens2[0])},
        ${parseInt(tokens2[1])},
        ${parseInt(tokens2[2])},
        0.05
      );
      color: ${bgColor};
      &:hover {
        background: ${bgColor};
        color: ${theme.palette.common.white};
      }
    }
  `;
});

export const Arrow = styled("span")<{ placement?: PopperPlacementType }>(
  ({ placement = "" }) => css`
    position: absolute;
    top: ${placement?.indexOf("start") >= 0
      ? "15px !important"
      : "calc(100% - 35px) !important"};
    left: ${placement?.indexOf("right") >= 0 ? "-10px" : "calc(100% - 15px)"};
    border: 1px solid;
    height: 20px;
    width: 20px;
    transform: rotate(45deg) !important;
    border-color: transparent transparent white white;
    background: white;
    border-radius: 4px;
    box-shadow: ${placement?.indexOf("right") >= 0
      ? "-8px 8px 12px rgb(0 0 0 / 6%)"
      : "8px -8px 12px rgb(0 0 0 / 6%)"};
  `
);

export const PopperContentContainer = styled(Box)(
  ({ theme }) => css`
    background: ${theme.palette.common.white};
    box-shadow: 0px 4px 6px rgba(37, 44, 50, 0.08),
      0px 4px 8px rgba(37, 44, 50, 0.1);
    border-radius: 5px;
    border: 1px solid ${theme.palette.grey[100]};
  `
);

export const StyledListItem = styled(ListItem)<{
  size?: "small" | "large";
  selected?: boolean;
}>(
  ({ theme, size = "large", selected }) => css`
    ${size === "small"
      ? css`
          padding: ${theme.spacing(1)} ${theme.spacing(2)};
        `
      : css`
          padding: ${theme.spacing(1.5)} ${theme.spacing(2.5)};
        `}
    cursor: pointer;
    &:hover {
      background: ${theme.palette.action.hover};
    }
    ${selected && `background: ${theme.palette.grey[100]};`}
    min-width: 160px;
  `
);
