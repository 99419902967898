import { useCallback, useContext, useEffect, useState } from "react";
import {
  DraftVariationProposal,
  GenerateRecordVariationProposalForVariationPreviewMutation,
  GenerateRecordVariationProposalForVariationPreviewMutationVariables,
  RecordVariationProposalForVariationPreview,
} from "generated/graphql";
import { useGraphMutation } from "hooks/useGraphMutation";
import { debounce } from "@mui/material";
import { eventDebounceDuration } from "../../../../../../constants";
import { VariationWidgetContext } from "../../VariationWidget/VariationWidget.context";
import { generateRecordVariationProposalForVariationPreviewMutation } from "./SubmitProposalForVariationAction.query";

export const useRecordProposalForVariationModal = (
  draftProposals: DraftVariationProposal[],
  preferredProposalId: string
) => {
  const { variation, contract } = useContext(VariationWidgetContext);

  const [variationPreview, setVariationPreview] =
    useState<RecordVariationProposalForVariationPreview>();

  const [generatePreview] = useGraphMutation<
    GenerateRecordVariationProposalForVariationPreviewMutation,
    GenerateRecordVariationProposalForVariationPreviewMutationVariables
  >(generateRecordVariationProposalForVariationPreviewMutation, {}, null);

  const loadPreview = debounce(
    useCallback(async () => {
      const { data } = await generatePreview({
        variables: {
          input: {
            variationId: variation!.id,
            proposalIds: draftProposals.map((proposal) => proposal.id),
            preferredProposalId,
          },
        },
      });

      if (data) {
        setVariationPreview(
          data.generateRecordVariationProposalForVariationPreview
        );
      }
    }, [generatePreview, variation, draftProposals, preferredProposalId]),
    eventDebounceDuration
  );

  useEffect(() => {
    loadPreview();
    // eslint-disable-next-line
  }, []);

  return {
    variationPreview,
    contract,
  };
};
