import { dateISOFormat } from "../../../../../constants";
import {
  Contract,
  ContractKeyDate,
  ContractSection,
  EditContractInput,
  EditContractKeyDateInput,
  EditContractSectionInput,
} from "generated/graphql";
import moment from "moment";

export const contractToEditContractInput = (
  contract: Contract
): EditContractInput => ({
  id: contract.id,
  contractTypeId: contract.contractTypeId,
  coordinatesLatitude: contract.coordinatesLatitude,
  coordinatesLongitude: contract.coordinatesLongitude,
  country: contract.country,
  endDate: contract.endDate,
  friendlyName: contract.friendlyName,
  name: contract.name,
  number: contract.number,
  projectId: contract.projectId,
  province: contract.province,
  startDate: contract.startDate,
  timeZone: contract.timeZone,
  value: contract.value,
  valueCurrency: contract.valueCurrency,
  sections:
    contract.sections?.map((section) =>
      contractSectionToEditContractSectionInput(section)
    ) ?? [],
  keyDates:
    contract.keyDates?.map((keyDate) =>
      contractKeyDateToEditContractKeyDateInput(keyDate)
    ) ?? [],
});

export const contractSectionToEditContractSectionInput = (
  contractSection: ContractSection
): EditContractSectionInput => ({
  id: contractSection.id,
  number: contractSection.number,
  description: contractSection.description,
  completionDate: moment(contractSection.completionDate).format(dateISOFormat),
  status: contractSection.status,
});

export const contractKeyDateToEditContractKeyDateInput = (
  contractKeyDate: ContractKeyDate
): EditContractKeyDateInput => ({
  id: contractKeyDate.id,
  number: contractKeyDate.number,
  status: contractKeyDate.status,
  conditionToBeMet: contractKeyDate.conditionToBeMet,
  keyDate: moment(contractKeyDate.keyDate).format(dateISOFormat),
});
