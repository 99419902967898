import {
  RecordClaimErrorAgreementDisagreementMutation,
  RecordClaimErrorAgreementDisagreementMutationVariables,
  SendClaimErrorAgreementDisagreementMutation,
  SendClaimErrorAgreementDisagreementMutationVariables,
} from "generated/graphql";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { ClaimWidgetContext } from "../../ClaimWidget/ClaimWidget.context";
import {
  recordClaimErrorAgreementDisagreementMutation,
  sendClaimErrorAgreementDisagreementMutation,
} from "./DisagreeWithNoticeOfErrorInAgreement.query";
import { OnMutationDone } from "decl";

export const useDisagreeWithNoticeOfErrorInAgreement = () => {
  const { t } = useTranslation();
  const { onChange, claim } = useContext(ClaimWidgetContext);

  const handleOnUpdate: OnMutationDone = (cache) => {
    cache.evict({
      id: "ROOT_QUERY",
      fieldName: "claimItems",
    });
    cache.evict({
      id: "ROOT_QUERY",
      fieldName: "claimHistoryItems",
      args: { claimId: claim!.id },
    });
    cache.evict({
      id: "ROOT_QUERY",
      fieldName: "claimDetailsExplorerItems",
      args: { claimId: claim!.id },
    });
    cache.evict({
      id: "ROOT_QUERY",
      fieldName: "claimAction",
    });
    cache.gc();
    onChange?.();
  };

  const [
    sendClaimErrorAgreementDisagreement,
    { loading: sendClaimErrorAgreementDisagreementLoading },
  ] = useGraphMutation<
    SendClaimErrorAgreementDisagreementMutation,
    SendClaimErrorAgreementDisagreementMutationVariables
  >(
    sendClaimErrorAgreementDisagreementMutation,
    {
      update: handleOnUpdate,
    },
    t("common.successMessages.entitySent", {
      entity: t("Projects.Claims.disagreementErrorInAgreement"),
    })
  );

  const [
    recordClaimErrorAgreementDisagreement,
    { loading: recordClaimErrorAgreementDisagreementLoading },
  ] = useGraphMutation<
    RecordClaimErrorAgreementDisagreementMutation,
    RecordClaimErrorAgreementDisagreementMutationVariables
  >(
    recordClaimErrorAgreementDisagreementMutation,
    {
      update: handleOnUpdate,
    },
    t("common.successMessages.entityRecorded", {
      entity: t("Projects.Claims.disagreementErrorInAgreement"),
    })
  );

  return {
    actionLoading:
      sendClaimErrorAgreementDisagreementLoading ||
      recordClaimErrorAgreementDisagreementLoading,
    sendClaimErrorAgreementDisagreement,
    recordClaimErrorAgreementDisagreement,
  };
};
