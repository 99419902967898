import {
  AddDraftRiskItemMutation,
  AddDraftRiskItemMutationVariables,
  AddRiskItemMutation,
  AddRiskItemMutationVariables,
  DraftRiskItemQuery,
  DraftRiskItemQueryVariables,
  EditDraftRiskItemMutation,
  EditDraftRiskItemMutationVariables,
} from "generated/graphql";
import { useGraphLazyQuery } from "hooks/useGraphLazyQuery";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useTranslation } from "react-i18next";
import {
  addDraftRiskItemMutation,
  addRiskItemMutation,
  draftRiskItemQuery,
  editDraftRiskItemMutation,
} from "./NewRiskItem.query";

export const useNewRisk = () => {
  const { t } = useTranslation();

  const [
    fetchDraftRiskData,
    {
      data: draftRiskData,
      loading: fetchDraftRiskDataLoading,
      refetch: refetchDraftRiskData,
    },
  ] = useGraphLazyQuery<DraftRiskItemQuery, DraftRiskItemQueryVariables>(
    draftRiskItemQuery
  );

  const [addRiskItem, { loading: addRiskItemLoading }] = useGraphMutation<
    AddRiskItemMutation,
    AddRiskItemMutationVariables
  >(
    addRiskItemMutation,
    {
      // update: (cache) => {
      //   cache.evict({ id: "ROOT_QUERY", fieldName: "riskItems" });
      //   cache.evict({ id: "ROOT_QUERY", fieldName: "draftRiskItems" });
      //   cache.gc();
      // },
    },
    t("common.successMessages.entityCreated", {
      entity: t("Projects.Risks.riskItem"),
    })
  );

  const [addRiskItemDraft, { loading: addRiskItemDraftLoading }] =
    useGraphMutation<
      AddDraftRiskItemMutation,
      AddDraftRiskItemMutationVariables
    >(
      addDraftRiskItemMutation,
      {
        // update: (cache) => {
        //   cache.evict({ id: "ROOT_QUERY", fieldName: "draftRiskItems" });
        //   cache.gc();
        // },
      },
      t("common.successMessages.entityCreated", {
        entity: t("Projects.Risks.draftRiskItem"),
      })
    );

  const [editRiskItemDraft, { loading: editRiskItemDraftLoading }] =
    useGraphMutation<
      EditDraftRiskItemMutation,
      EditDraftRiskItemMutationVariables
    >(
      editDraftRiskItemMutation,
      {
        update: (_cache) => {
          refetchDraftRiskData();

          //   cache.evict({ id: "ROOT_QUERY", fieldName: "draftRiskItems" });
          //   cache.gc();
        },
      },
      t("common.successMessages.entityUpdated", {
        entity: t("Projects.Risks.draftRiskItem"),
      })
    );

  return {
    draftRiskData,
    fetchDraftRiskData,
    fetchDraftRiskDataLoading,
    addRiskItem,
    addRiskItemLoading,
    addRiskItemDraft,
    addRiskItemDraftLoading,
    editRiskItemDraft,
    editRiskItemDraftLoading,
  };
};
