import { GridRowId } from "@mui/x-data-grid-pro";
import { DailyDiaryItem } from "generated/graphql";
import moment from "moment";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { ImportRecordsModal } from "./ImportRecordsModal";
import { ActionsDialogProps } from "components/ActionsDialog";
import { dateISOFormat } from "../../../../../../constants";

const firstOfCurrentMonth = new Date();
firstOfCurrentMonth.setDate(1);

export const useImportRecordsModal = (
  availableDiariesToImportFrom: DailyDiaryItem[],
  onClose: ActionsDialogProps["onClose"]
) => {
  const { t } = useTranslation();
  const { productInstanceId, date: crtDiaryDate } = useParams();

  const [recordIdsToImport, setRecordIdsToImport] = useState<GridRowId[]>([]);
  const [dateToImportFrom, setDateToImportFrom] = useState<Date>();
  const [selectedMonth, setSelectedMonth] = useState<Date>(firstOfCurrentMonth);

  const availableDatesToImportFrom = useMemo(
    () =>
      availableDiariesToImportFrom
        .map((dd) => new Date(dd.date))
        .filter((date) => !moment(date).isSame(moment(crtDiaryDate), "date")), // filter out current diary; should not be able to import from current diary
    [availableDiariesToImportFrom, crtDiaryDate]
  );

  const selectedDateDiary = useMemo(
    () =>
      availableDiariesToImportFrom.find((diary) =>
        moment(diary.date).isSame(moment(dateToImportFrom), "date")
      ),
    [dateToImportFrom, availableDiariesToImportFrom]
  );

  const handleDateToImportFromChange: React.ComponentProps<
    typeof ImportRecordsModal
  >["onChange"] = (date) => {
    setDateToImportFrom(date as Date);
  };

  const handleMonthChange = (month: Date) => {
    setSelectedMonth(month);
  };

  const handleSecondaryClick = () => {
    onClose?.({}, "escapeKeyDown");
  };

  const fetchAvailableDatesToImportFrom = useCallback(
    async <T extends any>(
      fetchFn: ({
        productInstanceId,
        dateFrom,
        dateTo,
      }: {
        productInstanceId: string;
        dateFrom: string;
        dateTo: string;
      }) => Promise<T>
    ) => {
      if (!selectedMonth) {
        return;
      }

      const dateMarginDays = 7;
      const longestMonthDays = 31;

      const dateFrom = moment(selectedMonth)
        .subtract(dateMarginDays, "days")
        .format(dateISOFormat);
      const dateTo = moment(selectedMonth)
        .add(longestMonthDays + dateMarginDays, "days")
        .format(dateISOFormat);

      if (dateFrom && dateTo) {
        await fetchFn({
          productInstanceId: productInstanceId!,
          dateFrom,
          dateTo,
        });
      }
    },
    [selectedMonth, productInstanceId]
  );

  const handleSelectionChange = (selectedRecords?: GridRowId[]) => {
    setRecordIdsToImport(selectedRecords ?? []);
  };

  return {
    t,
    productInstanceId,
    crtDiaryDate,
    recordIdsToImport,
    selectedDateDiary,
    availableDatesToImportFrom,
    setRecordIdsToImport,
    handleDateToImportFromChange,
    handleMonthChange,
    handleSecondaryClick,
    fetchAvailableDatesToImportFrom,
    handleSelectionChange,
  };
};
