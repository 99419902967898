import { gql } from "@apollo/client";
import { claimItemLiteFields } from "graphql/fragments/claimItemLite.fragment";
import { contractBindingTypeFields } from "graphql/fragments/contractBindingType.fragment";
import { itemDataFields } from "graphql/fragments/itemData.fragment";

// TODO: check if needed commented queries
export const claimItemsRegisterQuery = gql`
  ${claimItemLiteFields}
  ${contractBindingTypeFields}
  ${itemDataFields}

  query claimItemsRegister($productInstanceId: ID!) {
    claimItems(productInstanceId: $productInstanceId) {
      nextToken
      items {
        ...ClaimItemLiteFields
        lastAction {
          actionType
          date
          daysLate
        }
        nextStep {
          actionType
          dueDate
          partyId
          party {
            ...ContractBindingTypeFields
          }
        }
        # creator {
        #   ...CreatorFields
        # }
        # productInstance {
        #   id
        #   description
        #   numberingFormat
        #   status
        #   product {
        #     id
        #     name
        #   }
        #   contract {
        #     id
        #     name
        #     friendlyName
        #     status
        #     timeZone
        #     valueCurrency
        #     project {
        #       id
        #       name
        #       friendlyName
        #       status
        #     }
        #   }
        # }
        advanceWarning {
          id
          title
          # dateSent
          number
          # productInstance {
          #   id
          #   product {
          #     id
          #   }
          # }
        }
        regardingEvent {
          id
          title
          productInstanceId
          number
          data {
            ...ItemDataFields
          }
          productInstance {
            id
            product {
              id
            }
          }
        }
        regardingInstruction {
          id
          title
          number
          productInstanceId
          data {
            ...ItemDataFields
          }
          productInstance {
            id
            product {
              id
            }
          }
        }
        notifiedByParty {
          ...ContractBindingTypeFields
        }
        # ongoingAuthorizationAudit: AuthorizationWorkflowAudit
      }
    }
  }
`;
