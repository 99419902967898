import { gql } from "@apollo/client";

export const dailyDiaryExplorerDataQuery = gql`
  query dailyDiaryExplorerData($id: ID!) {
    productInstance(id: $id) {
      id
      contract {
        id
        projectId
      }
      product {
        id
      }
    }
  }
`;
