import {
  ClaimDetailsResponsePromptQuery,
  ClaimDetailsResponsePromptQueryVariables,
  DetailedClaim,
  RecordClaimDetailsResponseMutation,
  RecordClaimDetailsResponseMutationVariables,
  SendClaimDetailsResponseMutation,
  SendClaimDetailsResponseMutationVariables,
} from "generated/graphql";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ClaimWidgetContext } from "../../ClaimWidget/ClaimWidget.context";
import { OnMutationDone } from "decl";
import { useGraphQuery } from "hooks/useGraphQuery";
import {
  claimDetailsResponsePromptQuery,
  recordClaimDetailsResponseMutation,
  sendClaimDetailsResponseMutation,
} from "./RespondRegardingDetailedClaimAction.query";
import { useClaimDetailsExplorerItems } from "../../hooks/useClaimDetailsExplorerItems/useClaimDetailsExplorerItems";

export const useRespondRegardingDetailedClaimAction = () => {
  const { t } = useTranslation();
  const { onChange, claim, isFIDIC99RedYellow } =
    useContext(ClaimWidgetContext);
  const { detailedClaims, loading: detailedClaimsLoading } =
    useClaimDetailsExplorerItems(claim!.id);

  const handleOnUpdate: OnMutationDone = (cache) => {
    cache.evict({
      id: "ROOT_QUERY",
      fieldName: "claimItems",
    });
    cache.evict({
      id: "ROOT_QUERY",
      fieldName: "claimHistoryItems",
      args: { claimId: claim!.id },
    });
    cache.evict({
      id: "ROOT_QUERY",
      fieldName: "claimDetailsExplorerItems",
      args: { claimId: claim!.id },
    });
    cache.evict({
      id: "ROOT_QUERY",
      fieldName: "claimAction",
    });
    cache.gc();
    onChange?.();
  };

  const { data: promptData, loading } = useGraphQuery<
    ClaimDetailsResponsePromptQuery,
    ClaimDetailsResponsePromptQueryVariables
  >(claimDetailsResponsePromptQuery, {
    variables: {
      input: {
        claimId: claim!.id,
      },
    },
    skip: !claim,
  });

  const [
    sendResponseRegardingDetailedClaim,
    { loading: sendResponseRegardingDetailedClaimLoading },
  ] = useGraphMutation<
    SendClaimDetailsResponseMutation,
    SendClaimDetailsResponseMutationVariables
  >(
    sendClaimDetailsResponseMutation,
    {
      update: handleOnUpdate,
    },
    t(
      `Projects.Claims.successMessages.responseRegardingDetailedClaimSent${
        isFIDIC99RedYellow ? "99RY" : ""
      }`
    )
  );

  const [
    recordResponseRegardingDetailedClaim,
    { loading: recordResponseRegardingDetailedClaimLoading },
  ] = useGraphMutation<
    RecordClaimDetailsResponseMutation,
    RecordClaimDetailsResponseMutationVariables
  >(
    recordClaimDetailsResponseMutation,
    {
      update: handleOnUpdate,
    },
    t(
      `Projects.Claims.successMessages.responseRegardingDetailedClaimRecord${
        isFIDIC99RedYellow ? "99RY" : ""
      }`
    )
  );

  const pendingDetailedClaims = useMemo(
    () =>
      (promptData?.claimDetailsResponsePrompt.pendingDetailedClaims ??
        []) as DetailedClaim[],
    [promptData]
  );

  return {
    details: promptData?.claimDetailsResponsePrompt.details,
    pendingDetailedClaims,
    loading,
    detailedClaims,
    detailedClaimsLoading,
    actionLoading:
      sendResponseRegardingDetailedClaimLoading ||
      recordResponseRegardingDetailedClaimLoading,
    sendResponseRegardingDetailedClaim,
    recordResponseRegardingDetailedClaim,
  };
};
