import { Box, Collapse, useTheme } from "@mui/material";
import { Contract, InviteUserRoleInput } from "generated/graphql";
import { useMemo, useState } from "react";
import { ParentEntityRoleHeader } from "./ParentEntityRoleHeader";
import { ProductInstanceRoleSelector } from "./ProductInstanceRoleSelector";
import {
  acceptedRoleNames,
  defaultProductInstanceRole,
} from "./ProductInstanceRolesMapper";
import { LocalInviteUserRoleInput } from "./ProjectRolesContainer";

export type ContractRolesContainerProps = {
  contract: Contract;
  productInstanceRoles: InviteUserRoleInput[];
  onChange: (newPIRoleMappings: LocalInviteUserRoleInput[]) => void;
};

export const ContractRolesContainer: React.FC<ContractRolesContainerProps> = ({
  contract,
  productInstanceRoles,
  onChange,
}) => {
  const theme = useTheme();

  const [collapsed, setCollapsed] = useState(true);

  const toggleCollapse = () => {
    setCollapsed((state) => !state);
  };

  const productInstances = useMemo(
    () => contract.productInstances.items,
    [contract]
  );

  /**
   * TODO: Here, we're assuming all the products share the exact same Roles list, thus I'm taking the roles list
   * from the first child ProductInstance. If the Products' roles will differ in the future, we'll have to update this logic.
   */
  const roles = useMemo(() => {
    const rolesLite = (
      contract.productInstances.items[0]?.product.roles.items ?? []
    ).map((role) => ({
      id: role.id,
      name: role.name,
    }));

    return [defaultProductInstanceRole, ...rolesLite].filter(
      (role) => acceptedRoleNames.indexOf(role.name.toLowerCase()) >= 0
    );
  }, [contract]);

  const handleSetChildrenRole = (selectedRoleName: string) => {
    const allPIRoles = productInstances.map((pi) => ({
      productInstanceId: pi.id,
      productRoleId:
        pi.product.roles.items.find(
          (role) => role.name.toLowerCase() === selectedRoleName!.toLowerCase()
        )?.id ?? "",
    }));

    onChange(allPIRoles);
  };

  const handleProductInstanceRoleChange = (
    newPIRoleMapping: LocalInviteUserRoleInput
  ) => {
    onChange([newPIRoleMapping]);
  };

  return (
    <Box>
      <ParentEntityRoleHeader
        title={contract.name}
        collapsed={collapsed}
        roles={roles}
        indentationLevel={2}
        onToggleCollapse={toggleCollapse}
        onSetRole={handleSetChildrenRole}
      />
      <Collapse in={!collapsed}>
        {productInstances.map(({ id, description, product }, index) => (
          <ProductInstanceRoleSelector
            key={id}
            py={0.25}
            sx={{
              borderBottom:
                index < productInstances.length - 1
                  ? `1px solid ${theme.palette.grey[200]}`
                  : "none",
            }}
            productInstanceName={description}
            productInstanceId={id}
            selectedRoleId={
              productInstanceRoles.find(
                (prodInstanceRole) => prodInstanceRole.productInstanceId === id
              )?.productRoleId
            }
            roles={product.roles.items}
            onChange={handleProductInstanceRoleChange}
          />
        ))}
      </Collapse>
    </Box>
  );
};
