import { useCallback, useContext, useEffect, useState } from "react";
import { ClaimWidgetContext } from "../../ClaimWidget/ClaimWidget.context";
import { useGraphMutation } from "hooks/useGraphMutation";
import {
  GenerateSendClaimFurtherParticularsRequestPreviewMutation,
  GenerateSendClaimFurtherParticularsRequestPreviewMutationVariables,
  SendClaimFurtherParticularsRequestPreview,
} from "generated/graphql";
import { debounce } from "@mui/material";
import { eventDebounceDuration } from "../../../../../../constants";
import { generateSendClaimFurtherParticularsRequestPreviewMutation } from "./RequestFurtherParticularsAction.query";

export const useSendFurtherParticularsRequestModal = (reasons: string) => {
  const [claimPreview, setClaimPreview] =
    useState<SendClaimFurtherParticularsRequestPreview>();

  const { claim, contract } = useContext(ClaimWidgetContext);

  const [generateClaimPreview] = useGraphMutation<
    GenerateSendClaimFurtherParticularsRequestPreviewMutation,
    GenerateSendClaimFurtherParticularsRequestPreviewMutationVariables
  >(generateSendClaimFurtherParticularsRequestPreviewMutation, {}, null);

  const loadPreview = debounce(
    useCallback(async () => {
      const { data } = await generateClaimPreview({
        variables: {
          input: {
            claimId: claim!.id,
            reasons,
          },
        },
      });

      if (data) {
        setClaimPreview(data.generateSendClaimFurtherParticularsRequestPreview);
      }
    }, [generateClaimPreview, claim, reasons]),
    eventDebounceDuration
  );

  useEffect(() => {
    loadPreview();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  return {
    claimPreview,
    contract,
  };
};
