import { OnMutationDone } from "decl";
import {
  AddDraftVariationAgreementMutation,
  AddDraftVariationAgreementMutationVariables,
  AddDraftVariationDeterminationMutation,
  AddDraftVariationDeterminationMutationVariables,
  AddDraftVariationProposalMutation,
  AddDraftVariationProposalMutationVariables,
  EditDraftVariationAgreementMutation,
  EditDraftVariationAgreementMutationVariables,
  EditDraftVariationDeterminationMutation,
  EditDraftVariationDeterminationMutationVariables,
  EditDraftVariationProposalMutation,
  EditDraftVariationProposalMutationVariables,
  RemoveDraftVariationAgreementMutation,
  RemoveDraftVariationAgreementMutationVariables,
  RemoveDraftVariationDeterminationMutation,
  RemoveDraftVariationDeterminationMutationVariables,
  RemoveDraftVariationProposalMutation,
  RemoveDraftVariationProposalMutationVariables,
} from "generated/graphql";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useCallback, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { VariationWidgetContext } from "../VariationWidget/VariationWidget.context";
import {
  addDraftVariationAgreementMutation,
  addDraftVariationDeterminationMutation,
  addDraftVariationProposalMutation,
  editDraftVariationAgreementMutation,
  editDraftVariationDeterminationMutation,
  editDraftVariationProposalMutation,
  removeDraftVariationAgreementMutation,
  removeDraftVariationDeterminationMutation,
  removeDraftVariationProposalMutation,
} from "./VariationProposalAgreementDeterminationModal.query";

export const useVariationProposalAgreementDeterminationModal = () => {
  const { onChange, variation } = useContext(VariationWidgetContext);
  const { t } = useTranslation();

  const [modalVisibility, setModalVisibility] = useState(false);

  const toggleModalVisibility = () => {
    setModalVisibility((state) => !state);
  };

  const onUpdated: OnMutationDone = useCallback(
    (cache) => {
      cache.evict({ id: "ROOT_QUERY", fieldName: "variationItems" });
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "variationProposalForProposedVariationPrompt",
        args: { input: { variationId: variation!.id } },
      });
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "variationProposalForVariationPrompt",
        args: { input: { variationId: variation!.id } },
      });
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "variationAgreementNoticePrompt",
        args: { input: { variationId: variation!.id } },
      });
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "variationDeterminationNoticePrompt",
        args: { input: { variationId: variation!.id } },
      });
      cache.gc();
      onChange?.();
    },
    [onChange, variation]
  );

  const [addDraftVOProposal, { loading: addDraftVOProposalLoading }] =
    useGraphMutation<
      AddDraftVariationProposalMutation,
      AddDraftVariationProposalMutationVariables
    >(
      addDraftVariationProposalMutation,
      {
        update: onUpdated,
      },
      t("common.successMessages.entityCreated", {
        entity: t("Projects.Variations.draftVOProposal"),
      })
    );

  const [editDraftVOProposal, { loading: editDraftVOProposalLoading }] =
    useGraphMutation<
      EditDraftVariationProposalMutation,
      EditDraftVariationProposalMutationVariables
    >(
      editDraftVariationProposalMutation,
      {
        update: onUpdated,
      },
      t("common.successMessages.entityUpdated", {
        entity: t("Projects.Variations.draftVOProposal"),
      })
    );

  const [removeDraftVOProposal, { loading: removeDraftVOProposalLoading }] =
    useGraphMutation<
      RemoveDraftVariationProposalMutation,
      RemoveDraftVariationProposalMutationVariables
    >(
      removeDraftVariationProposalMutation,
      {
        update: onUpdated,
      },
      t("common.successMessages.entityDeleted", {
        entity: t("Projects.Variations.draftVOProposal"),
      })
    );

  // Agreement
  const [addDraftVOAgreement, { loading: addDraftVOAgreementLoading }] =
    useGraphMutation<
      AddDraftVariationAgreementMutation,
      AddDraftVariationAgreementMutationVariables
    >(
      addDraftVariationAgreementMutation,
      {
        update: onUpdated,
      },
      t("common.successMessages.entityCreated", {
        entity: t("Projects.Variations.draftVOAgreement"),
      })
    );

  const [editDraftVOAgreement, { loading: editDraftVOAgreementLoading }] =
    useGraphMutation<
      EditDraftVariationAgreementMutation,
      EditDraftVariationAgreementMutationVariables
    >(
      editDraftVariationAgreementMutation,
      {
        update: onUpdated,
      },
      t("common.successMessages.entityUpdated", {
        entity: t("Projects.Variations.draftVOAgreement"),
      })
    );

  const [removeDraftVOAgreement, { loading: removeDraftVOAgreementLoading }] =
    useGraphMutation<
      RemoveDraftVariationAgreementMutation,
      RemoveDraftVariationAgreementMutationVariables
    >(
      removeDraftVariationAgreementMutation,
      {
        update: onUpdated,
      },
      t("common.successMessages.entityDeleted", {
        entity: t("Projects.Variations.draftVOAgreement"),
      })
    );

  // Determination
  const [addDraftVODetermination, { loading: addDraftVODeterminationLoading }] =
    useGraphMutation<
      AddDraftVariationDeterminationMutation,
      AddDraftVariationDeterminationMutationVariables
    >(
      addDraftVariationDeterminationMutation,
      {
        update: onUpdated,
      },
      t("common.successMessages.entityCreated", {
        entity: t("Projects.Variations.draftVODetermination"),
      })
    );

  const [
    editDraftVODetermination,
    { loading: editDraftVODeterminationLoading },
  ] = useGraphMutation<
    EditDraftVariationDeterminationMutation,
    EditDraftVariationDeterminationMutationVariables
  >(
    editDraftVariationDeterminationMutation,
    {
      update: onUpdated,
    },
    t("common.successMessages.entityUpdated", {
      entity: t("Projects.Variations.draftVODetermination"),
    })
  );

  const [
    removeDraftVODetermination,
    { loading: removeDraftVODeterminationLoading },
  ] = useGraphMutation<
    RemoveDraftVariationDeterminationMutation,
    RemoveDraftVariationDeterminationMutationVariables
  >(
    removeDraftVariationDeterminationMutation,
    {
      update: onUpdated,
    },
    t("common.successMessages.entityDeleted", {
      entity: t("Projects.Variations.draftVODetermination"),
    })
  );

  return {
    modalVisibility,
    toggleModalVisibility,
    addDraftVOProposal,
    editDraftVOProposal,
    removeDraftVOProposal,
    addDraftVOAgreement,
    editDraftVOAgreement,
    removeDraftVOAgreement,
    addDraftVODetermination,
    editDraftVODetermination,
    removeDraftVODetermination,
    loading:
      addDraftVOProposalLoading ||
      editDraftVOProposalLoading ||
      removeDraftVOProposalLoading ||
      addDraftVOAgreementLoading ||
      editDraftVOAgreementLoading ||
      removeDraftVOAgreementLoading ||
      addDraftVODeterminationLoading ||
      editDraftVODeterminationLoading ||
      removeDraftVODeterminationLoading,
  };
};
