import { CompEventHistoryItem, ProductType } from "generated/graphql";
import { CEHistoryItemDataView } from "./CEHistoryItemDataView";
import { BaseHistoryItemDetails } from "../../HistoryModal/BaseHistoryItemDetails";

export type CEHistoryItemDetailsProps = {
  historyItem: CompEventHistoryItem;
};

export const CEHistoryItemDetails: React.FC<CEHistoryItemDetailsProps> = ({
  historyItem,
}) => {
  return (
    <BaseHistoryItemDetails
      historyItem={historyItem}
      productType={ProductType.CompEvents}
    >
      <CEHistoryItemDataView historyItem={historyItem} />
    </BaseHistoryItemDetails>
  );
};
