import {
  GridColDef,
  GridRowId,
  GridValueFormatterParams,
} from "@mui/x-data-grid-pro";
import {
  CompanyLookupCollection,
  DailyDiaryPresetSection,
  DailyDiaryWeatherRecord,
} from "generated/graphql";
import { useMemo } from "react";
import { useDailyDiaryStatus } from "../../../hooks/useDailyDiaryStatus";
import { useTranslation } from "react-i18next";
import { unitValueFormatter } from "./WeatherTable.utils";
import { useAttachmentsColumn } from "../../AttachmentsColumn";
import { useKebabButtonColumn } from "../../KebabButtonColumn";
import { useCreatedByColumn } from "../../CreatedByColumn";

export const useColumns = ({
  localLookups,
  section,
  withAttachments,
  onEdit,
  onRemove,
  onAttachmentsClick,
}: {
  onEdit?: (rowId: GridRowId) => void;
  onRemove?: (rowId: GridRowId) => void;
  onAttachmentsClick: (rowId: GridRowId) => void;
  withAttachments?: boolean;
  localLookups: CompanyLookupCollection[];
  section: DailyDiaryPresetSection;
}) => {
  const { t } = useTranslation();
  const { isDraftOrEmptyDailyDiary } = useDailyDiaryStatus();
  const attachmentsColumn = useAttachmentsColumn(onAttachmentsClick);
  const kebabButtonColumn = useKebabButtonColumn<DailyDiaryWeatherRecord>(
    onEdit,
    onRemove
  );
  const createdByColumn = useCreatedByColumn<DailyDiaryWeatherRecord>();

  return useMemo((): GridColDef<DailyDiaryWeatherRecord>[] => {
    return [
      {
        field: "conditions",
        headerName: t("Projects.DailyDiaries.Weather.conditions"),
        flex: 0.1,
        resizable: true,
        valueGetter: (params) =>
          localLookups
            .find((lkp) => lkp.id === params.row.conditionsLookupId)
            ?.options.items?.find(
              (option) => option.id === params.row.conditionsOptionId
            )?.value,
      },
      {
        field: "temperature",
        headerName: t("Projects.DailyDiaries.Weather.temperature"),
        flex: 0.1,
        maxWidth: 100,
        resizable: true,
        valueFormatter: (params: GridValueFormatterParams) =>
          unitValueFormatter(params.value, "Temperature", section, t),
      },
      {
        field: "rainFall",
        headerName: t("Projects.DailyDiaries.Weather.rainfall"),
        flex: 0.1,
        maxWidth: 90,
        resizable: true,
        valueFormatter: (params: GridValueFormatterParams) =>
          unitValueFormatter(params.value, "Rainfall", section, t),
      },
      {
        field: "windSpeed",
        headerName: t("Projects.DailyDiaries.Weather.windSpeed"),
        flex: 0.1,
        maxWidth: 90,
        resizable: true,
        valueFormatter: (params: GridValueFormatterParams) =>
          unitValueFormatter(params.value, "WindSpeed", section, t),
      },
      {
        field: "humidity",
        headerName: t("Projects.DailyDiaries.Weather.humidity"),
        flex: 0.1,
        maxWidth: 70,
        resizable: true,
        valueFormatter: (params: GridValueFormatterParams) =>
          unitValueFormatter(params.value, "Humidity", section, t),
      },
      {
        field: "timeOfMeasurement",
        headerName: t("Projects.DailyDiaries.Weather.measuredAt"),
        flex: 0.1,
        maxWidth: 95,
        resizable: true,
        valueFormatter: (params) => params.value.slice(0, 5), // showing only HH:mm, omitting `:ss`
      },
      createdByColumn,
      ...(withAttachments ? [attachmentsColumn] : []),
      ...(isDraftOrEmptyDailyDiary && (onRemove || onEdit)
        ? [kebabButtonColumn]
        : []),
    ];
  }, [
    t,
    localLookups,
    section,
    isDraftOrEmptyDailyDiary,
    attachmentsColumn,
    kebabButtonColumn,
    createdByColumn,
    withAttachments,
    onEdit,
    onRemove,
  ]);
};
