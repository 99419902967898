import { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useGraphQuery } from "hooks/useGraphQuery";
import {
  RecordVariationRevisedProposalForProposedVariationRequestMutation,
  RecordVariationRevisedProposalForProposedVariationRequestMutationVariables,
  SendVariationRevisedProposalForProposedVariationRequestMutation,
  SendVariationRevisedProposalForProposedVariationRequestMutationVariables,
  VariationRevisedProposalForProposedVariationRequestPromptQuery,
  VariationRevisedProposalForProposedVariationRequestPromptQueryVariables,
} from "generated/graphql";
import { OnMutationDone } from "decl";
import { useGraphMutation } from "hooks/useGraphMutation";
import { VariationWidgetContext } from "../../VariationWidget/VariationWidget.context";
import {
  recordVariationRevisedProposalForProposedVariationRequestMutation,
  sendVariationRevisedProposalForProposedVariationRequestMutation,
  variationRevisedProposalForProposedVariationRequestPromptQuery,
} from "./RequestRevisedProposalAction.query";

export const useRequestRevisedProposalAction = () => {
  const { t } = useTranslation();
  const { onChange, variation } = useContext(VariationWidgetContext);

  const { data: promptData, loading } = useGraphQuery<
    VariationRevisedProposalForProposedVariationRequestPromptQuery,
    VariationRevisedProposalForProposedVariationRequestPromptQueryVariables
  >(variationRevisedProposalForProposedVariationRequestPromptQuery, {
    variables: {
      input: {
        variationId: variation?.id!,
      },
    },
  });

  const onUpdated: OnMutationDone = useCallback(
    (cache) => {
      cache.evict({ id: "ROOT_QUERY", fieldName: "variationItems" });
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "variationProposalExplorerItems",
        args: { variationId: variation!.id },
      });
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "variationRevisedProposalForProposedVariationRequestPrompt",
        args: { input: { variationId: variation!.id } },
      });
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "variationAction",
      });
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "variationHistoryItems",
        args: { variationId: variation!.id },
      });
      cache.gc();
      onChange?.();
    },
    [onChange, variation]
  );

  const [
    sendVariationRevisedProposalRequest,
    { loading: sendVariationRevisedProposalRequestLoading },
  ] = useGraphMutation<
    SendVariationRevisedProposalForProposedVariationRequestMutation,
    SendVariationRevisedProposalForProposedVariationRequestMutationVariables
  >(
    sendVariationRevisedProposalForProposedVariationRequestMutation,
    { update: onUpdated },
    t("common.successMessages.entitySent", {
      entity: t("Projects.Variations.variationRevisedProposalRequest"),
    })
  );

  const [
    recordVariationRevisedProposalRequest,
    { loading: recordVariationRevisedProposalRequestLoading },
  ] = useGraphMutation<
    RecordVariationRevisedProposalForProposedVariationRequestMutation,
    RecordVariationRevisedProposalForProposedVariationRequestMutationVariables
  >(
    recordVariationRevisedProposalForProposedVariationRequestMutation,
    { update: onUpdated },
    t("common.successMessages.entityRecorded", {
      entity: t("Projects.Variations.variationRevisedProposalRequest"),
    })
  );

  return {
    remarks:
      promptData?.variationRevisedProposalForProposedVariationRequestPrompt
        .remarks ?? "",
    loading,
    sendVariationRevisedProposalRequest,
    recordVariationRevisedProposalRequest,
    actionLoading:
      sendVariationRevisedProposalRequestLoading ||
      recordVariationRevisedProposalRequestLoading,
  };
};
