import {
  GridColDef,
  GridRenderCellParams,
  GridValueFormatterParams,
} from "@mui/x-data-grid-pro";
import { StatusOption, StatusTag } from "components/StatusTag/StatusTag";
import { dateISOFormat } from "../../../../../../constants";
import { Contract, ContractStatus } from "generated/graphql";
import moment from "moment";
import {
  statusComparatorFunction,
  useDateCreatedColumnConfig,
} from "helpers/dataGrid.helpers";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { getUserName } from "helpers/miscelaneous";

export const useColumns = (
  contractStatusOptions: StatusOption<ContractStatus>[],
  onStatusChange: (row: Contract, newStatus: ContractStatus) => void
): GridColDef<Contract>[] => {
  const { t } = useTranslation();
  const dateCreatedColConfig = useDateCreatedColumnConfig();
  const columns = useMemo(
    () =>
      [
        {
          field: "friendlyName",
          headerName: t("common.labels.friendlyName"),
          flex: 0.125,
          minWidth: 190,
          resizable: true,
        },
        {
          field: "status",
          headerName: t("common.labels.status"),
          flex: 0.125,
          minWidth: 90,
          resizable: true,
          sortComparator: statusComparatorFunction,
          renderCell: (params: GridRenderCellParams<any, Contract, any>) => {
            return (
              <StatusTag
                status={params.row.status}
                options={contractStatusOptions}
                onChange={(newStatus: StatusOption<ContractStatus>) =>
                  onStatusChange(params.row, newStatus.id)
                }
              />
            );
          },
        },
        {
          field: "number",
          headerName: t("common.labels.number"),
          flex: 0.15,
          minWidth: 90,
          resizable: true,
        },
        {
          field: "contractTypeName",
          headerName: t("common.labels.type"),
          flex: 0.15,
          minWidth: 90,
          resizable: true,
          valueGetter: (params) =>
            `${params.row.contractType.description} ${
              params.row.contractType.version ?? ""
            } ${params.row.contractType.subType ?? ""}`,
        },
        {
          field: "startDate",
          headerName: t("common.labels.startDate"),
          flex: 0.15,
          minWidth: 90,
          resizable: true,
          valueFormatter: (params: GridValueFormatterParams) => {
            return params.value
              ? moment(params.value).format(dateISOFormat)
              : "";
          },
        },
        {
          field: "province",
          headerName: t("AdminConsole.Contracts.labels.province"),
          flex: 0.15,
          minWidth: 90,
          resizable: true,
        },
        {
          field: "country",
          headerName: t("common.labels.country"),
          flex: 0.15,
          minWidth: 90,
          resizable: true,
        },
        {
          field: "timeZone",
          headerName: t("common.labels.timezone"),
          flex: 0.15,
          minWidth: 90,
          resizable: true,
        },
        dateCreatedColConfig,
        {
          field: "creator",
          headerName: t("common.labels.createdBy"),
          flex: 0.15,
          minWidth: 90,
          resizable: true,
          valueGetter: (params) => {
            return getUserName(params.row.creator);
          },
        },
      ] as GridColDef<Contract>[],
    [t, dateCreatedColConfig, contractStatusOptions, onStatusChange]
  );
  return columns;
};
