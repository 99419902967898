import {
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
} from "@mui/x-data-grid-pro";
import {
  AttachmentStatus,
  CompEventAssessment,
  DraftAssessment,
} from "generated/graphql";
import { TFunction } from "i18next";
import { TrashIcon } from "components/Icons/TrashIcon";
import { Typography } from "@mui/material";
import { TimeTag } from "containers/Projects/components/HistoryModal/TimeTag";
import { PriceTag } from "containers/Projects/components/HistoryModal/PriceTag";

export const getColumns = (
  currency: string,
  t: TFunction,
  onDelete?: (rowId: string) => void
): GridColDef<DraftAssessment | CompEventAssessment>[] => {
  return [
    {
      field: "reference",
      headerName: t("Projects.CompEvents.assessmentReference"),
      flex: 0.125,
      minWidth: 190,
      resizable: true,
      renderCell: (
        params: GridRenderCellParams<
          any,
          DraftAssessment | CompEventAssessment,
          any
        >
      ) => (
        <Typography variant="p2" fontWeight={600} color="grey.800">
          {params.row.reference}
        </Typography>
      ),
    },
    {
      field: "price",
      headerName: t("common.labels.price"),
      flex: 0.15,
      minWidth: 120,
      maxWidth: 180,
      resizable: true,
      valueGetter: (rowData) => rowData.row.price,
      renderCell: (
        params: GridRenderCellParams<
          DraftAssessment | CompEventAssessment,
          number,
          any
        >
      ) => (
        <PriceTag
          value={params.value}
          currency={currency}
          sx={{ width: "100%" }}
        />
      ),
    },
    {
      field: "time",
      headerName: t("common.labels.time"),
      flex: 0.15,
      minWidth: 120,
      maxWidth: 180,
      resizable: true,
      valueGetter: (rowData) => rowData.row.time,
      renderCell: (
        params: GridRenderCellParams<
          DraftAssessment | CompEventAssessment,
          number,
          any
        >
      ) => <TimeTag value={params.value} sx={{ width: "100%" }} />,
    },
    {
      field: "attachments",
      headerName: t("Attachments.attachments"),
      flex: 0.125,
      width: 130,
      resizable: true,
      valueGetter: (params) => {
        const activeAttachmentsNumber = params.row.attachments.filter(
          (attach) => attach.status === AttachmentStatus.Active
        ).length;

        return t("Projects.CompEvents.ActionModal.attachmentsNo", {
          number: activeAttachmentsNumber,
        });
      },
    },
    ...(onDelete
      ? [
          {
            field: "options",
            headerName: "",
            width: 50,
            resizable: true,
            sortable: false,
            renderCell: (
              params: GridRenderCellParams<
                any,
                DraftAssessment | CompEventAssessment,
                any
              >
            ) => {
              return (
                <GridActionsCellItem
                  className="visible-on-hover"
                  icon={<TrashIcon />}
                  label="Delete"
                  onClick={(evt) => {
                    evt.preventDefault();
                    evt.stopPropagation();

                    onDelete(params.row.id);
                  }}
                />
              );
            },
          },
        ]
      : []),
  ];
};
