import { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useGraphQuery } from "hooks/useGraphQuery";
import {
  RecordVariationProposalAbandonmentMutation,
  RecordVariationProposalAbandonmentMutationVariables,
  SendVariationProposalAbandonmentMutation,
  SendVariationProposalAbandonmentMutationVariables,
  VariationProposalAbandonmentPromptQuery,
  VariationProposalAbandonmentPromptQueryVariables,
} from "generated/graphql";
import { OnMutationDone } from "decl";
import { useGraphMutation } from "hooks/useGraphMutation";
import { VariationWidgetContext } from "../../VariationWidget/VariationWidget.context";
import {
  recordVariationProposalAbandonmentMutation,
  sendVariationProposalAbandonmentMutation,
  variationProposalAbandonmentPromptQuery,
} from "./AbandonProposedVariationAction.query";

export const useAbandonProposedVariationAction = () => {
  const { t } = useTranslation();
  const { onChange, variation } = useContext(VariationWidgetContext);

  const { data: promptData, loading } = useGraphQuery<
    VariationProposalAbandonmentPromptQuery,
    VariationProposalAbandonmentPromptQueryVariables
  >(variationProposalAbandonmentPromptQuery, {
    variables: {
      input: {
        variationId: variation?.id!,
      },
    },
  });

  const onUpdated: OnMutationDone = useCallback(
    (cache) => {
      cache.evict({ id: "ROOT_QUERY", fieldName: "variationItems" });
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "variationProposalExplorerItems",
        args: { variationId: variation!.id },
      });
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "variationProposalAbandonmentPrompt",
        args: { input: { variationId: variation!.id } },
      });
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "variationAction",
      });
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "variationHistoryItems",
        args: { variationId: variation!.id },
      });
      cache.gc();
      onChange?.();
    },
    [onChange, variation]
  );

  const [
    sendVariationAbandonmentNotice,
    { loading: sendVariationAbandonmentNoticeLoading },
  ] = useGraphMutation<
    SendVariationProposalAbandonmentMutation,
    SendVariationProposalAbandonmentMutationVariables
  >(
    sendVariationProposalAbandonmentMutation,
    { update: onUpdated },
    t("common.successMessages.entitySent", {
      entity: t("Projects.Variations.variationAbandonment"),
    })
  );

  const [
    recordVariationAbandonmentNotice,
    { loading: recordVariationAbandonmentNoticeLoading },
  ] = useGraphMutation<
    RecordVariationProposalAbandonmentMutation,
    RecordVariationProposalAbandonmentMutationVariables
  >(
    recordVariationProposalAbandonmentMutation,
    { update: onUpdated },
    t("common.successMessages.entityRecorded", {
      entity: t("Projects.Variations.variationAbandonment"),
    })
  );

  return {
    remarks: promptData?.variationProposalAbandonmentPrompt.remarks ?? "",
    loading,
    sendVariationAbandonmentNotice,
    recordVariationAbandonmentNotice,
    actionLoading:
      sendVariationAbandonmentNoticeLoading ||
      recordVariationAbandonmentNoticeLoading,
  };
};
