import {
  Box,
  Breadcrumbs,
  Collapse,
  Link,
  ThemeProvider,
  Typography,
  useTheme,
} from "@mui/material";
import { DetailsList } from "components/DetailsList";
import { EntityDetailsHeader } from "components/EntityDetailsHeader";
import { PageContentContainer } from "components/PageContentContainer";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useMemo, useState } from "react";
import { useProjectLite } from "containers/AdminConsole/hooks/useProjectLite";
import { CollapsibleHeader } from "components/CollapsibleHeader";
import { Path } from "phosphor-react";
import { useAuthorizationAuditTrailDetails } from "./useAuthorizationAuditTrailDetails";
import { getUserName } from "helpers/miscelaneous";
import { AuthorizationLevels } from "components/Authorization/AuthorizeDenyActionModal/components/AuthorizationLevels/AuthorizationLevels";
import { CenteredLoadingIndicator } from "components/CenteredLoadingIndicator";
import moment from "moment";
import { dateISOFormat } from "../../../../../constants";
import { extendedTheme } from "theme/extendedTheme";
import { computeRegardingColumnText } from "../ProjectDetails/ProjectAuthorizationTrails/ProjectAuthorizationTrails.constants";

export const AuthorizationAuditTrailDetails: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const { projectId, authAuditTrailId } = useParams();

  const [showDetails, setShowDetails] = useState(true);

  const { projectDataLite, loading: projectDataLoading } = useProjectLite(
    projectId!
  );

  const { authAuditTrail, loading: authAuditTrailLoading } =
    useAuthorizationAuditTrailDetails(authAuditTrailId!);

  const handleBreadcrumbClick = (parent?: "project") => {
    if (parent === "project") {
      navigate(-1);
    } else {
      navigate(-2);
    }
  };

  const authAuditEntityObj = useMemo(() => {
    if (!authAuditTrail) {
      return [];
    }

    return [
      {
        label: t("common.labels.contract"),
        id: "contract",
        value: authAuditTrail.action.productInstance.contract.friendlyName,
      },
      {
        label: t("common.labels.product"),
        id: "product",
        value: authAuditTrail.action.productInstance.product.name,
      },
      {
        label: t("AdminConsole.AuthorizationWorkflows.action"),
        id: "action",
        value: authAuditTrail.action.productOutputAction.name,
      },
      {
        label: t("common.labels.regarding"),
        id: "regarding",
        value: computeRegardingColumnText(authAuditTrail),
      },
      {
        label: t("AdminConsole.AuthorizationWorkflows.workflow"),
        id: "workflow",
        value: authAuditTrail.workflow.name,
      },
      {
        label: t("common.labels.status"),
        id: "status",
        value: t(`Home.Authorizations.status.${authAuditTrail.status}`),
      },
      {
        label: t("AdminConsole.AuthorizationAuditTrails.initiatedBy"),
        id: "initiatedBy",
        value: getUserName(authAuditTrail.initiatedBy),
      },
      {
        label: t("AdminConsole.AuthorizationAuditTrails.dateInitiated"),
        id: "dateInitiated",
        value: moment(authAuditTrail.dateInitiated).format(dateISOFormat),
      },
    ];
  }, [authAuditTrail, t]);

  const loading = authAuditTrailLoading || projectDataLoading;

  const initiatorStr = useMemo(() => {
    if (authAuditTrail?.initiatedBy) {
      return getUserName(authAuditTrail.initiatedBy);
    }

    return "";
  }, [authAuditTrail]);

  return (
    <Box>
      <EntityDetailsHeader
        loading={loading}
        title={t(
          "AdminConsole.AuthorizationAuditTrails.authorizationAuditTrail"
        )}
        subtitle={
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Link key="1" onClick={() => handleBreadcrumbClick()}>
              <Typography variant="body2">
                {t("AdminConsole.Projects.labels.projects")}
              </Typography>
            </Link>
            <Link key="2" onClick={() => handleBreadcrumbClick("project")}>
              <Typography variant="body2">
                {projectDataLite?.friendlyName}
              </Typography>
            </Link>
          </Breadcrumbs>
        }
        creator={initiatorStr}
        dateCreated={authAuditTrail?.dateCreated}
      />
      <CollapsibleHeader
        title={t(
          "AdminConsole.AuthorizationAuditTrails.authorizationAuditTrailDetails"
        )}
        collapsed={!showDetails}
        icon={<Path color={theme.palette.primary.main} />}
        onToggleCollapse={() => setShowDetails((state) => !state)}
        withShadow={false}
      />
      <Collapse in={showDetails}>
        <PageContentContainer>
          <DetailsList loading={loading} entity={authAuditEntityObj} />
        </PageContentContainer>
      </Collapse>
      <ThemeProvider
        theme={(outerTheme) => ({
          ...outerTheme,
          ...extendedTheme,
        })}
      >
        <Box px={4} pb={4}>
          {projectDataLoading || !authAuditTrail ? (
            <CenteredLoadingIndicator />
          ) : (
            <AuthorizationLevels
              authAudit={authAuditTrail}
              showLevelsConnector={false}
            />
          )}
        </Box>
      </ThemeProvider>
    </Box>
  );
};
