import {
  ApolloError,
  DocumentNode,
  FetchResult,
  MutationFunctionOptions,
  MutationHookOptions,
  OperationVariables,
  TypedDocumentNode,
  useMutation,
} from "@apollo/client";
import { snackbarAutoHideDuration } from "../constants";
import { useSnackbar } from "notistack";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";

type MutateFunctionReturn<TData> = Promise<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  FetchResult<TData, Record<string, any>, Record<string, any>>
>;

export type MutateFunction<TData, TVariables> = (
  options?: MutationFunctionOptions<TData, TVariables>
) => MutateFunctionReturn<TData>;

export type UseGraphMutationResponse<TData, TVariables> = [
  MutateFunction<TData, TVariables>,
  {
    data?: TData | null;
    loading: boolean;
    called: boolean;
    error?: ApolloError;
  }
];

export enum ErrorHandlingType {
  Snackbar = "snackbar",
  None = "none",
}

export type ErrorHandlingOptions = {
  type: ErrorHandlingType;
  errorMessage?: string;
};

export type SuccessHandlingMessage = string;

export const defaultErrorHandlingOptions = {
  type: ErrorHandlingType.Snackbar,
  errorMessage: "common.errorMessages.generic",
};

/**
 * Hook to execute a GraphQL mutations which handles network errors.
 * @param mutation - The mutation to execute.
 * @param options - Option that apply to the mutation.
 * @returns A mutate function that can be called at any time to execute the mutation and an object with fields that represent the current status of the mutation's execution.
 */
export const useGraphMutation = <TData = any, TVariables = OperationVariables>(
  mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: MutationHookOptions<TData, TVariables>,
  successHandlingMessage: SuccessHandlingMessage | null = "common.successMessages.actionCompletedSuccessfully",
  errorHandlingOption: ErrorHandlingOptions = defaultErrorHandlingOptions
): UseGraphMutationResponse<TData, TVariables> => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [mutate, { error, ...mutationResult }] = useMutation(mutation, options);

  const customMutate = useCallback(
    async (mutateOptions?: MutationFunctionOptions<TData, TVariables>) => {
      try {
        const result = await mutate(mutateOptions);

        if (successHandlingMessage) {
          enqueueSnackbar(t(successHandlingMessage), {
            autoHideDuration: snackbarAutoHideDuration,
            variant: "success",
          });
        }

        return result;
      } catch (e) {
        const err = e as ApolloError;
        if (errorHandlingOption.type === "snackbar") {
          // TODO: talk to Abel and come up with an error type thrown from BE that includes errorMessage and errorCode. Based on the errorCode, translate the messages
          enqueueSnackbar(
            err.message ?? t(errorHandlingOption?.errorMessage!),
            {
              variant: "error",
            }
          );
        }
        return { errors: err, data: undefined } as any;
      }
    },
    // as errorHandlingOptions is an object, we prefer to take the first passed argument version rather than having to extract in the consumer the options in a const every time
    // eslint-disable-next-line
    [mutate, enqueueSnackbar, t, successHandlingMessage]
  );

  useEffect(() => {
    if (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
    // as errorHandlingOptions is an object, we prefer to take the first passed argument version rather than having to extract in the consumer the options in a const every time
    // eslint-disable-next-line
  }, [error]);

  return [customMutate, { ...mutationResult, error }];
};
