import { v4 as uuidv4 } from "uuid";
import { Box, styled } from "@mui/material";
import { StyledDataGrid } from "components/StyledDataGrid";
import {
  AuthorizationWorkflowLevelInput,
  AuthorizationWorkflowLevelMode,
  User,
} from "generated/graphql";
import { useColumns } from "./AuthorizationWorkflowLevels.constants";
import { DataGridAddRecordButton } from "components/DataGridAddRecordButton";
import {
  computeGridRowModes,
  rowsContainTemporaryRecord,
} from "helpers/dataGrid.helpers";
import { useCallback, useEffect, useState } from "react";
import {
  sortLevels,
  workflowLevelsToLocalWorkflowLevels,
} from "./AuthorizationWorkflowLevels.utils";
import {
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowParams,
  MuiEvent,
} from "@mui/x-data-grid-pro";
import { temporaryRowId } from "../../../../../../constants";

export type AuthorizationWorkflowLevelInputWithID = {
  id: string;
} & AuthorizationWorkflowLevelInput;

export type AuthorizationWorkflowLevelsProps = {
  workflowLevels?: AuthorizationWorkflowLevelInputWithID[];
  users: User[];
  loading: boolean;
  readOnly?: boolean;
  onChange: (workflowLevels: AuthorizationWorkflowLevelInputWithID[]) => void;
};

const LocalStyledGrid = styled(StyledDataGrid)`
  .MuiDataGrid-cell {
    min-height: 52px !important;
  }
`;

export const AuthorizationWorkflowLevels: React.FC<
  AuthorizationWorkflowLevelsProps
> = ({ workflowLevels, users, loading, readOnly, onChange }) => {
  const [rows, setRows] = useState<AuthorizationWorkflowLevelInputWithID[]>(
    workflowLevelsToLocalWorkflowLevels(
      workflowLevels ? sortLevels(workflowLevels) : []
    )
  );
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>(
    computeGridRowModes(rows)
  );

  const handleModeChange = (
    row: AuthorizationWorkflowLevelInputWithID,
    newMode: AuthorizationWorkflowLevelMode
  ) => {
    if (row.id === temporaryRowId) {
      setRows((crtRows) =>
        crtRows.map((crtRow) =>
          crtRow.id === row.id
            ? {
                ...crtRow,
                mode: newMode,
              }
            : crtRow
        )
      );
    } else {
      onChange(
        rows.map((crtRow) =>
          crtRow.id === row.id
            ? {
                sequence: crtRow.sequence,
                userIds: crtRow.userIds,
                mode: newMode,
                id: crtRow.id,
              }
            : {
                sequence: crtRow.sequence,
                userIds: crtRow.userIds,
                mode: crtRow.mode,
                id: crtRow.id,
              }
        )
      );
    }
  };

  const handleUserChange = (
    row: AuthorizationWorkflowLevelInputWithID,
    updatedUserIds: string[]
  ) => {
    if (row.id === temporaryRowId) {
      setRows((crtRows) =>
        crtRows.map((crtRow) =>
          crtRow.id === row.id
            ? {
                ...crtRow,
                userIds: updatedUserIds,
              }
            : crtRow
        )
      );
    } else {
      onChange(
        rows.map((crtRow) =>
          crtRow.id === row.id
            ? {
                sequence: crtRow.sequence,
                mode: crtRow.mode,
                userIds: updatedUserIds,
                id: crtRow.id,
              }
            : {
                sequence: crtRow.sequence,
                userIds: crtRow.userIds,
                mode: crtRow.mode,
                id: crtRow.id,
              }
        )
      );
    }
  };

  /**
   * This function does not do the actual save because the data inside the row is not commited until it gets out of EditMode. Thus,
   * we're closing the editMode here, and process the add/edit inside processRowUpdate
   */
  const handleRowSaveClick = useCallback((rowId: GridRowId) => {
    setRowModesModel((curModel) => ({
      ...curModel,
      [rowId]: { mode: GridRowModes.View },
    }));
  }, []);

  const handleDeleteRow = useCallback(
    (rowId: GridRowId) => {
      if (rowId === temporaryRowId) {
        // just remove the temporary row from local rows
        setRows((curRows) =>
          curRows
            .filter((row) => row.id !== rowId)
            .map((crtRow, index) => ({
              ...crtRow,
              sequence: index + 1,
            }))
        );
      } else {
        // call BE to delete row
        onChange(
          rows
            .filter((level) => level.id !== rowId)!
            .map((crtRow, index) => ({
              sequence: index + 1,
              mode: crtRow.mode,
              userIds: crtRow.userIds,
              id: crtRow.id,
            }))
        );
      }
    },
    [onChange, rows]
  );

  const handleRowEditStart = (
    _: GridRowParams,
    event: MuiEvent<React.SyntheticEvent>
  ) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (_, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowOrderChange: GridEventListener<"rowOrderChange"> = (
    params
  ) => {
    const workflowLevel = params.row;

    if (params.targetIndex + 1 !== workflowLevel.sequence) {
      const rowsClone = rows.slice(0);
      const removedRow = rowsClone.splice(params.oldIndex, 1);
      rowsClone.splice(params.targetIndex, 0, removedRow[0]);

      onChange(
        rowsClone.map((crtRow, index) => ({
          mode: crtRow.mode,
          userIds: crtRow.userIds,
          sequence: index + 1,
          id: crtRow.id,
        }))
      );
    }
  };

  const handleRowChangesCommited = useCallback(
    (
      newRow: GridRowModel<AuthorizationWorkflowLevelInputWithID>,
      oldRow: GridRowModel<AuthorizationWorkflowLevelInputWithID>
    ) => {
      if (newRow.id === temporaryRowId) {
        if (newRow.mode && newRow.userIds.length) {
          onChange(
            [
              ...rows.filter((row) => row.id !== temporaryRowId),
              {
                mode: newRow.mode,
                sequence: newRow.sequence,
                userIds: newRow.userIds,
                id: uuidv4(),
              },
            ].map((row) => ({
              mode: row.mode,
              sequence: row.sequence,
              userIds: row.userIds,
              id: row.id,
            }))
          );
        } else {
          setRowModesModel((prevData) => ({
            ...prevData,
            [temporaryRowId]: {
              mode: GridRowModes.Edit,
              fieldToFocus: !newRow.mode ? "mode" : "users",
            },
          }));
        }
      } else if (
        newRow.mode !== oldRow.mode ||
        newRow.sequence !== oldRow.sequence ||
        newRow.userIds !== oldRow.userIds
      ) {
        onChange([
          ...rows
            .filter((row) => row.id === newRow.id)
            .map((row) => ({
              mode: row.mode,
              sequence: row.sequence,
              userIds: row.userIds,
              id: row.id,
            })),
          {
            mode: newRow.mode,
            sequence: newRow.sequence,
            userIds: newRow.userIds,
            id: newRow.id,
          },
        ]);
      }

      return newRow;
    },
    [onChange, rows]
  );

  const handleAddTemporaryRecord = () => {
    setRows((currentRecords) => [
      ...currentRecords,
      {
        id: temporaryRowId,
        mode: "" as any,
        sequence: rows.length + 1,
        userIds: [],
        users: { items: [] },
      },
    ]);

    setTimeout(() => {
      setRowModesModel((prevData) => ({
        ...prevData,
        [temporaryRowId]: {
          mode: GridRowModes.Edit,
          fieldToFocus: "mode",
        },
      }));
    });
  };

  const columns = useColumns({
    users,
    rowModesModel,
    readOnly,
    onSaveRow: handleRowSaveClick,
    onDeleteRow: handleDeleteRow,
    onModeChange: handleModeChange,
    onUserChange: handleUserChange,
  });

  useEffect(() => {
    const computedRows = workflowLevelsToLocalWorkflowLevels(
      workflowLevels ? sortLevels(workflowLevels) : []
    );

    setRows(computedRows);
    setRowModesModel(computeGridRowModes(computedRows));
  }, [workflowLevels]);

  return (
    <Box sx={{ maxHeight: 600, width: "100%", overflowY: "auto" }}>
      <LocalStyledGrid
        rows={rows}
        columns={columns}
        getRowId={(rowData: AuthorizationWorkflowLevelInputWithID) =>
          rowData.id
        }
        loading={loading}
        getRowHeight={() => "auto"}
        onRowEditStart={handleRowEditStart}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={handleRowChangesCommited}
        onRowOrderChange={handleRowOrderChange}
        rowModesModel={rowModesModel}
        rowReordering={!readOnly}
        disableRowSelectionOnClick
        hideFooter
        autoHeight
      />
      {!readOnly && (
        <DataGridAddRecordButton
          ml={0}
          onClick={handleAddTemporaryRecord}
          disabled={rowsContainTemporaryRecord(rows)}
        />
      )}
    </Box>
  );
};
