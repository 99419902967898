import { useCEWidgetNextStepSection } from "containers/Projects/components/CompEvents/CompEventWidget/components/CEWidgetNextStepSection/useCEWidgetNextStepSection";
import { CompEventItem, CompEventRegardingType } from "generated/graphql";
import { useMemo } from "react";
import { useCompEventProductInstance } from "./useCompEventProductInstance";

export const useShallDisplayCompEventWidget = (
  regardingType: CompEventRegardingType,
  regardingId: string,
  compEvent?: CompEventItem,
  contractId?: string
) => {
  const { compEventProductInstance, loading: compEventProdInstanceLoading } =
    useCompEventProductInstance(contractId);

  const { compEventActionList, ongoingAuthorizationAudit } =
    useCEWidgetNextStepSection(
      regardingType,
      regardingId,
      compEventProductInstance?.id,
      compEvent?.id
    );

  const canAccessCompEventWidget = useMemo(() => {
    if (compEventProdInstanceLoading) {
      return false;
    }

    if (!compEventProductInstance) {
      return false;
    }

    return (
      !compEventProdInstanceLoading &&
      !!compEventProductInstance &&
      (!!compEvent ||
        compEventActionList.length > 0 ||
        !!ongoingAuthorizationAudit)
    );
  }, [
    compEventProductInstance,
    compEventProdInstanceLoading,
    compEventActionList,
    compEvent,
    ongoingAuthorizationAudit,
  ]);

  return canAccessCompEventWidget;
};
