import { Typography } from "@mui/material";
import { ActionsDialogProps } from "components/ActionsDialog";
import { BasicModal } from "components/BasicModal/BasicModal";
import { IconContainer } from "components/IconContainer";
import { FormPublicApi } from "decl";
import {
  AddDailyDiaryResourceRecordInput,
  AttachmentInput,
  CompanyLookupCollection,
  DailyDiaryPresetSection,
  DailyDiaryResourceRecord,
  EditDailyDiaryResourceRecordInput,
} from "generated/graphql";
import { Plus } from "phosphor-react";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { RecordModalFooter } from "../../RecordModalFooter";
import { ResourceRecordForm } from "./ResourceRecordForm";
import { useDisableBackdropClick } from "../../../hooks/useDisableBackdropClick";

export enum ResourceType {
  Manpower,
  Equipment,
}

export type ResourceRecordModalProps = {
  resourceRecord?: DailyDiaryResourceRecord;
  resourceLookupCollections: CompanyLookupCollection[];
  section: DailyDiaryPresetSection;
  type?: ResourceType;
  onAttachmentsUpdated: (attachments: AttachmentInput[]) => void;
  onPrimaryClick: (
    resourceRecord:
      | AddDailyDiaryResourceRecordInput
      | EditDailyDiaryResourceRecordInput,
    closeModal: boolean
  ) => void;
  onSecondaryClick: () => void;
} & Omit<ActionsDialogProps, "onPrimaryClick" | "onSecondaryClick">;

export const ResourceRecordModal: React.FC<ResourceRecordModalProps> = ({
  resourceRecord,
  resourceLookupCollections,
  section,
  type,
  onAttachmentsUpdated,
  onPrimaryClick,
  onSecondaryClick,
  onClose,
  primaryBtnCaption,
  secondaryBtnCaption,
  primaryBtnLoading,
  secondaryBtnLoading,
  primaryBtnDisabled,
  ...restDialogProps
}) => {
  const { t } = useTranslation();
  const formApiRef = useRef<FormPublicApi>(null);
  const { handleClose } = useDisableBackdropClick(onClose);

  const [addAnotherRecord, setAddAnotherRecord] = useState(false);
  const [modalFooterKey, setModalFooterKey] = useState(0); // TODO: remove this when updating useAttachments module or creating a new one specific for modals

  const resourceRecordRef = useRef<
    AddDailyDiaryResourceRecordInput | EditDailyDiaryResourceRecordInput
  >();

  const handleResourceRecordChange = (
    updatedResourceRecord:
      | EditDailyDiaryResourceRecordInput
      | AddDailyDiaryResourceRecordInput
  ) => {
    resourceRecordRef.current = updatedResourceRecord;
  };

  const handlePrimaryBtnClick = () => {
    if (formApiRef.current?.validate()) {
      if (resourceRecord) {
        // edit
        onPrimaryClick(resourceRecordRef.current!, true);
      } else {
        // add
        const { id, ...addRecordInput } =
          resourceRecordRef.current! as EditDailyDiaryResourceRecordInput;
        onPrimaryClick(addRecordInput, !addAnotherRecord);

        if (addAnotherRecord) {
          // clear modal
          formApiRef.current.reset();
          setModalFooterKey((crtKey) => crtKey + 1);
        }
      }
    }
  };

  return (
    <BasicModal
      headerIcon={
        <IconContainer greyBackground>
          <Plus size={24} />
        </IconContainer>
      }
      title={
        <Typography
          variant="h3"
          fontWeight={600}
          fontSize="20px"
          color="grey.800"
        >
          {t(
            `Projects.DailyDiaries.${
              type === ResourceType.Manpower
                ? "ManpowerModal"
                : "EquipmentModal"
            }.${resourceRecord ? "editResourceRecord" : "addResourceRecord"}`
          )}
        </Typography>
      }
      fullWidth
      onClose={handleClose}
      maxWidth="sm"
      {...restDialogProps}
    >
      <ResourceRecordForm
        apiRef={formApiRef}
        resourceLookups={resourceLookupCollections}
        section={section}
        onChange={handleResourceRecordChange}
        resourceRecord={resourceRecord}
      />
      <RecordModalFooter
        mt={4}
        key={modalFooterKey}
        onAddAnotherRecordChange={setAddAnotherRecord}
        addAnotherRecord={addAnotherRecord}
        showAddAnotherRecord={!resourceRecord}
        primaryBtnCaption={primaryBtnCaption}
        primaryBtnLoading={primaryBtnLoading}
        primaryBtnDisabled={primaryBtnDisabled}
        secondaryBtnCaption={secondaryBtnCaption}
        secondaryBtnLoading={secondaryBtnLoading}
        attachments={resourceRecord?.attachments ?? []}
        onAttachmentsUpdated={onAttachmentsUpdated}
        onPrimaryClick={handlePrimaryBtnClick}
        onSecondaryClick={onSecondaryClick}
      />
    </BasicModal>
  );
};
