import { debounce } from "@mui/material";
import { useCallback, useContext, useEffect, useState } from "react";
import {
  GenerateSendClaimDeterminationNoticePreviewMutation,
  GenerateSendClaimDeterminationNoticePreviewMutationVariables,
  SendClaimDeterminationNoticePreview,
} from "generated/graphql";
import { useGraphMutation } from "hooks/useGraphMutation";
import { ClaimWidgetContext } from "../../ClaimWidget/ClaimWidget.context";
import { eventDebounceDuration } from "../../../../../../constants";
import { generateSendClaimDeterminationNoticePreviewMutation } from "./NotifyClaimDeterminationAction.query";

export const useSendClaimDeterminationNoticeModal = (
  draftClaimDeterminationId: string
) => {
  const [claimPreview, setClaimPreview] =
    useState<SendClaimDeterminationNoticePreview>();

  const { claim, contract } = useContext(ClaimWidgetContext);

  const [generateClaimPreview] = useGraphMutation<
    GenerateSendClaimDeterminationNoticePreviewMutation,
    GenerateSendClaimDeterminationNoticePreviewMutationVariables
  >(generateSendClaimDeterminationNoticePreviewMutation, {}, null);

  const loadPreview = debounce(
    useCallback(async () => {
      const { data } = await generateClaimPreview({
        variables: {
          input: {
            claimId: claim!.id,
            draftClaimDeterminationId,
          },
        },
      });

      if (data) {
        setClaimPreview(data.generateSendClaimDeterminationNoticePreview);
      }
    }, [generateClaimPreview, claim, draftClaimDeterminationId]),
    eventDebounceDuration
  );

  useEffect(() => {
    loadPreview();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  return {
    claimPreview,
    contract,
  };
};
