import { Box, Grid, Typography } from "@mui/material";
import {
  useCallback,
  useContext,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { VariationProposal } from "generated/graphql";
import { RecordProductItemExtraData } from "containers/Projects/components/RecordProductItemForm";
import { FormLabel } from "components/FormLabel";
import { useTranslation } from "react-i18next";
import { RichTextArea } from "components/RichTextArea/RichTextArea";
import {
  DataValidators,
  ValidatorType,
  validateData,
} from "helpers/validators";
import { VariationActionFormProps } from "../VariationActionView";
import { VariationWidgetContext } from "../../VariationWidget/VariationWidget.context";
import { useConsentToProposalForProposedVariationAction } from "./useConsentToProposalForProposedVariationAction";
import {
  VOProposalsAgreementsDeterminationsTable,
  VariationResolveType,
} from "../components/VOProposalsAgreementsDeterminationsTable/VOProposalsAgreementsDeterminationsTable";
import { SendConsentToProposalForProposedVariationModal } from "./SendConsentToProposalForProposedVariationModal";
import { RecordConsentToProposalForProposedVariationModal } from "./RecordConsentToProposalForProposedVariationModal";
import { VariationProposalAgreementDeterminationReadOnlyModal } from "../../VariationProposalAgreementDeterminationReadOnlyModal/VariationProposalAgreementDeterminationReadOnlyModal";

const dataValidators: DataValidators = {
  remarks: {
    validators: [ValidatorType.RequiredRichText],
  },
  variationProposal: {
    validators: [ValidatorType.Required],
  },
};

type FormDataType = {
  remarks: string;
  variationProposal?: VariationProposal;
  // attachments?: AttachmentInput[];
};

const defaultFormData: FormDataType = {
  remarks: "",
  variationProposal: undefined,
  // attachments: [],
};

export const ConsentToProposalForProposedVariationAction: React.FC<
  VariationActionFormProps & { triggersAuthWorkflow?: boolean }
> = ({ apiRef, onClose, triggersAuthWorkflow }) => {
  const { t } = useTranslation();
  const { variation, contract } = useContext(VariationWidgetContext);

  const [formData, setFormData] = useState<FormDataType>(defaultFormData);
  const [formDataErrors, setFormDataErrors] = useState<{
    [key: string]: string;
  }>({});
  const [sendModalVisibility, setSendModalVisibility] = useState(false);
  const [recordModalVisibility, setRecordModalVisibility] = useState(false);
  const [clickedVOProposal, setClickedVOProposal] =
    useState<VariationProposal>();

  const {
    sendVariationProposalConsent,
    recordVariationProposalConsent,
    actionLoading,
    proposals,
    loading: proposalsLoading,
  } = useConsentToProposalForProposedVariationAction(triggersAuthWorkflow);

  const toggleSendModalVisibility = () => {
    setSendModalVisibility((state) => !state);
  };

  const toggleRecordModalVisibility = () => {
    setRecordModalVisibility((state) => !state);
  };

  const handleSendVOProposalConsent = async () => {
    if (formData.variationProposal) {
      await sendVariationProposalConsent({
        variables: {
          input: {
            variationId: variation?.id!,
            remarks: formData.remarks,
            acceptedProposalId: formData.variationProposal.id,
            // attachments: formData.attachments,
          },
        },
      });
    }
    toggleSendModalVisibility();
    onClose();
  };

  const handleRecordVOProposalConsent = async (
    extraData: RecordProductItemExtraData
  ) => {
    if (formData.variationProposal) {
      await recordVariationProposalConsent({
        variables: {
          input: {
            variationId: variation?.id!,
            remarks: formData.remarks,
            acceptedProposalId: formData.variationProposal.id,
            // attachments: formData.attachments,
            dateSent: extraData.dateSent,
            givenById: extraData.givenById,
            number: extraData.number,
          },
        },
      });
    }
    toggleRecordModalVisibility();
    onClose();
  };

  const handleTextFieldChange = (remarks: string) => {
    setFormData((curData) => ({
      ...curData,
      remarks,
    }));

    setFormDataErrors((curFormDataErrs) => {
      const { remarks: _, ...rest } = curFormDataErrs;

      return rest;
    });
  };

  const validateForm = useCallback((formData: FormDataType) => {
    const result = validateData(formData, dataValidators);

    if (result.valid) {
      setFormDataErrors({});
    } else {
      setFormDataErrors(result.errors);
    }

    return result.valid;
  }, []);

  const isFormValid = useMemo(
    () => !!formData.remarks && formData.variationProposal,
    [formData]
  );

  // const handleAttachmentsUpdated = async (
  //   attachmentsUpdated: AttachmentInput[]
  // ) => {
  //   setFormData((crtFormData) => ({
  //     ...crtFormData,
  //     attachments: attachmentsUpdated,
  //   }));
  // };

  const handleProposalSelectionChange = useCallback(
    (selectedProposal?: VariationResolveType) => {
      setFormData((crtFormData) => ({
        ...crtFormData,
        variationProposal: selectedProposal as VariationProposal | undefined,
      }));

      setFormDataErrors((curFormDataErrs) => {
        const { variationProposal: _, ...rest } = curFormDataErrs;

        return rest;
      });
    },
    []
  );

  const handleProposalClick = (clickedProposal: VariationResolveType) => {
    setClickedVOProposal(clickedProposal as VariationProposal);
  };

  const handleVOProposalModalClose = () => {
    setClickedVOProposal(undefined);
  };

  // const {
  //   allAttachments,
  //   addAttachments,
  //   removeAttachment,
  //   updateAttachment,
  //   downloadAttachment,
  //   unloadLocalAttachments,
  // } = useAttachments([], handleAttachmentsUpdated); // always starting from empty attachments.

  // const handleBeforeAbort = useCallback(() => {
  //   // flow of consenting with a VO proposal aborted. Remove all allegedly added attachments from the server
  //   unloadLocalAttachments();
  // }, [unloadLocalAttachments]);

  // const {
  //   imageAttachmentPreviewModalVisible,
  //   imagePreviewData,
  //   previewUrl,
  //   handleAttachmentClick,
  //   closeModal: closeImagePreviewModal,
  // } = useImagePreviewModal(downloadAttachment);

  useImperativeHandle(
    apiRef,
    () => ({
      validate: () => validateForm(formData),
      record: toggleRecordModalVisibility,
      send: toggleSendModalVisibility,
      onBeforeAbort: () => {},
      // onBeforeAbort: handleBeforeAbort,
    }),
    [formData, validateForm]
  );

  return (
    <>
      {clickedVOProposal && (
        <VariationProposalAgreementDeterminationReadOnlyModal
          open={!!clickedVOProposal}
          onClose={handleVOProposalModalClose}
          item={clickedVOProposal}
          contract={contract}
        />
      )}

      {/* <PhotoAttachmentPreviewModal
        open={imageAttachmentPreviewModalVisible}
        attachment={imagePreviewData?.attachment}
        creatorName={imagePreviewData?.creatorName}
        creatorCompany={imagePreviewData?.creatorCompany}
        previewUrl={previewUrl}
        onClose={closeImagePreviewModal}
        onDownload={downloadAttachment}
      /> */}
      {isFormValid && sendModalVisibility && formData.variationProposal && (
        <SendConsentToProposalForProposedVariationModal
          open={sendModalVisibility}
          remarks={formData.remarks}
          acceptedProposal={formData.variationProposal}
          // attachments={allAttachments as EnhancedAttachment[]}
          onPrimaryClick={handleSendVOProposalConsent}
          onSecondaryClick={toggleSendModalVisibility}
          onClose={toggleSendModalVisibility}
          primaryBtnLoading={actionLoading}
        />
      )}
      {isFormValid && recordModalVisibility && formData.variationProposal && (
        <RecordConsentToProposalForProposedVariationModal
          open={recordModalVisibility}
          remarks={formData.remarks}
          acceptedProposal={formData.variationProposal}
          // attachments={allAttachments as EnhancedAttachment[]}
          onPrimaryClick={handleRecordVOProposalConsent}
          onSecondaryClick={toggleRecordModalVisibility}
          onClose={toggleRecordModalVisibility}
          primaryBtnLoading={actionLoading}
        />
      )}
      <Box display="flex" flexDirection="column">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <VOProposalsAgreementsDeterminationsTable
              contractCurrency={contract.valueCurrency}
              items={proposals}
              type="VariationProposal"
              loading={proposalsLoading}
              hasError={!!formDataErrors.variationProposal}
              onSelectionChange={handleProposalSelectionChange}
              onRowClick={handleProposalClick}
            />
            {!!formDataErrors.variationProposal && (
              <Typography variant="caption" color="error" mt={0.5}>
                {t("Projects.Variations.ActionModal.noProposalSelected")}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <FormLabel label={t("common.labels.remarks")} required />
            <RichTextArea
              content={formData.remarks}
              error={!!formDataErrors.remarks}
              onChange={handleTextFieldChange}
            />
            {!!formDataErrors.remarks && (
              <Typography variant="caption" color="error" mt={0.5}>
                {formDataErrors.remarks}
              </Typography>
            )}
          </Grid>
          {/* <Grid item xs={12}>
            <Attachments
              editMode
              showTabs={false}
              dencity={AttachmentsDencity.Compact}
              defaultLayout={AttachmentsLayout.List}
              attachments={allAttachments}
              onAttachmentsAdd={addAttachments}
              onAttachmentRemove={removeAttachment}
              onAttachmentUpdate={updateAttachment}
              onAttachmentClick={handleAttachmentClick}
            />
          </Grid> */}
        </Grid>
      </Box>
    </>
  );
};
