import {
  GridActionsCellItem,
  GridActionsCellItemProps,
  GridColDef,
  GridRowId,
  GridRowModes,
  GridRowModesModel,
  GridRowParams,
  GridValueFormatterParams,
} from "@mui/x-data-grid-pro";
import { DoubleSaveIcon } from "components/Icons/DoubleSaveIcon";
import { TrashIcon } from "components/Icons/TrashIcon";
import { JSXElementConstructor, ReactElement, useMemo } from "react";
import { dateISOFormat, temporaryRowId } from "../constants";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { getUserName } from "./miscelaneous";
import { User } from "generated/graphql";

export type BaseDataGridEntity = {
  id: string;
};

export const computeGridRowModes = <T extends BaseDataGridEntity>(
  entities: T[]
): GridRowModesModel => {
  const rowModesModel: GridRowModesModel = {};
  entities?.forEach((entity) => {
    rowModesModel[entity.id] = {
      mode: GridRowModes.View,
      ignoreModifications: true,
    };
  });

  return rowModesModel;
};

// TODO: reuse in whole app
export const rowsContainTemporaryRecord = (rows: any[]): boolean => {
  return !!rows.find((row) => row.id === temporaryRowId);
};

export const getCommonActions: (
  params: GridRowParams<any>,
  rowModesModel: GridRowModesModel,
  onSaveRow: (rowId: GridRowId) => void,
  onDeleteRow: (rowId: GridRowId) => void
) => ReactElement<
  GridActionsCellItemProps,
  string | JSXElementConstructor<any>
>[] = ({ id }, rowModesModel, onSaveRow, onDeleteRow) => {
  const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
  const actions = [];

  if (isInEditMode) {
    actions.push(
      <GridActionsCellItem
        icon={<DoubleSaveIcon width="20px" height="20px" />}
        label="Save"
        onClick={() => onSaveRow(id)}
      />,
      <GridActionsCellItem
        icon={<TrashIcon />}
        label="Delete"
        onClick={() => onDeleteRow(id)}
      />
    );
  } else {
    actions.push(
      <GridActionsCellItem
        className="visible-on-hover"
        icon={<TrashIcon />}
        label="Delete"
        onClick={() => onDeleteRow(id)}
      />
    );
  }

  return actions;
};

export const statusComparatorFunction = (status1: string, status2: string) => {
  if (status1 < status2) {
    return -1;
  } else if (status1 > status2) {
    return 1;
  }

  return 0;
};

export const datetimeComparatorFn = (
  datetime1?: string,
  datetime2?: string
) => {
  if (!datetime1 && !datetime2) {
    return 0;
  }
  if (!datetime1) {
    return -1;
  }
  if (!datetime2) {
    return 1;
  }

  const timestamp1 = new Date(datetime1).getTime();
  const timestamp2 = new Date(datetime2).getTime();

  return timestamp1 - timestamp2;
};

export const useDateCreatedColumnConfig = (format?: string) => {
  const { t } = useTranslation();

  const config = useMemo(
    () => ({
      field: "dateCreated",
      headerName: t("common.labels.dateCreated"),
      width: 140,
      resizable: true,
      valueFormatter: (params: GridValueFormatterParams) => {
        return params.value
          ? moment(params.value).format(format ?? dateISOFormat)
          : "";
      },
    }),
    [t, format]
  );

  return config;
};

export const useCreatorColumnConfig = <T extends { creator: User }>() => {
  const { t } = useTranslation();

  const config: GridColDef<T> = useMemo(
    () => ({
      field: "creator",
      headerName: t("common.labels.createdBy"),
      width: 120,
      resizable: true,
      valueGetter: (params) => {
        return getUserName(params.row.creator);
      },
    }),
    [t]
  );

  return config;
};
