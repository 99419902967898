import { gql } from "@apollo/client";
import { authorizationWorkflowActionMappingFields } from "graphql/fragments/authorizationWorkflowActionMapping.fragment";
import { creatorFields } from "graphql/fragments/creator.fragment";

const localAuthProductInstanceFields = gql`
  fragment LocalAuthProductInstanceFields on ProductInstance {
    id
    description
    numberingFormat
    product {
      id
      numberingFormat
    }
  }
`;

export const homePageAuthorizationWorkflowAuditFields = gql`
  ${creatorFields}
  ${localAuthProductInstanceFields}
  ${authorizationWorkflowActionMappingFields}

  fragment HomePageAuthorizationWorkflowAuditFields on AuthorizationWorkflowAudit {
    id
    initiatedById
    initiatedBy {
      ...CreatorFields
    }
    regardingProductItemId
    regardingProductType
    regardingItemHistoryId
    actionId
    action {
      ...AuthorizationWorkflowActionMappingFields
    }
    status
    dateCreated
    dateInitiated
    regardingProductItem {
      ... on CompEventItem {
        __typename
        id
        number
        productInstance {
          ...LocalAuthProductInstanceFields
        }
      }
      ... on EarlyWarningItem {
        __typename
        id
        number
        title
        productInstance {
          ...LocalAuthProductInstanceFields
        }
      }
      ... on InstructionItem {
        __typename
        id
        number
        title
        productInstance {
          ...LocalAuthProductInstanceFields
        }
      }
      ... on DailyDiaryItem {
        id
        date
        number
        productInstance {
          ...LocalAuthProductInstanceFields
        }
      }
      ... on ClaimItem {
        __typename
        id
        number
        productInstance {
          ...LocalAuthProductInstanceFields
        }
      }
      ... on VariationItem {
        __typename
        id
        number
        productInstance {
          ...LocalAuthProductInstanceFields
        }
      }
    }
  }
`;

export const userPendingAuthorizationActionsQuery = gql`
  ${homePageAuthorizationWorkflowAuditFields}

  query userPendingAuthorizationActions {
    userPendingAuthorizationActions {
      ...HomePageAuthorizationWorkflowAuditFields
    }
  }
`;
