import { Box, Typography } from "@mui/material";
import { emptyRichTextHTML } from "../../../../../../../constants";
import { CompEventHistoryNoticeOfSilenceQuotationAnswers } from "generated/graphql";
import { useTranslation } from "react-i18next";
import { NotifyOfSilenceCEHistorySummaryCommon } from "./NotifyOfSilenceCEHistorySummaryCommon";
import { QuotationsTable } from "../SubmitQuotationCEAction/QuotationsTable/QuotationsTable";
import { useContext } from "react";
import { CompEventWidgetContext } from "containers/Projects/components/CompEvents/CompEventWidget/CompEventWidget.context";
import { useCEQuotationAssessmentModal } from "../../../CEQuotationAssessmentModal/useCEQuotationAssessmentModal";
import { CEQuotationAssessmentReadOnlyModal } from "../../../CEQuotationAssessmentReadOnlyModal/CEQuotationAssessmentReadOnlyModal";

export const NotifyOfSilenceCEQuotationHistorySummary: React.FC<
  Omit<CompEventHistoryNoticeOfSilenceQuotationAnswers, "acceptedQuotationId">
> = ({ remarks, deemedAcceptedDate, acceptedQuotation }) => {
  const { t } = useTranslation();

  const { contract } = useContext(CompEventWidgetContext);
  const {
    modalVisibility: quotationModalVisibility,
    toggleModalVisibility: toggleQuotationModalVisibility,
  } = useCEQuotationAssessmentModal();

  const computedValue =
    remarks && remarks !== emptyRichTextHTML
      ? remarks
      : t("common.labels.none");

  return (
    <Box display="flex" flexDirection="column">
      <NotifyOfSilenceCEHistorySummaryCommon
        remarks={computedValue}
        deemedAcceptedDate={deemedAcceptedDate}
      />
      <Box mt={2}>
        <CEQuotationAssessmentReadOnlyModal
          open={quotationModalVisibility}
          onClose={toggleQuotationModalVisibility}
          quotationAssessment={acceptedQuotation}
          contract={contract}
        />
        <Typography variant="p2" color="grey.800" fontWeight={600}>
          {t("Projects.CompEvents.ActionModal.quotationToDeemAccepted")}
        </Typography>
        <Box mt={0.5}>
          <QuotationsTable
            quotations={[acceptedQuotation]}
            contractCurrency={contract.valueCurrency ?? ""}
            onRowClick={toggleQuotationModalVisibility}
          />
        </Box>
      </Box>
    </Box>
  );
};
