import { Box, Color, debounce, useTheme } from "@mui/material";
import { ActionsDialog, ActionsDialogProps } from "components/ActionsDialog";
import { CollapsibleContainer } from "components/CollapsibleContainer";
import { CompEventWidgetContext } from "containers/Projects/components/CompEvents/CompEventWidget/CompEventWidget.context";
import { FullScreenProductItemPreview } from "containers/Projects/components/FullScreenProductItemPreview";
import { ProductItemCard } from "components/ProductItemCard/ProductItemCard";
import { SmallProductItemPreview } from "containers/Projects/components/SmallProductItemPreview";
import { RecipientsPreview } from "containers/Projects/components/RecipientsPreview";
import {
  CompEventActionType,
  GenerateSendCompEventNoticeRequestQuotationPreviewMutation,
  GenerateSendCompEventNoticeRequestQuotationPreviewMutationVariables,
  ListItem,
  ProductType,
  SendCompEventNoticeRequestQuotationPreview,
} from "generated/graphql";
import { PaperPlaneTilt } from "phosphor-react";
import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGraphMutation } from "hooks/useGraphMutation";
import { eventDebounceDuration } from "../../../../../../../constants";
import { useRemoveCEPreview } from "../../useRemoveCEPreview";
import { IconContainer } from "components/IconContainer";
import { CenteredLoadingIndicator } from "components/CenteredLoadingIndicator";
import { ActionModalTitle } from "../../../../ActionModal/ActionModalTitle";
import { generateSendCompEventNoticeRequestQuotationPreviewMutation } from "graphql/mutations/generateSendCompEventNoticeRequestQuotationPreview";
import { NotifyCERequestQuotationSummary } from "./NotifyCERequestQuotationSummary";

export type SendCENoticeAndRequestQuotationProps = {
  claimType: ListItem;
  assumptions?: string;
} & ActionsDialogProps;

export const SendCENoticeAndRequestQuotation: React.FC<
  SendCENoticeAndRequestQuotationProps
> = ({
  claimType,
  assumptions,
  onClose,
  onPrimaryClick,
  onSecondaryClick,
  ...restDialogProps
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const {
    productInstanceId,
    projectName,
    contract,
    regardingId,
    regardingType,
    compEvent,
  } = useContext(CompEventWidgetContext);

  const [compEventPreview, setCompEventPreview] =
    useState<SendCompEventNoticeRequestQuotationPreview>();
  const [fullPreviewModalVisibility, setFullPreviewModalVisibility] =
    useState(false);

  const [generateCEPreview] = useGraphMutation<
    GenerateSendCompEventNoticeRequestQuotationPreviewMutation,
    GenerateSendCompEventNoticeRequestQuotationPreviewMutationVariables
  >(generateSendCompEventNoticeRequestQuotationPreviewMutation, {}, null);
  const { removeCEPreview } = useRemoveCEPreview();

  const toggleFullPreviewModalVisibility = () => {
    setFullPreviewModalVisibility((state) => !state);
  };

  const handlePrimaryClick = () => {
    clearModal();
    onPrimaryClick();
  };

  const handleSecondaryClick = () => {
    clearModal();
    onSecondaryClick();
  };

  const handleClose = async (
    event: {},
    reason: "backdropClick" | "escapeKeyDown"
  ) => {
    clearModal();
    onClose?.(event, reason);
  };

  const clearModal = async () => {
    if (compEventPreview?.id) {
      await removeCEPreview({ variables: { id: compEventPreview.id } });
    }
  };

  const loadPreview = debounce(
    useCallback(async () => {
      const { data } = await generateCEPreview({
        variables: {
          input: {
            claimTypeId: claimType.id,
            assumptions,
            productInstanceId,
            regardingId,
            regardingType,
          },
        },
      });

      if (data) {
        setCompEventPreview(
          data.generateSendCompEventNoticeRequestQuotationPreview
        );
      }
    }, [
      productInstanceId,
      generateCEPreview,
      claimType.id,
      assumptions,
      regardingId,
      regardingType,
    ]),
    eventDebounceDuration
  );

  useEffect(() => {
    loadPreview();
    // TODO: right now we're generating a preview based on the first set of props the component receives. If it was to re-generate every time the props change
    // and knowing how React is working, it would've generated too many preview files which had to be deleted later. If previews turn out not to be accurate,
    // we should instead do a deep equality on the props and remove the old one and generate a new preview only if props are indeed different.

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  return (
    <>
      {compEventPreview && (
        <FullScreenProductItemPreview
          previewUrl={compEventPreview?.fileUrl}
          open={fullPreviewModalVisibility}
          onClose={toggleFullPreviewModalVisibility}
        />
      )}
      <ActionsDialog
        iconsHeader={
          <IconContainer>
            <PaperPlaneTilt
              size={18}
              color={(theme.palette.secondary as Partial<Color>)[700]}
            />
          </IconContainer>
        }
        onPrimaryClick={handlePrimaryClick}
        onSecondaryClick={handleSecondaryClick}
        primaryBtnDisabled={!compEventPreview}
        primaryBtnCaption={t("common.buttons.send")}
        onClose={handleClose}
        fullWidth
        content={
          !compEventPreview ? (
            <CenteredLoadingIndicator />
          ) : (
            <Box>
              <ActionModalTitle mb={2}>
                {t(
                  `Projects.CompEvents.ActionTypes.${CompEventActionType.NotifyCompEventRequestQuotation}`
                )}
              </ActionModalTitle>
              <NotifyCERequestQuotationSummary
                claimType={claimType.name}
                assumptions={assumptions}
              />
              <Box mt={4}>
                <ProductItemCard
                  itemName={compEvent?.number!}
                  productType={ProductType.CompEvents}
                  projectName={projectName}
                  contractName={contract.friendlyName}
                />
              </Box>
              <Box mt={4}>
                <CollapsibleContainer title={t("common.labels.preview")}>
                  <SmallProductItemPreview
                    imageUrl={compEventPreview.fileUrl}
                    onClick={toggleFullPreviewModalVisibility}
                  />
                </CollapsibleContainer>
              </Box>
              <Box mt={4}>
                <CollapsibleContainer
                  title={`${t(`common.labels.recipients`)} (${
                    compEventPreview.recipients.length
                  })`}
                >
                  <RecipientsPreview recipients={compEventPreview.recipients} />
                </CollapsibleContainer>
              </Box>
            </Box>
          )
        }
        sx={{ minWidth: "420px" }}
        {...restDialogProps}
      />
    </>
  );
};
