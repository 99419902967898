import { Box, CircularProgress } from "@mui/material";
import { ProductType } from "generated/graphql";
import React, { useImperativeHandle, forwardRef, useMemo } from "react";
import { ListItemsDivider } from "../../../../components/ListItemsDivider";
import { ChangelogListItem } from "./ChangelogListItem";
import { useChangelog } from "./useChangelog";

export type ChangelogPublicAPI = {
  reload: () => void;
};

export type ChangelogProps = {
  productItemId: string;
  productType: ProductType;
  productInstanceId: string;
  contractTimezone: string;
};

export const Changelog = forwardRef(
  (
    {
      productItemId,
      productType,
      productInstanceId,
      contractTimezone,
    }: ChangelogProps,
    ref: React.Ref<ChangelogPublicAPI>
  ) => {
    const { changelogs, loading, effectPreset, reload } = useChangelog({
      productItemId,
      type: productType,
      productInstanceId,
    });

    const publicAPI = useMemo(
      () => ({
        reload,
      }),
      [reload]
    );

    useImperativeHandle(ref, () => publicAPI, [publicAPI]);

    return loading ? (
      <Box display="flex" justifyContent="center" width="100%">
        <CircularProgress />
      </Box>
    ) : (
      <Box
        display="flex"
        flexDirection="column"
        pb={changelogs?.length ? 3 : 0}
      >
        {changelogs?.length ? (
          <>
            {changelogs.map((changelog, index) => (
              <React.Fragment key={changelog.id}>
                <ChangelogListItem
                  changelog={changelog}
                  effectPreset={effectPreset!}
                  contractTimezone={contractTimezone}
                />
                {index < changelogs.length - 1 && <ListItemsDivider />}
              </React.Fragment>
            ))}
          </>
        ) : null}
      </Box>
    );
  }
);
