import { Stack, Typography, TypographyProps } from "@mui/material";
import { AuthorizationWorkflowAudit } from "generated/graphql";
import { useTranslation } from "react-i18next";
import { getUserName } from "helpers/miscelaneous";
import moment from "moment";
import { dateTimeISOFormat } from "../../../constants";
import { ActionMetaDataContainer } from "containers/Home/components/Authorizations/AuthorizeDenyActionView/AuthorizeDenyActionView.styled";

export type ActionMetadataProps = {
  authAudit: AuthorizationWorkflowAudit;
};

const MetadataHeading: React.FC<TypographyProps> = ({
  children,
  ...restProps
}) => (
  <Typography variant="p2" color="grey.800" fontWeight={600} {...restProps}>
    {children}
  </Typography>
);

const MetadataTextContent: React.FC<TypographyProps> = ({
  children,
  ...restProps
}) => (
  <Typography variant="p1" color="grey.600" {...restProps}>
    {children}
  </Typography>
);

export const ActionMetadata: React.FC<ActionMetadataProps> = ({
  authAudit,
}) => {
  const { t } = useTranslation();

  return (
    <ActionMetaDataContainer spacing={2} direction="column">
      <Stack spacing={0.5}>
        <Typography variant="p2" color="grey.800" fontWeight={600}>
          {t("Home.Authorizations.action")}
        </Typography>
        <Typography variant="p2" color="grey.800" data-testid="action">
          {t(
            `Home.Authorizations.AuthorizeDenyModal.actions.${authAudit.action.productOutputAction.name}`
          )}
        </Typography>
      </Stack>
      <Stack direction="row">
        <Stack spacing={0.5} flex={1}>
          <MetadataHeading>
            {t("Home.Authorizations.initiator")}
          </MetadataHeading>
          <MetadataTextContent data-testid="initiator">
            {getUserName(authAudit.initiatedBy)}
          </MetadataTextContent>
        </Stack>
        <Stack spacing={0.5} flex={1}>
          <MetadataHeading>
            {t("Home.Authorizations.initiated")}
          </MetadataHeading>
          <MetadataTextContent data-testid="initiated">
            {moment(authAudit.dateInitiated).format(dateTimeISOFormat)}
          </MetadataTextContent>
        </Stack>
      </Stack>
    </ActionMetaDataContainer>
  );
};
