import { Box } from "@mui/material";
import {
  GridCellParams,
  GridEventListener,
  GridFilterModel,
  GridLogicOperator,
  GridRowParams,
  GridRowSelectionModel,
  GridSortModel,
} from "@mui/x-data-grid-pro";
import { GridHeader } from "components/GridHeader";
import { StatusOption } from "components/StatusTag/StatusTag";
import { useContractTypeStatusOptions } from "components/StatusTag/useContractTypeStatusOptions";
import { StyledDataGrid } from "components/StyledDataGrid";
import {
  Product,
  ProductsQuery,
  ProductsQueryVariables,
  ProductStatus,
} from "generated/graphql";
import { productsQuery } from "graphql/queries/products.query";
import { exportToExcel } from "helpers/exportToExcel";
import { NewAppPaths } from "helpers/paths/paths";
import { useDataGridVisibleRows } from "hooks/useDataGridVisibleRows";
import { useGraphQuery } from "hooks/useGraphQuery";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useColumns } from "./Products.constants";

export const Products = () => {
  const { t } = useTranslation();
  const statusOptions =
    useContractTypeStatusOptions() as StatusOption<ProductStatus>[];
  const navigate = useNavigate();

  const { visibleRowsCount, gridApiRef } = useDataGridVisibleRows();
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>();
  const [sortingModel, setSortingModel] = useState<GridSortModel>([
    { field: "name", sort: "asc" },
  ]);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [
      {
        field: "status",
        operator: "isAnyOf",
        value: [ProductStatus.Active, ProductStatus.Offline],
      },
    ],
    logicOperator: GridLogicOperator.And,
    quickFilterLogicOperator: GridLogicOperator.And,
    quickFilterValues: [],
  });

  const { data: products, loading: getProductsLoading } = useGraphQuery<
    ProductsQuery,
    ProductsQueryVariables
  >(productsQuery);

  const handleExcelExport = () => {
    const columns = [
      { header: t("common.labels.name"), key: "name", width: 20 },
      { header: t("common.labels.status"), key: "status", width: 20 },
      { header: t("common.labels.dateCreated"), key: "dateCreated", width: 20 },
    ];
    const rows =
      products!.products!.items.filter(
        (product) => (selectionModel || []).indexOf(product.id) >= 0
      ) || [];

    exportToExcel(t("AdminConsole.Products.labels.products"), columns, rows);
  };

  const handleRowClick: GridEventListener<"rowClick"> | undefined = useCallback(
    (rowData: GridRowParams<Product>) => {
      const selection = window.getSelection()?.toString();
      if (!selection) {
        navigate(
          NewAppPaths.authorized.AdminConsole.children.System.children.Products.children.ProductDetails.pathConstructor(
            rowData.row.id
          )
        );
      }
    },
    [navigate]
  );

  const columns = useColumns(statusOptions);

  return (
    <Box display="flex" flexDirection="column" alignItems="center" flex="1">
      <GridHeader
        title={t("AdminConsole.Products.labels.products")}
        actionButtonCaption={t("common.buttons.addEntity", {
          entity: t("AdminConsole.Products.labels.product").toLowerCase(),
        })}
        visibleRowsCount={visibleRowsCount || 0}
        selectedCount={selectionModel?.length || 0}
        onExportToExcel={handleExcelExport}
        sticky
      />
      <Box sx={{ flex: 1, width: "100%" }}>
        <StyledDataGrid
          apiRef={gridApiRef}
          rows={(products?.products.items as any as Product[]) || []}
          columns={columns}
          getRowId={(rowData: Product) => rowData.id}
          onRowSelectionModelChange={setSelectionModel}
          loading={getProductsLoading}
          sortingMode="client"
          sortModel={sortingModel}
          onSortModelChange={setSortingModel}
          filterMode="client"
          filterModel={filterModel}
          onFilterModelChange={setFilterModel}
          onRowClick={handleRowClick}
          getCellClassName={(params: GridCellParams<any, Product, any>) => {
            return params.row.status === ProductStatus.Removed
              ? "greyed-out"
              : "";
          }}
          checkboxSelection
          disableRowSelectionOnClick
        />
      </Box>
    </Box>
  );
};
