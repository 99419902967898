import {
  AuthorizationActionStatus,
  AuthorizationWorkflow,
  AuthorizationWorkflowAudit,
  AuthorizationWorkflowAuditStatus,
  AuthorizationWorkflowLevel,
  AuthorizationWorkflowLevelMode,
  EarlyWarningItem,
  InstructionItem,
  ProductType,
  User,
} from "generated/graphql";

export const computeLevelStatus = (
  level: AuthorizationWorkflowLevel,
  authAudit: AuthorizationWorkflowAudit
): AuthorizationWorkflowAuditStatus => {
  const activeAuthAuditTrails =
    authAudit.auditTrails?.filter(
      (auditTrail) => auditTrail.status !== AuthorizationActionStatus.Pending
    ) ?? [];
  const allLevelApproverIds = level.userIds;
  const settledTrails =
    activeAuthAuditTrails.filter((auditTrail) =>
      allLevelApproverIds.includes(auditTrail.creatorId)
    ) ?? [];

  let status: AuthorizationWorkflowAuditStatus;
  const denialDecision = settledTrails.find(
    (trail) => trail.status === AuthorizationActionStatus.Denied
  );

  if (denialDecision) {
    status = AuthorizationWorkflowAuditStatus.Denied;
  } else {
    const approvalDecisionsLength = settledTrails.filter(
      (trail) => trail.status === AuthorizationActionStatus.Approved
    ).length;

    if (level.mode === AuthorizationWorkflowLevelMode.AnyoneCanAuthorize) {
      status =
        approvalDecisionsLength > 0
          ? AuthorizationWorkflowAuditStatus.Authorized
          : AuthorizationWorkflowAuditStatus.InProgress;
    } else {
      status =
        approvalDecisionsLength === allLevelApproverIds.length
          ? AuthorizationWorkflowAuditStatus.Authorized
          : AuthorizationWorkflowAuditStatus.InProgress;
    }
  }

  return status;
};

export const isLoggedInUserReviewer = (
  authAudit?: AuthorizationWorkflowAudit,
  authWorkflow?: AuthorizationWorkflow,
  authenticatedUser?: User
) => {
  const levels = authAudit?.workflow?.levels ?? authWorkflow?.levels;

  const isUserReviewer = !!(
    authenticatedUser &&
    !!levels?.find((level) => level.userIds.includes(authenticatedUser.id))
  );

  const getLastAuthorizedLevel = (authAudit: AuthorizationWorkflowAudit) => {
    let lastAuthorizedLevel = -1;
    const levels = authAudit.workflow.levels;

    if (!authAudit) {
      return lastAuthorizedLevel;
    }

    for (let index = 0; index < levels.length; index++) {
      const level = levels[index];
      if (
        computeLevelStatus(level, authAudit) ===
        AuthorizationWorkflowAuditStatus.Authorized
      ) {
        lastAuthorizedLevel++;
      } else {
        break;
      }
    }

    return lastAuthorizedLevel;
  };

  // TODO: this could use some tests
  const canUserReviewCrtLevel = () => {
    if (authenticatedUser) {
      if (authAudit) {
        // compute current level and check if loggedIn user is on the crt level of approvers
        const lastAuthorizedLevel = getLastAuthorizedLevel(authAudit);
        if (authAudit?.status === AuthorizationWorkflowAuditStatus.InProgress) {
          const nextLevel = authAudit.workflow.levels.at(
            lastAuthorizedLevel + 1
          );
          const userPartOfCrtLevel = !!nextLevel?.userIds.includes(
            authenticatedUser.id
          );

          if (userPartOfCrtLevel) {
            const userAlreadyAuthorized =
              authAudit.auditTrails?.find(
                (trail) => trail.creatorId === authenticatedUser.id
              )?.status === AuthorizationActionStatus.Approved;
            return !userAlreadyAuthorized;
          }

          return userPartOfCrtLevel;
        } else {
          // nothing more to authorize
          return false;
        }
      } else if (authWorkflow) {
        // verify loggedIn user is on the first level of approvers of the workflow
        return !!authWorkflow.levels
          .at(0)
          ?.userIds.includes(authenticatedUser.id);
      }
    }

    return false;
  };

  return {
    canUserReviewCrtLevel: canUserReviewCrtLevel(),
    isUserReviewer,
  };
};

export const computeRegardingColumnText = (
  authAudit: AuthorizationWorkflowAudit
) => {
  return [
    ProductType.CompEvents,
    ProductType.DailyDiary,
    ProductType.Claims,
    ProductType.Variations,
  ].indexOf(authAudit.regardingProductType) >= 0
    ? authAudit.regardingProductItem.number
    : [ProductType.EarlyWarnings, ProductType.Instructions].indexOf(
        authAudit.regardingProductType
      ) >= 0
    ? (authAudit.regardingProductItem as EarlyWarningItem | InstructionItem)
        .title
    : `${
        authAudit.regardingProductItem.productInstance.numberingFormat ??
        authAudit.regardingProductItem.productInstance.product.numberingFormat
      }XXXX`;
};
