import { gql } from "@apollo/client";
import { productOutputActionFields } from "./productOutputAction.fragment";
import { authorizationWorkflowFields } from "./authorizationWorkflow.fragment";
import { creatorFields } from "./creator.fragment";
import { contractBindingTypeFields } from "./contractBindingType.fragment";

export const authorizationWorkflowActionMappingFields = gql`
  ${productOutputActionFields}
  ${authorizationWorkflowFields}
  ${creatorFields}
  ${contractBindingTypeFields}

  fragment AuthorizationWorkflowActionMappingFields on AuthorizationWorkflowActionMapping {
    id
    productOutputActionId
    productOutputAction {
      ...ProductOutputActionFields
    }
    workflowId
    workflow {
      ...AuthorizationWorkflowFields
    }
    partyId
    party {
      ...ContractBindingTypeFields
    }
    productInstanceId
    productInstance {
      id
      description
      product {
        id
        name
      }
      contractId
      contract {
        id
        friendlyName
        valueCurrency
        project {
          id
          friendlyName
        }
      }
    }
    status
    dateCreated
    creatorId
    creator {
      ...CreatorFields
    }
    modifiedById
    modifiedBy {
      ...CreatorFields
    }
    dateModified
  }
`;
