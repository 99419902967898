import { Box } from "@mui/material";
import { emptyRichTextHTML } from "../../../../../../../constants";
import { CompEventHistoryNoticeOfSilenceCompEventNoticeAnswers } from "generated/graphql";
import { useTranslation } from "react-i18next";
import { NotifyOfSilenceCEHistorySummaryCommon } from "./NotifyOfSilenceCEHistorySummaryCommon";

export const NotifyOfSilenceCENoticeHistorySummary: React.FC<
  CompEventHistoryNoticeOfSilenceCompEventNoticeAnswers
> = ({ remarks, deemedAcceptedDate }) => {
  const { t } = useTranslation();
  const computedValue =
    remarks && remarks !== emptyRichTextHTML
      ? remarks
      : t("common.labels.none");

  return (
    <Box display="flex" flexDirection="column">
      <NotifyOfSilenceCEHistorySummaryCommon
        remarks={computedValue}
        deemedAcceptedDate={deemedAcceptedDate}
      />
    </Box>
  );
};
