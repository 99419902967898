import {
  createContext,
  useState,
  Dispatch,
  RefObject,
  SetStateAction,
  useMemo,
} from "react";
import { CommentEditorPublicAPI } from "./Comment/CommentWithReplyEditor";
import { noop } from "helpers/miscelaneous";

export type CommentsContextType = {
  currentOpenEditorRef: RefObject<CommentEditorPublicAPI> | null;
  setCurrentOpenEditorRef: Dispatch<
    SetStateAction<RefObject<CommentEditorPublicAPI> | null>
  >;
};

export const CommentsContext = createContext<CommentsContextType>({
  currentOpenEditorRef: null,
  setCurrentOpenEditorRef: noop,
});

export const CommentsContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [currentOpenEditorRef, setCurrentOpenEditorRef] =
    useState<RefObject<CommentEditorPublicAPI> | null>(null);

  const contextValue = useMemo(
    () => ({
      currentOpenEditorRef,
      setCurrentOpenEditorRef,
    }),
    [currentOpenEditorRef]
  );

  return (
    <CommentsContext.Provider value={contextValue}>
      {children}
    </CommentsContext.Provider>
  );
};
