import { Box, Color, useTheme } from "@mui/material";
import { ActionsDialog, ActionsDialogProps } from "components/ActionsDialog";
import { CollapsibleContainer } from "components/CollapsibleContainer";
import { FullScreenProductItemPreview } from "containers/Projects/components/FullScreenProductItemPreview";
import { ProductItemCard } from "components/ProductItemCard/ProductItemCard";
import { SmallProductItemPreview } from "containers/Projects/components/SmallProductItemPreview";
import { RecipientsPreview } from "containers/Projects/components/RecipientsPreview";
import {
  ProductType,
  SendVariationAgreementNoticePreview,
  SendVariationConfirmationPreview,
  SendVariationDeterminationNoticePreview,
  SendVariationInstructionCommenceWorkPreview,
  SendVariationNoAgreementNoticePreview,
  SendVariationObjectionProposedVariationPreview,
  SendVariationObjectionVariationPreview,
  SendVariationProposalAbandonmentPreview,
  SendVariationProposalConsentForProposedVariationPreview,
  SendVariationProposalForProposedVariationPreview,
  SendVariationProposalForVariationPreview,
  SendVariationRevisedProposalForProposedVariationRequestPreview,
  SendVariationRevocationPreview,
  VariationActionType,
} from "generated/graphql";
import { PaperPlaneTilt } from "phosphor-react";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { IconContainer } from "components/IconContainer";
import { CenteredLoadingIndicator } from "components/CenteredLoadingIndicator";
import { ActionModalTitle } from "containers/Projects/components/ActionModal/ActionModalTitle";
import { VariationWidgetContext } from "../../VariationWidget/VariationWidget.context";
import { useRemoveVariationPreview } from "../hooks/useRemoveVariationPreview";
import {
  variationActionTypeFIDIC17WhiteTypeMapping,
  variationActionTypeFIDIC99RedYellowTypeMapping,
} from "../../Variations.types";
import { variationActionTypeTranslations } from "../../VariationWidget/VariationWidget.constants";

export type SendActionProps = {
  variationPreview?:
    | SendVariationProposalForProposedVariationPreview
    | SendVariationObjectionProposedVariationPreview
    | SendVariationAgreementNoticePreview
    | SendVariationProposalConsentForProposedVariationPreview
    | SendVariationNoAgreementNoticePreview
    | SendVariationRevisedProposalForProposedVariationRequestPreview
    | SendVariationProposalAbandonmentPreview
    | SendVariationDeterminationNoticePreview
    | SendVariationProposalForVariationPreview
    | SendVariationRevocationPreview
    | SendVariationObjectionVariationPreview
    | SendVariationConfirmationPreview
    | SendVariationInstructionCommenceWorkPreview;
  actionType: VariationActionType;
  summaryEl: React.ReactNode;
} & ActionsDialogProps;

// Note: maybe merge with SendAction from Claims
export const SendAction: React.FC<SendActionProps> = ({
  variationPreview,
  actionType,
  summaryEl,
  onClose,
  onPrimaryClick,
  onSecondaryClick,
  ...restDialogProps
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const {
    productInstanceNumberingFormat,
    variation,
    projectName,
    contract,
    isFIDIC17White,
    isFIDIC99RedYellow,
  } = useContext(VariationWidgetContext);

  const [fullPreviewModalVisibility, setFullPreviewModalVisibility] =
    useState(false);

  const { removeVariationPreview } = useRemoveVariationPreview();

  const toggleFullPreviewModalVisibility = () => {
    setFullPreviewModalVisibility((state) => !state);
  };

  const handlePrimaryClick = () => {
    clearModal();
    onPrimaryClick();
  };

  const handleSecondaryClick = () => {
    clearModal();
    onSecondaryClick();
  };

  const handleClose = async (
    event: {},
    reason: "backdropClick" | "escapeKeyDown"
  ) => {
    clearModal();
    onClose?.(event, reason);
  };

  const clearModal = async () => {
    if (variationPreview?.id) {
      await removeVariationPreview({ variables: { id: variationPreview.id } });
    }
  };

  return (
    <>
      {variationPreview && (
        <FullScreenProductItemPreview
          previewUrl={variationPreview?.fileUrl}
          open={fullPreviewModalVisibility}
          onClose={toggleFullPreviewModalVisibility}
        />
      )}
      <ActionsDialog
        iconsHeader={
          <IconContainer>
            <PaperPlaneTilt
              size={18}
              color={(theme.palette.secondary as Partial<Color>)[700]}
            />
          </IconContainer>
        }
        onPrimaryClick={handlePrimaryClick}
        onSecondaryClick={handleSecondaryClick}
        primaryBtnCaption={t("common.buttons.send")}
        primaryBtnDisabled={!variationPreview}
        onClose={handleClose}
        fullWidth
        content={
          !variationPreview ? (
            <CenteredLoadingIndicator />
          ) : (
            <Box>
              <ActionModalTitle mb={2}>
                {t(
                  variationActionTypeTranslations[
                    isFIDIC99RedYellow
                      ? variationActionTypeFIDIC99RedYellowTypeMapping(
                          actionType
                        )
                      : isFIDIC17White
                      ? variationActionTypeFIDIC17WhiteTypeMapping(actionType)
                      : actionType
                  ]
                )}
              </ActionModalTitle>
              {summaryEl}
              <Box mt={4}>
                <ProductItemCard
                  itemName={
                    variation?.number ??
                    `${productInstanceNumberingFormat}-XXXX`
                  }
                  productType={ProductType.Variations}
                  projectName={projectName}
                  contractName={contract.friendlyName}
                />
              </Box>
              <Box mt={4}>
                <CollapsibleContainer title={t("common.labels.preview")}>
                  <SmallProductItemPreview
                    imageUrl={variationPreview.fileUrl}
                    onClick={toggleFullPreviewModalVisibility}
                  />
                </CollapsibleContainer>
              </Box>
              <Box mt={4}>
                <CollapsibleContainer
                  title={`${t(`common.labels.recipients`)} (${
                    variationPreview.recipients.length
                  })`}
                >
                  <RecipientsPreview recipients={variationPreview.recipients} />
                </CollapsibleContainer>
              </Box>
            </Box>
          )
        }
        sx={{ minWidth: "420px" }}
        {...restDialogProps}
      />
    </>
  );
};
