import { Grid, Typography, TypographyProps } from "@mui/material";
import { CenteredLoadingIndicator } from "components/CenteredLoadingIndicator";
import { StatusTagNew } from "components/StatusTag/StatusTagNew";
import { dateISOFormat } from "../../../../../constants";
import { dailyDiaryStatusOptions } from "containers/Projects/components/DailyDiariesView/DailyDiariesView.constants";
import { DailyDiaryItemStatus, User } from "generated/graphql";
import { getUserName } from "helpers/miscelaneous";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { SectionTitle } from "./SectionTitle";

export type DailyDiaryGeneralInfoProps = {
  loading?: boolean;
  dailyDiaryNumber: string;
  revisionStatus: DailyDiaryItemStatus;
  dateSent?: string;
  sentBy?: User;
};

const SectionDataHeader: React.FC<TypographyProps> = ({
  children,
  ...props
}) => (
  <Typography variant="p2" fontWeight={600} color="grey.800" {...props}>
    {children}
  </Typography>
);

const SectionData: React.FC<TypographyProps> = ({ children, ...props }) => (
  <Typography variant="p1" color="grey.600" {...props}>
    {children}
  </Typography>
);

export const DailyDiaryGeneralInfo: React.FC<DailyDiaryGeneralInfoProps> = ({
  loading,
  dailyDiaryNumber,
  revisionStatus,
  dateSent,
  sentBy,
}) => {
  const { t } = useTranslation();

  return loading ? (
    <CenteredLoadingIndicator />
  ) : (
    <>
      <SectionTitle pb={3}>
        {t("Projects.DailyDiaries.generalInfo")}
      </SectionTitle>
      <Grid container width="100%" spacing={3}>
        <Grid item xs={6} display="flex" flexDirection="column" flex={1}>
          <SectionDataHeader mb={0.5}>
            {t("Projects.DailyDiaries.diaryNumber")}
          </SectionDataHeader>
          <SectionData>{dailyDiaryNumber}</SectionData>
        </Grid>
        <Grid item xs={6} display="flex" flexDirection="column" flex={1}>
          <SectionDataHeader mb={0.5}>
            {t("common.labels.status")}
          </SectionDataHeader>
          <StatusTagNew
            selectedOptionId={revisionStatus}
            disabled
            options={dailyDiaryStatusOptions}
          />
        </Grid>
        {dateSent && sentBy && (
          <>
            <Grid item xs={6} display="flex" flexDirection="column" flex={1}>
              <SectionDataHeader mb={0.5}>
                {t("common.labels.dateSent")}
              </SectionDataHeader>
              <SectionData>
                {moment(dateSent).format(dateISOFormat)}
              </SectionData>
            </Grid>
            <Grid item xs={6} display="flex" flexDirection="column" flex={1}>
              <SectionDataHeader mb={0.5}>
                {t("common.labels.sentBy")}
              </SectionDataHeader>
              <SectionData>{getUserName(sentBy)}</SectionData>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};
