import { Stack } from "@mui/material";
import {
  AuthorizationWorkflowAuditTrail,
  AuthorizationWorkflowLevel,
} from "generated/graphql";
import { ReviewersLevel } from "./ReviewersLevel";

type ReviewersListProps = {
  authLevels: AuthorizationWorkflowLevel[];
  auditTrails: AuthorizationWorkflowAuditTrail[];
};

export const ReviewersList: React.FC<ReviewersListProps> = ({
  authLevels,
  auditTrails,
}) => {
  return (
    <Stack spacing={3} width="100%">
      {authLevels.map((level, index) => {
        const levelAuditTrails = auditTrails.filter((trail) =>
          level.userIds.includes(trail.creatorId)
        );
        return (
          <ReviewersLevel
            key={level.sequence}
            level={level}
            levelNo={index + 1}
            auditTrails={levelAuditTrails}
          />
        );
      })}
    </Stack>
  );
};
