import { Box, Typography, useTheme } from "@mui/material";
import { ItemTag } from "components/ItemTag";
import {
  FieldTypeEnum,
  ProductInstanceSchemaQuery,
  ProductInstanceSchemaQueryVariables,
} from "generated/graphql";
import { useMemo } from "react";
import { SeverityReadOnly } from "containers/Projects/components/SchemaInterpretor/Field/Severity/SeverityReadOnly";
import { defaultSeverityPreset } from "containers/Projects/components/SchemaInterpretor/Field/Severity/Severity.constants";
import { SeverityValue } from "containers/Projects/components/SchemaInterpretor/Field/Severity/Severity.decl";
import { useGraphQuery } from "hooks/useGraphQuery";
import { productInstanceSchemaQuery } from "graphql/queries/productInstanceSchema.query";
import { EffectWidgetValue } from "containers/Projects/components/SchemaInterpretor/Field/Effect/Effect.decl";
import { Effect } from "containers/Projects/components/SchemaInterpretor/Field/Effect/Effect";
import { ProductItem } from "containers/Projects/Projects.decl";
import { RichTextArea } from "components/RichTextArea/RichTextArea";
import { ProductItemPreviewContainer } from "components/ProductItemPreview/ProductItemPreviewContainer";
import { ProductItemTypenameToProduct } from "containers/Projects/Projects.constants";

export type ProductItemPreviewProps<T extends ProductItem> = {
  productItem: T;
  includeSeverity?: boolean;
};

// WARN: we rely on Details field from General description to not change its name. In case it does, it won't be shown in the preview popup. (TODO for better solution)
const detailsFieldName = "Details";

export const ProductItemPreview = <T extends ProductItem>({
  productItem,
  includeSeverity = true,
}: ProductItemPreviewProps<T>) => {
  const theme = useTheme();

  const {
    data: productInstanceSchemaData,
    loading: getProductInstanceSchemaDataLoading,
  } = useGraphQuery<
    ProductInstanceSchemaQuery,
    ProductInstanceSchemaQueryVariables
  >(productInstanceSchemaQuery, {
    variables: { id: productItem.productInstanceId },
    fetchPolicy: "cache-first",
  });

  const productItemType = ProductItemTypenameToProduct[productItem.__typename!];

  const productItemDescription = useMemo(() => {
    const sectionWithDetailsField = productItem.data.sections.find(
      (section) => {
        return !!section.entries.find(
          (field) => field.name.toLowerCase() === detailsFieldName.toLowerCase()
        );
      }
    );

    return sectionWithDetailsField
      ? sectionWithDetailsField.entries.find(
          (field) => field.name.toLowerCase() === detailsFieldName.toLowerCase()
        )!.value
      : null;
  }, [productItem]);

  const severityValue: SeverityValue = productItem.severity
    ? JSON.parse(productItem.severity)
    : undefined;

  const effectValue = useMemo(() => {
    const sectionNameThatContainsEffect =
      productInstanceSchemaData?.productInstance.productSchema.schemaSections.items.find(
        (section) =>
          section.schemaFields.items.find(
            (field) => field.fieldType.shortCode === FieldTypeEnum.Eff
          )
      )?.name;

    const effectValueStr = productItem.data.sections.find(
      (section) => section.name === sectionNameThatContainsEffect
    )?.entries[0].value;

    return effectValueStr
      ? (JSON.parse(effectValueStr) as EffectWidgetValue)
      : undefined;
  }, [productInstanceSchemaData, productItem]);

  return (
    <ProductItemPreviewContainer loading={getProductInstanceSchemaDataLoading}>
      <Box display="flex" alignItems="center" width="100%">
        <ItemTag type={productItemType} />
        <Typography
          ml={1.5}
          variant="h3"
          color={theme.palette.grey[900]}
          overflow="hidden"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
        >
          {productItem.title}
          <Typography
            variant="h3"
            component="span"
            fontWeight={400}
            ml={0.75}
            color={theme.palette.grey[900]}
          >
            {`(${productItem.number})`}
          </Typography>
        </Typography>
      </Box>
      {productItemDescription && (
        <Box mt={1}>
          <RichTextArea
            readOnly
            content={productItemDescription ?? ""}
            lineClamp={4}
          />
        </Box>
      )}
      {includeSeverity && (
        <Box mt={3} width="100%">
          <SeverityReadOnly
            size="small"
            severityPreset={defaultSeverityPreset}
            consequenceValue={severityValue?.consequence}
            likelihoodValue={severityValue?.likelihood}
          />
        </Box>
      )}
      {effectValue && (
        <Box mt={3} width="100%">
          <Effect
            editable={false}
            noOverflow
            productInstanceId={productItem.productInstanceId}
            data={effectValue}
            contractCurrency={
              productItem.productInstance.contract.valueCurrency ?? ""
            }
          />
        </Box>
      )}
    </ProductItemPreviewContainer>
  );
};
