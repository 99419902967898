import { Box, Typography, useTheme } from "@mui/material";
import { emptyRichTextHTML } from "../../../../constants";
import { RichTextArea } from "components/RichTextArea/RichTextArea";
import { memo } from "react";
import { useTranslation } from "react-i18next";

export type ReasonSummaryProps = { reason?: string; label?: string };

export const ReasonSummary: React.FC<ReasonSummaryProps> = memo(
  ({ reason, label }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const computedLabel = label ?? t("common.labels.reason");
    const computedValue =
      reason && reason !== emptyRichTextHTML ? reason : t("common.labels.none");

    return (
      <Box display="flex" flexDirection="column">
        <Typography variant="p2" color="grey.800" fontWeight={600} mb={0.5}>
          {computedLabel}
        </Typography>
        <RichTextArea
          readOnly
          content={computedValue}
          color={theme.palette.grey[600]}
        />
      </Box>
    );
  }
);
