import { debounce } from "@mui/material";
import { useCallback, useContext, useEffect, useState } from "react";
import { generateSendClaimNoticePreviewMutation } from "./NotifyClaimAction.query";
import {
  GenerateSendClaimNoticePreviewMutation,
  GenerateSendClaimNoticePreviewMutationVariables,
  ListItem,
  SendClaimNoticePreview,
} from "generated/graphql";
import { useGraphMutation } from "hooks/useGraphMutation";
import { ClaimWidgetContext } from "../../ClaimWidget/ClaimWidget.context";
import { eventDebounceDuration } from "../../../../../../constants";

export const useSendNotifyClaimAction = (
  claimType: ListItem,
  awGiven?: ListItem
) => {
  const [claimPreview, setClaimPreview] = useState<SendClaimNoticePreview>();

  const { productInstanceId, regardingId, regardingType } =
    useContext(ClaimWidgetContext);

  const [generateClaimPreview] = useGraphMutation<
    GenerateSendClaimNoticePreviewMutation,
    GenerateSendClaimNoticePreviewMutationVariables
  >(generateSendClaimNoticePreviewMutation, {}, null);

  const loadPreview = debounce(
    useCallback(async () => {
      const { data } = await generateClaimPreview({
        variables: {
          input: {
            claimTypeId: claimType.id,
            advanceWarningId: awGiven?.id,
            productInstanceId,
            regardingId,
            regardingType,
          },
        },
      });

      if (data) {
        setClaimPreview(data.generateSendClaimNoticePreview);
      }
    }, [
      productInstanceId,
      generateClaimPreview,
      claimType.id,
      awGiven?.id,
      regardingId,
      regardingType,
    ]),
    eventDebounceDuration
  );

  useEffect(() => {
    loadPreview();
    // TODO: right now we're generating a preview based on the first set of props the component receives. If it was to re-generate every time the props change
    // and knowing how React is working, it would've generated too many preview files which had to be deleted later. If previews turn out not to be accurate,
    // we should instead do a deep equality on the props and remove the old one and generate a new preview only if props are indeed different.

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  return {
    claimPreview,
  };
};
