import {
  ProductTypename,
  ProductTypenameToProduct,
} from "containers/Projects/Projects.constants";
import {
  ProductInstanceStatus,
  ProductType,
  ProductUserRoleStatus,
} from "generated/graphql";
import { useContext, useMemo } from "react";
import { GlobalContext } from "state-management/globalContext/Global.context";

export const useEarlyWarningsProductInstance = (contractId: string) => {
  const { authenticatedUser, loading } = useContext(GlobalContext);

  const earlyWarningsProductInstance = useMemo(() => {
    const earlyWarningsProdInstances = authenticatedUser?.roles.items
      .filter((role) => {
        const temp =
          role.status === ProductUserRoleStatus.Active &&
          ProductTypenameToProduct[
            (role.productInstance.product as any).__typename as ProductTypename
          ] === ProductType.EarlyWarnings;

        return temp;
      })
      .map((role) => role.productInstance)
      .filter(
        (prodInstance) => prodInstance.status === ProductInstanceStatus.Active
      )
      .filter((prodInstance) => prodInstance.contractId === contractId);

    return earlyWarningsProdInstances?.[0];
  }, [authenticatedUser, contractId]);

  return {
    earlyWarningsProductInstance,
    loading,
  };
};
