import { CommonTabs } from "../../../../../constants";
import { StyledProductItemsHeader } from "containers/Projects/components/ProductItemsView/ProductItemsView.styled";
import { TabWithCount } from "containers/Projects/components/TabsWithCount/TabsWithCount";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  AuthorizationWorkflowAudit,
  AuthorizationWorkflowAuditStatus,
  DailyDiaryItem,
  ProductType,
} from "generated/graphql";
import { useAuthorizationsInitiatedByYou } from "./useAuthorizationInitiatedByYou";
import { AuthorizationInitiatedByYouTable } from "./AuthorizationInitiatedByYouTable";
import { ProductItemsTablePublicAPI } from "containers/Projects/components/ProductItemsView/ProductItemsView.decl";
import { GridRowSelectionModel } from "@mui/x-data-grid-pro";
import { useNavigate } from "react-router-dom";
import { NewAppPaths } from "helpers/paths/paths";
import { TableContainer } from "../../TableContainer";
import { useAuthStore } from "state-management/store/authStore";

export const AuthorizationsInitiatedByYou: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const setAuthModalState = useAuthStore((state) => state.setAuthModalState);
  const [selectedTab, setSelectedTab] = useState<string>(CommonTabs.All);

  const tableRef = useRef<ProductItemsTablePublicAPI>(null);
  const [tableSelectionModel, setTableSelectionModel] =
    useState<GridRowSelectionModel>();

  const [localAuthAudits, setLocalAuthAudits] = useState<
    AuthorizationWorkflowAudit[]
  >([]);

  const { authorizationsInitiatedByYou, hasMore, loadMore, loading } =
    useAuthorizationsInitiatedByYou();

  const handleClearSelection = () => {
    tableRef.current?.clearSelection();
  };

  const handleExportToExcel = () => {
    tableRef.current?.exportToExcel();
  };

  const handleRowClick = (authAudit: AuthorizationWorkflowAudit) => {
    if (authAudit.regardingProductType === ProductType.DailyDiary) {
      const dailyDiaryItem = authAudit.regardingProductItem as DailyDiaryItem;

      navigate(
        NewAppPaths.authorized.Projects.children.DailyDiaryDetails.pathConstructor(
          dailyDiaryItem.productInstance.id,
          dailyDiaryItem.date,
          dailyDiaryItem.id
        )
      );
    } else {
      setAuthModalState({
        authAuditId: authAudit.id,
        type: "AuditTrail",
      });
    }
  };

  const tabs = useMemo(() => {
    const localTabs: TabWithCount[] = [
      {
        id: CommonTabs.All,
        label: t("common.labels.all"),
        count: authorizationsInitiatedByYou.length,
      },
    ];

    Object.values(AuthorizationWorkflowAuditStatus).forEach((statusOption) => {
      localTabs.push({
        id: statusOption,
        label: t(`Home.Authorizations.status.${statusOption}`),
        count:
          authorizationsInitiatedByYou.filter(
            (action) => action.status === statusOption
          ).length || 0,
      });
    });

    return localTabs;
  }, [authorizationsInitiatedByYou, t]);

  useEffect(() => {
    if (selectedTab === CommonTabs.All) {
      setLocalAuthAudits(authorizationsInitiatedByYou);
      return;
    }

    setLocalAuthAudits(
      authorizationsInitiatedByYou.filter(
        (audit) => audit.status === selectedTab
      )
    );
  }, [selectedTab, authorizationsInitiatedByYou]);

  return (
    <>
      <StyledProductItemsHeader
        tabs={tabs}
        selectedItemsCount={tableSelectionModel?.length ?? 0}
        onSelectedTabChange={setSelectedTab}
        onExportToExcel={handleExportToExcel}
        onClearSelection={handleClearSelection}
      />
      <TableContainer
        mt={3}
        height={500}
        data-testid="auth-container-initiated-by-you"
      >
        <AuthorizationInitiatedByYouTable
          selectionModel={tableSelectionModel}
          audits={localAuthAudits}
          loading={loading}
          apiRef={tableRef}
          hasMore={hasMore}
          onRowClick={handleRowClick}
          onLoadMore={loadMore}
          onSelectionModelChange={setTableSelectionModel}
        />
      </TableContainer>
    </>
  );
};
