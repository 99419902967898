import { Stack, Typography, css, styled } from "@mui/material";
import { OverflowTooltip } from "components/OverflowTooltip";

type DailyDiaryRecordSummaryProps = {
  recordJSON: Record<string, string>;
};

const Container = styled(Stack)(
  ({ theme }) => css`
    border: 1px solid ${theme.palette.grey[200]};
    background: ${theme.palette.background.default};
    padding: ${theme.spacing(2)} ${theme.spacing(4)};
    border-radius: 8px;
    position: sticky;
    top: 84px;
    z-index: 2;
  `
);

export const DailyDiaryRecordSummary: React.FC<
  DailyDiaryRecordSummaryProps
> = ({ recordJSON }) => {
  return (
    <Container direction="row">
      {Object.entries(recordJSON).map(([key, value]) => (
        <Stack spacing={0.5} flex={1} key={key} direction="column">
          <Typography variant="p2" fontWeight={600} color="grey.800">
            {key}
          </Typography>
          <OverflowTooltip
            title={value}
            typographyProps={{
              variant: "p2",
              color: "grey.600",
              maxWidth: "200px",
            }}
          />
        </Stack>
      ))}
    </Container>
  );
};
