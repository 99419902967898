import { useCallback, useContext, useEffect, useState } from "react";
import { ClaimWidgetContext } from "../../ClaimWidget/ClaimWidget.context";
import { useGraphMutation } from "hooks/useGraphMutation";
import {
  GenerateSendClaimFurtherParticularsSubmissionPreviewMutation,
  GenerateSendClaimFurtherParticularsSubmissionPreviewMutationVariables,
  SendClaimFurtherParticularsSubmissionPreview,
} from "generated/graphql";
import { debounce } from "@mui/material";
import { eventDebounceDuration } from "../../../../../../constants";
import { generateSendClaimFurtherParticularsSubmissionPreviewMutation } from "./ProvideFurtherParticularsAction.query";

export const useSendFurtherParticularsSubmissionModal = (details: string) => {
  const [claimPreview, setClaimPreview] =
    useState<SendClaimFurtherParticularsSubmissionPreview>();

  const { claim, contract } = useContext(ClaimWidgetContext);

  const [generateClaimPreview] = useGraphMutation<
    GenerateSendClaimFurtherParticularsSubmissionPreviewMutation,
    GenerateSendClaimFurtherParticularsSubmissionPreviewMutationVariables
  >(generateSendClaimFurtherParticularsSubmissionPreviewMutation, {}, null);

  const loadPreview = debounce(
    useCallback(async () => {
      const { data } = await generateClaimPreview({
        variables: {
          input: {
            claimId: claim!.id,
            details,
          },
        },
      });

      if (data) {
        setClaimPreview(
          data.generateSendClaimFurtherParticularsSubmissionPreview
        );
      }
    }, [generateClaimPreview, claim, details]),
    eventDebounceDuration
  );

  useEffect(() => {
    loadPreview();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  return {
    claimPreview,
    contract,
  };
};
