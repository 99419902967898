import { ActionsDialogProps } from "components/ActionsDialog";
import { ClaimActionType } from "generated/graphql";
import { SendAction } from "../components/SendAction";
import { EnhancedAttachment } from "containers/Projects/components/Attachments/Attachments.decl";
import { ReasonsAttachmentsSummary } from "../../../ActionModal/ReasonsAttachmentsSummary";
import { useSendErrorFoundInAgreementNoticeModal } from "./useSendErrorFoundInAgreementNoticeModal";
import { useTranslation } from "react-i18next";

export type SendErrorFoundInAgreementNoticeModalProps = {
  details: string;
  attachments?: EnhancedAttachment[];
} & ActionsDialogProps;

export const SendErrorFoundInAgreementNoticeModal: React.FC<
  SendErrorFoundInAgreementNoticeModalProps
> = ({ details, attachments = [], ...restDialogProps }) => {
  const { claimPreview } = useSendErrorFoundInAgreementNoticeModal(details);
  const { t } = useTranslation();

  return (
    <>
      <SendAction
        claimPreview={claimPreview}
        actionType={ClaimActionType.NotifyErrorFoundAgreement}
        summaryEl={
          <ReasonsAttachmentsSummary
            text={details}
            label={t("Projects.Claims.ActionModal.errorsFound")}
            attachments={attachments}
          />
        }
        {...restDialogProps}
      />
    </>
  );
};
