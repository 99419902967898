import { OnMutationDone } from "decl";
import {
  AddContractSectionInput,
  Contract,
  ContractSectionStatus,
  EditContractInput,
  EditContractMutation,
  EditContractMutationVariables,
  EditContractSectionInput,
} from "generated/graphql";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useSnackbar } from "notistack";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { editContractMutation } from "../ContractDetails.query";
import { contractToEditContractInput } from "../ContractDetails.utils";
import { snackbarAutoHideDuration } from "../../../../../../constants";

export type useContractSectionsArgs = {
  contract?: Contract;
  onEdit: OnMutationDone;
};

export const useContractSections = ({
  contract,
  onEdit,
}: useContractSectionsArgs) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [editContract, { loading }] = useGraphMutation<
    EditContractMutation,
    EditContractMutationVariables
  >(
    editContractMutation,
    {
      update: onEdit,
    },
    null
  );

  const handleAddSection = useCallback(
    async (newSection: AddContractSectionInput) => {
      const contractToUpdate: EditContractInput = contractToEditContractInput(
        contract!
      );
      contractToUpdate.sections?.push({
        ...newSection,
        status: ContractSectionStatus.Active,
      });

      const { data } = await editContract({
        variables: {
          input: contractToUpdate,
        },
      });

      if (data) {
        enqueueSnackbar(
          t("common.successMessages.entityCreated", {
            entity: t("AdminConsole.ContractSections.contractSection"),
          }),
          {
            autoHideDuration: snackbarAutoHideDuration,
            variant: "success",
          }
        );
      }

      return !!data;
    },
    [editContract, contract, t, enqueueSnackbar]
  );

  const handleEditSection = useCallback(
    async (updatedSection: EditContractSectionInput) => {
      const isDeleteCase =
        updatedSection.status === ContractSectionStatus.Removed;
      const contractToUpdate: EditContractInput = contractToEditContractInput(
        contract!
      );

      if (isDeleteCase) {
        contractToUpdate.sections = contractToUpdate.sections?.filter(
          (section) => section.id !== updatedSection.id
        );
      } else {
        contractToUpdate.sections = contractToUpdate.sections?.map((section) =>
          section.id === updatedSection.id ? updatedSection : section
        );
      }

      const { data } = await editContract({
        variables: {
          input: contractToUpdate,
        },
      });

      if (data) {
        enqueueSnackbar(
          t(
            `common.successMessages.${
              isDeleteCase ? "entityDeleted" : "entityUpdated"
            }`,
            {
              entity: t("AdminConsole.ContractSections.contractSection"),
            }
          ),
          {
            autoHideDuration: snackbarAutoHideDuration,
            variant: "success",
          }
        );
      }

      return !!data;
    },
    [editContract, contract, t, enqueueSnackbar]
  );

  return {
    addSection: handleAddSection,
    editSection: handleEditSection,
    loading,
  };
};
