import { VariationActionType } from "generated/graphql";
import {
  VariationActionTypeAll,
  VariationActionTypeFIDIC17White,
  VariationActionTypeFIDIC99RedYellow,
} from "../Variations.types";

export const variationActionTypeTranslations: Record<
  VariationActionTypeAll,
  string
> = {
  [VariationActionType.SubmitProposalForVariation]:
    "Projects.Variations.ActionTypes.SubmitProposalForVariation",
  [VariationActionType.SubmitProposalForProposedVariation]:
    "Projects.Variations.ActionTypes.SubmitProposalForProposedVariation",
  [VariationActionType.ConsentToProposalForProposedVariation]:
    "Projects.Variations.ActionTypes.ConsentToProposalForProposedVariation",
  [VariationActionTypeFIDIC99RedYellow.ConsentToProposalForProposedVariation99RY]:
    "Projects.Variations.ActionTypes.ConsentToProposalForProposedVariation99RY",
  [VariationActionType.GiveReasonsWhyProposalCannotBeGivenForProposedVariation]:
    "Projects.Variations.ActionTypes.GiveReasonsWhyProposalCannotBeGivenForProposedVariation",
  [VariationActionType.NotifyAgreementVariation]:
    "Projects.Variations.ActionTypes.NotifyAgreementVariation",
  [VariationActionType.NotifyAgreementVariationNotReached]:
    "Projects.Variations.ActionTypes.NotifyAgreementVariationNotReached",
  [VariationActionType.RequestRevisedProposalForProposedVariation]:
    "Projects.Variations.ActionTypes.RequestRevisedProposalForProposedVariation",
  [VariationActionType.NotifyDeterminationVariation]:
    "Projects.Variations.ActionTypes.NotifyDeterminationVariation",
  [VariationActionType.AbandonProposedVariation]:
    "Projects.Variations.ActionTypes.AbandonProposedVariation",
  [VariationActionTypeFIDIC99RedYellow.AbandonProposedVariation99RY]:
    "Projects.Variations.ActionTypes.AbandonProposedVariation99RY",
  [VariationActionType.RevokeInstructionOfVariation]:
    "Projects.Variations.ActionTypes.RevokeInstructionOfVariation",
  [VariationActionTypeFIDIC17White.RevokeInstructionOfVariationFIDIC17White]:
    "Projects.Variations.ActionTypes.RevokeInstructionOfVariationFIDIC17White",
  [VariationActionTypeFIDIC17White.AbandonProposedVariationFIDIC17White]:
    "Projects.Variations.ActionTypes.AbandonProposedVariationFIDIC17White",
  [VariationActionTypeFIDIC17White.ConsentToProposalForProposedVariationFIDIC17White]:
    "Projects.Variations.ActionTypes.ConsentToProposalForProposedVariationFIDIC17White",
  [VariationActionTypeFIDIC17White.ConfirmInstructionOfVariationFIDIC17White]:
    "Projects.Variations.ActionTypes.ConfirmInstructionOfVariationFIDIC17White",
  [VariationActionType.ObjectToInstructionOfVariation]:
    "Projects.Variations.ActionTypes.ObjectToInstructionOfVariation",
  [VariationActionType.ConfirmInstructionOfVariation]:
    "Projects.Variations.ActionTypes.ConfirmInstructionOfVariation",
  [VariationActionType.InstructWorkToCommenceOnVariation]:
    "Projects.Variations.ActionTypes.InstructWorkToCommenceOnVariation",
} as any;

/**
 * Note: many of these intentionally don't have translations because the text displayed for `NextStep` is
 * actually the next action to take in a happy flow (submit variation proposal -> agreement)
 * with some minor deviations like requestMoreTime, etc.
 */
export const variationStatusToNextStepWordingMap: Record<
  VariationActionTypeAll,
  string | null
> = {
  [VariationActionType.None]: "Projects.Variations.nextStepWordings.None",
  [VariationActionType.SubmitProposalForProposedVariation]:
    "Projects.Variations.nextStepWordings.SubmitProposalForProposedVariation",
  [VariationActionType.ConsentToProposalForProposedVariation]:
    "Projects.Variations.nextStepWordings.ConsentToProposalForProposedVariation",
  [VariationActionType.NotifyAgreementVariation]:
    "Projects.Variations.nextStepWordings.NotifyAgreementVariation",
  [VariationActionType.NotifyAgreementVariationNotReached]:
    "Projects.Variations.nextStepWordings.NotifyAgreementVariationNotReached",
  [VariationActionType.NotifyDeterminationVariation]:
    "Projects.Variations.nextStepWordings.NotifyDeterminationVariation",
  [VariationActionType.SubmitProposalForVariation]:
    "Projects.Variations.nextStepWordings.SubmitProposalForVariation",
  [VariationActionType.ConfirmInstructionOfVariation]:
    "Projects.Variations.nextStepWordings.ConfirmInstructionOfVariation",
  [VariationActionType.InstructWorkToCommenceOnVariation]:
    "Projects.Variations.nextStepWordings.InstructWorkToCommenceOnVariation",
  [VariationActionTypeFIDIC17White.ConfirmInstructionOfVariationFIDIC17White]:
    "Projects.Variations.nextStepWordings.ConfirmInstructionOfVariationFIDIC17White",
} as any; // TODO: remove when all completed
