import { Typography } from "@mui/material";
import { ActionsDialogProps } from "components/ActionsDialog";
import { BasicModal } from "components/BasicModal/BasicModal";
import { IconContainer } from "components/IconContainer";
import { FormPublicApi } from "decl";
import {
  AddDailyDiaryWorkRecordInput,
  AttachmentInput,
  DailyDiaryPresetSection,
  DailyDiaryWorkRecord,
  EditDailyDiaryWorkRecordInput,
} from "generated/graphql";
import { Plus } from "phosphor-react";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { RecordModalFooter } from "../../RecordModalFooter";
import { WorkLookups, WorkOptionalColumn } from "../WorkTable/WorkTable";
import { WorkRecordForm } from "./WorkRecordForm";
import { useDisableBackdropClick } from "../../../hooks/useDisableBackdropClick";

export type WorkRecordModalProps = {
  workRecord?: DailyDiaryWorkRecord;
  workLookups: WorkLookups;
  section: DailyDiaryPresetSection;
  optionalColumns: WorkOptionalColumn[];
  onAttachmentsUpdated: (attachments: AttachmentInput[]) => void;
  onPrimaryClick: (
    workRecord: AddDailyDiaryWorkRecordInput | EditDailyDiaryWorkRecordInput,
    closeModal: boolean
  ) => void;
  onSecondaryClick: () => void;
} & Omit<ActionsDialogProps, "onPrimaryClick" | "onSecondaryClick">;

export const WorkRecordModal: React.FC<WorkRecordModalProps> = ({
  workRecord,
  workLookups,
  optionalColumns,
  section,
  onAttachmentsUpdated,
  onPrimaryClick,
  onSecondaryClick,
  onClose,
  primaryBtnCaption,
  secondaryBtnCaption,
  primaryBtnLoading,
  secondaryBtnLoading,
  primaryBtnDisabled,
  ...restDialogProps
}) => {
  const { t } = useTranslation();
  const formApiRef = useRef<FormPublicApi>(null);
  const { handleClose } = useDisableBackdropClick(onClose);

  const [addAnotherRecord, setAddAnotherRecord] = useState(false);
  const [modalFooterKey, setModalFooterKey] = useState(0); // TODO: remove this when updating useAttachments module or creating a new one specific for modals

  const workRecordRef = useRef<
    AddDailyDiaryWorkRecordInput | EditDailyDiaryWorkRecordInput
  >();

  const handleWorkRecordChange = (
    updatedWorkRecord:
      | EditDailyDiaryWorkRecordInput
      | AddDailyDiaryWorkRecordInput
  ) => {
    workRecordRef.current = updatedWorkRecord;
  };

  const handlePrimaryBtnClick = () => {
    if (formApiRef.current?.validate()) {
      if (workRecord) {
        // edit
        onPrimaryClick(workRecordRef.current!, true);
      } else {
        // add
        const { id, ...addRecordInput } =
          workRecordRef.current! as EditDailyDiaryWorkRecordInput;
        onPrimaryClick(addRecordInput, !addAnotherRecord);

        if (addAnotherRecord) {
          // clear modal
          formApiRef.current.reset();
          setModalFooterKey((crtKey) => crtKey + 1);
        }
      }
    }
  };

  return (
    <BasicModal
      headerIcon={
        <IconContainer greyBackground>
          <Plus size={24} />
        </IconContainer>
      }
      title={
        <Typography
          variant="h3"
          fontWeight={600}
          fontSize="20px"
          color="grey.800"
        >
          {t(
            `Projects.DailyDiaries.WorkModal.${
              workRecord ? "editWorkRecord" : "addWorkRecord"
            }`
          )}
        </Typography>
      }
      fullWidth
      onClose={handleClose}
      maxWidth="sm"
      {...restDialogProps}
    >
      <WorkRecordForm
        apiRef={formApiRef}
        workLookups={workLookups}
        optionalColumns={optionalColumns}
        section={section}
        onChange={handleWorkRecordChange}
        workRecord={workRecord}
      />
      <RecordModalFooter
        mt={4}
        key={modalFooterKey}
        onAddAnotherRecordChange={setAddAnotherRecord}
        showAddAnotherRecord={!workRecord}
        addAnotherRecord={addAnotherRecord}
        primaryBtnCaption={
          primaryBtnCaption ??
          (workRecord ? t("common.labels.saveChanges") : undefined)
        }
        attachments={workRecord?.attachments ?? []}
        onAttachmentsUpdated={onAttachmentsUpdated}
        primaryBtnLoading={primaryBtnLoading}
        primaryBtnDisabled={primaryBtnDisabled}
        secondaryBtnCaption={secondaryBtnCaption}
        secondaryBtnLoading={secondaryBtnLoading}
        onPrimaryClick={handlePrimaryBtnClick}
        onSecondaryClick={onSecondaryClick}
      />
    </BasicModal>
  );
};
