import {
  ClaimItem,
  CompEventItem,
  Contract,
  ListItem,
  VariationItem,
} from "generated/graphql";

export const isVariation = (
  item: ClaimItem | CompEventItem | VariationItem
): item is VariationItem => {
  return (item as VariationItem).__typename === "VariationItem";
};

export const startsWithDigit = (name: string) =>
  name[0] >= "0" && name[0] <= "9";
export function customSort(a: ListItem, b: ListItem) {
  const nameA = a.name;
  const nameB = b.name;

  if (startsWithDigit(nameA)) {
    if (startsWithDigit(nameB)) {
      const pattern = /-?\b\d+\b/g;
      const matches = nameA.match(pattern);
      const matches2 = nameB.match(pattern);

      const nameANumbers = [
        matches?.[0] ?? null,
        matches?.[1] ?? null,
        matches?.[2] ?? null,
      ];
      const nameBNumbers = [
        matches2?.[0] ?? null,
        matches2?.[1] ?? null,
        matches2?.[2] ?? null,
      ];

      for (let i = 0; i < 3; i++) {
        const nrA = nameANumbers[i];
        const nrB = nameBNumbers[i];

        if (nrA && nrB) {
          const comparison = parseInt(nrA) - parseInt(nrB);
          if (comparison !== 0) {
            return comparison;
          }
        }

        if (!nrB) {
          return -1;
        }
      }

      return 1;
    } else {
      return -1;
    }
  }

  if (startsWithDigit(nameB)) {
    return 1;
  }

  return nameA.localeCompare(nameB);
}

export const isNECContractType = (contract?: Contract) =>
  contract ? contract.contractType.description.includes("NEC") : false;

export const isNEC3PSCContractType = (contract?: Contract) =>
  contract
    ? contract.contractType.description.includes("NEC") &&
      contract.contractType.subType?.includes("PSC") &&
      contract.contractType.version?.includes("3")
    : false;

// NOTE: There is no isFIDIC17Red/Yellow because that was the first FIDIC implementation, thus that's the base.
export const isFIDIC17WhiteContractType = (contract?: Contract) => {
  return contract
    ? contract.contractType.description.toUpperCase().includes("FIDIC") &&
        contract.contractType.subType?.toLowerCase().includes("white") &&
        contract.contractType.version?.toLowerCase().includes("2017")
    : false;
};

export const isFIDIC99RedYellowContractType = (contract?: Contract) => {
  return contract
    ? contract.contractType.description.toUpperCase().includes("FIDIC") &&
        (contract.contractType.subType?.toLowerCase().includes("red") ||
          contract.contractType.subType?.toLowerCase().includes("yellow")) &&
        contract.contractType.version?.toLowerCase().includes("1999")
    : false;
};
