import { Box, Typography, useTheme } from "@mui/material";
import {
  GridColDef,
  GridRenderCellParams,
  GridValueFormatterParams,
} from "@mui/x-data-grid-pro";
import { ItemTag } from "components/ItemTag";
import { StatusTagNew } from "components/StatusTag/StatusTagNew";
import { CompEventItem, ProductType } from "generated/graphql";
import moment from "moment";
import { compEventStatusOptions } from "../../CompEventsView.constants";
import { CompEventItemPreview } from "../CompEventItemPreview";
import { dateISOFormat } from "../../../../../../../constants";
import {
  datetimeComparatorFn,
  statusComparatorFunction,
} from "helpers/dataGrid.helpers";
import { TimeTag } from "containers/Projects/components/HistoryModal/TimeTag";
import { PriceTag } from "containers/Projects/components/HistoryModal/PriceTag";
import { OverflowTooltip } from "components/OverflowTooltip";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";

export const useColumns = (): GridColDef<CompEventItem>[] => {
  const theme = useTheme();
  const { t } = useTranslation();

  return useMemo(
    () => [
      {
        field: "number",
        headerName: t("Projects.CompEvents.ceNumber"),
        flex: 0.125,
        minWidth: 150,
        resizable: true,
      },
      {
        field: "status",
        headerName: t("common.labels.status"),
        flex: 0.1,
        minWidth: 120,
        maxWidth: 130,
        resizable: true,
        sortComparator: statusComparatorFunction,
        valueGetter: (params) => params.row.status,
        renderCell: (params: GridRenderCellParams<CompEventItem, any, any>) => {
          return (
            <StatusTagNew
              selectedOptionId={params.row.status}
              disabled
              options={compEventStatusOptions}
            />
          );
        },
      },
      {
        field: "price",
        headerName: t("common.labels.price"),
        flex: 0.125,
        minWidth: 120,
        maxWidth: 180,
        resizable: true,
        valueGetter: (params) => params.row.price,
        renderCell: (
          params: GridRenderCellParams<
            CompEventItem,
            number | null | undefined,
            any
          >
        ) => {
          return (
            <PriceTag
              value={params.value}
              currency={params.row.productInstance.contract.valueCurrency}
              sx={{ width: "100%" }}
            />
          );
        },
      },
      {
        field: "time",
        headerName: t("common.labels.time"),
        flex: 0.125,
        minWidth: 120,
        maxWidth: 180,
        resizable: true,
        renderCell: (params: GridRenderCellParams<CompEventItem, any, any>) => (
          <TimeTag value={params.row.time} sx={{ width: "100%" }} />
        ),
      },
      {
        field: "earlyWarning",
        headerName: t("Projects.EarlyWarnings.earlyWarning"),
        flex: 0.2,
        minWidth: 175,
        resizable: true,
        valueGetter: (params) => params.row.earlyWarning?.number ?? "",
        renderCell: (params: GridRenderCellParams<CompEventItem, any, any>) => {
          return params.row.earlyWarning ? (
            <Box display="flex" alignItems="center" width="100%">
              <ItemTag type={ProductType.EarlyWarnings} />
              <OverflowTooltip
                title={params.row.earlyWarning?.number}
                typographyProps={{
                  variant: "p2",
                  ml: 1,
                  color: theme.palette.grey[700],
                }}
              />
            </Box>
          ) : null;
        },
      },
      {
        field: "lastAction",
        headerName: t("Projects.CompEvents.lastAction"),
        flex: 0.075,
        minWidth: 120,
        maxWidth: 130,
        resizable: true,
        sortComparator: datetimeComparatorFn,
        valueGetter: (params) => params.row.lastAction.date,
        valueFormatter: (params: GridValueFormatterParams) =>
          moment(params.value).format(dateISOFormat),
      },
      {
        field: "regarding",
        headerName: t("common.labels.regarding"),
        flex: 0.25,
        minWidth: 225,
        resizable: true,
        sortable: true,
        valueGetter: (params) =>
          (params.row.regardingEvent?.title ||
            params.row.regardingInstruction?.title) ??
          "",
        renderCell: (
          params: GridRenderCellParams<CompEventItem, string, any>
        ) => {
          return (
            <Box display="flex" alignItems="center">
              <ItemTag
                type={
                  params.row.regardingEvent
                    ? ProductType.Events
                    : ProductType.Instructions
                }
              />
              <Typography variant="p2" ml={1} color="grey.700">
                {params.value}
              </Typography>
            </Box>
          );
        },
      },
      {
        field: "options",
        headerName: "",
        width: 50,
        resizable: true,
        sortable: false,
        renderCell: (params: GridRenderCellParams<CompEventItem, any, any>) => {
          return <CompEventItemPreview compEvent={params.row} />;
        },
      },
    ],
    [t, theme]
  );
};
