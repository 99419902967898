import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { AsteriskIcon } from "components/Icons/AsteriskIcon";
import { FormPublicApi } from "decl";
import { validateData } from "helpers/validators";
import { useCallback, useEffect, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import { dataValidators, defaultFormData } from "./InsertTableForm.constants";

export type TableConfigType = {
  noOfRows: number;
  noOfColumns: number;
  includeHeaders?: boolean;
};

export type InsertTableFormProps = {
  apiRef?: React.Ref<FormPublicApi>;
  onChange?: (tableConfig: TableConfigType) => void;
};

export const InsertTableForm: React.FC<InsertTableFormProps> = ({
  apiRef,
  onChange,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [formData, setFormData] = useState<TableConfigType>(defaultFormData);
  const [formDataErrors, setFormDataErrors] = useState<{
    [key: string]: string;
  }>({});

  const handleTextFieldChange: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = (evt) => {
    setFormData((curData) => ({
      ...curData,
      [evt.target.name]: evt.target.value,
    }));

    setFormDataErrors((curFormDataErrs) => {
      const { [evt.target.name]: _, ...rest } = curFormDataErrs;

      return rest;
    });
  };

  const handleCheckboxChange = (
    evt: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setFormData((curData) => ({
      ...curData,
      [evt.target.name]: checked,
    }));
  };

  useEffect(() => {
    onChange?.(formData);
  }, [formData, onChange]);

  const validateForm = useCallback((formData: TableConfigType) => {
    const validationResult = validateData(formData, dataValidators);

    if (validationResult.valid) {
      setFormDataErrors({});
      return true;
    }
    setFormDataErrors(validationResult.errors);

    return false;
  }, []);

  const resetForm = useCallback(() => {
    setFormData(defaultFormData);
  }, []);

  useImperativeHandle(
    apiRef,
    () => ({
      validate: () => validateForm(formData),
      reset: resetForm,
    }),
    [validateForm, formData, resetForm]
  );

  return (
    <Grid container spacing={2}>
      <Grid item md={12}>
        {/* TODO: the same label is used in RecordEWForm too. If it becomes repetitive, extract it */}
        <Box display="flex" alignItems="center">
          <Typography
            variant="p1"
            fontWeight={600}
            color={theme.palette.grey[800]}
            mb={1}
          >
            {t("Projects.AdvancedRichTextField.noOfRows")}
          </Typography>
          <Box alignSelf="flex-start">
            <AsteriskIcon />
          </Box>
        </Box>
        <TextField
          fullWidth
          name="noOfRows"
          value={formData.noOfRows}
          onChange={handleTextFieldChange}
          type="number"
          error={!!formDataErrors.noOfRows}
          helperText={formDataErrors.noOfRows}
          required
        />
      </Grid>
      <Grid item md={12}>
        <Box display="flex" alignItems="center">
          <Typography
            variant="p1"
            fontWeight={600}
            color={theme.palette.grey[800]}
            mb={1}
          >
            {t("Projects.AdvancedRichTextField.noOfColumns")}
          </Typography>
          <Box alignSelf="flex-start">
            <AsteriskIcon />
          </Box>
        </Box>
        <TextField
          fullWidth
          name="noOfColumns"
          value={formData.noOfColumns}
          onChange={handleTextFieldChange}
          type="number"
          error={!!formDataErrors.noOfColumns}
          helperText={formDataErrors.noOfColumns}
          required
        />
      </Grid>
      <Grid item md={12}>
        <FormControlLabel
          label={
            <Typography variant="caption">
              {t("Projects.AdvancedRichTextField.includeHeaders")}
            </Typography>
          }
          labelPlacement="end"
          control={
            <Checkbox
              inputProps={{
                "aria-label": `${t(
                  "Projects.AdvancedRichTextField.includeHeaders"
                )}`,
              }}
              name="includeHeaders"
              onChange={handleCheckboxChange}
              checked={!!formData.includeHeaders}
            />
          }
        />
      </Grid>
    </Grid>
  );
};
