import { Typography } from "@mui/material";
import {
  GridColDef,
  GridEditInputCell,
  GridRenderCellParams,
  GridRenderEditCellParams,
  GridRowId,
  GridRowModesModel,
} from "@mui/x-data-grid-pro";
import { temporaryRowId } from "../../../../../../../../../constants";
import {
  ItemStatusOptionReason,
  ItemStatusOptionReasonStatus,
} from "generated/graphql";
import {
  StatusTag,
  StatusOption,
} from "../../../../../../../../../components/StatusTag/StatusTag";
import {
  getCommonActions,
  statusComparatorFunction,
  useDateCreatedColumnConfig,
} from "helpers/dataGrid.helpers";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { getUserName } from "helpers/miscelaneous";

export const useColumns = (
  statusOptions: StatusOption<ItemStatusOptionReasonStatus>[],
  rowModesModel: GridRowModesModel,
  onStatusChange: (
    row: ItemStatusOptionReason,
    newStatus: ItemStatusOptionReasonStatus
  ) => void,
  handleSaveRow: (rowId: GridRowId) => void,
  handleDeleteRow: (rowId: GridRowId) => void
): GridColDef<ItemStatusOptionReason>[] => {
  const { t } = useTranslation();
  const dateCreatedColConfig = useDateCreatedColumnConfig();
  const columns = useMemo(
    () =>
      [
        {
          field: "displayOrder",
          headerName: t("AdminConsole.Products.labels.displayOrder"),
          flex: 0.125,
          minWidth: 190,
          resizable: true,
          sortable: false,
        },
        {
          field: "description",
          headerName: t("AdminConsole.Products.labels.reason"),
          flex: 0.125,
          minWidth: 190,
          resizable: true,
          editable: true,
          sortable: false,
          renderEditCell: (
            params: GridRenderEditCellParams<any, ItemStatusOptionReason>
          ) => {
            return params.row.id === temporaryRowId ? (
              <GridEditInputCell {...params} error={!params.row.description} />
            ) : (
              <Typography variant="body2">{params.row.description}</Typography>
            );
          },
        },
        {
          field: "status",
          headerName: t("common.labels.status"),
          flex: 0.125,
          minWidth: 90,
          resizable: true,
          sortable: false,
          sortComparator: statusComparatorFunction,
          renderCell: (
            params: GridRenderCellParams<any, ItemStatusOptionReason, any>
          ) => {
            return (
              <StatusTag
                status={params.row.status}
                options={statusOptions}
                disablePortal={false}
                onChange={(
                  newStatus: StatusOption<ItemStatusOptionReasonStatus>
                ) => onStatusChange(params.row, newStatus.id)}
              />
            );
          },
        },
        dateCreatedColConfig,
        {
          field: "creator",
          headerName: t("common.labels.createdBy"),
          flex: 0.125,
          sortable: false,
          minWidth: 190,
          resizable: true,
          valueGetter: (params) => {
            return getUserName(params.row.creator);
          },
        },
        {
          field: "actions",
          flex: 0.05,
          type: "actions",
          sortable: false,
          getActions: (cellParams) =>
            getCommonActions(
              cellParams,
              rowModesModel,
              handleSaveRow,
              handleDeleteRow
            ),
        },
      ] as GridColDef<ItemStatusOptionReason>[],
    [
      t,
      dateCreatedColConfig,
      statusOptions,
      rowModesModel,
      onStatusChange,
      handleSaveRow,
      handleDeleteRow,
    ]
  );
  return columns;
};
