import { LoadingButton } from "@mui/lab";
import { Box, Grid, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { isValidEmail } from "helpers/validators";
import { useTranslation } from "react-i18next";
import { ResetPasswordFooter } from "./ResetPasswordFooter";
import { AuthPageHeader } from "./AuthPageHeader";
import { FIELD_VALIDATION_ERRORS } from "../../../constants";

export type ResetPasswordFormProps = {
  loading?: boolean;
  onReset: (email: string) => void;
};

export const ResetPasswordForm: React.FC<ResetPasswordFormProps> = ({
  loading,
  onReset,
}) => {
  const { t } = useTranslation();

  const [email, setEmail] = useState("");
  const [emailErrors, setEmailErrors] = useState("");

  const validateEmail = () => {
    if (!email) {
      setEmailErrors(FIELD_VALIDATION_ERRORS.RequiredField);
      return false;
    }

    if (!isValidEmail(email).valid) {
      setEmailErrors(FIELD_VALIDATION_ERRORS.InvalidField);
      return false;
    }

    return true;
  };

  const handleResetPassword = () => {
    if (validateEmail()) {
      onReset(email);
    }
  };

  const handleKeyDown: React.KeyboardEventHandler<HTMLDivElement> = (evt) => {
    if (evt.key === "Enter") {
      handleResetPassword();
    }
  };

  const handleEmailChange: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = (evt) => {
    setEmail(evt.target.value);
    setEmailErrors("");
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      maxWidth="400px"
    >
      <Box mb={7}>
        <AuthPageHeader
          title={t("ResetPassword.title")}
          subtitle={t("ResetPassword.subtitle")}
        />
      </Box>
      <Grid container rowGap={4}>
        <Grid item xs={12}>
          <>
            <Box mb={1}>
              <Typography variant="p1" fontWeight={600} color="grey.800">
                {t("common.labels.email")}
              </Typography>
            </Box>
            <TextField
              fullWidth
              name="email"
              value={email}
              onChange={handleEmailChange}
              type="email"
              onKeyDown={handleKeyDown}
              variant="outlined"
              error={!!emailErrors}
              helperText={emailErrors}
              size="small"
              required
            />
          </>
        </Grid>
        <Grid item xs={12} mt={2}>
          <LoadingButton
            onClick={handleResetPassword}
            loading={loading}
            variant="contained"
            fullWidth
            style={{ textTransform: "none" }}
            size="large"
          >
            <Typography color="white" variant="p2" fontWeight={700}>
              {t("ResetPassword.requestResetLink")}
            </Typography>
          </LoadingButton>
        </Grid>
        <Grid item xs={12}>
          <ResetPasswordFooter />
        </Grid>
      </Grid>
    </Box>
  );
};
