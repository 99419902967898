import { gql } from "@apollo/client";
import { creatorFields } from "./creator.fragment";

export const newClaimTypeOverrideFields = gql`
  ${creatorFields}

  fragment NewClaimTypeOverrideFields on NewClaimTypeOverride {
    id
    contractId
    clause
    description
    status
    dateCreated
    creatorId
    contract {
      id
      name
      friendlyName
    }
    creator {
      ...CreatorFields
    }
  }
`;
