import {
  GridColDef,
  GridRenderEditCellParams,
  GridRowId,
  GridRowModesModel,
} from "@mui/x-data-grid-pro";
import { AuthorizationWorkflowLevelMode, User } from "generated/graphql";
import { useTranslation } from "react-i18next";
import { SyntheticEvent, useMemo } from "react";
import {
  Autocomplete,
  ListItem,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { DataGridSingleSelect } from "components/DataGridSingleSelect";
import { AuthorizationWorkflowLevelInputWithID } from "./AuthorizationWorkflowLevels";
import { getUserName } from "helpers/miscelaneous";
import { getCommonActions } from "helpers/dataGrid.helpers";

type UseColumnProps = {
  users: User[];
  rowModesModel: GridRowModesModel;
  readOnly?: boolean;
  onModeChange: (
    row: AuthorizationWorkflowLevelInputWithID,
    newMode: AuthorizationWorkflowLevelMode
  ) => void;
  onUserChange: (
    row: AuthorizationWorkflowLevelInputWithID,
    updatedUserIds: string[]
  ) => void;
  onSaveRow: (rowId: GridRowId) => void;
  onDeleteRow: (rowId: GridRowId) => void;
};

export const useColumns = ({
  users,
  rowModesModel,
  readOnly,
  onModeChange,
  onUserChange,
  onSaveRow,
  onDeleteRow,
}: UseColumnProps): GridColDef<AuthorizationWorkflowLevelInputWithID>[] => {
  const { t } = useTranslation();
  const modes = useMemo(
    () => [
      {
        value: AuthorizationWorkflowLevelMode.EveryoneMustAuthorize,
        label: t(
          `AdminConsole.AuthorizationWorkflows.levelModes.${AuthorizationWorkflowLevelMode.EveryoneMustAuthorize}`
        ),
      },
      {
        value: AuthorizationWorkflowLevelMode.AnyoneCanAuthorize,
        label: t(
          `AdminConsole.AuthorizationWorkflows.levelModes.${AuthorizationWorkflowLevelMode.AnyoneCanAuthorize}`
        ),
      },
    ],
    [t]
  );

  const userOptions = useMemo(
    () =>
      users.map((user) => ({
        value: user.id,
        label: `${user.registered ? getUserName(user) : user.email} [${
          user.company.tradingName
        }]`,
      })),
    [users]
  );

  const columns = useMemo(
    () =>
      [
        {
          field: "sequence",
          headerName: t("AdminConsole.AuthorizationWorkflows.sequence"),
          width: 125,
          resizable: true,
        },
        {
          field: "mode",
          headerName: t("common.labels.mode"),
          flex: 0.15,
          minWidth: 90,
          resizable: true,
          editable: true,
          type: "singleSelect",
          valueOptions: modes,
          valueFormatter: ({ id: _, value }) => {
            const selectedOption = modes.find(
              (valueOption: { value: string; label: string }) =>
                valueOption.value === value
            );

            return selectedOption?.label;
          },
          renderEditCell: (
            cellParams: GridRenderEditCellParams<
              any,
              AuthorizationWorkflowLevelInputWithID,
              any
            >
          ) => (
            <DataGridSingleSelect
              cellParams={cellParams}
              disabled={readOnly}
              error={!cellParams.row.mode}
              onValueChange={(event: SelectChangeEvent<any>) => {
                !readOnly && onModeChange(cellParams.row, event.target.value);
              }}
            />
          ),
        },
        {
          field: "users",
          headerName: t("common.labels.users"),
          flex: 0.4,
          editable: true,
          resizable: true,
          valueGetter: (params) => {
            return userOptions.filter((userOption) =>
              params.row.userIds.includes(userOption.value)
            );
          },
          valueFormatter: ({ id: _, value }) => {
            return (value as typeof userOptions)
              .map((userOption) => userOption.label)
              .join(", ");
          },
          renderEditCell: (
            cellParams: GridRenderEditCellParams<
              AuthorizationWorkflowLevelInputWithID,
              any,
              any
            >
          ) => (
            <Autocomplete
              id="users-autocomplete"
              options={userOptions}
              getOptionLabel={(optionValue) => {
                return optionValue.label;
              }}
              value={userOptions.filter((userOption) =>
                cellParams.row.userIds.includes(userOption.value)
              )}
              getOptionKey={(optionValue) => optionValue.value}
              readOnly={readOnly}
              onChange={(_event: SyntheticEvent<Element, Event>, value) => {
                onUserChange(
                  cellParams.row,
                  value.map((user) => user.value)
                );
              }}
              renderOption={(props, option) => (
                <ListItem
                  {...props}
                  key={option.value}
                  data-testid={option.value}
                >
                  {option.label}
                </ListItem>
              )}
              fullWidth
              multiple
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={
                    !cellParams.row.userIds || !cellParams.row.userIds.length
                  }
                  required
                  variant="outlined"
                />
              )}
            />
          ),
        },
        {
          field: "actions",
          flex: 0.05,
          type: "actions",
          sortable: false,
          getActions: (cellParams) =>
            readOnly
              ? []
              : getCommonActions(
                  cellParams,
                  rowModesModel,
                  onSaveRow,
                  onDeleteRow
                ),
        },
      ] as GridColDef<AuthorizationWorkflowLevelInputWithID>[],
    [
      t,
      rowModesModel,
      modes,
      readOnly,
      onDeleteRow,
      onModeChange,
      onSaveRow,
      onUserChange,
      userOptions,
    ]
  );
  return columns;
};
