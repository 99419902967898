import { LoadingButton } from "@mui/lab";
import { Box, Grid, TextField, Typography, useTheme } from "@mui/material";
import { Card } from "components/Card";
import { PasswordEyeAdornment } from "components/miscellaneous/PasswordEyeAdornment";
import { PasswordInfo } from "components/PasswordInfo";
import { defaultCognitoPasswordRequirementsRegex } from "../../../../constants";
import { ConfirmChangesModal } from "containers/Settings/components/ConfirmChangesModal";
import {
  UserChangePasswordInput,
  UserChangePasswordMutation,
  UserChangePasswordMutationVariables,
} from "generated/graphql";
import {
  DataValidators,
  validateData,
  ValidatorType,
} from "helpers/validators";
import { useCallbackPrompt } from "hooks/useCallbackPrompt";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useCallback, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { userChangePasswordMutation } from "./PasswordsSettings.query";

type PasswordsFormType = UserChangePasswordInput & { confirmPassword: string };

const defaultFormData: PasswordsFormType = {
  oldPassword: "",
  newPassword: "",
  confirmPassword: "",
};

const FieldLabel: React.FC<{ label: string; error?: boolean }> = ({
  label,
  error,
}) => {
  const theme = useTheme();

  return (
    <Box display="flex" alignItems="center" mb={1.5}>
      <Typography
        variant="body1"
        color={error ? "error.main" : "grey.800"}
        mr={0.5}
        fontWeight={600}
      >
        {label}
      </Typography>
      <PasswordInfo color={theme.palette.grey[800]} error={error} />
    </Box>
  );
};

export const Passwords = () => {
  const { t } = useTranslation();

  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [formData, setFormData] = useState<PasswordsFormType>(defaultFormData);
  const [formDataErrors, setFormDataErrors] = useState<{
    [key: string]: string;
  }>({});
  const [isFormPristine, setIsFormPristine] = useState(true);

  const {
    showPrompt: showBeforeNavigateAwayModal,
    confirmNavigation,
    cancelNavigation,
  } = useCallbackPrompt(!isFormPristine);

  const dataValidators: DataValidators = useMemo(
    () => ({
      oldPassword: {
        validators: [ValidatorType.ValidText],
        getData: () => [
          formData.oldPassword,
          defaultCognitoPasswordRequirementsRegex,
        ],
      },
      newPassword: {
        validators: [ValidatorType.ValidText],
        getData: () => [
          formData.newPassword,
          defaultCognitoPasswordRequirementsRegex,
        ],
      },
      confirmPassword: {
        validators: [ValidatorType.ValidConfirmPassword],
        getData: () => [formData.newPassword, formData.confirmPassword],
      },
    }),
    [formData.newPassword, formData.confirmPassword, formData.oldPassword]
  );

  const [userChangePassword, { loading: userChangePasswordLoading }] =
    useGraphMutation<
      UserChangePasswordMutation,
      UserChangePasswordMutationVariables
    >(
      userChangePasswordMutation,
      {},
      t("common.successMessages.passwordsUpdated")
    );

  const validateForm = useCallback(() => {
    const validationResult = validateData(formData, dataValidators);

    if (validationResult.valid) {
      setFormDataErrors({});
      return true;
    }
    setFormDataErrors(validationResult.errors);

    return false;
  }, [formData, dataValidators]);

  const handleChangePassword = useCallback(async () => {
    if (validateForm()) {
      const { errors } = await userChangePassword({
        variables: {
          input: {
            oldPassword: formData.oldPassword,
            newPassword: formData.newPassword,
          },
        },
      });

      if (!errors) {
        setFormData(defaultFormData);
        setIsFormPristine(true);
      }
    }
  }, [formData, userChangePassword, validateForm]);

  const handleKeyDown: React.KeyboardEventHandler<HTMLDivElement> = (evt) => {
    if (evt.key === "Enter") {
      handleChangePassword();
    }
  };

  const handleTextFieldChange: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = (evt) => {
    setFormData((curData) => ({
      ...curData,
      [evt.target.name]: evt.target.value,
    }));

    setFormDataErrors((curFormDataErrs) => {
      const { [evt.target.name]: _, ...rest } = curFormDataErrs;

      return rest;
    });
    setIsFormPristine(false);
  };

  const handleConfirmModalClose = () => {
    cancelNavigation();
  };

  const handleConfirmModalDontSave = () => {
    confirmNavigation(); // for navigate away scenarios
  };

  const handleConfirmModalSave = async () => {
    await handleChangePassword();
    confirmNavigation();
  };

  return (
    <Card>
      <ConfirmChangesModal
        open={showBeforeNavigateAwayModal}
        secondaryBtnCaption={t("common.buttons.dontSave")}
        primaryBtnCaption={t("common.buttons.save")}
        primaryBtnLoading={userChangePasswordLoading}
        onClose={handleConfirmModalClose}
        onPrimaryClick={handleConfirmModalSave}
        onSecondaryClick={handleConfirmModalDontSave}
      />
      <Typography variant="h3" fontWeight={600} color="grey.900" mb={4}>
        {t("ResetPassword.changePassword")}
      </Typography>
      <Grid container spacing={6}>
        <Grid item container md={12} xs={12} spacing={6}>
          <Grid item xs={12}>
            <FieldLabel
              label={`${t("common.labels.currentPassword")} *`}
              error={!!formDataErrors.oldPassword}
            />
            <TextField
              fullWidth
              name="oldPassword"
              onChange={handleTextFieldChange}
              value={formData.oldPassword}
              onKeyDown={handleKeyDown}
              type={showOldPassword ? "text" : "password"}
              variant="outlined"
              size="small"
              error={!!formDataErrors.oldPassword}
              helperText={formDataErrors.oldPassword}
              required
              InputProps={{
                endAdornment: (
                  <PasswordEyeAdornment
                    visibility={showOldPassword}
                    onToggleVisibility={() =>
                      setShowOldPassword((state) => !state)
                    }
                    mb={0}
                  />
                ),
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <FieldLabel
            label={t("common.labels.newPassword")}
            error={!!formDataErrors.newPassword}
          />
          <TextField
            fullWidth
            name="newPassword"
            onChange={handleTextFieldChange}
            value={formData.newPassword}
            onKeyDown={handleKeyDown}
            type={showNewPassword ? "text" : "password"}
            variant="outlined"
            size="small"
            error={!!formDataErrors.newPassword}
            helperText={formDataErrors.newPassword}
            required
            InputProps={{
              endAdornment: (
                <PasswordEyeAdornment
                  visibility={showNewPassword}
                  onToggleVisibility={() =>
                    setShowNewPassword((state) => !state)
                  }
                  mb={0}
                />
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <FieldLabel
            label={t("common.labels.repeatPassword")}
            error={!!formDataErrors.confirmPassword}
          />
          <TextField
            fullWidth
            name="confirmPassword"
            onChange={handleTextFieldChange}
            value={formData.confirmPassword}
            onKeyDown={handleKeyDown}
            type={showConfirmPassword ? "text" : "password"}
            variant="outlined"
            size="small"
            error={!!formDataErrors.confirmPassword}
            helperText={formDataErrors.confirmPassword}
            required
            InputProps={{
              endAdornment: (
                <PasswordEyeAdornment
                  visibility={showConfirmPassword}
                  onToggleVisibility={() =>
                    setShowConfirmPassword((state) => !state)
                  }
                  mb={0}
                />
              ),
            }}
          />
        </Grid>
      </Grid>
      <Box ml="auto" mt={5}>
        <LoadingButton
          variant="contained"
          onClick={handleChangePassword}
          loading={userChangePasswordLoading}
        >
          {t("ResetPassword.changePassword")}
        </LoadingButton>
      </Box>
    </Card>
  );
};
