import {
  ClaimNoticeInvalidNoticePromptQuery,
  ClaimNoticeInvalidNoticePromptQueryVariables,
  RecordClaimNoticeInvalidNoticeMutation,
  RecordClaimNoticeInvalidNoticeMutationVariables,
  SendClaimNoticeInvalidNoticeMutation,
  SendClaimNoticeInvalidNoticeMutationVariables,
} from "generated/graphql";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { ClaimWidgetContext } from "../../ClaimWidget/ClaimWidget.context";

import { OnMutationDone } from "decl";
import { useGraphQuery } from "hooks/useGraphQuery";
import {
  claimNoticeInvalidNoticePromptQuery,
  recordClaimNoticeInvalidNoticeMutation,
  sendClaimNoticeInvalidNoticeMutation,
} from "./ConsiderNoticeOfClaimInvalidAction.query";

export const useConsiderNoticeOfClaimInvalidAction = () => {
  const { t } = useTranslation();
  const { onChange, claim, isFIDIC99RedYellow } =
    useContext(ClaimWidgetContext);

  const handleOnUpdate: OnMutationDone = (cache) => {
    cache.evict({
      id: "ROOT_QUERY",
      fieldName: "claimItems",
    });
    cache.evict({
      id: "ROOT_QUERY",
      fieldName: "claimHistoryItems",
      args: { claimId: claim!.id },
    });
    cache.evict({
      id: "ROOT_QUERY",
      fieldName: "claimDetailsExplorerItems",
      args: { claimId: claim!.id },
    });
    cache.evict({
      id: "ROOT_QUERY",
      fieldName: "claimAction",
    });
    cache.gc();
    onChange?.();
  };

  const { data: promptData, loading } = useGraphQuery<
    ClaimNoticeInvalidNoticePromptQuery,
    ClaimNoticeInvalidNoticePromptQueryVariables
  >(claimNoticeInvalidNoticePromptQuery, {
    variables: {
      input: {
        claimId: claim!.id,
      },
    },
    skip: !claim,
  });

  const [
    sendNoticeOfClaimInvalid,
    { loading: sendNoticeOfClaimInvalidLoading },
  ] = useGraphMutation<
    SendClaimNoticeInvalidNoticeMutation,
    SendClaimNoticeInvalidNoticeMutationVariables
  >(
    sendClaimNoticeInvalidNoticeMutation,
    {
      update: handleOnUpdate,
    },
    t(
      `Projects.Claims.successMessages.noticeOfClaimInvalidSent${
        isFIDIC99RedYellow ? "99RY" : ""
      }`
    )
  );

  const [
    recordNoticeOfClaimInvalid,
    { loading: recordNoticeOfClaimInvalidLoading },
  ] = useGraphMutation<
    RecordClaimNoticeInvalidNoticeMutation,
    RecordClaimNoticeInvalidNoticeMutationVariables
  >(
    recordClaimNoticeInvalidNoticeMutation,
    {
      update: handleOnUpdate,
    },
    t(
      `Projects.Claims.successMessages.noticeOfClaimInvalidRecord${
        isFIDIC99RedYellow ? "99RY" : ""
      }`
    )
  );

  return {
    reasons: promptData?.claimNoticeInvalidNoticePrompt.reasons,
    loading,
    actionLoading:
      sendNoticeOfClaimInvalidLoading || recordNoticeOfClaimInvalidLoading,
    sendNoticeOfClaimInvalid,
    recordNoticeOfClaimInvalid,
  };
};
