import { Typography } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { StatusTagNew } from "components/StatusTag/StatusTagNew";
import { dateISOFormat } from "../../../../../../constants";
import { dailyDiaryDateFormat } from "containers/Projects/containers/DailyDiary/DailyDiary.constants";
import { DailyDiaryItem } from "generated/graphql";
import { TFunction } from "i18next";
import moment from "moment";
import { dailyDiaryStatusOptions } from "../../DailyDiariesView.constants";
import {
  datetimeComparatorFn,
  statusComparatorFunction,
} from "helpers/dataGrid.helpers";
import { getUserName } from "helpers/miscelaneous";

export const getColumns = (
  t: TFunction,
  onlyBaseColumns = false
): GridColDef<DailyDiaryItem>[] => {
  const dateColumn: GridColDef<DailyDiaryItem>[] = [
    {
      field: "date",
      headerName: t("common.labels.date"),
      flex: 0.1,
      minWidth: 120,
      maxWidth: 160,
      resizable: true,
      sortComparator: datetimeComparatorFn,
      renderCell: (params: GridRenderCellParams<any, DailyDiaryItem, any>) => (
        <Typography variant="p2" fontWeight={600} color="grey.700">
          {moment(params.row.date).format(dailyDiaryDateFormat)}
        </Typography>
      ),
    },
  ];

  const statusColumn: GridColDef<DailyDiaryItem>[] = [
    {
      field: "status",
      headerName: t("common.labels.status"),
      flex: 0.1,
      minWidth: 120,
      maxWidth: 130,
      resizable: true,
      sortComparator: statusComparatorFunction,
      valueGetter: (params) => params.row.status,
      renderCell: (params: GridRenderCellParams<any, DailyDiaryItem, any>) => {
        return params.row.status ? (
          <StatusTagNew
            selectedOptionId={params.row.status}
            disabled
            options={dailyDiaryStatusOptions}
          />
        ) : null;
      },
    },
  ];

  const revisionsColumn: GridColDef<DailyDiaryItem>[] = [
    {
      field: "revisions",
      headerName: t("Projects.DailyDiaries.revisions"),
      width: 100,
      resizable: true,
      valueGetter: (params) => {
        const revisionsCount = params.row.latestRevision
          ? Number(params.row.latestRevision.number)
          : 0; // Because Revision0 doesn't count

        return revisionsCount || "";
      },
    },
  ];

  const baseColumns: GridColDef<DailyDiaryItem>[] = [
    ...dateColumn,
    ...statusColumn,
    ...revisionsColumn,
  ];

  if (onlyBaseColumns) {
    return baseColumns;
  }

  return [
    ...dateColumn,
    {
      field: "number",
      headerName: "#",
      flex: 0.1,
      minWidth: 150,
      resizable: true,
    },
    ...statusColumn,
    ...revisionsColumn,
    {
      field: "sentBy",
      headerName: t("common.labels.sentBy"),
      flex: 0.15,
      minWidth: 90,
      resizable: true,
      valueGetter: (params) => {
        return getUserName(params.row.sentBy);
      },
    },
    {
      field: "dateCreated",
      headerName: t("common.labels.dateSent"),
      flex: 0.1,
      minWidth: 120,
      maxWidth: 130,
      resizable: true,
      sortComparator: datetimeComparatorFn,
      valueGetter: (params) =>
        params.row.dateSent
          ? moment(params.row.dateSent).format(dateISOFormat)
          : "",
    },
  ];
};
