import {
  DraftVariationProposal,
  GenerateSendVariationProposalForVariationPreviewMutation,
  GenerateSendVariationProposalForVariationPreviewMutationVariables,
  SendVariationProposalForVariationPreview,
} from "generated/graphql";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useCallback, useContext, useEffect, useState } from "react";
import { debounce } from "@mui/material";
import { eventDebounceDuration } from "../../../../../../constants";
import { VariationWidgetContext } from "../../VariationWidget/VariationWidget.context";
import { generateSendVariationProposalForVariationPreviewMutation } from "./SubmitProposalForVariationAction.query";

export const useSendProposalForVariationModal = (
  draftProposals: DraftVariationProposal[],
  preferredProposalId: string
) => {
  const { variation, contract } = useContext(VariationWidgetContext);
  const [variationPreview, setVariationPreview] =
    useState<SendVariationProposalForVariationPreview>();

  const [generatePreview] = useGraphMutation<
    GenerateSendVariationProposalForVariationPreviewMutation,
    GenerateSendVariationProposalForVariationPreviewMutationVariables
  >(generateSendVariationProposalForVariationPreviewMutation, {}, null);

  const loadPreview = debounce(
    useCallback(async () => {
      const { data } = await generatePreview({
        variables: {
          input: {
            variationId: variation!.id,
            preferredProposalId,
            proposalIds: draftProposals.map((proposal) => proposal.id),
          },
        },
      });

      if (data) {
        setVariationPreview(
          data.generateSendVariationProposalForVariationPreview
        );
      }
    }, [generatePreview, variation, draftProposals, preferredProposalId]),
    eventDebounceDuration
  );

  useEffect(() => {
    loadPreview();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  return {
    variationPreview,
    contract,
  };
};
