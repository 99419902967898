import {
  Contract,
  ContractLiteQuery,
  ContractLiteQueryVariables,
} from "generated/graphql";
import { contractLiteQuery } from "graphql/queries/contractLite.query";
import { useGraphQuery } from "hooks/useGraphQuery";
import { useMemo } from "react";

export const useContractLite = (contractId?: string) => {
  const { data: contractDataLite, loading: contractLiteLoading } =
    useGraphQuery<ContractLiteQuery, ContractLiteQueryVariables>(
      contractLiteQuery,
      {
        variables: { id: contractId! },
        skip: !contractId,
      }
    );

  const contractLite = useMemo(
    () => contractDataLite?.contract as Contract,
    [contractDataLite]
  );

  return {
    contractLite,
    contractLiteLoading,
  };
};
