import { useTheme } from "@mui/material";
import {
  GridEventListener,
  GridFilterModel,
  GridLogicOperator,
  GridRowParams,
  GridRowSelectionModel,
  GridSortModel,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import { NewStyledDataGrid } from "components/StyledDataGrid";
import {
  EarlyWarningItem,
  InstructionItem,
  ItemStatusOption,
  ProductType,
} from "generated/graphql";
import {
  useCallback,
  useImperativeHandle,
  useMemo,
  useState,
  useEffect,
  useRef,
} from "react";
import { useTranslation } from "react-i18next";
import {
  getEarlyWarningsTableColumns,
  getEventsTableColumns,
  getRisksTableColumns,
  getInstructionsTableColumns,
} from "../ProductItemsView.constants";
import { exportToExcel } from "../../../../../helpers/exportToExcel";
import { ProductItemsTablePublicAPI } from "../ProductItemsView.decl";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ProductItem } from "containers/Projects/Projects.decl";
import { computeSeverityRating } from "../../SchemaInterpretor/Field/Severity/Severity.utils";
import { defaultSeverityPreset } from "../../SchemaInterpretor/Field/Severity/Severity.constants";
import { getProductItemDetailsPath } from "helpers/paths/pathConstructors";
import { ExplorerState } from "../../Explorer/Explorer.context";
import { useContractByProductInstanceId } from "containers/Projects/hooks/useContractByProductInstanceId/useContractByProductInstanceId";
import { isNECContractType } from "containers/Projects/Projects.utils";
import { getUserName } from "helpers/miscelaneous";

export type ProductItemsTableProps<T extends ProductItem> = {
  productItems: T[];
  statusOptions: ItemStatusOption[];
  loading: boolean;
  productType?: ProductType;
  apiRef?: React.Ref<ProductItemsTablePublicAPI>;
  selectionModel?: GridRowSelectionModel;
  onSelectionModelChange: (newSelectionModel: GridRowSelectionModel) => void;
};
const defaultRisksEventsSortingModel: GridSortModel = [
  { field: "dateCreated", sort: "desc" },
];
const defaultEWsInstructionsSortingModel: GridSortModel = [
  { field: "dateSent", sort: "desc" },
];

export const ProductItemsTable = <T extends ProductItem>({
  productItems,
  statusOptions,
  loading,
  apiRef,
  productType,
  selectionModel,
  onSelectionModelChange,
}: ProductItemsTableProps<T>) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const defaultSoringModelSet = useRef(false);
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get("projectId");
  const contractId = searchParams.get("contractId");
  const productId = searchParams.get("productId");
  const productInstanceId = searchParams.get("productInstanceId");

  const isInstructionsRegister = productType === ProductType.Instructions;
  const { contract, loading: contractLoading } = useContractByProductInstanceId(
    productInstanceId!,
    !isInstructionsRegister
  );
  const isContractTypeNEC = useMemo(
    () => isNECContractType(contract),
    [contract]
  );

  const gridApiRef = useGridApiRef();
  const [sortingModel, setSortingModel] = useState<GridSortModel>([]);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
    logicOperator: GridLogicOperator.And,
    quickFilterLogicOperator: GridLogicOperator.And,
    quickFilterValues: [],
  });

  const handleExportToExcel = useCallback(async () => {
    if (!productItems || !productItems.length) {
      console.warn("No records to export");
      return;
    }

    const columns = [
      {
        header: t("common.labels.id"),
        key: "number",
        width: 20,
      },
      { header: t("common.labels.title"), key: "title", width: 20 },
      {
        header: t("common.labels.status"),
        key: "status",
        width: 20,
      },
      ...([ProductType.Events, ProductType.Instructions].indexOf(
        productType!
      ) >= 0
        ? []
        : [
            {
              header: t("Projects.Risks.severity"),
              key: "severity",
              width: 20,
            },
          ]),
      { header: t("common.labels.owner"), key: "owner", width: 20 },
      ...(productType === ProductType.RisksRegister
        ? [
            {
              header: t("common.labels.dateModified"),
              key: "dateModified",
              width: 20,
            },
            {
              header: t("common.labels.dateCreated"),
              key: "dateCreated",
              width: 20,
            },
          ]
        : [
            {
              header: t("common.labels.dateSent"),
              key: "dateSent",
              width: 20,
            },
          ]),
      ...(productType === ProductType.Events ||
      productType === ProductType.Instructions
        ? [
            {
              header: t("common.labels.related"),
              key: "related",
              width: 20,
            },
          ]
        : []),
    ];

    const rows = productItems
      .filter(
        (productItem) => (selectionModel || []).indexOf(productItem.id) >= 0
      )
      .map((productItem) => ({
        ...productItem,
        id: productItem.number,
        status: productItem.statusOption.description,
        severity: productItem.severity
          ? computeSeverityRating(
              // TODO: use product item severity preset, not the default one
              defaultSeverityPreset,
              JSON.parse(productItem.severity).consequence,
              JSON.parse(productItem.severity).likelihood
            ).name
          : t("common.labels.unknown"),
        owner: getUserName(productItem.owner),
        ...(productType === ProductType.RisksRegister
          ? {
              dateCreated: productItem.dateCreated
                ? new Date(productItem.dateCreated)
                : "",
              dateModified: productItem.dateModified
                ? new Date(productItem.dateModified)
                : "",
            }
          : {
              dateSent: (productItem as EarlyWarningItem).dateSent
                ? new Date((productItem as EarlyWarningItem).dateSent)
                : "",
            }),
        ...(productType === ProductType.Events ||
        productType === ProductType.Instructions
          ? {
              related:
                (productItem as InstructionItem).compEvent?.number ??
                (productItem as InstructionItem).claim?.number ??
                (productItem as InstructionItem).variation?.number,
            }
          : {}),
      }));

    const fileName =
      productType === ProductType.RisksRegister
        ? t("Projects.Risks.risks")
        : productType === ProductType.EarlyWarnings
        ? t("Projects.EarlyWarnings.earlyWarnings")
        : productType === ProductType.Events
        ? t("Projects.Events.events")
        : t("Projects.Instructions.instructions");
    exportToExcel(fileName, columns, rows);
  }, [productItems, selectionModel, t, productType]);

  const handleRowClick: GridEventListener<"rowClick"> | undefined = useCallback(
    (rowData: GridRowParams<ProductItem>) => {
      const selection = window.getSelection()?.toString();
      if (!selection) {
        navigate(
          getProductItemDetailsPath(
            productInstanceId!,
            rowData.row.id,
            productType!
          ),
          {
            state: {
              projectId,
              contractId,
              productId,
              productInstanceId,
            } as ExplorerState,
          }
        );
      }
    },
    [navigate, productType, contractId, productId, productInstanceId, projectId]
  );

  const handleClearSelection = useCallback(() => {
    onSelectionModelChange([]);
  }, [onSelectionModelChange]);

  const handleSelectionModelChange = (
    newSelectionModel: GridRowSelectionModel
  ) => {
    onSelectionModelChange(newSelectionModel);
  };

  useImperativeHandle(
    apiRef,
    () => ({
      clearSelection: handleClearSelection,
      exportToExcel: handleExportToExcel,
    }),
    [handleClearSelection, handleExportToExcel]
  );

  const columns = useMemo(() => {
    if (productItems && productItems.length) {
      switch (productType) {
        case ProductType.RisksRegister:
          return getRisksTableColumns(statusOptions, t, theme);
        case ProductType.EarlyWarnings:
          return getEarlyWarningsTableColumns(statusOptions, t, theme);
        case ProductType.Events:
          return getEventsTableColumns(statusOptions, t, theme);
        case ProductType.Instructions:
          return getInstructionsTableColumns(
            statusOptions,
            t,
            theme,
            isContractTypeNEC
          );
      }
    }

    return [];
  }, [statusOptions, t, theme, productItems, productType, isContractTypeNEC]);

  const getDefaultSortingModel = useCallback((productType?: ProductType) => {
    if (!productType) {
      return [];
    }

    if (
      [ProductType.RisksRegister, ProductType.Events].indexOf(productType) >= 0
    ) {
      return defaultRisksEventsSortingModel;
    }
    return defaultEWsInstructionsSortingModel;
  }, []);

  useEffect(() => {
    if (
      columns &&
      columns.length &&
      !sortingModel.length &&
      !defaultSoringModelSet.current
    ) {
      // Columns loaded & no sortingModel set. Setting default sorting model
      setSortingModel(getDefaultSortingModel(productType));
      defaultSoringModelSet.current = true;
    }
  }, [columns, productType, getDefaultSortingModel, sortingModel]);

  const localProductItems = useMemo(() => productItems || [], [productItems]);

  return (
    <NewStyledDataGrid
      apiRef={gridApiRef}
      rows={localProductItems}
      columns={columns}
      getRowId={(rowData: ProductItem) => rowData.id}
      rowSelectionModel={selectionModel}
      onRowSelectionModelChange={handleSelectionModelChange}
      loading={loading || contractLoading}
      sortingMode="client"
      sortModel={sortingModel}
      onSortModelChange={setSortingModel}
      filterMode="client"
      filterModel={filterModel}
      onFilterModelChange={setFilterModel}
      onRowClick={handleRowClick}
      checkboxSelection
      disableRowSelectionOnClick
      hideFooter
    />
  );
};
