import {
  GenerateSendVariationObjectionVariationPreviewMutation,
  GenerateSendVariationObjectionVariationPreviewMutationVariables,
  SendVariationObjectionVariationPreview,
} from "generated/graphql";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useCallback, useContext, useEffect, useState } from "react";
import { debounce } from "@mui/material";
import { eventDebounceDuration } from "../../../../../../constants";
import { VariationWidgetContext } from "../../VariationWidget/VariationWidget.context";
import { generateSendVariationObjectionVariationPreviewMutation } from "./ObjectToInstructionOfVariationAction.query";

export const useSendObjectionToInstructionOfVariationModal = (
  reasons: string
) => {
  const { variation } = useContext(VariationWidgetContext);
  const [variationPreview, setVariationPreview] =
    useState<SendVariationObjectionVariationPreview>();

  const [generatePreview] = useGraphMutation<
    GenerateSendVariationObjectionVariationPreviewMutation,
    GenerateSendVariationObjectionVariationPreviewMutationVariables
  >(generateSendVariationObjectionVariationPreviewMutation, {}, null);

  const loadPreview = debounce(
    useCallback(async () => {
      const { data } = await generatePreview({
        variables: {
          input: {
            variationId: variation!.id,
            reasons,
          },
        },
      });

      if (data) {
        setVariationPreview(
          data.generateSendVariationObjectionVariationPreview
        );
      }
    }, [generatePreview, variation, reasons]),
    eventDebounceDuration
  );

  useEffect(() => {
    loadPreview();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  return {
    variationPreview,
  };
};
