import { Box } from "@mui/material";
import { ProductType, VariationItem } from "generated/graphql";
import { ProductItemPreviewContainer } from "components/ProductItemPreview/ProductItemPreviewContainer";
import { ProductItemPreviewHeader } from "components/ProductItemPreview/ProductItemPreviewHeader";
import { useContractByProductInstanceId } from "containers/Projects/hooks/useContractByProductInstanceId/useContractByProductInstanceId";
import { CenteredLoadingIndicator } from "components/CenteredLoadingIndicator";
import { VariationWidget } from "../VariationWidget/VariationWidget";
import { noop } from "helpers/miscelaneous";

export type VariationItemPreviewProps = {
  variation: VariationItem;
};

export const VariationItemPreview: React.FC<VariationItemPreviewProps> = ({
  variation,
}) => {
  const { contract, loading } = useContractByProductInstanceId(
    variation.productInstanceId
  );

  return loading || !contract ? (
    <CenteredLoadingIndicator />
  ) : (
    <ProductItemPreviewContainer>
      <ProductItemPreviewHeader
        productType={ProductType.Variations}
        itemNumber={variation.number}
        dateCreated={variation.dateCreated}
      />
      <Box mt={3} width="100%">
        <VariationWidget
          previewMode
          loading={false}
          variation={variation}
          contract={contract}
          regardingId={variation.regardingId}
          projectId={contract.project.id}
          projectName={contract.project.friendlyName}
          instructionItem={variation.regardingInstruction ?? undefined}
          onChange={noop}
        />
      </Box>
    </ProductItemPreviewContainer>
  );
};
