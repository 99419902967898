import countryCodes from "country-codes-list";
import { CustomLabels } from "react-flags-select/build/types";

export const getCountriesData = (): CustomLabels => {
  const countries = countryCodes.all().map((country) => ({
    primary: country.officialLanguageNameEn,
    secondary: country.countryCallingCode,
  }));

  const toReturn: CustomLabels = {};

  countries.forEach((country) => {
    toReturn[country.primary] = {
      ...country,
      secondary: `+${country.secondary}`,
    };
  });

  return toReturn;
};

export const getCountryCodeByPhoneNumber = (phoneNumber: string) => {
  const allPrefixNumbers = countryCodes.all().map((data) => ({
    countryCallingCode: `+${data.countryCallingCode}`,
    countryCode: data.countryCode,
  }));

  const phoneNumberWithPlus =
    phoneNumber[0] === "+" ? phoneNumber : `+${phoneNumber}`;

  const countriesThatMatchPrefix = allPrefixNumbers.filter(
    (countryData) =>
      phoneNumberWithPlus.indexOf(countryData.countryCallingCode) === 0
  );

  return countriesThatMatchPrefix[0].countryCode;
};
