import { CompEventWidgetContext } from "containers/Projects/components/CompEvents/CompEventWidget/CompEventWidget.context";
import { OnMutationDone } from "decl";
import {
  RecordCompEventOwnAssessmentNoticeMutation,
  RecordCompEventOwnAssessmentNoticeMutationVariables,
  SendCompEventOwnAssessmentNoticeMutation,
  SendCompEventOwnAssessmentNoticeMutationVariables,
} from "generated/graphql";
import { recordCompEventOwnAssessmentNoticeMutation } from "graphql/mutations/recordCompEventOwnAssessmentNotice";
import { sendCompEventOwnAssessmentNoticeMutation } from "graphql/mutations/sendCompEventOwnAssessmentNotice";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";

export const useNotifyOwnAssessmentCEAction = () => {
  const { t } = useTranslation();
  const { onChange, compEvent } = useContext(CompEventWidgetContext);

  const onUpdated: OnMutationDone = useCallback(
    (cache) => {
      cache.evict({ id: "ROOT_QUERY", fieldName: "compEventItems" });
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "compEventHistoryItems",
        args: { compEventId: compEvent!.id },
      });
      cache.gc();
      onChange?.();
    },
    [onChange, compEvent]
  );

  const [
    sendCEOwnAssessmentNotice,
    { loading: sendCEOwnAssessmentNoticeLoading },
  ] = useGraphMutation<
    SendCompEventOwnAssessmentNoticeMutation,
    SendCompEventOwnAssessmentNoticeMutationVariables
  >(
    sendCompEventOwnAssessmentNoticeMutation,
    {
      update: onUpdated,
    },
    t("common.successMessages.entitySent", {
      entity: t("Projects.CompEvents.compEventOwnAssessmentNotice"),
    })
  );

  const [
    recordCEOwnAssessmentNotice,
    { loading: recordCEOwnAssessmentNoticeLoading },
  ] = useGraphMutation<
    RecordCompEventOwnAssessmentNoticeMutation,
    RecordCompEventOwnAssessmentNoticeMutationVariables
  >(
    recordCompEventOwnAssessmentNoticeMutation,
    {
      update: onUpdated,
    },
    t("common.successMessages.entityRecorded", {
      entity: t("Projects.CompEvents.compEventOwnAssessmentNotice"),
    })
  );

  return {
    actionLoading:
      sendCEOwnAssessmentNoticeLoading || recordCEOwnAssessmentNoticeLoading,
    sendCEOwnAssessmentNotice,
    recordCEOwnAssessmentNotice,
  };
};
