import { Box, Grid, Typography, useTheme } from "@mui/material";
import { ListItemsDivider } from "components/ListItemsDivider";
import { FormPublicApi } from "decl";
import { AttachmentInput, AttachmentStatus } from "generated/graphql";
import { validateData } from "helpers/validators";
import React, {
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Attachments } from "../../../Attachments/Attachments";
import { useAttachments } from "../../../Attachments/hooks/useAttachments";
import { useImagePreviewModal } from "../../../Attachments/hooks/useImagePreviewModal";
import { CompEventWidgetContext } from "../../../CompEvents/CompEventWidget/CompEventWidget.context";
import { FormLabel } from "../../../../../../components/FormLabel";
import { PhotoAttachmentPreviewModal } from "../../../PhotoAttachmentPreviewModal/PhotoAttachmentPreviewModal";
import { RichTextArea } from "../../../../../../components/RichTextArea/RichTextArea";
import {
  dataValidators,
  getDefaultData,
} from "./CEquotationAssessment.constants";
import {
  CEQuotationAssessmentProps,
  QuotationAssessmentFormDataType,
} from "./CEQuotationAssessment.decl";
import {
  draftAssessmentToEditAssessmentInput,
  quotationVariationToEditQuotationInput,
} from "./CEQuotationAssessment.utils";
import { ImpactedDateField } from "../../../ActionModal/ImpactedDateField";
import { CurrencyTextField } from "components/CurrencyTextField";
import { AttachmentsLayout } from "../../../Attachments/components/AttachmentsHeader/AttachmentsHeader";
import {
  TimeField,
  TimeFieldActionType,
} from "containers/Projects/components/ActionModal/TimeField";

export type CEQuotationAssessmentFormPublicAPI = FormPublicApi & {
  onBeforeAbort: () => void;
};

export const CEQuotationAssessment: React.FC<CEQuotationAssessmentProps> = ({
  contractCurrency,
  sections,
  keyDates,
  draftQuotation,
  draftAssessment,
  apiRef,
  onAttachmentsLoadingChange,
  onChange,
  onAttachmentsChange,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { compEvent } = useContext(CompEventWidgetContext);

  const [formData, setFormData] = useState<QuotationAssessmentFormDataType>(
    quotationVariationToEditQuotationInput(draftQuotation) ??
      draftAssessmentToEditAssessmentInput(draftAssessment) ??
      getDefaultData(compEvent!.id, keyDates, sections)
  );
  const [formDataErrors, setFormDataErrors] = useState<{
    [key: string]: string;
  }>({});

  const isEditMode = useMemo(
    () => draftQuotation || draftAssessment,
    [draftQuotation, draftAssessment]
  );

  const computedAttachments = useMemo(
    () => draftAssessment?.attachments ?? draftQuotation?.attachments ?? [],
    [draftAssessment, draftQuotation]
  );

  const handleDetailsChange = (htmlContent: string) => {
    setFormData((curData) => ({
      ...curData,
      details: htmlContent,
    }));

    setFormDataErrors((curFormDataErrs) => {
      const { details: _, ...rest } = curFormDataErrs;

      return rest;
    });
  };

  const handleTextFieldChange: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = (evt) => {
    setFormData((crtData) => ({
      ...crtData,
      [evt.target.name]: evt.target.value,
    }));

    setFormDataErrors((curFormDataErrs) => {
      const { [evt.target.name]: _, ...rest } = curFormDataErrs;

      return rest;
    });
  };

  const handleTimeDecrement = () => {
    setFormData((crtData) => ({
      ...crtData,
      time: crtData.time - 1,
    }));
  };

  const handleTimeIncrement = () => {
    setFormData((crtData) => ({
      ...crtData,
      time: crtData.time + 1,
    }));
  };

  const handleImpactedDateArrayChange = (
    id: string,
    type: "section" | "keyDate",
    timeActionType: TimeFieldActionType
  ) => {
    const impactedDateArray =
      type === "section" ? "sectionalChanges" : "keyDatesChanges";

    const currentImpactedDateArrayIds = formData[impactedDateArray].map(
      (impactedDateChange) => impactedDateChange?.id
    );

    if (currentImpactedDateArrayIds.indexOf(id) >= 0) {
      // date impact already exists. Updating it...
      setFormData((crtFormData) => {
        return {
          ...crtFormData,
          [impactedDateArray]: crtFormData[impactedDateArray].map(
            (crtDateChange) =>
              crtDateChange?.id === id
                ? {
                    ...crtDateChange,
                    days:
                      timeActionType === TimeFieldActionType.Increment
                        ? crtDateChange.days + 1
                        : crtDateChange.days - 1,
                  }
                : crtDateChange
          ),
        };
      });
    } else {
      setFormData((crtFormData) => ({
        ...crtFormData,
        [impactedDateArray]: [
          ...crtFormData[impactedDateArray],
          {
            id,
            days: timeActionType === TimeFieldActionType.Increment ? 1 : -1,
          },
        ],
      }));
    }
  };

  const handleAttachmentsUpdated = async (
    attachmentsUpdated: AttachmentInput[]
  ) => {
    setFormData((crtFormData) => ({
      ...crtFormData,
      attachments: attachmentsUpdated,
    }));

    onAttachmentsChange?.({
      ...formData,
      attachments: attachmentsUpdated,
    });
  };

  const validateForm = useCallback(
    (formData: QuotationAssessmentFormDataType) => {
      const validationResult = validateData(formData, dataValidators);

      if (validationResult.valid) {
        setFormDataErrors({});
        return true;
      }
      setFormDataErrors(validationResult.errors);
      return false;
    },
    []
  );

  const reset = useCallback(() => {
    setFormData(
      quotationVariationToEditQuotationInput(draftQuotation) ??
        draftAssessmentToEditAssessmentInput(draftAssessment) ??
        getDefaultData(compEvent!.id, keyDates, sections)
    );
  }, [draftQuotation, draftAssessment, keyDates, sections, compEvent]);

  const {
    allAttachments,
    attachmentsLoading,
    addAttachments,
    removeAttachment,
    updateAttachment,
    downloadAttachment,
    unloadLocalAttachments,
  } = useAttachments(
    computedAttachments.filter(
      (attach) => attach.status === AttachmentStatus.Active
    ),
    handleAttachmentsUpdated
  );

  const handleBeforeAbort = useCallback(() => {
    if (!isEditMode) {
      // quotation/assessment not submitted. Remove all allegedly added attachments from the server
      unloadLocalAttachments();
    }
  }, [isEditMode, unloadLocalAttachments]);

  const {
    imageAttachmentPreviewModalVisible,
    imagePreviewData,
    previewUrl,
    handleAttachmentClick,
    closeModal: closeImagePreviewModal,
  } = useImagePreviewModal(downloadAttachment);

  useImperativeHandle(
    apiRef,
    () => ({
      reset,
      validate: () => validateForm(formData),
      onBeforeAbort: handleBeforeAbort,
    }),
    [validateForm, formData, reset, handleBeforeAbort]
  );

  useEffect(() => {
    setFormData(
      quotationVariationToEditQuotationInput(draftQuotation) ??
        draftAssessmentToEditAssessmentInput(draftAssessment) ??
        getDefaultData(compEvent!.id, keyDates, sections)
    );
  }, [draftQuotation, draftAssessment, keyDates, sections, compEvent]);

  useEffect(() => {
    onAttachmentsLoadingChange?.(attachmentsLoading);
  }, [attachmentsLoading, onAttachmentsLoadingChange]);

  useEffect(() => {
    onChange?.(formData);
  }, [formData, onChange]);

  return (
    <>
      <PhotoAttachmentPreviewModal
        open={imageAttachmentPreviewModalVisible}
        attachment={imagePreviewData?.attachment}
        creatorName={imagePreviewData?.creatorName}
        creatorCompany={imagePreviewData?.creatorCompany}
        previewUrl={previewUrl}
        onClose={closeImagePreviewModal}
        onDownload={downloadAttachment}
      />

      <Grid container spacing={4}>
        <Grid item xs={12}>
          <FormLabel
            label={t(
              "Projects.CompEvents.QuotationAssessmentModal.detailsOfAssessment"
            )}
            required
          />
          <RichTextArea
            content={formData.details}
            error={!!formDataErrors.details}
            onChange={handleDetailsChange}
          />
          {!!formDataErrors.details && (
            <Typography variant="caption" color="error" mt={0.5}>
              {formDataErrors.details}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} display="flex" alignItems="center">
          <Box display="flex" flexDirection="column" width="240px">
            <FormLabel label={t("common.labels.price")} required />
            <CurrencyTextField
              name="price"
              value={formData.price}
              onChange={handleTextFieldChange}
              size="small"
              error={!!formDataErrors.price}
              helperText={formDataErrors.price}
              currency={contractCurrency ?? ""}
              required
            />
          </Box>
          <Box display="flex" flexDirection="column" ml={3} width="240px">
            <FormLabel
              label={t(
                "Projects.CompEvents.QuotationAssessmentModal.timeCompletionDate"
              )}
              required
            />
            <TimeField
              number={formData.time}
              onDecrement={handleTimeDecrement}
              onIncrement={handleTimeIncrement}
            />
          </Box>
        </Grid>
        {keyDates && keyDates.length > 0 && (
          <Grid item xs={12}>
            <Box>
              <FormLabel
                label={t(
                  "Projects.CompEvents.QuotationAssessmentModal.keyDates"
                )}
                required
              />
              {keyDates.map((keyDate) => (
                <React.Fragment key={keyDate.id}>
                  <ImpactedDateField
                    keyDate={keyDate}
                    timeImpact={
                      formData.keyDatesChanges.find(
                        (keyDateImpact) => keyDateImpact?.id === keyDate.id
                      ) ?? undefined
                    }
                    onIncrement={() =>
                      handleImpactedDateArrayChange(
                        keyDate.id,
                        "keyDate",
                        TimeFieldActionType.Increment
                      )
                    }
                    onDecrement={() =>
                      handleImpactedDateArrayChange(
                        keyDate.id,
                        "keyDate",
                        TimeFieldActionType.Decrement
                      )
                    }
                  />
                  <ListItemsDivider sx={{ sy: theme.spacing(1.5) }} />
                </React.Fragment>
              ))}
            </Box>
          </Grid>
        )}
        {sections && sections.length > 0 && (
          <Grid item xs={12}>
            <Box>
              <FormLabel
                label={t(
                  "Projects.CompEvents.QuotationAssessmentModal.sectionalCompletionDates"
                )}
                required
              />
              {sections.map((section) => (
                <React.Fragment key={section.id}>
                  <ImpactedDateField
                    section={section}
                    timeImpact={
                      formData.sectionalChanges.find(
                        (sectionImpact) =>
                          sectionImpact?.id === section.id ||
                          sectionImpact?.id === section.number
                      ) ?? undefined
                    }
                    onIncrement={() =>
                      handleImpactedDateArrayChange(
                        section.id,
                        "section",
                        TimeFieldActionType.Increment
                      )
                    }
                    onDecrement={() =>
                      handleImpactedDateArrayChange(
                        section.id,
                        "section",
                        TimeFieldActionType.Decrement
                      )
                    }
                  />
                  <ListItemsDivider sx={{ sy: theme.spacing(1.5) }} />
                </React.Fragment>
              ))}
            </Box>
          </Grid>
        )}
        <Grid item xs={12}>
          <Attachments
            editMode
            showTabs={false}
            defaultLayout={AttachmentsLayout.List}
            attachments={allAttachments}
            onAttachmentsAdd={addAttachments}
            onAttachmentRemove={removeAttachment}
            onAttachmentUpdate={updateAttachment}
            onAttachmentClick={handleAttachmentClick}
          />
        </Grid>
      </Grid>
    </>
  );
};
