import { Box, CircularProgress, css, styled, Typography } from "@mui/material";
import { LockIcon } from "components/Icons/LockIcon";
import { XCircleIcon } from "components/Icons/XCircleIcon";
import moment from "moment";
import { FileItemProps } from "../../Attachments.decl";
import { isAttachment, stringToFileMIMEType } from "../../Attachments.utils";
import { FileTypeIcon } from "../FileTypeIcon";
import { useFileItem } from "../../hooks/useFileItem";
import { FileDescription } from "../FileDescription";

const FileListItemContainer = styled(Box)(
  ({ theme }) => css`
    border: 1px solid ${theme.palette.grey[200]};
    border-radius: 12px;
    display: flex;
    align-items: center;
    overflow: hidden;
    cursor: pointer;
  `
);

export const FileListItem: React.FC<FileItemProps> = (props) => {
  const { file, editable = true } = props;
  const { handleDelete, handleDescriptionChange, handleListItemClick } =
    useFileItem(props);

  return (
    <FileListItemContainer px={2} py={1.25} onClick={handleListItemClick}>
      <FileTypeIcon type={stringToFileMIMEType(file.mimeType)} />
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        ml={1.5}
        flex={1}
        overflow="hidden"
      >
        <Typography
          variant="p1"
          fontWeight={600}
          color="grey.800"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          width="100%"
        >
          {file.fileName}
        </Typography>
        <FileDescription
          editable={editable}
          file={file}
          onDescriptionChange={handleDescriptionChange}
        />
      </Box>
      <Box display="flex" alignItems="center">
        <Typography variant="p3" color="grey.600" mx={2}>
          {moment(file.dateCreated).fromNow()}
        </Typography>
        {isAttachment(file) && file.locked ? (
          <LockIcon />
        ) : !isAttachment(file) && file.loading ? (
          <CircularProgress size="20px" />
        ) : editable ? (
          <XCircleIcon onClick={handleDelete} />
        ) : null}
      </Box>
    </FileListItemContainer>
  );
};
