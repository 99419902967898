import {
  ClaimActionType,
  ClaimItem,
  ClaimStatus,
  CompEventItem,
  EventItem,
  InstructionItem,
  VariationItem,
} from "generated/graphql";
import { claimStatusToNextStepWordingMap } from "./ClaimWidget/ClaimWidget.constants";
import { claimActionTypeFIDIC99RedYellowTypeMapping } from "./types";

export const isClaim = (
  productItem: CompEventItem | ClaimItem | VariationItem
): productItem is ClaimItem =>
  !!((productItem as ClaimItem).__typename === "ClaimItem");

export const getNextStepText = (
  claim?: ClaimItem,
  isFIDIC99RedYellow?: boolean
) => {
  if (claim) {
    if (claim.nextStep) {
      if (claim.status === ClaimStatus.Accepted) {
        if (
          claim.lastAction.actionType ===
          ClaimActionType.NotifyErrorFoundAgreement
        ) {
          if (
            claim.lastAction.party.description
              .toLowerCase()
              .includes("engineer")
          ) {
            // engineer notified error found in Agreement
            return claimStatusToNextStepWordingMap.ReviseAgreement;
          } else {
            // contractor / employer notified error found in Agreement
            return claimStatusToNextStepWordingMap.ResponseToErrorInAgreement;
          }
        } else if (
          claim.lastAction.actionType ===
          ClaimActionType.NotifyErrorFoundDetermination
        ) {
          if (
            claim.lastAction.party.description
              .toLowerCase()
              .includes("engineer")
          ) {
            // engineer notified error found in Determination
            return claimStatusToNextStepWordingMap.ReviseDetermination;
          } else {
            // contractor / employer notified error found in Determination
            return claimStatusToNextStepWordingMap.ResponseToErrorInDetermination;
          }
        }
      }
      return claimStatusToNextStepWordingMap[
        isFIDIC99RedYellow
          ? claimActionTypeFIDIC99RedYellowTypeMapping(
              claim.nextStep.actionType
            )
          : claim.nextStep.actionType
      ];
    } else {
      return null;
    }
  } else {
    return isFIDIC99RedYellow
      ? claimStatusToNextStepWordingMap.None99RY
      : claimStatusToNextStepWordingMap.None;
  }
};

export const getNextStepDueDate = (
  claim?: ClaimItem,
  eventItem?: EventItem,
  instructionItem?: InstructionItem
) => {
  return claim
    ? claim.nextStep?.dueDate
    : eventItem?.notifyDueDate || instructionItem?.notifyDueDate;
};
