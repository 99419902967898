import { ClaimItem, ClaimRegardingType } from "generated/graphql";
import { useMemo } from "react";
import { useClaimWidgetNextStepSection } from "./useClaimWidgetNextStepSection";
import { useClaimProductInstance } from "./useClaimProductInstance";

export const useShallDisplayClaimWidget = (
  regardingType: ClaimRegardingType,
  regardingId: string,
  claim?: ClaimItem,
  contractId?: string
) => {
  const { claimProductInstance, loading: claimProdInstanceLoading } =
    useClaimProductInstance(contractId);

  const { claimActionList } = useClaimWidgetNextStepSection(
    regardingType,
    regardingId,
    claimProductInstance?.id,
    claim?.id
  );

  const canAccessClaimWidget = useMemo(() => {
    if (claimProdInstanceLoading) {
      return false;
    }

    if (!claimProductInstance) {
      return false;
    }

    return (
      !claimProdInstanceLoading &&
      !!claimProductInstance &&
      (!!claim || claimActionList.length > 0)
    );
  }, [claimProductInstance, claimProdInstanceLoading, claimActionList, claim]);

  return canAccessClaimWidget;
};
