import { Box } from "@mui/material";
import {
  GridCellParams,
  GridEventListener,
  GridFilterModel,
  GridLogicOperator,
  GridRowParams,
  GridRowSelectionModel,
  GridSortModel,
} from "@mui/x-data-grid-pro";
import { StyledDataGrid } from "components/StyledDataGrid";
import {
  ChangeProjectStatusMutation,
  ChangeProjectStatusMutationVariables,
  Project,
  ProjectsQuery,
  ProjectsQueryVariables,
  ProjectStatus,
} from "generated/graphql";
import { exportToExcel } from "helpers/exportToExcel";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useContractStatusOptions } from "../../../../components/StatusTag/useContractStatusOptions";
import { useColumns } from "./constants";
import { changeProjectStatusMutation, projectsQuery } from "./Projects.query";
import { NewAppPaths } from "helpers/paths/paths";
import { GridHeader } from "components/GridHeader";
import { useDataGridVisibleRows } from "hooks/useDataGridVisibleRows";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useGraphQuery } from "hooks/useGraphQuery";
import { StatusOption } from "components/StatusTag/StatusTag";
import { getProjectActiveContractsNumber } from "./Projects.utils";

export const Projects = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const projectStatusOptions =
    useContractStatusOptions() as StatusOption<ProjectStatus>[];

  const { visibleRowsCount, gridApiRef } = useDataGridVisibleRows();
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>();
  const [sortingModel, setSortingModel] = useState<GridSortModel>([
    { field: "friendlyName", sort: "asc" },
  ]);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [
      {
        field: "status",
        operator: "isAnyOf",
        value: [
          ProjectStatus.Active,
          ProjectStatus.Closed,
          ProjectStatus.Offline,
        ],
      },
    ],
    logicOperator: GridLogicOperator.And,
    quickFilterLogicOperator: GridLogicOperator.And,
    quickFilterValues: [],
  });

  // TODO: based on user role, fetch all projects or only company projects
  const {
    data: projectsData,
    refetch: refetchProjects,
    loading: getProjectsLoading,
  } = useGraphQuery<ProjectsQuery, ProjectsQueryVariables>(projectsQuery);

  const [changeProjectStatus, { loading: changeProjectStatusLoading }] =
    useGraphMutation<
      ChangeProjectStatusMutation,
      ChangeProjectStatusMutationVariables
    >(
      changeProjectStatusMutation,
      {
        update: () => {
          refetchProjects();
        },
      },
      t("common.successMessages.entityUpdated", {
        entity: t("common.labels.project"),
      })
    );

  const handleExcelExport = async () => {
    const columns = [
      {
        header: t("common.labels.friendlyName"),
        key: "friendlyName",
        width: 20,
      },
      { header: t("common.labels.status"), key: "status", width: 20 },
      {
        header: t("AdminConsole.Projects.labels.sector"),
        key: "sector",
        width: 20,
      },
      {
        header: t("AdminConsole.Projects.labels.activeContracts"),
        key: "activeContracts",
        width: 20,
      },
      { header: t("common.labels.dateCreated"), key: "dateCreated", width: 20 },
      { header: t("common.labels.createdBy"), key: "creator", width: 20 },
    ];
    const rows = projectsData!.projects?.items
      .filter((project) => (selectionModel || []).indexOf(project.id) >= 0)
      .map((project) => {
        const activeContractsNr = getProjectActiveContractsNumber(
          project as Project
        );

        return {
          ...project,
          activeContracts: activeContractsNr,
          dateCreated: project ? new Date(project.dateCreated) : "",
        };
      });

    exportToExcel(t("common.labels.projects"), columns, rows);
  };

  const handleCreateNewProject = () => {
    navigate(
      NewAppPaths.authorized.AdminConsole.children.Projects.children.NewProject
    );
  };

  const handleGridRowSelectionModelChange = useCallback(
    (selectionModel: GridRowSelectionModel) => {
      setSelectionModel(selectionModel);
    },
    []
  );

  const handleProjectStatusChange = useCallback(
    async (rowData: Project, newProjectStatus: ProjectStatus) => {
      await changeProjectStatus({
        variables: {
          id: rowData.id,
          status: newProjectStatus,
        },
      });
    },
    [changeProjectStatus]
  );

  const handleRowClick: GridEventListener<"rowClick"> | undefined = useCallback(
    (clickedRowData: GridRowParams<Project>) => {
      const selection = window.getSelection()?.toString();

      if (!selection) {
        navigate(
          NewAppPaths.authorized.AdminConsole.children.Projects.children.ProjectDetails.pathConstructor(
            clickedRowData.row.id
          )
        );
      }
    },
    [navigate]
  );

  const columns = useColumns(projectStatusOptions, handleProjectStatusChange);

  return (
    <Box display="flex" flexDirection="column" alignItems="center" flex="1">
      <GridHeader
        title={t("AdminConsole.Projects.labels.projects")}
        actionButtonCaption={t("common.buttons.addEntity", {
          entity: t("common.labels.project").toLowerCase(),
        })}
        visibleRowsCount={visibleRowsCount || 0}
        selectedCount={selectionModel?.length || 0}
        onExportToExcel={handleExcelExport}
        onActionButtonClick={handleCreateNewProject}
        sticky
      />
      <Box sx={{ flex: 1, width: "100%" }}>
        <StyledDataGrid
          apiRef={gridApiRef}
          rows={(projectsData?.projects.items as Project[]) || []}
          columns={columns}
          getRowId={(rowData: Project) => rowData.id}
          onRowSelectionModelChange={handleGridRowSelectionModelChange}
          loading={getProjectsLoading || changeProjectStatusLoading}
          sortingMode="client"
          sortModel={sortingModel}
          onSortModelChange={setSortingModel}
          filterMode="client"
          filterModel={filterModel}
          onFilterModelChange={setFilterModel}
          onRowClick={handleRowClick}
          getCellClassName={(params: GridCellParams<any, Project, any>) => {
            return params.row.status === ProjectStatus.Removed
              ? "greyed-out"
              : "";
          }}
          checkboxSelection
          disableRowSelectionOnClick
        />
      </Box>
    </Box>
  );
};
