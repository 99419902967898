import {
  FormControl,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { FormLabel } from "components/FormLabel";
import { UnitInput } from "containers/Projects/components/UnitInput/UnitInput";
import { FormPublicApi } from "decl";
import {
  AddDailyDiaryWeatherRecordInput,
  CompanyLookupCollection,
  DailyDiaryPresetSection,
  DailyDiaryWeatherRecord,
  EditDailyDiaryWeatherRecordInput,
} from "generated/graphql";
import { validateData } from "helpers/validators";
import {
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { weatherRecordToEditInput } from "../WeatherSection.utils";
import { dailyDiaryTimeFormat } from "../../../DailyDiary.constants";
import { TimePicker } from "components/TimePicker";
import { FormErrorLabel } from "components/FormErrorLabel";
import { addDefaultMinutes00 } from "../../../utilts";
import { useWeatherRecordFormValidators } from "./useWeatherRecordFormValidators";

export type WeatherRecordFormProps = {
  weatherRecord?: DailyDiaryWeatherRecord;
  weatherLookups: CompanyLookupCollection[];
  section: DailyDiaryPresetSection;
  apiRef?: React.Ref<FormPublicApi>;
  onChange: (
    weatherRecord:
      | AddDailyDiaryWeatherRecordInput
      | EditDailyDiaryWeatherRecordInput
  ) => void;
};

export const defaultFormData: EditDailyDiaryWeatherRecordInput = {
  id: "",
  conditionsOptionId: "",
  humidity: "" as any, // note: because undefined would make the field uncontrolled and then controlled and for `null` value MUI throws error
  rainFall: "" as any,
  temperature: "" as any,
  timeOfMeasurement: null,
  windSpeed: "" as any,
  attachments: [],
};

export const WeatherRecordForm: React.FC<WeatherRecordFormProps> = ({
  weatherRecord,
  section,
  weatherLookups,
  apiRef,
  onChange,
}) => {
  const { t } = useTranslation();

  const firstFieldRef = useRef<any>(null);

  const [formData, setFormData] = useState<
    AddDailyDiaryWeatherRecordInput | EditDailyDiaryWeatherRecordInput
  >(weatherRecord ? weatherRecordToEditInput(weatherRecord) : defaultFormData);
  const [formDataErrors, setFormDataErrors] = useState<{
    [key: string]: string;
  }>({});

  const handleWeatherConditionsChange = (
    event: SelectChangeEvent<string | null>
  ) => {
    setFormData((curData) => ({
      ...curData,
      conditionsOptionId: event.target.value ?? "",
    }));

    setFormDataErrors((crtFormDataErrs) => {
      const { conditionsOptionId: _, ...rest } = crtFormDataErrs;

      return rest;
    });
  };

  const conditionsLookupOptions = useMemo(
    () =>
      weatherLookups.find(
        (lkp) =>
          lkp.id ===
          section.fields.find((field) => field.name === "Conditions")!.lookupId
      )!.options.items,
    [weatherLookups, section]
  );

  const dataValidators = useWeatherRecordFormValidators(
    section,
    formData.conditionsOptionId,
    conditionsLookupOptions
  );

  const handleUnitValueChange = (
    newValue: number | undefined,
    attribute: "humidity" | "rainFall" | "temperature" | "windSpeed"
  ) => {
    let computedValue = newValue;

    if (attribute !== "temperature" && newValue && newValue < 0) {
      computedValue = 0;
    }

    setFormData((crtFormData) => ({
      ...crtFormData,
      [attribute]: computedValue ?? "",
    }));
  };

  const resetForm = useCallback(() => {
    setFormData(defaultFormData);
    firstFieldRef.current.focus();
  }, []);

  const handleIncrementUnitValue = useCallback(
    (attribute: "humidity" | "rainFall" | "temperature" | "windSpeed") => {
      setFormData((crtFormData) => ({
        ...crtFormData,
        [attribute]: Number(crtFormData[attribute]) + 1,
      }));
    },
    []
  );

  const handleDecrementUnitValue = useCallback(
    (attribute: "humidity" | "rainFall" | "temperature" | "windSpeed") => {
      setFormData((crtFormData) => {
        let newValue = Number(crtFormData[attribute]) - 1;
        if (attribute !== "temperature" && newValue < 0) {
          newValue = 0;
        }

        return {
          ...crtFormData,
          [attribute]: newValue,
        };
      });
    },
    []
  );

  const validateForm = useCallback(
    (
      formData:
        | AddDailyDiaryWeatherRecordInput
        | EditDailyDiaryWeatherRecordInput
    ) => {
      const validationResult = validateData(formData, dataValidators);

      if (validationResult.valid) {
        setFormDataErrors({});
        return true;
      }
      setFormDataErrors(validationResult.errors);

      return false;
    },
    [dataValidators]
  );

  const handleTimePickerValueChange = (value: Date | null) => {
    setFormData((crtFormData) => ({
      ...crtFormData,
      timeOfMeasurement: value,
    }));

    setFormDataErrors((crtFormDataErrs) => {
      const { timeOfMeasurement: _, ...rest } = crtFormDataErrs;

      return rest;
    });
  };

  useEffect(() => {
    onChange({
      ...formData,
      ...(String(formData.humidity) !== ""
        ? { humidity: formData.humidity }
        : { humidity: undefined }),
      ...(String(formData.rainFall) !== ""
        ? { rainFall: formData.rainFall }
        : { rainFall: undefined }),
      ...(String(formData.temperature) !== ""
        ? { temperature: formData.temperature }
        : { temperature: undefined }),
      ...(String(formData.windSpeed) !== ""
        ? { windSpeed: formData.windSpeed }
        : { windSpeed: undefined }),
      ...(formData.timeOfMeasurement
        ? {
            timeOfMeasurement: moment(formData.timeOfMeasurement).format(
              dailyDiaryTimeFormat
            ),
          }
        : {}),
    });
  }, [onChange, formData]);

  useImperativeHandle(
    apiRef,
    () => ({
      validate: () => validateForm(formData),
      reset: resetForm,
    }),
    [resetForm, formData, validateForm]
  );

  useEffect(() => {
    firstFieldRef.current.focus();
  }, []);

  const handleHoursInputChange = (evt: InputEvent) => {
    addDefaultMinutes00(
      evt,
      !formData.timeOfMeasurement,
      handleTimePickerValueChange
    );
  };

  const isConditionsRequired = section.fields.find(
    (field) => field.name === "Conditions"
  )?.isRequired;
  const isTemperatureRequired = section.fields.find(
    (field) => field.name === "Temperature"
  )?.isRequired;
  const isRainfallRequired = section.fields.find(
    (field) => field.name === "Rainfall"
  )?.isRequired;
  const isWindSpeedRequired = section.fields.find(
    (field) => field.name === "WindSpeed"
  )?.isRequired;
  const isHumidityRequired = section.fields.find(
    (field) => field.name === "Humidity"
  )?.isRequired;
  const isTimeOfMeasurementRequired = section.fields.find(
    (field) => field.name === "TimeOfMeasurement"
  )?.isRequired;

  return (
    <Grid container spacing={5}>
      <Grid item xs={12}>
        <FormControl variant="standard" sx={{ minWidth: 120 }} fullWidth>
          <FormLabel
            label={t("Projects.DailyDiaries.Weather.conditions")}
            required={isConditionsRequired}
          />
          <Select
            labelId="weather-conditions-select-label"
            id="weather-conditions-select"
            value={formData.conditionsOptionId}
            onChange={handleWeatherConditionsChange}
            variant="outlined"
            error={!!formDataErrors.conditionsOptionId}
            size="small"
            inputRef={firstFieldRef}
            required={isConditionsRequired}
          >
            {conditionsLookupOptions.map((lkpOption) => (
              <MenuItem key={lkpOption.id} value={lkpOption.id}>
                {lkpOption.value}
              </MenuItem>
            ))}
          </Select>
          {!!formDataErrors.conditionsOptionId && (
            <FormErrorLabel
              dataTestId="conditionsOptionId-error-msg"
              errorMessage={formDataErrors.conditionsOptionId}
            />
          )}
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormLabel
          label={t("Projects.DailyDiaries.Weather.temperature")}
          required={isTemperatureRequired}
        />
        <UnitInput
          unit={t(
            `Projects.DailyDiaries.Units.${
              section.fields.find((field) => field.name === "Temperature")
                ?.unit ?? ""
            }`
          )}
          required={isTemperatureRequired}
          value={formData.temperature}
          onChange={(newVal) => handleUnitValueChange(newVal, "temperature")}
          onIncrement={() => handleIncrementUnitValue("temperature")}
          onDecrement={() => handleDecrementUnitValue("temperature")}
        />
      </Grid>
      <Grid item xs={6}>
        <FormLabel
          label={t("Projects.DailyDiaries.Weather.rainfall")}
          required={isRainfallRequired}
        />
        <UnitInput
          unit={t(
            `Projects.DailyDiaries.Units.${
              section.fields.find((field) => field.name === "Rainfall")?.unit ??
              ""
            }`
          )}
          required={isRainfallRequired}
          value={formData.rainFall}
          onChange={(newVal) => handleUnitValueChange(newVal, "rainFall")}
          onIncrement={() => handleIncrementUnitValue("rainFall")}
          onDecrement={() => handleDecrementUnitValue("rainFall")}
        />
      </Grid>
      <Grid item xs={6}>
        <FormLabel
          label={t("Projects.DailyDiaries.Weather.windSpeed")}
          required={isWindSpeedRequired}
        />
        <UnitInput
          unit={t(
            `Projects.DailyDiaries.Units.${
              section.fields.find((field) => field.name === "WindSpeed")
                ?.unit ?? ""
            }`
          )}
          required={isWindSpeedRequired}
          value={formData.windSpeed}
          onChange={(newVal) => handleUnitValueChange(newVal, "windSpeed")}
          onIncrement={() => handleIncrementUnitValue("windSpeed")}
          onDecrement={() => handleDecrementUnitValue("windSpeed")}
        />
      </Grid>
      <Grid item xs={6}>
        <FormLabel
          label={t("Projects.DailyDiaries.Weather.humidity")}
          required={isHumidityRequired}
        />
        <UnitInput
          unit={t(
            `Projects.DailyDiaries.Units.${
              section.fields.find((field) => field.name === "Humidity")?.unit ??
              ""
            }`
          )}
          required={isHumidityRequired}
          value={formData.humidity}
          onChange={(newVal) => handleUnitValueChange(newVal, "humidity")}
          onIncrement={() => handleIncrementUnitValue("humidity")}
          onDecrement={() => handleDecrementUnitValue("humidity")}
        />
        {!!formDataErrors.humidity && (
          <FormErrorLabel
            dataTestId="humidity-error-msg"
            errorMessage={formDataErrors.humidity}
          />
        )}
      </Grid>
      <Grid item xs={6}>
        <FormLabel
          label={t("Projects.DailyDiaries.Weather.measuredAt")}
          required={isTimeOfMeasurementRequired}
        />
        <TimePicker
          label="hh:mm"
          value={formData.timeOfMeasurement}
          onChange={handleTimePickerValueChange}
          required={isTimeOfMeasurementRequired}
          slotProps={{
            textField: {
              size: "small",
              fullWidth: true,
              onInput: handleHoursInputChange,
            },
          }}
          ampm={false}
          error={!!formDataErrors.timeOfMeasurement}
        />
        {!!formDataErrors.timeOfMeasurement && (
          <FormErrorLabel
            dataTestId="timeOfMeasurement-error-msg"
            errorMessage={formDataErrors.timeOfMeasurement}
          />
        )}
      </Grid>
    </Grid>
  );
};
