import {
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
} from "@mui/x-data-grid-pro";
import { AttachmentStatus, DraftVariationProposal } from "generated/graphql";
import { TrashIcon } from "components/Icons/TrashIcon";
import { Typography } from "@mui/material";
import {
  LocalVOResolveType,
  VariationItemTypes,
  VariationResolveType,
} from "./VOProposalsAgreementsDeterminationsTable";
import { TimeTag } from "containers/Projects/components/HistoryModal/TimeTag";
import { PriceTag } from "containers/Projects/components/HistoryModal/PriceTag";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";

// TODO: extract common columns in separate hooks. Each column in a separate hook
export const useColumns = (
  currency: string,
  type?: VariationItemTypes,
  onDelete?: (rowId: string) => void
): GridColDef<DraftVariationProposal | LocalVOResolveType>[] => {
  const { t } = useTranslation();

  return useMemo(
    () => [
      {
        field: "reference",
        headerName: t(
          `Projects.Variations.${
            type === "VariationAgreement"
              ? "variationAgreementReference"
              : type === "VariationProposal"
              ? "proposalReference"
              : "variationDeterminationReference"
          }`
        ),
        flex: 0.125,
        minWidth: 190,
        resizable: true,
        renderCell: (
          params: GridRenderCellParams<
            any,
            DraftVariationProposal | LocalVOResolveType,
            any
          >
        ) => (
          <Typography variant="p2" fontWeight={600} color="grey.800">
            {params.row.reference}
          </Typography>
        ),
      },
      {
        field: "price",
        headerName: t("common.labels.price"),
        flex: 0.15,
        minWidth: 120,
        maxWidth: 180,
        resizable: true,
        valueGetter: (params) => params.row.price,
        renderCell: (
          params: GridRenderCellParams<
            DraftVariationProposal | LocalVOResolveType,
            number,
            any
          >
        ) => (
          <PriceTag
            value={params.value}
            currency={currency}
            sx={{ width: "100%" }}
          />
        ),
      },
      {
        field: "time",
        headerName: t("Projects.Variations.EOT"),
        flex: 0.15,
        minWidth: 120,
        maxWidth: 180,
        resizable: true,
        valueGetter: (rowData) => rowData.row.time,
        renderCell: (
          params: GridRenderCellParams<
            DraftVariationProposal | LocalVOResolveType,
            number,
            any
          >
        ) => <TimeTag value={params.value} sx={{ width: "100%" }} />,
      },
      {
        field: "attachments",
        headerName: t("Attachments.attachments"),
        flex: 0.125,
        width: 130,
        resizable: true,
        valueGetter: (params) => {
          const activeAttachmentsNumber = (params.row.attachments || []).filter(
            (attach) => attach.status === AttachmentStatus.Active
          ).length;

          return t("Projects.CompEvents.ActionModal.attachmentsNo", {
            number: activeAttachmentsNumber,
          });
        },
      },
      ...(onDelete
        ? [
            {
              field: "options",
              headerName: "",
              width: 50,
              resizable: true,
              sortable: false,
              renderCell: (
                params: GridRenderCellParams<
                  any,
                  DraftVariationProposal | LocalVOResolveType,
                  any
                >
              ) => {
                return (
                  <GridActionsCellItem
                    className="visible-on-hover"
                    icon={<TrashIcon />}
                    label="Delete"
                    onClick={(evt) => {
                      evt.preventDefault();
                      evt.stopPropagation();

                      onDelete(params.row.id);
                    }}
                  />
                );
              },
            },
          ]
        : []),
    ],
    [t, currency, onDelete, type]
  );
};

export const localVOItemToVOItem = (
  localVOItem: LocalVOResolveType
): VariationResolveType => {
  const { isSelected, ...rest } = localVOItem;

  return rest;
};
