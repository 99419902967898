import { OnMutationDone } from "decl";
import {
  AddProductUserRoleInput,
  AddProductUserRoleMutation,
  AddProductUserRoleMutationVariables,
  ChangeProductUserRoleStatusMutation,
  ChangeProductUserRoleStatusMutationVariables,
  Company,
  CompanyStatus,
  EditProductUserRoleInput,
  EditProductUserRoleMutation,
  EditProductUserRoleMutationVariables,
  GetAvailableUsersForRolesQuery,
  GetAvailableUsersForRolesQueryVariables,
  ProductUserRole,
  ProductUserRoleStatus,
  UserStatus,
} from "generated/graphql";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useGraphQuery } from "hooks/useGraphQuery";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  addProductUserRoleMutation,
  changeProductUserRoleStatusMutation,
  editProductUserRoleMutation,
  getAvailableUsersForRolesQuery,
} from "./ProductInstanceRoles.query";
import uniqBy from "lodash.uniqby";

export type useProductInstanceRolesProps = {
  onAdd: OnMutationDone;
  onEdit: OnMutationDone;
  onStatusChange: OnMutationDone;
};

export const useProductInstanceRoles = (
  productInstanceId: string,
  { onAdd, onEdit, onStatusChange }: useProductInstanceRolesProps
) => {
  const { t } = useTranslation();

  const { data: usersForRolesData, loading: usersForRolesDataLoading } =
    useGraphQuery<
      GetAvailableUsersForRolesQuery,
      GetAvailableUsersForRolesQueryVariables
    >(getAvailableUsersForRolesQuery, { variables: { id: productInstanceId } });

  const [addUserRole, { loading: addUserRoleLoading }] = useGraphMutation<
    AddProductUserRoleMutation,
    AddProductUserRoleMutationVariables
  >(
    addProductUserRoleMutation,
    {
      update: onAdd,
    },
    t("common.successMessages.entityCreated", {
      entity: t("common.labels.role"),
    })
  );

  const [editUserRole, { loading: editUserRoleLoading }] = useGraphMutation<
    EditProductUserRoleMutation,
    EditProductUserRoleMutationVariables
  >(
    editProductUserRoleMutation,
    {
      update: onEdit,
    },
    t("common.successMessages.entityUpdated", {
      entity: t("common.labels.role"),
    })
  );

  const [changeUserRoleStatus, { loading: changeUserRoleStatusLoading }] =
    useGraphMutation<
      ChangeProductUserRoleStatusMutation,
      ChangeProductUserRoleStatusMutationVariables
    >(
      changeProductUserRoleStatusMutation,
      {
        update: onStatusChange,
      },
      t("common.successMessages.entityUpdated", {
        entity: t("common.labels.role"),
      })
    );

  const handleAddUserRole = useCallback(
    async (userRole: AddProductUserRoleInput) => {
      const { errors } = await addUserRole({
        variables: {
          input: userRole,
        },
      });

      return !errors;
    },
    [addUserRole]
  );

  const handleEditUserRole = useCallback(
    async (userRole: EditProductUserRoleInput) => {
      const { errors } = await editUserRole({
        variables: {
          input: userRole,
        },
      });

      return !errors;
    },
    [editUserRole]
  );

  const handleUserRoleStatusChange = useCallback(
    async (userRole: ProductUserRole, newStatus: ProductUserRoleStatus) => {
      const { errors } = await changeUserRoleStatus({
        variables: {
          id: userRole.id,
          status: newStatus,
        },
      });

      return !errors;
    },
    [changeUserRoleStatus]
  );

  const computedUsersForRoles = useMemo(() => {
    const contractActiveBindings =
      usersForRolesData?.productInstance.contract.activeBindings.items;
    const allCompaniesUsersInvolved = contractActiveBindings
      ?.map((binding) => {
        // do not show users of removed companies
        if (binding.company.status === CompanyStatus.Removed) {
          return [];
        }

        const companyData: Partial<Company> = {
          id: binding.company.id,
          registeredName: binding.company.registeredName,
          tradingName: binding.company.tradingName,
        };
        const users = binding.company.users.items;

        return users.map((user) => ({
          ...user,
          company: companyData,
        }));
      })
      .flat();

    const activeUsers = allCompaniesUsersInvolved?.filter(
      (user) => user.status !== UserStatus.Removed
    );

    const uniqUsers = uniqBy(activeUsers, "id");

    return uniqUsers;
  }, [usersForRolesData]);

  return {
    availableUsersForRoles: computedUsersForRoles,
    availableUsersForRolesLoading: usersForRolesDataLoading,
    addUserRole: handleAddUserRole,
    addUserRoleLoading,
    editUserRole: handleEditUserRole,
    editUserRoleLoading,
    changeUserRoleStatus: handleUserRoleStatusChange,
    changeUserRoleStatusLoading,
  };
};
