import { ActionsDialogProps } from "components/ActionsDialog";
import { RecordProductItemExtraData } from "containers/Projects/components/RecordProductItemForm";
import { ClaimActionType } from "generated/graphql";
import { RecordAction } from "../components/RecordAction";
import { useRecordFurtherParticularsRequestModal } from "./useRecordFurtherParticularsRequestModal";
import { EnhancedAttachment } from "containers/Projects/components/Attachments/Attachments.decl";
import { ReasonsAttachmentsSummary } from "../../../ActionModal/ReasonsAttachmentsSummary";
import { useTranslation } from "react-i18next";

export type RecordFurtherParticularsRequestModalProps = {
  reasons: string;
  attachments?: EnhancedAttachment[];
  onPrimaryClick: (extraData: RecordProductItemExtraData) => void;
} & Omit<ActionsDialogProps, "onPrimaryClick">;

export const RecordFurtherParticularsRequestModal: React.FC<
  RecordFurtherParticularsRequestModalProps
> = ({ reasons, attachments = [], ...restDialogProps }) => {
  const { t } = useTranslation();
  const { claimPreview } = useRecordFurtherParticularsRequestModal(reasons);
  return (
    <RecordAction
      actionType={ClaimActionType.RequestFurtherParticulars}
      claimPreview={claimPreview}
      summaryEl={
        <ReasonsAttachmentsSummary
          text={reasons}
          label={t("common.labels.reasons")}
          attachments={attachments}
        />
      }
      {...restDialogProps}
    />
  );
};
