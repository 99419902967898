import { Box, Breadcrumbs, Link, Typography } from "@mui/material";
import { NewEntityHeader } from "components/NewEntityHeader";
import { PageContentContainer } from "components/PageContentContainer";
import { PageContentHeaderSize } from "components/PageContentHeader/PageContentHeader";
import { FormPublicApi } from "decl";
import {
  AddProductInstanceInput,
  EditProductInstanceInput,
  ItemStatusCollection,
  ProductSchema,
} from "generated/graphql";
import { useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ProductInstanceForm } from "../../components/ProductInstanceForm/ProductInstanceForm";
import { useProductInstanceForm } from "../../components/ProductInstanceForm/useProductInstanceForm";
import { useNewProductInstance } from "./useNewProductInstance";

export const NewProductInstance = () => {
  const { t } = useTranslation();
  const productInstanceData = useRef<AddProductInstanceInput>();
  const ref = useRef<FormPublicApi>(null);

  const navigate = useNavigate();

  const {
    addNewProductInstance,
    projectDataLite,
    contractLite,
    products,
    productsLoading,
    projectDataLiteLoading,
    contractLiteLoading,
    addNewProductInstanceLoading,
  } = useNewProductInstance();

  const {
    loading: productDataLoading,
    selectedProductSchemas,
    selectedProductStatusCollections,
    handleSelectedProductChange,
  } = useProductInstanceForm();

  const handleAddProductInstance = useCallback(async () => {
    if (ref.current?.validate()) {
      const { errors } = await addNewProductInstance({
        variables: {
          input: productInstanceData.current!,
        },
      });

      if (!errors) {
        navigate(-1);
      }
    }
  }, [addNewProductInstance, navigate]);

  const handleFormChange = useCallback(
    (data: AddProductInstanceInput | EditProductInstanceInput) => {
      productInstanceData.current = data as AddProductInstanceInput;
    },
    []
  );

  const handleBreadcrumbClick = (parent: "contract" | "project") => {
    if (parent === "contract") {
      navigate(-1);
    } else {
      navigate(-2);
    }
  };

  return (
    <Box display="flex" flexDirection="column" position="relative">
      <NewEntityHeader
        onAdd={handleAddProductInstance}
        size={PageContentHeaderSize.Large}
        title={
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-around"
            alignItems="flex-start"
            pl={3}
          >
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              <Link key="1" onClick={() => handleBreadcrumbClick("project")}>
                <Typography variant="body2">
                  {projectDataLite?.friendlyName}
                </Typography>
              </Link>
              <Link key="2" onClick={() => handleBreadcrumbClick("contract")}>
                <Typography variant="body2">
                  {contractLite?.friendlyName}
                </Typography>
              </Link>
            </Breadcrumbs>
            <Typography variant="h5">
              {t("AdminConsole.ProductInstances.labels.newProductInstance")}
            </Typography>
          </Box>
        }
        generalLoading={
          addNewProductInstanceLoading ||
          contractLiteLoading ||
          projectDataLiteLoading
        }
        addActionLoading={addNewProductInstanceLoading}
      />
      <PageContentContainer>
        <ProductInstanceForm
          products={products}
          selectedProductSchemas={
            (selectedProductSchemas as ProductSchema[]) || []
          }
          selectedProductStatusCollections={
            (selectedProductStatusCollections as ItemStatusCollection[]) || []
          }
          disabled={productsLoading || productDataLoading}
          apiRef={ref}
          contractId={contractLite?.id}
          onChange={handleFormChange}
          onProductChange={handleSelectedProductChange}
        />
      </PageContentContainer>
    </Box>
  );
};
