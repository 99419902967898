import {
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
} from "@mui/x-data-grid-pro";
import { AttachmentStatus } from "generated/graphql";
import { TrashIcon } from "components/Icons/TrashIcon";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import {
  ClaimItemsTypes,
  LocalClaimResolveType,
} from "./DetailedClaimsAgreementsDeterminationsTable";
import { PriceTag } from "containers/Projects/components/HistoryModal/PriceTag";
import { TimeTag } from "containers/Projects/components/HistoryModal/TimeTag";

export const useColumns = (
  currency: string,
  type?: ClaimItemsTypes,
  isFIDIC99RedYellow?: boolean,
  onDelete?: (rowId: string) => void
): GridColDef<LocalClaimResolveType>[] => {
  const { t } = useTranslation();

  return useMemo(
    () => [
      {
        field: "reference",
        headerName: t(
          `Projects.Claims.${
            type === "ClaimAgreement"
              ? "claimAgreementReference"
              : type === "DetailedClaim"
              ? `detailedClaimReference${isFIDIC99RedYellow ? "99RY" : ""}`
              : "claimDeterminationReference"
          }`
        ),
        flex: 0.125,
        minWidth: 190,
        resizable: true,
        renderCell: (
          params: GridRenderCellParams<LocalClaimResolveType, any, any>
        ) => (
          <Typography variant="p2" fontWeight={600} color="grey.800">
            {params.row.reference}
          </Typography>
        ),
      },
      {
        field: "price",
        headerName: t("Projects.Claims.additionalPayment"),
        flex: 0.15,
        resizable: true,
        valueGetter: (rowData) => rowData.row.price,
        renderCell: (
          params: GridRenderCellParams<LocalClaimResolveType, number, any>
        ) => (
          <PriceTag
            value={params.value}
            currency={currency}
            sx={{ width: "100%" }}
          />
        ),
      },
      {
        field: "time",
        headerName: t("Projects.Claims.EOTTime"),
        flex: 0.15,
        resizable: true,
        valueGetter: (rowData) => rowData.row.time,
        renderCell: (
          params: GridRenderCellParams<LocalClaimResolveType, number, any>
        ) => <TimeTag value={params.value} sx={{ width: "100%" }} />,
      },
      {
        field: "attachments",
        headerName: t("Attachments.attachments"),
        flex: 0.125,
        width: 130,
        resizable: true,
        valueGetter: (params) => {
          const activeAttachmentsNumber = (params.row.attachments || []).filter(
            (attach) => attach.status === AttachmentStatus.Active
          ).length;

          return t("Projects.CompEvents.ActionModal.attachmentsNo", {
            number: activeAttachmentsNumber,
          });
        },
      },
      ...(onDelete
        ? [
            {
              field: "options",
              headerName: "",
              width: 50,
              resizable: true,
              sortable: false,
              renderCell: (
                params: GridRenderCellParams<LocalClaimResolveType, any, any>
              ) => {
                return (
                  <GridActionsCellItem
                    className="visible-on-hover"
                    icon={<TrashIcon />}
                    label="Delete"
                    onClick={(evt) => {
                      evt.preventDefault();
                      evt.stopPropagation();

                      onDelete(params.row.id);
                    }}
                  />
                );
              },
            },
          ]
        : []),
    ],
    [t, currency, type, isFIDIC99RedYellow, onDelete]
  );
};
