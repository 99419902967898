import { gql } from "@apollo/client";
import { creatorFields } from "graphql/fragments/creator.fragment";

export const dailyDiaryItemRegisterViewFields = gql`
  ${creatorFields}

  fragment DailyDiaryItemRegisterViewFields on DailyDiaryItem {
    id
    date
    number
    status
    latestRevision {
      id
      number
      status
    }
    dateSent
    sentBy {
      ...CreatorFields
    }
  }
`;

export const dailyDiaryItemsQuery = gql`
  ${dailyDiaryItemRegisterViewFields}

  query dailyDiaryItems(
    $productInstanceId: ID!
    $limit: Int!
    $nextToken: String
  ) {
    dailyDiaryItems(
      productInstanceId: $productInstanceId
      limit: $limit
      nextToken: $nextToken
    ) {
      nextToken
      items {
        ...DailyDiaryItemRegisterViewFields
      }
    }
  }
`;
