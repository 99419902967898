import {
  Box,
  ClickAwayListener,
  Fade,
  Popper,
  Typography,
} from "@mui/material";
import { PopperContentContainer } from "components/StatusTag/StatusTag.styled";
import {
  ProductsWithSchemasQuery,
  ProductsWithSchemasQueryVariables,
  SchemaField,
} from "generated/graphql";
import { useGraphQuery } from "hooks/useGraphQuery";
import { Info } from "phosphor-react";
import { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { productsWithSchemasQuery } from "./Placeholders.query";
import { PlaceholdersPopperContent } from "./PlaceholdersPopperContent";

export const Placeholders: React.FC = () => {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [popperRef, setPopperRef] = useState<HTMLElement | null>(null);

  const { data: products, loading: getProductsLoading } = useGraphQuery<
    ProductsWithSchemasQuery,
    ProductsWithSchemasQueryVariables
  >(productsWithSchemasQuery);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (!popperRef?.contains((event as any).target)) {
      setAnchorEl(event.currentTarget);
    }
    event.stopPropagation();
  };

  const open = Boolean(anchorEl);
  const id = open ? "transition-popper" : undefined;

  const handleClickAway = () => {
    setAnchorEl(null);
  };

  const ewSchemaFields = useMemo(() => {
    if (!products || !products?.products.items.length) {
      return [];
    }

    const ewProduct = products?.products.items.find((product) => {
      const nameLowerCased = product.name.toLowerCase();

      return (
        nameLowerCased.indexOf("early") >= 0 ||
        nameLowerCased.indexOf("warning") >= 0
      );
    });

    const defaultEwSchema = ewProduct?.productSchemas.items.find(
      (schema) => schema.isDefault
    )!;

    const allFields: SchemaField[] = [];

    defaultEwSchema.schemaSections.items.forEach((section) => {
      section.schemaFields.items.forEach((field) => {
        allFields.push({
          ...field,
          name: `${section.name}.${field.name}`,
        } as SchemaField);
      });
    });

    return allFields;
  }, [products]);

  return (
    <Box onClick={handleClick}>
      <Box
        position="absolute"
        top={16}
        display="flex"
        alignItems="center"
        sx={{ cursor: "pointer" }}
      >
        <Info size={20} />
        <Typography ml={1} color="text.primary">
          {t("AdminConsole.Templates.labels.seePlaceholders")}
        </Typography>
      </Box>
      {open && (
        <Popper
          id={id}
          open={open}
          anchorEl={anchorEl}
          transition
          placement="bottom-start"
          disablePortal={false}
          ref={setPopperRef}
          sx={{ zIndex: 20 }}
          modifiers={[
            {
              name: "flip",
              enabled: true,
              options: {
                altBoundary: true,
                rootBoundary: "document",
                padding: 8,
              },
            },
            {
              name: "offset",
              options: {
                offset: [0, 0],
              },
            },
          ]}
        >
          {({ TransitionProps }) => (
            <ClickAwayListener onClickAway={handleClickAway}>
              <div>
                <Fade {...TransitionProps} timeout={350}>
                  <PopperContentContainer>
                    <PlaceholdersPopperContent
                      loading={getProductsLoading}
                      schemaFields={ewSchemaFields}
                    />
                  </PopperContentContainer>
                </Fade>
              </div>
            </ClickAwayListener>
          )}
        </Popper>
      )}
    </Box>
  );
};
