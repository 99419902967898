import { CompEventWidgetContext } from "containers/Projects/components/CompEvents/CompEventWidget/CompEventWidget.context";
import { OnMutationDone } from "decl";
import {
  RecordCompEventQuotationRevisionRequestMutation,
  RecordCompEventQuotationRevisionRequestMutationVariables,
  SendCompEventQuotationRevisionRequestMutation,
  SendCompEventQuotationRevisionRequestMutationVariables,
} from "generated/graphql";
import { recordCompEventQuotationRevisionRequestMutation } from "graphql/mutations/recordCompEventQuotationRevisionRequest";
import { sendCompEventQuotationRevisionRequestMutation } from "graphql/mutations/sendCompEventQuotationRevisionRequest";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";

export const useRequestRevisedQuotationCEAction = () => {
  const { t } = useTranslation();
  const { onChange, compEvent } = useContext(CompEventWidgetContext);

  const onUpdated: OnMutationDone = useCallback(
    (cache) => {
      cache.evict({ id: "ROOT_QUERY", fieldName: "compEventItems" });
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "compEventHistoryItems",
        args: { compEventId: compEvent!.id },
      });
      cache.gc();
      onChange?.();
    },
    [onChange, compEvent]
  );

  const [
    sendCEQuotationRevisionRequest,
    { loading: sendCEQuotationRevisionRequestLoading },
  ] = useGraphMutation<
    SendCompEventQuotationRevisionRequestMutation,
    SendCompEventQuotationRevisionRequestMutationVariables
  >(
    sendCompEventQuotationRevisionRequestMutation,
    {
      update: onUpdated,
    },
    t("common.successMessages.entitySent", {
      entity: t("Projects.CompEvents.compEventQuotationRevisionRequest"),
    })
  );

  const [
    recordCEQuotationRevisionRequest,
    { loading: recordCEQuotationRevisionRequestLoading },
  ] = useGraphMutation<
    RecordCompEventQuotationRevisionRequestMutation,
    RecordCompEventQuotationRevisionRequestMutationVariables
  >(
    recordCompEventQuotationRevisionRequestMutation,
    {
      update: onUpdated,
    },
    t("common.successMessages.entityRecorded", {
      entity: t("Projects.CompEvents.compEventQuotationRevisionRequest"),
    })
  );

  return {
    actionLoading:
      sendCEQuotationRevisionRequestLoading ||
      recordCEQuotationRevisionRequestLoading,
    sendCEQuotationRevisionRequest,
    recordCEQuotationRevisionRequest,
  };
};
