import { SelectChangeEvent, Typography } from "@mui/material";
import {
  GridColDef,
  GridRenderCellParams,
  GridRenderEditCellParams,
  GridRowId,
  GridRowModesModel,
  GridEditInputCell,
  GridEditSingleSelectCell,
} from "@mui/x-data-grid-pro";
import { StatusOption, StatusTag } from "components/StatusTag/StatusTag";
import { temporaryRowId } from "../../../../../../../../constants";
import {
  EffectEstimationType,
  EffectOption,
  EffectOptionStatus,
} from "generated/graphql";
import {
  getCommonActions,
  statusComparatorFunction,
  useDateCreatedColumnConfig,
} from "helpers/dataGrid.helpers";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { getUserName } from "helpers/miscelaneous";

const estimationTypeTranslationsMapping = {
  [EffectEstimationType.Decimal]: "AdminConsole.Effect.labels.decimal",
  [EffectEstimationType.None]: "common.labels.none",
  [EffectEstimationType.Monetary]: "AdminConsole.Effect.labels.monetary",
};

export const useColumns = (
  statusOptions: StatusOption<EffectOptionStatus>[],
  onStatusChange: (row: EffectOption, newStatus: EffectOptionStatus) => void,
  rowModesModel: GridRowModesModel,
  handleSaveRow: (rowId: GridRowId) => void,
  handleDeleteRow: (rowId: GridRowId) => void,
  onEstimationTypeChange: (
    row: EffectOption,
    newEstimationType: EffectEstimationType
  ) => void
): GridColDef<EffectOption>[] => {
  const { t } = useTranslation();
  const dateCreatedColConfig = useDateCreatedColumnConfig();
  const estimationTypeOptions = Object.entries(
    estimationTypeTranslationsMapping
  ).map(([key, value]) => ({
    value: key,
    label: t(value),
  }));
  const columns = useMemo(
    () =>
      [
        {
          field: "label",
          headerName: t("common.labels.label"),
          flex: 0.125,
          minWidth: 190,
          resizable: true,
          editable: true,
          sortable: true,
          renderEditCell: (
            params: GridRenderEditCellParams<any, EffectOption>
          ) => {
            return params.row.id !== temporaryRowId ? (
              <Typography variant="body2">{params.row.label}</Typography>
            ) : (
              <GridEditInputCell {...params} error={!params.row.label} />
            );
          },
        },
        {
          field: "status",
          headerName: t("common.labels.status"),
          flex: 0.125,
          minWidth: 90,
          resizable: true,
          sortable: true,
          sortComparator: statusComparatorFunction,
          renderCell: (
            params: GridRenderCellParams<any, EffectOption, any>
          ) => {
            return (
              <StatusTag
                status={params.row.status}
                options={statusOptions}
                disablePortal={false}
                disabled={params.row.id === temporaryRowId}
                onChange={(newStatus: StatusOption<EffectOptionStatus>) =>
                  onStatusChange(params.row, newStatus.id)
                }
              />
            );
          },
        },
        {
          field: "estimationType",
          headerName: t("AdminConsole.Effect.labels.estimationType"),
          flex: 0.125,
          minWidth: 90,
          resizable: true,
          editable: true,
          sortable: true,
          type: "singleSelect",
          valueOptions: estimationTypeOptions,
          valueFormatter: ({ id: _, value: selectedEstimationType }) => {
            const selectedOption = estimationTypeOptions.find(
              (valueOption: { value: string; label: string }) =>
                valueOption.value === selectedEstimationType
            );

            return selectedOption?.label;
          },
          renderEditCell: (
            params: GridRenderEditCellParams<any, EffectOption, any>
          ) => {
            return params.row.id !== temporaryRowId ? (
              <Typography variant="body2">
                {params.row.estimationType}
              </Typography>
            ) : (
              <GridEditSingleSelectCell
                {...params}
                error={!params.row.estimationType}
                onValueChange={(event: SelectChangeEvent<any>) => {
                  onEstimationTypeChange(params.row, event.target.value);
                }}
                initialOpen={false}
              />
            );
          },
        },
        {
          field: "unit",
          headerName: t("AdminConsole.Effect.labels.unit"),
          flex: 0.125,
          minWidth: 190,
          resizable: true,
          editable: true,
          sortable: true,
          renderEditCell: (
            params: GridRenderEditCellParams<any, EffectOption>
          ) => {
            return params.row.id !== temporaryRowId ||
              params.row.estimationType !== EffectEstimationType.Decimal ? (
              <Typography variant="body2">{params.row.unit}</Typography>
            ) : (
              <GridEditInputCell
                {...params}
                error={
                  params.row.estimationType === EffectEstimationType.Decimal
                    ? !params.row.unit
                    : false
                }
              />
            );
          },
        },
        dateCreatedColConfig,
        {
          field: "creator",
          headerName: t("common.labels.createdBy"),
          flex: 0.125,
          minWidth: 190,
          resizable: true,
          sortable: true,
          valueGetter: (params) => {
            return getUserName(params.row.creator);
          },
        },
        {
          field: "actions",
          flex: 0.05,
          type: "actions",
          sortable: false,
          getActions: (cellParams) =>
            getCommonActions(
              cellParams,
              rowModesModel,
              handleSaveRow,
              handleDeleteRow
            ),
        },
      ] as GridColDef<EffectOption>[],
    [
      t,
      dateCreatedColConfig,
      statusOptions,
      rowModesModel,
      estimationTypeOptions,
      onStatusChange,
      handleSaveRow,
      handleDeleteRow,
      onEstimationTypeChange,
    ]
  );
  return columns;
};
