import { Box, Color, useTheme } from "@mui/material";
import { ActionsDialog, ActionsDialogProps } from "components/ActionsDialog";
import { CollapsibleContainer } from "components/CollapsibleContainer";
import { IconContainer } from "components/IconContainer";
import { ProductItemCard } from "components/ProductItemCard/ProductItemCard";
import { RecipientsPreview } from "containers/Projects/components/RecipientsPreview";
import {
  RecordProductItemExtraData,
  RecordProductItemForm,
} from "containers/Projects/components/RecordProductItemForm";
import { useContractBindingTypes } from "containers/Projects/hooks/useContractBindingTypes";
import {
  ProductType,
  RecordVariationAgreementNoticePreview,
  RecordVariationConfirmationPreview,
  RecordVariationDeterminationNoticePreview,
  RecordVariationInstructionCommenceWorkPreview,
  RecordVariationNoAgreementNoticePreview,
  RecordVariationObjectionProposedVariationPreview,
  RecordVariationObjectionVariationPreview,
  RecordVariationProposalAbandonmentPreview,
  RecordVariationProposalConsentForProposedVariationPreview,
  RecordVariationProposalForProposedVariationPreview,
  RecordVariationProposalForVariationPreview,
  RecordVariationRevisedProposalForProposedVariationRequestPreview,
  RecordVariationRevocationPreview,
  VariationActionType,
} from "generated/graphql";
import { Plugs } from "phosphor-react";
import { useContext, useRef } from "react";
import { useTranslation } from "react-i18next";
import { CenteredLoadingIndicator } from "components/CenteredLoadingIndicator";
import { ActionModalTitle } from "containers/Projects/components/ActionModal/ActionModalTitle";
import { VariationWidgetContext } from "../../VariationWidget/VariationWidget.context";
import {
  variationActionTypeFIDIC17WhiteTypeMapping,
  variationActionTypeFIDIC99RedYellowTypeMapping,
} from "../../Variations.types";
import { variationActionTypeTranslations } from "../../VariationWidget/VariationWidget.constants";

export type RecordActionProps = {
  variationPreview?:
    | RecordVariationProposalForProposedVariationPreview
    | RecordVariationObjectionProposedVariationPreview
    | RecordVariationAgreementNoticePreview
    | RecordVariationProposalConsentForProposedVariationPreview
    | RecordVariationNoAgreementNoticePreview
    | RecordVariationRevisedProposalForProposedVariationRequestPreview
    | RecordVariationProposalAbandonmentPreview
    | RecordVariationDeterminationNoticePreview
    | RecordVariationProposalForVariationPreview
    | RecordVariationRevocationPreview
    | RecordVariationObjectionVariationPreview
    | RecordVariationConfirmationPreview
    | RecordVariationInstructionCommenceWorkPreview;
  actionType: VariationActionType;
  summaryEl: React.ReactNode;
  onPrimaryClick: (extraData: RecordProductItemExtraData) => void;
} & Omit<ActionsDialogProps, "onPrimaryClick">;

// Note: maybe merge with Claims RecordAction
export const RecordAction: React.FC<RecordActionProps> = ({
  summaryEl,
  variationPreview,
  actionType,
  onPrimaryClick,
  ...restDialogProps
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const {
    contract,
    productInstanceNumberingFormat,
    variation,
    projectName,
    isFIDIC17White,
    isFIDIC99RedYellow,
  } = useContext(VariationWidgetContext);
  const { bindingTypes, loading: bindingTypesLoading } =
    useContractBindingTypes(contract.id);
  const extraData = useRef<RecordProductItemExtraData>();
  const extraDataValid = useRef<boolean>(false);

  const handleExtraDataChange = (data: RecordProductItemExtraData) => {
    extraData.current = data;
  };

  const handleExtraDataValidityChange = (isValid: boolean) => {
    extraDataValid.current = isValid;
  };

  const handlePrimaryClick = () => {
    if (extraDataValid.current) {
      onPrimaryClick(extraData.current!);
    }
  };

  return (
    <ActionsDialog
      onPrimaryClick={handlePrimaryClick}
      primaryBtnCaption={t("common.buttons.record")}
      iconsHeader={
        <IconContainer>
          <Plugs
            size={18}
            color={(theme.palette.secondary as Partial<Color>)[700]}
          />
        </IconContainer>
      }
      primaryBtnDisabled={!variationPreview}
      content={
        !variationPreview || bindingTypesLoading ? (
          <CenteredLoadingIndicator />
        ) : (
          <Box>
            <ActionModalTitle mb={2}>
              {`${t(
                variationActionTypeTranslations[
                  isFIDIC99RedYellow
                    ? variationActionTypeFIDIC99RedYellowTypeMapping(actionType)
                    : isFIDIC17White
                    ? variationActionTypeFIDIC17WhiteTypeMapping(actionType)
                    : actionType
                ]
              )} ${t("common.labels.record")}`}
            </ActionModalTitle>
            {summaryEl}
            <Box mt={4}>
              <RecordProductItemForm
                bindingTypes={bindingTypes}
                productType={ProductType.Variations}
                onChange={handleExtraDataChange}
                onValidationChange={handleExtraDataValidityChange}
              />
            </Box>
            <Box mt={4}>
              <ProductItemCard
                itemName={
                  variation?.number ?? `${productInstanceNumberingFormat}-XXXX`
                }
                productType={ProductType.Variations}
                projectName={projectName}
                contractName={contract.friendlyName}
              />
            </Box>
            <Box mt={4}>
              <CollapsibleContainer
                title={`${t(`common.labels.recipients`)} (${
                  variationPreview.recipients.length
                })`}
              >
                <RecipientsPreview recipients={variationPreview.recipients} />
              </CollapsibleContainer>
            </Box>
          </Box>
        )
      }
      sx={{ minWidth: "420px" }}
      {...restDialogProps}
    />
  );
};
