import { Box, CircularProgress, Grid, ThemeProvider } from "@mui/material";
import { NewPageContentContainer } from "components/NewPageContentContainer";
import { Attachments } from "containers/Projects/components/Attachments/Attachments";
import { useAttachments } from "containers/Projects/components/Attachments/hooks/useAttachments";
import { useImagePreviewModal } from "containers/Projects/components/Attachments/hooks/useImagePreviewModal";
import {
  Changelog,
  ChangelogPublicAPI,
} from "containers/Projects/components/Changelog/Changelog";
import { Comments } from "containers/Projects/components/Comments/Comments";
import { CompEventWidget } from "containers/Projects/components/CompEvents/CompEventWidget/CompEventWidget";
import {
  ExplorerContext,
  ExplorerState,
} from "containers/Projects/components/Explorer/Explorer.context";
import { PhotoAttachmentPreviewModal } from "containers/Projects/components/PhotoAttachmentPreviewModal/PhotoAttachmentPreviewModal";
import { SchemaInterpretor } from "containers/Projects/components/SchemaInterpretor/SchemaInterpretor";
import { CollapsibleSectionContainer } from "containers/Projects/components/SchemaInterpretor/Section/Section";
import {
  Attachment,
  AttachmentInput,
  AttachmentStatus,
  ChangeEventItemStatusMutation,
  ChangeEventItemStatusMutationVariables,
  ClaimItem,
  ClaimRegardingType,
  CompEventItem,
  CompEventRegardingType,
  Contract,
  EditEventItemMutation,
  EditEventItemMutationVariables,
  EventItem,
  ItemStatusOption,
  ProductSchema,
  ProductType,
  User,
} from "generated/graphql";
import { changeEventItemStatusMutation } from "graphql/mutations/changeEventStatus";
import { editEventItemMutation } from "graphql/mutations/editEventItem";
import { NewAppPaths } from "helpers/paths/paths";
import { PermissionEnum } from "helpers/Permissions/Permissions.constants";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useHasAccess } from "hooks/useHasAccess";
import { useShallDisplayCompEventWidget } from "hooks/useShallDisplayCompEventWidget";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { GlobalContext } from "state-management/globalContext/Global.context";
import { extendedTheme } from "theme/extendedTheme";
import { RiskEventDetailsHeader } from "../RiskDetails/components/RiskEventDetailsHeader/RiskEventDetailsHeader";
import { useEventItem } from "hooks/useEventItem";
import { SectionContainer } from "components/miscellaneous/SectionContainer";
import { useNavigateToRegisterPage } from "containers/Projects/hooks/useNavigateToRegisterPage";
import { useShallDisplayClaimWidget } from "containers/Projects/hooks/useShallDisplayClaimWidget";
import { ClaimWidget } from "containers/Projects/components/Claims/ClaimWidget/ClaimWidget";
import { NotifiedUsers } from "containers/Projects/components/NotifiedUsers/NotifiedUsers";
import { NotifiedUsersWidgetHeader } from "containers/Projects/components/NotifiedUsers/NotifiedUsersWidgetHeader";

export const EventDetails = () => {
  const { productInstanceId, eventItemId } = useParams();
  const navigate = useNavigate();
  const handleNavigateToEventsRegister = useNavigateToRegisterPage();
  const { t } = useTranslation();
  const location = useLocation();
  const explorerStateRef = useRef<ExplorerState | undefined>();
  const { authenticatedUser } = useContext(GlobalContext);
  const {
    changeExplorerEntities,
    clear: clearExplorerData,
    setLoading: setExplorerDataLoading,
  } = useContext(ExplorerContext);

  const [changelogApiRef, setChangelogApiRef] = useState<ChangelogPublicAPI>();
  const reloadChangelog = useCallback(() => {
    changelogApiRef?.reload();
  }, [changelogApiRef]);

  const {
    eventItemData,
    refetchEventData,
    loading: eventItemDataLoading,
    error,
  } = useEventItem(eventItemId!);

  const canAccessCompEventWidget = useShallDisplayCompEventWidget(
    CompEventRegardingType.Event,
    eventItemId!,
    (eventItemData?.compEvent ?? undefined) as CompEventItem | undefined,
    eventItemData?.productInstance.contract.id
  );

  const canAccessClaimWidget = useShallDisplayClaimWidget(
    ClaimRegardingType.Event,
    eventItemId!,
    (eventItemData?.claim ?? undefined) as ClaimItem | undefined,
    eventItemData?.productInstance.contract.id
  );

  const [changeEventItemStatus, { loading: changeEventItemStatusLoading }] =
    useGraphMutation<
      ChangeEventItemStatusMutation,
      ChangeEventItemStatusMutationVariables
    >(
      changeEventItemStatusMutation,
      {
        update: (_cache) => {
          refetchEventData();

          // cache.evict({ id: "ROOT_QUERY", fieldName: "eventItems" });
          // cache.gc();
          reloadChangelog();
        },
      },
      t("common.successMessages.entityUpdated", {
        entity: t("Projects.Events.event"),
      })
    );

  const [doEditEvent] = useGraphMutation<
    EditEventItemMutation,
    EditEventItemMutationVariables
  >(
    editEventItemMutation,
    {
      update: () => {
        refetchEventData();
        reloadChangelog();
      },
    },
    t("common.successMessages.entityUpdated", {
      entity: t("Projects.Events.event"),
    })
  );

  const handleAttachmentsUpdated = async (
    attachmentsUpdated: AttachmentInput[]
  ) => {
    await doEditEvent({
      variables: {
        input: {
          id: eventItemId!,
          data: eventItemData!.data,
          attachments: attachmentsUpdated ?? eventItemData!.attachments,
        },
      },
    });
  };

  const {
    allAttachments,
    addAttachments,
    removeAttachment,
    updateAttachment,
    downloadAttachment,
  } = useAttachments(
    ((eventItemData?.attachments as Attachment[]) ?? []).filter(
      (attach) => attach.status === AttachmentStatus.Active
    ) || [],
    handleAttachmentsUpdated
  );

  const {
    imageAttachmentPreviewModalVisible,
    imagePreviewData,
    previewUrl,
    handleAttachmentClick,
    closeModal: closeImagePreviewModal,
  } = useImagePreviewModal(downloadAttachment);

  const triggerEditRiskFlow = () => {
    navigate(
      NewAppPaths.authorized.Projects.children.EditEvent.pathConstructor(
        eventItemData!.productInstanceId,
        eventItemData!.id
      ),
      {
        state: explorerStateRef.current,
      }
    );
  };

  const handleChangeRiskStatus = async (
    newStatus: ItemStatusOption,
    reasonId: string,
    remarks?: string
  ) => {
    await changeEventItemStatus({
      variables: {
        input: {
          id: eventItemData!.id,
          newStatusId: newStatus.id,
          reasonId,
          remarks,
        },
      },
    });
  };

  useEffect(() => {
    if (changelogApiRef) {
      changelogApiRef.reload();
    }
  }, [changelogApiRef]);

  useEffect(() => {
    if (eventItemData) {
      const explorerState = {
        projectId: eventItemData.productInstance.contract.project.id,
        contractId: eventItemData.productInstance.contract.id,
        productId: eventItemData.productInstance.product.id,
        productInstanceId: eventItemData.productInstanceId,
      };
      explorerStateRef.current = explorerState;
      changeExplorerEntities(explorerState);
    }
  }, [changeExplorerEntities, eventItemData]);

  useEffect(() => {
    if (location.state) {
      explorerStateRef.current = location.state as ExplorerState;
      changeExplorerEntities(location.state as ExplorerState);
    } else {
      // state not preset, needs fetching
      setExplorerDataLoading(true);
    }
  }, [setExplorerDataLoading, location, changeExplorerEntities]);

  useEffect(() => {
    if (eventItemData) {
      setExplorerDataLoading(false);
    }
  }, [setExplorerDataLoading, eventItemData]);

  useEffect(() => {
    if (error && !eventItemData) {
      clearExplorerData();
      navigate(NewAppPaths.authorized.NotFound);
    }
  }, [error, eventItemData, navigate, clearExplorerData]);

  const isCurrentUserEventOwner =
    eventItemData?.ownerId === authenticatedUser?.id;
  const canChangeStatus =
    useHasAccess(
      undefined,
      [PermissionEnum.ChangeStatus],
      productInstanceId ?? undefined
    ) || isCurrentUserEventOwner;
  const canManageAttachments =
    useHasAccess(
      undefined,
      [PermissionEnum.ManageAttachments],
      productInstanceId!
    ) || isCurrentUserEventOwner;

  return (
    <ThemeProvider
      theme={(outerTheme) => ({
        ...outerTheme,
        ...extendedTheme,
      })}
    >
      <PhotoAttachmentPreviewModal
        open={imageAttachmentPreviewModalVisible}
        attachment={imagePreviewData?.attachment}
        creatorName={imagePreviewData?.creatorName}
        creatorCompany={imagePreviewData?.creatorCompany}
        previewUrl={previewUrl}
        onClose={closeImagePreviewModal}
        onDownload={downloadAttachment}
      />
      <NewPageContentContainer>
        <Box height="100%" width="100%">
          <RiskEventDetailsHeader
            title={eventItemData?.title || ""}
            titleSuffix={`(${eventItemData?.number})`}
            statusOptionId={eventItemData?.statusOptionId || ""}
            productType={ProductType.Events}
            dataLoading={eventItemDataLoading || changeEventItemStatusLoading}
            statusOptions={
              (eventItemData?.productInstance.statusCollection.statusOptions
                .items as ItemStatusOption[]) ?? []
            }
            isCurrentUserProductItemOwner={isCurrentUserEventOwner}
            onStatusChange={
              canChangeStatus ? handleChangeRiskStatus : undefined
            }
            onEdit={triggerEditRiskFlow}
            onBack={handleNavigateToEventsRegister}
          />
          <Box mt={3}>
            {eventItemDataLoading || !eventItemData ? (
              <Box display="flex" alignItems="center" justifyContent="center">
                <CircularProgress />
              </Box>
            ) : (
              <SchemaInterpretor
                editMode={false}
                productItemType={ProductType.Events}
                schema={
                  eventItemData.productInstance.productSchema as ProductSchema
                }
                metadata={{
                  owner: eventItemData.owner as User,
                  creator: eventItemData.creator as User,
                  dateModified: eventItemData.dateModified,
                  dateCreated: eventItemData.dateCreated,
                  productItemId: eventItemData.id,
                }}
                schemaValues={eventItemData.data}
                contractCurrency={
                  eventItemData.productInstance.contract.valueCurrency ?? ""
                }
                contractTimezone={
                  eventItemData.productInstance.contract.timeZone ?? ""
                }
                productInstanceId={eventItemData.productInstanceId}
                onOwnerChange={reloadChangelog}
                mainColumnExtraWidgets={
                  <>
                    <Grid item xs={12} key={"attachments"} position="relative">
                      <SectionContainer>
                        <Attachments
                          editMode={canManageAttachments === true}
                          attachments={allAttachments}
                          onAttachmentsAdd={addAttachments}
                          onAttachmentRemove={removeAttachment}
                          onAttachmentUpdate={updateAttachment}
                          onAttachmentClick={handleAttachmentClick}
                        />
                      </SectionContainer>
                    </Grid>
                    <Grid item xs={12} key={"comments"} position="relative">
                      <SectionContainer>
                        <Comments
                          productType={ProductType.Events}
                          productItemId={eventItemId!}
                          productInstanceId={productInstanceId!}
                          onCommentAdded={reloadChangelog}
                        />
                      </SectionContainer>
                    </Grid>
                  </>
                }
                secondaryColumnExtraWidgets={
                  <>
                    {canAccessCompEventWidget && (
                      <Box width="100%" key="comp-event" position="relative">
                        <SectionContainer>
                          <CompEventWidget
                            loading={eventItemDataLoading}
                            regardingId={eventItemId!}
                            compEvent={eventItemData.compEvent as CompEventItem}
                            contract={
                              eventItemData.productInstance.contract as Contract
                            }
                            projectId={
                              eventItemData.productInstance.contract.project.id
                            }
                            projectName={
                              eventItemData.productInstance.contract.project
                                .friendlyName
                            }
                            eventItem={eventItemData as EventItem}
                            onChange={refetchEventData}
                          />
                        </SectionContainer>
                      </Box>
                    )}
                    {canAccessClaimWidget && (
                      <Box width="100%" key="claim" position="relative">
                        <SectionContainer>
                          <ClaimWidget
                            loading={eventItemDataLoading}
                            claim={eventItemData.claim as ClaimItem}
                            regardingId={eventItemId!}
                            contract={
                              eventItemData.productInstance.contract as Contract
                            }
                            projectId={
                              eventItemData.productInstance.contract.project.id
                            }
                            projectName={
                              eventItemData.productInstance.contract.project
                                .friendlyName
                            }
                            eventItem={eventItemData as EventItem}
                            onChange={refetchEventData}
                          />
                        </SectionContainer>
                      </Box>
                    )}
                    {eventItemData.notificationRecipients.length > 0 && (
                      <Box
                        width="100%"
                        key="notified-users"
                        position="relative"
                      >
                        <CollapsibleSectionContainer
                          collapsible
                          title={
                            <NotifiedUsersWidgetHeader
                              recipientsCount={
                                eventItemData.notificationRecipients.length
                              }
                            />
                          }
                        >
                          <NotifiedUsers
                            recipients={eventItemData.notificationRecipients}
                          />
                        </CollapsibleSectionContainer>
                      </Box>
                    )}
                    <Box width="100%" key="changelog" position="relative">
                      <CollapsibleSectionContainer
                        collapsible
                        title="Changelog"
                        maxHeight="600px"
                        overflow="auto"
                      >
                        <Changelog
                          productItemId={eventItemData.id}
                          productType={ProductType.Events}
                          productInstanceId={productInstanceId!}
                          contractTimezone={
                            eventItemData?.productInstance.contract.timeZone ??
                            ""
                          }
                          ref={(apiRef) => {
                            setChangelogApiRef(apiRef!);
                          }}
                        />
                      </CollapsibleSectionContainer>
                    </Box>
                  </>
                }
              />
            )}
          </Box>
        </Box>
      </NewPageContentContainer>
    </ThemeProvider>
  );
};
