import { useGraphQuery } from "hooks/useGraphQuery";
import { EFFECT_SECTION_NAME } from "../../../../../constants";
import {
  ItemDataSectionInput,
  ItemEffectPresetQuery,
  ItemEffectPresetQueryVariables,
} from "generated/graphql";
import { itemEffectPresetQuery } from "graphql/queries/effectPresetByProductInstanceId.query";
import { useParams } from "react-router-dom";
import { EffectWidgetValue } from "containers/Projects/components/SchemaInterpretor/Field/Effect/Effect.decl";
import { useMemo } from "react";

export const useDefaultEffectValues = () => {
  const { productInstanceId } = useParams();
  const { data: effectPreset, loading: getEffectPresetLoading } = useGraphQuery<
    ItemEffectPresetQuery,
    ItemEffectPresetQueryVariables
  >(itemEffectPresetQuery, {
    variables: { productInstanceId: productInstanceId! },
    fetchPolicy: "cache-first",
  });

  const effectDefaultValues = useMemo((): ItemDataSectionInput | undefined => {
    if (getEffectPresetLoading) {
      return;
    }

    const effectFieldValueObj: EffectWidgetValue = {
      effectPresetId: effectPreset!.itemEffectPreset.id,
      answers: effectPreset!.itemEffectPreset.options.items.map((option) => ({
        optionId: option.id,
        selected: false,
      })),
    };

    return {
      name: EFFECT_SECTION_NAME,
      entries: [
        {
          name: EFFECT_SECTION_NAME,
          value: JSON.stringify(effectFieldValueObj),
        },
      ],
    };
  }, [effectPreset, getEffectPresetLoading]);

  return {
    effectDefaultValues,
  };
};
