import { ActionsDialogProps } from "components/ActionsDialog";
import { ClaimActionType } from "generated/graphql";
import { SendAction } from "../components/SendAction";
import { EnhancedAttachment } from "containers/Projects/components/Attachments/Attachments.decl";
import { ReasonsAttachmentsSummary } from "../../../ActionModal/ReasonsAttachmentsSummary";
import { useTranslation } from "react-i18next";
import { useSendDisagreementRegardingNoticeOfErrorInDeterminationModal } from "./useSendDisagreementRegardingNoticeOfErrorInDeterminationModal";

export type SendDisagreementRegardingNoticeOfErrorInDeterminationModalProps = {
  remarks: string;
  attachments?: EnhancedAttachment[];
} & ActionsDialogProps;

export const SendDisagreementRegardingNoticeOfErrorInDeterminationModal: React.FC<
  SendDisagreementRegardingNoticeOfErrorInDeterminationModalProps
> = ({ remarks, attachments = [], ...restDialogProps }) => {
  const { claimPreview } =
    useSendDisagreementRegardingNoticeOfErrorInDeterminationModal(remarks);
  const { t } = useTranslation();

  return (
    <>
      <SendAction
        claimPreview={claimPreview}
        actionType={ClaimActionType.DisagreeWithNoticeErrorDetermination}
        summaryEl={
          <ReasonsAttachmentsSummary
            text={remarks}
            label={t("common.labels.remarks")}
            attachments={attachments}
          />
        }
        {...restDialogProps}
      />
    </>
  );
};
