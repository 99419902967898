import { useCallback, useContext, useEffect, useState } from "react";
import {
  GenerateRecordVariationConfirmationPreviewMutation,
  GenerateRecordVariationConfirmationPreviewMutationVariables,
  RecordVariationConfirmationPreview,
} from "generated/graphql";
import { useGraphMutation } from "hooks/useGraphMutation";
import { debounce } from "@mui/material";
import { eventDebounceDuration } from "../../../../../../constants";
import { VariationWidgetContext } from "../../VariationWidget/VariationWidget.context";
import { generateRecordVariationConfirmationPreviewMutation } from "./ConfirmInstructionOfVariationAction.query";

export const useRecordInstructionOfVariationConfirmationModal = (
  remarks: string
) => {
  const { variation } = useContext(VariationWidgetContext);

  const [variationPreview, setVariationPreview] =
    useState<RecordVariationConfirmationPreview>();

  const [generatePreview] = useGraphMutation<
    GenerateRecordVariationConfirmationPreviewMutation,
    GenerateRecordVariationConfirmationPreviewMutationVariables
  >(generateRecordVariationConfirmationPreviewMutation, {}, null);

  const loadPreview = debounce(
    useCallback(async () => {
      const { data } = await generatePreview({
        variables: {
          input: {
            variationId: variation!.id,
            remarks,
          },
        },
      });

      if (data) {
        setVariationPreview(data.generateRecordVariationConfirmationPreview);
      }
    }, [generatePreview, variation, remarks]),
    eventDebounceDuration
  );

  useEffect(() => {
    loadPreview();
    // eslint-disable-next-line
  }, []);

  return {
    variationPreview,
  };
};
