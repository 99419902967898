import { gql } from "@apollo/client";
import { contractBindingTypeFields } from "graphql/fragments/contractBindingType.fragment";
import { itemDataFields } from "graphql/fragments/itemData.fragment";
import { variationItemLiteFields } from "graphql/fragments/variationItemLite.fragment";

// TODO: check if needed commented queries
export const variationItemsRegisterQuery = gql`
  ${variationItemLiteFields}
  ${contractBindingTypeFields}
  ${itemDataFields}

  query variationItemsRegister($productInstanceId: ID!) {
    variationItems(productInstanceId: $productInstanceId) {
      nextToken
      items {
        ...VariationItemLiteFields
        lastAction {
          actionType
          date
          daysLate
          # partyId: ID!
          # party: ContractBindingType!
        }
        nextStep {
          actionType
          dueDate
          partyId
          party {
            ...ContractBindingTypeFields
          }
        }
        # creator {
        #   ...CreatorFields
        # }
        # productInstance {
        #   id
        #   description
        #   numberingFormat
        #   status
        #   product {
        #     id
        #     name
        #   }
        #   contract {
        #     id
        #     name
        #     friendlyName
        #     status
        #     timeZone
        #     valueCurrency
        #     project {
        #       id
        #       name
        #       friendlyName
        #       status
        #     }
        #   }
        # }
        regardingInstruction {
          id
          title
          number
          productInstanceId
          dateCreated
          data {
            ...ItemDataFields
          }
          productInstance {
            id
            product {
              id
            }
          }
        }
        # ongoingAuthorizationAudit: AuthorizationWorkflowAudit
      }
    }
  }
`;
