import {
  GenerateSendVariationInstructionCommenceWorkPreviewMutation,
  GenerateSendVariationInstructionCommenceWorkPreviewMutationVariables,
  SendVariationInstructionCommenceWorkPreview,
} from "generated/graphql";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useCallback, useContext, useEffect, useState } from "react";
import { debounce } from "@mui/material";
import { eventDebounceDuration } from "../../../../../../constants";
import { VariationWidgetContext } from "../../VariationWidget/VariationWidget.context";
import { generateSendVariationInstructionCommenceWorkPreviewMutation } from "./InstructWorkToCommenceAction.query";

export const useSendWorkToCommenceInstructionModal = (remarks: string) => {
  const { variation } = useContext(VariationWidgetContext);
  const [variationPreview, setVariationPreview] =
    useState<SendVariationInstructionCommenceWorkPreview>();

  const [generatePreview] = useGraphMutation<
    GenerateSendVariationInstructionCommenceWorkPreviewMutation,
    GenerateSendVariationInstructionCommenceWorkPreviewMutationVariables
  >(generateSendVariationInstructionCommenceWorkPreviewMutation, {}, null);

  const loadPreview = debounce(
    useCallback(async () => {
      const { data } = await generatePreview({
        variables: {
          input: {
            variationId: variation!.id,
            remarks,
          },
        },
      });

      if (data) {
        setVariationPreview(
          data.generateSendVariationInstructionCommenceWorkPreview
        );
      }
    }, [generatePreview, variation, remarks]),
    eventDebounceDuration
  );

  useEffect(() => {
    loadPreview();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  return {
    variationPreview,
  };
};
