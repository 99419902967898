import { Box, Typography, useTheme } from "@mui/material";
import { AsteriskIcon } from "components/Icons/AsteriskIcon";
import {
  FieldTypeEnum,
  ItemDataEntryInput,
  SchemaField,
} from "generated/graphql";
import {
  DataValidators,
  validateData,
  ValidatorType,
} from "helpers/validators";
import {
  useMemo,
  useState,
  ChangeEventHandler,
  useEffect,
  useCallback,
  useContext,
} from "react";
import { SchemaInterpretorContext } from "../SchemaInterpretor.context";
import { AdvancedRichTextArea } from "./AdvancedRichTextArea/AdvancedRichTextArea";
import { Effect } from "./Effect/Effect";
import { EffectWidgetValue } from "./Effect/Effect.decl";
import { LookupField } from "./LookupField";
import { RichTextArea } from "../../../../../components/RichTextArea/RichTextArea";
import { defaultSeverityPreset } from "./Severity/Severity.constants";
import { SeverityValue } from "./Severity/Severity.decl";
import { SeverityEditable } from "./Severity/SeverityEditable";
import { SeverityReadOnly } from "./Severity/SeverityReadOnly";
import { Textarea } from "../../../../../components/TextArea";
import { TextField } from "./TextField";
import { EnumField } from "./EnumField";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { dateISOFormat, dateTimeISOFormat } from "../../../../../constants";
import { FormErrorLabel } from "components/FormErrorLabel";
import { InstructionTypeField } from "./InstructionTypeField";

export type FieldProps = {
  field: SchemaField;
  editMode: boolean;
  fieldValue?: ItemDataEntryInput;
  showLabel?: boolean;
  onFieldValueChange: (newFieldValue: ItemDataEntryInput) => void;
  onFieldValidationChange: (validity: boolean, fieldId: string) => void;
};

export const Field: React.FC<FieldProps> = ({
  field,
  editMode,
  showLabel = false,
  fieldValue,
  onFieldValueChange,
  onFieldValidationChange,
}) => {
  const theme = useTheme();
  const {
    productInstanceId,
    contractCurrency,
    contractTypeId,
    isNECContractType /* , contractTimezone */,
  } = useContext(
    // TODO: uncomment when BE sends back date value for FieldType.Date type
    SchemaInterpretorContext
  );

  const [fieldError, setFieldError] = useState<string>();
  const [fieldTouched, setFieldTouched] = useState(false);

  const dataValidators = useMemo(() => {
    const validators: DataValidators = {
      field: {
        validators: [],
      },
    };

    if (field.isRequired) {
      if (field.fieldType.shortCode === FieldTypeEnum.Dt) {
        validators.field.validators = [
          ...validators.field.validators,
          ValidatorType.ValidDateMaxDateToday,
        ];
      } else if (
        field.fieldType.shortCode === FieldTypeEnum.Rt ||
        field.fieldType.shortCode === FieldTypeEnum.Rta
      ) {
        validators.field.validators = [
          ...validators.field.validators,
          ValidatorType.RequiredRichText,
        ];
      } else {
        validators.field.validators = [
          ...validators.field.validators,
          ValidatorType.Required,
        ];
      }
    }

    if ((field.fieldType.shortCode as FieldTypeEnum) === FieldTypeEnum.Srs) {
      validators.field = {
        validators: [...validators.field.validators, ValidatorType.Contains],
        getData: () => [fieldValue?.value || "", ["consequence", "likelihood"]],
      };
    }

    return validators;
  }, [field, fieldValue]);

  const validateForm = useCallback(
    (value?: string) => {
      const validationResult = validateData(
        { field: value || fieldValue?.value },
        dataValidators
      );

      setFieldError(
        validationResult.valid ? "" : validationResult.errors.field
      );
    },
    [dataValidators, fieldValue]
  );

  const handleDatetimeChange = (date: Date | null) => {
    const newDate = moment(date).format(dateTimeISOFormat);
    setFieldTouched(true);
    onFieldValueChange({
      name: field.name || "",
      value: newDate ?? "",
    });
  };

  const handleDateChange = (date: Date | null) => {
    const newDate = moment(date).format(dateISOFormat);

    setFieldTouched(true);
    onFieldValueChange({
      name: field.name || "",
      value: newDate ?? "",
    });
  };

  const handleTextFieldChange: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (event) => {
    if (event.target.value !== fieldValue?.value) {
      setFieldTouched(true);

      onFieldValueChange({
        name: field.name || "",
        value: event.target.value,
      });
    }
  };

  const handleEffectFieldValueChange = (newEffectValue: EffectWidgetValue) => {
    setFieldTouched(true);

    onFieldValueChange({
      name: field.name || "",
      value: JSON.stringify(newEffectValue),
    });
  };

  const handleStringFieldChange = (value: string) => {
    if (value !== fieldValue?.value) {
      setFieldTouched(true);

      onFieldValueChange({
        name: field.name || "",
        value,
      });
    }
  };

  useEffect(() => {
    validateForm();
  }, [validateForm]);

  useEffect(() => {
    onFieldValidationChange(!fieldError, field.id);
  }, [fieldError, field, onFieldValidationChange]);

  const getFieldComponent = (fieldShortCode: FieldTypeEnum) => {
    switch (fieldShortCode) {
      case FieldTypeEnum.Mlt:
        // multi line text
        return editMode ? (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            width="100%"
            boxSizing="border-box"
          >
            <Textarea
              required={field.isRequired}
              value={fieldValue?.value ?? ""}
              error={!!fieldError && fieldTouched}
              onChange={handleTextFieldChange}
              dataTestId={`${field.displayText}-textarea`}
            />
            {fieldError && fieldTouched && (
              <FormErrorLabel
                dataTestId={`${field.displayText}-error`}
                errorMessage={fieldError}
              />
            )}
          </Box>
        ) : (
          <Typography
            variant="p1"
            color={theme.palette.grey[800]}
            data-testid={`${field.displayText}-mlt-readonly`}
          >
            {fieldValue?.value}
          </Typography>
        );
      case FieldTypeEnum.Rt:
        // rich text field (multi line)
        return (
          <>
            <RichTextArea
              readOnly={!editMode}
              fontSize="16px"
              content={fieldValue?.value ?? ""}
              error={!!fieldError && fieldTouched}
              dataTestId={`${field.displayText}-rt`}
              onChange={handleStringFieldChange}
            />
            {fieldError && fieldTouched && (
              <FormErrorLabel
                dataTestId={`${field.displayText}-error`}
                errorMessage={fieldError}
              />
            )}
          </>
        );
      case FieldTypeEnum.Rta:
        // advanced rich text field (multi line)
        return (
          <AdvancedRichTextArea
            readOnly={!editMode}
            content={fieldValue?.value ?? ""}
            dataTestId={`${field.displayText}-rta`}
            error={!!fieldError && fieldTouched}
            onChange={handleStringFieldChange}
          />
        );
      case FieldTypeEnum.Slt:
        // single line text
        return editMode ? (
          <TextField
            required={field.isRequired}
            value={fieldValue?.value ?? ""}
            dataTestId={`${field.displayText}-slt`}
            onChange={handleTextFieldChange}
            error={!!fieldError && fieldTouched}
            helperText={fieldError && fieldTouched ? fieldError : undefined}
          />
        ) : (
          <Typography
            variant="p1"
            color={theme.palette.grey[800]}
            data-testid={`${field.displayText}-slt-readonly`}
          >
            {fieldValue?.value}
          </Typography>
        );
      case FieldTypeEnum.Srs: {
        // severity
        const deserialisedFieldValue: SeverityValue = fieldValue
          ? JSON.parse(fieldValue.value)
          : { consequence: undefined, likelihood: undefined };

        return editMode ? (
          <SeverityEditable
            severityPreset={defaultSeverityPreset}
            consequenceValue={deserialisedFieldValue.consequence}
            likelihoodValue={deserialisedFieldValue.likelihood}
            onChange={handleStringFieldChange}
          />
        ) : (
          <SeverityReadOnly
            severityPreset={defaultSeverityPreset}
            consequenceValue={deserialisedFieldValue.consequence}
            likelihoodValue={deserialisedFieldValue.likelihood}
          />
        );
      }
      case FieldTypeEnum.Eff: {
        // effect
        const deserialisedFieldValue: EffectWidgetValue = fieldValue
          ? JSON.parse(fieldValue.value)
          : undefined;

        return (
          <Effect
            editable={editMode}
            productInstanceId={productInstanceId}
            data={deserialisedFieldValue}
            contractCurrency={contractCurrency ?? ""}
            onChange={handleEffectFieldValueChange}
          />
        );
      }
      case FieldTypeEnum.Dt:
        // dateTime picker
        return editMode ? (
          <DatePicker
            value={fieldValue?.value ? new Date(fieldValue.value) : null}
            onChange={handleDatetimeChange}
            format="yyyy-MM-dd"
            maxDate={new Date() as any}
            slotProps={{
              popper: {
                placement: "bottom-end",
              },
              textField: {
                fullWidth: true,
                error: !!fieldError && fieldTouched,
                helperText: fieldError && fieldTouched ? fieldError : undefined,
                required: field.isRequired,
                ["data-testid" as any]: `${field.displayText}-dt`,
              },
            }}
          />
        ) : (
          <Typography
            variant="p1"
            data-testid="dateTime-picker-readonly"
            color={theme.palette.grey[800]}
          >
            {fieldValue?.value
              ? moment(fieldValue?.value).format(dateISOFormat) // TODO: uncomment when BE send back date only value
              : // ? moment(fieldValue?.value)
                //     .tz(contractTimezone)
                //     .format(`${dateISOFormat} HH:mm:ss`)
                ""}
          </Typography>
        );
      case FieldTypeEnum.D:
        // date picker
        return editMode ? (
          <DatePicker
            value={fieldValue?.value ? new Date(fieldValue.value) : null}
            onChange={handleDateChange}
            format="yyyy-MM-dd"
            maxDate={new Date() as any}
            slotProps={{
              popper: {
                placement: "bottom-end",
              },
              textField: {
                fullWidth: true,
                error: !!fieldError && fieldTouched,
                helperText: fieldError && fieldTouched ? fieldError : undefined,
                required: field.isRequired,
                ["data-testid" as any]: `${field.displayText}-d`,
              },
            }}
          />
        ) : (
          <Typography
            variant="p1"
            data-testid="datepicker-readonly"
            color={theme.palette.grey[800]}
          >
            {fieldValue?.value
              ? moment(fieldValue?.value).format(dateISOFormat)
              : ""}
          </Typography>
        );
      case FieldTypeEnum.Lkp: {
        // lookup
        return (
          <LookupField
            field={field}
            readOnly={!editMode}
            fieldValue={fieldValue?.value ?? ""}
            fieldError={fieldError}
            fieldTouched={fieldTouched}
            onChange={handleStringFieldChange}
          />
        );
      }
      case FieldTypeEnum.Enu: {
        // enum
        const rawEnumOptions = JSON.parse(field.extraData!) as string[];
        const enumOptions = rawEnumOptions.map((enumOption) => ({
          value: enumOption,
          label: enumOption,
        }));

        return (
          <EnumField
            editMode={editMode}
            options={enumOptions}
            fieldTouched={fieldTouched}
            fieldError={fieldError}
            fieldValue={enumOptions.find(
              (option) => option.value === fieldValue?.value
            )}
            fieldDisplayText={field.displayText}
            onChange={handleStringFieldChange}
          />
        );
      }
      case FieldTypeEnum.InstType: {
        // instruction type
        const instTypeData = JSON.parse(field.extraData!) as {
          [key: string]: string[];
        };
        const enumOptions = instTypeData[contractTypeId ?? ""];

        return (
          <InstructionTypeField
            editMode={editMode}
            options={enumOptions}
            fieldTouched={fieldTouched}
            fieldError={fieldError}
            fieldValue={fieldValue?.value}
            fieldDisplayText={field.displayText}
            isNECContractType={isNECContractType}
            onChange={handleStringFieldChange}
          />
        );
      }
      default:
        return (
          <Typography variant="p2" color="grey.500">
            TBD
          </Typography>
        );
    }
  };

  return (
    <Box width="100%" display="flex" flexDirection="column">
      {showLabel || (editMode && field.displayText.trim()) ? (
        <Box display="flex" alignItems="center">
          <Typography
            variant="p1"
            fontWeight={600}
            mb={1}
            color={theme.palette.grey[800]}
          >
            {field.displayText}
          </Typography>
          {field.isRequired && editMode && (
            <Box alignSelf="flex-start">
              <AsteriskIcon />
            </Box>
          )}
        </Box>
      ) : null}
      {getFieldComponent(field.fieldType.shortCode as FieldTypeEnum)}
    </Box>
  );
};
