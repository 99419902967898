import { NotificationRecipient } from "generated/graphql";
import { RecipientsPreview } from "../RecipientsPreview";
import {
  css,
  IconButton,
  Stack,
  styled,
  Typography,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CaretRight } from "phosphor-react";

type NotifiedUsersProps = {
  recipients: NotificationRecipient[];
};

const StyledCaretRight = styled(CaretRight)(
  ({ theme }) => css`
    padding-top: ${theme.spacing(0.25)};
  `
);

const smallListSize = 5;

export const NotifiedUsers: React.FC<NotifiedUsersProps> = ({ recipients }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [localRecipients, setLocalRecipients] = useState<
    NotificationRecipient[]
  >(recipients.slice(0, smallListSize));
  const [showMoreToggled, setShowMoreToggled] = useState<boolean>(false);

  const handleShowMore = () => {
    setShowMoreToggled(true);
  };

  const handleShowLess = () => {
    setShowMoreToggled(false);
  };

  useEffect(() => {
    if (showMoreToggled) {
      setLocalRecipients(recipients);
    } else {
      setLocalRecipients(recipients.slice(0, smallListSize));
    }
  }, [showMoreToggled, recipients]);

  return (
    <Stack spacing={3} alignItems="flex-end">
      <RecipientsPreview recipients={localRecipients} />
      {recipients.length > smallListSize ? (
        !showMoreToggled ? (
          <IconButton onClick={handleShowMore}>
            <Stack direction="row" alignItems="center" spacing={0.5}>
              <Typography variant="body2" fontWeight={700} color="grey.800">
                {t("NotifiedUsers.showMore")}
              </Typography>
              <StyledCaretRight
                size={14}
                weight="bold"
                color={theme.palette.grey[800]}
              />
            </Stack>
          </IconButton>
        ) : (
          <IconButton onClick={handleShowLess}>
            <Stack direction="row" alignItems="center" spacing={0.5}>
              <Typography variant="body2" fontWeight={700} color="grey.800">
                {t("NotifiedUsers.showLess")}
              </Typography>
              <StyledCaretRight
                size={14}
                weight="bold"
                color={theme.palette.grey[800]}
              />
            </Stack>
          </IconButton>
        )
      ) : null}
    </Stack>
  );
};
