import { Box, Stack, Tooltip, Typography, useTheme } from "@mui/material";
import { ActionsDialog, ActionsDialogProps } from "components/ActionsDialog";
import { IconContainer } from "components/IconContainer";
import {
  AddDraftClaimAgreementInput,
  AddDraftClaimDeterminationInput,
  AddDraftDetailedClaimInput,
  ContractSection,
  ContractSectionStatus,
  DetailedClaim,
  DraftClaimAgreement,
  DraftClaimDetermination,
  DraftDetailedClaim,
  EditDraftClaimAgreementInput,
  EditDraftClaimDeterminationInput,
  EditDraftDetailedClaimInput,
  ProductType,
} from "generated/graphql";
import { Info, Money } from "phosphor-react";
import { useCallback, useContext, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FormPublicApi } from "decl";
import { DetailedClaimAgreementDeterminationForm } from "./DetailedClaimAgreementDeterminationForm/DetailedClaimAgreementDeterminationForm";
import { ContractPriceTimeChangeReadOnlyView } from "../../HistoryModal/ContractPriceTimeChangeReadOnlyView";
import { ClaimWidgetContext } from "../ClaimWidget/ClaimWidget.context";
import { RichTextArea } from "components/RichTextArea/RichTextArea";
import { ListItemsDivider } from "components/ListItemsDivider";

export enum ModalType {
  DraftDetailedClaim = "DraftDetailedClaim",
  DraftClaimAgreement = "DraftClaimAgreement",
  DraftClaimDetermination = "DraftClaimDetermination",
}

export type DetailedClaimFormPublicAPI = FormPublicApi & {
  onBeforeAbort: () => void;
};

enum InternalModalType {
  CreateDraftDetailedClaim = "CreateDraftDetailedClaim",
  EditDraftDetailedClaim = "EditDraftDetailedClaim",
  CreateDraftClaimAgreement = "CreateDraftClaimAgreement",
  EditDraftClaimAgreement = "EditDraftClaimAgreement",
  CreateDraftClaimDetermination = "CreateDraftClaimDetermination",
  EditDraftClaimDetermination = "EditDraftClaimDetermination",
}

export type DetailedClaimAgreementDeterminationFormDataType =
  | (AddDraftDetailedClaimInput | EditDraftDetailedClaimInput)
  | (AddDraftClaimAgreementInput | EditDraftClaimAgreementInput)
  | (AddDraftClaimDeterminationInput | EditDraftClaimDeterminationInput);

export type DetailedClaimAgreementDeterminationModalProps = {
  type: ModalType;
  contractCurrency: string;
  contractSections: ContractSection[];
  draftDetailedClaim?: DraftDetailedClaim;
  draftClaimAgreement?: DraftClaimAgreement;
  draftClaimDetermination?: DraftClaimDetermination;
  finalDetailedClaim?: DetailedClaim;
  onPrimaryClick: (
    draftClaimData: DetailedClaimAgreementDeterminationFormDataType
  ) => void;
  onAttachmentsChange: (
    draftClaimData: DetailedClaimAgreementDeterminationFormDataType
  ) => void;
} & Omit<ActionsDialogProps, "onPrimaryClick">;

export const DetailedClaimAgreementDeterminationModal: React.FC<
  DetailedClaimAgreementDeterminationModalProps
> = ({
  draftClaimAgreement,
  draftDetailedClaim,
  draftClaimDetermination,
  type,
  contractCurrency,
  contractSections,
  finalDetailedClaim,
  maxWidth = "md",
  onPrimaryClick,
  onSecondaryClick,
  onAttachmentsChange,
  ...restDialogProps
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { contract, isFIDIC99RedYellow } = useContext(ClaimWidgetContext);
  const formRef = useRef<DetailedClaimFormPublicAPI>(null);
  const formDataRef = useRef<DetailedClaimAgreementDeterminationFormDataType>();
  const [primaryBtnPressed, setPrimaryBtnPressed] = useState(false);
  const [isFormValid, setIsFormValid] = useState(true);
  const [attachmentsLoading, setAttachmentsLoading] = useState<boolean>();

  const isEditMode = useMemo(
    () => draftClaimAgreement || draftDetailedClaim || draftClaimDetermination,
    [draftClaimAgreement, draftDetailedClaim, draftClaimDetermination]
  );

  const activeSections = useMemo(
    () =>
      contractSections.filter(
        (section) => section.status === ContractSectionStatus.Active
      ),
    [contractSections]
  );

  const internalModalType = useMemo(() => {
    if (type === ModalType.DraftDetailedClaim) {
      return draftDetailedClaim
        ? InternalModalType.EditDraftDetailedClaim
        : InternalModalType.CreateDraftDetailedClaim;
    } else if (type === ModalType.DraftClaimDetermination) {
      return draftClaimDetermination
        ? InternalModalType.EditDraftClaimDetermination
        : InternalModalType.CreateDraftClaimDetermination;
    }
    return draftClaimAgreement
      ? InternalModalType.EditDraftClaimAgreement
      : InternalModalType.CreateDraftClaimAgreement;
  }, [type, draftDetailedClaim, draftClaimAgreement, draftClaimDetermination]);

  const headerText = useMemo(() => {
    switch (internalModalType) {
      case InternalModalType.CreateDraftDetailedClaim:
        return {
          title: t("Projects.Claims.DetailedClaimModal.createTitle", {
            entity: t(
              `Projects.Claims.detailedClaim${isFIDIC99RedYellow ? "99RY" : ""}`
            ),
          }),
          subtitle: t("Projects.Claims.DetailedClaimModal.createSubtitle", {
            entity: t(
              `Projects.Claims.detailedClaim${isFIDIC99RedYellow ? "99RY" : ""}`
            ),
          }),
        };
      case InternalModalType.EditDraftDetailedClaim:
        return {
          title: t("Projects.Claims.DetailedClaimModal.editTitle", {
            entity: t(
              `Projects.Claims.detailedClaim${isFIDIC99RedYellow ? "99RY" : ""}`
            ),
          }),
          subtitle: t("Projects.Claims.DetailedClaimModal.editSubtitle", {
            entity: t(
              `Projects.Claims.detailedClaim${isFIDIC99RedYellow ? "99RY" : ""}`
            ),
          }),
        };
      case InternalModalType.CreateDraftClaimAgreement:
        return {
          title: t("Projects.Claims.DetailedClaimModal.createTitle", {
            entity: t(
              `Projects.Claims.claimAgreement${
                isFIDIC99RedYellow ? "99RY" : ""
              }`
            ),
          }),
          subtitle: t("Projects.Claims.DetailedClaimModal.createSubtitle", {
            entity: t(
              `Projects.Claims.claimAgreement${
                isFIDIC99RedYellow ? "99RY" : ""
              }`
            ),
          }),
        };
      case InternalModalType.EditDraftClaimAgreement:
        return {
          title: t("Projects.Claims.DetailedClaimModal.editTitle", {
            entity: t(
              `Projects.Claims.claimAgreement${
                isFIDIC99RedYellow ? "99RY" : ""
              }`
            ),
          }),
          subtitle: t("Projects.Claims.DetailedClaimModal.editSubtitle", {
            entity: t(
              `Projects.Claims.claimAgreement${
                isFIDIC99RedYellow ? "99RY" : ""
              }`
            ),
          }),
        };
      case InternalModalType.CreateDraftClaimDetermination:
        return {
          title: t("Projects.Claims.DetailedClaimModal.createTitle", {
            entity: t(
              `Projects.Claims.claimDetermination${
                isFIDIC99RedYellow ? "99RY" : ""
              }`
            ),
          }),
          subtitle: t("Projects.Claims.DetailedClaimModal.createSubtitle", {
            entity: t(
              `Projects.Claims.claimDetermination${
                isFIDIC99RedYellow ? "99RY" : ""
              }`
            ),
          }),
        };
      case InternalModalType.EditDraftClaimDetermination:
        return {
          title: t("Projects.Claims.DetailedClaimModal.editTitle", {
            entity: t(
              `Projects.Claims.claimDetermination${
                isFIDIC99RedYellow ? "99RY" : ""
              }`
            ),
          }),
          subtitle: t("Projects.Claims.DetailedClaimModal.editSubtitle", {
            entity: t(
              `Projects.Claims.claimDetermination${
                isFIDIC99RedYellow ? "99RY" : ""
              }`
            ),
          }),
        };
    }
  }, [internalModalType, t, isFIDIC99RedYellow]);

  const primaryBtnCaption = isEditMode
    ? t("common.buttons.save")
    : `${t("common.buttons.create")} ${t(
        `Projects.Claims.${
          type === ModalType.DraftDetailedClaim
            ? `detailedClaim${isFIDIC99RedYellow ? "99RY" : ""}`
            : type === ModalType.DraftClaimAgreement
            ? `claimAgreement${isFIDIC99RedYellow ? "99RY" : ""}`
            : `claimDetermination${isFIDIC99RedYellow ? "99RY" : ""}`
        }`
      )}`;

  const handleSubmit = useCallback(() => {
    const isFormValid = formRef.current?.validate();

    if (isFormValid) {
      onPrimaryClick(formDataRef.current!);
      setPrimaryBtnPressed(true);
    }

    setIsFormValid(!!isFormValid);
  }, [onPrimaryClick]);

  const handleSecondaryClick = () => {
    formRef.current?.onBeforeAbort();
    onSecondaryClick();
  };

  const handleClose = () => {
    formRef.current?.onBeforeAbort();
    onSecondaryClick();
  };

  const handleQuotationAssessmentChange = useCallback(
    (formData: DetailedClaimAgreementDeterminationFormDataType) => {
      formDataRef.current = formData;
      setIsFormValid(true);
    },
    []
  );

  const initialAgreementDeterminationClaim = useMemo(() => {
    if (type !== ModalType.DraftDetailedClaim && finalDetailedClaim) {
      return {
        attachments: [],
        claimId: finalDetailedClaim.claimId,
        details: "",
        price: finalDetailedClaim.price,
        sectionalChanges: finalDetailedClaim.sectionalChanges.map(
          (timeChange) => {
            const crtSection = contractSections.find(
              (section) => section.number === timeChange.number
            );

            return {
              id: crtSection?.id,
              days: timeChange.days,
            };
          }
        ),
        time: finalDetailedClaim.time,
      } as any as DraftClaimAgreement | DraftClaimDetermination;
    }
  }, [type, finalDetailedClaim, contractSections]);

  return (
    <ActionsDialog
      iconsHeader={
        <IconContainer greyBackground>
          <Money size={24} />
        </IconContainer>
      }
      onPrimaryClick={handleSubmit}
      onSecondaryClick={handleSecondaryClick}
      onClose={handleClose}
      fullWidth
      maxWidth={maxWidth}
      contentSx={{ minWidth: "420px" }}
      primaryBtnCaption={primaryBtnCaption}
      primaryBtnDisabled={!isFormValid || attachmentsLoading}
      primaryBtnDisabledTooltipText={
        !isFormValid
          ? t("common.labels.invalidForm")
          : attachmentsLoading
          ? t("Attachments.loadingTooltip")
          : undefined
      }
      content={
        <Stack direction="row">
          <Stack direction="column" flex={1}>
            <Box display="flex" flexDirection="column" pb={4}>
              <Typography
                variant="h3"
                fontWeight={600}
                color={theme.palette.grey[800]}
                mb={1}
              >
                {headerText.title}
              </Typography>
              <Typography variant="p1" color={theme.palette.grey[700]}>
                {headerText.subtitle}
              </Typography>
            </Box>
            <DetailedClaimAgreementDeterminationForm
              apiRef={formRef}
              sections={activeSections}
              contractCurrency={contractCurrency}
              draftDetailedClaim={draftDetailedClaim}
              draftClaimAgreement={
                internalModalType ===
                InternalModalType.CreateDraftClaimAgreement
                  ? (initialAgreementDeterminationClaim as DraftClaimAgreement)
                  : internalModalType ===
                    InternalModalType.EditDraftClaimAgreement
                  ? draftClaimAgreement
                  : undefined
              }
              draftClaimDetermination={
                internalModalType ===
                InternalModalType.CreateDraftClaimDetermination
                  ? (initialAgreementDeterminationClaim as DraftClaimDetermination)
                  : internalModalType ===
                    InternalModalType.EditDraftClaimDetermination
                  ? draftClaimDetermination
                  : undefined
              }
              type={type}
              onAttachmentsLoadingChange={setAttachmentsLoading}
              onChange={handleQuotationAssessmentChange}
              onAttachmentsChange={onAttachmentsChange}
            />
          </Stack>
          {type !== ModalType.DraftDetailedClaim && finalDetailedClaim && (
            <Stack direction="row" flex={0.7}>
              <ListItemsDivider orientation="vertical" sx={{ ml: 4 }} />
              <Stack
                direction="column"
                sx={{
                  pl: 4,
                }}
              >
                <Box display="flex" flexDirection="column" pb={4}>
                  <Stack direction="row" alignItems="center">
                    <Typography
                      variant="h3"
                      fontWeight={600}
                      color={theme.palette.grey[800]}
                      pr={0.5}
                    >
                      {finalDetailedClaim.reference}
                    </Typography>
                    <Tooltip
                      title={t(
                        `Projects.Claims.latestDetailedClaimSubmitted${
                          isFIDIC99RedYellow ? "99RY" : ""
                        }`
                      )}
                      arrow
                      placement="bottom"
                    >
                      <Info size={20} color={theme.palette.grey[800]} />
                    </Tooltip>
                  </Stack>
                </Box>
                <ContractPriceTimeChangeReadOnlyView
                  detailsCustomRender={
                    <Stack direction="column">
                      <Typography
                        variant="p2"
                        fontWeight={600}
                        color="grey.800"
                      >
                        {t(
                          `Projects.Claims.DetailedClaimModal.detailsOfDetailedClaim${
                            isFIDIC99RedYellow ? "99RY" : ""
                          }`
                        )}
                      </Typography>
                      <RichTextArea
                        content={finalDetailedClaim.details}
                        readOnly
                        color={theme.palette.grey[600]}
                        fontSize="16px"
                      />
                    </Stack>
                  }
                  time={finalDetailedClaim.time}
                  price={finalDetailedClaim.price}
                  contract={contract}
                  dateSent={finalDetailedClaim.dateCreated}
                  sectionalChanges={finalDetailedClaim.sectionalChanges}
                  attachments={finalDetailedClaim.attachments}
                  productType={ProductType.Claims}
                />
              </Stack>
            </Stack>
          )}
        </Stack>
      }
      primaryBtnLoading={primaryBtnPressed}
      {...restDialogProps}
    />
  );
};
