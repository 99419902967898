import { Box, SelectChangeEvent, Tooltip } from "@mui/material";
import {
  enUS,
  GridCellParams,
  GridEventListener,
  GridFilterModel,
  GridLogicOperator,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowParams,
  GridRowSelectionModel,
  MuiEvent,
} from "@mui/x-data-grid-pro";
import { StatusOption } from "components/StatusTag/StatusTag";
import { useActiveRemovedStatusOptions } from "components/StatusTag/useActiveRemovedStatusOptions";
import { StyledDataGrid } from "components/StyledDataGrid";
import {
  AddSchemaFieldInput,
  EditSchemaFieldInput,
  FieldType,
  SchemaField,
  SchemaFieldStatus,
  User,
} from "generated/graphql";
import { computeGridRowModes } from "helpers/dataGrid.helpers";
import { useDataGridVisibleRows } from "hooks/useDataGridVisibleRows";
import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { GlobalContext } from "state-management/globalContext/Global.context";
import { rowsContainTemporaryRecord, sortFields } from "./SectionFields.utils";
import { exportToExcel } from "helpers/exportToExcel";
import { useColumns } from "./SectionFields.constants";
import moment from "moment";
import {
  dateISOFormat,
  temporaryRowId,
} from "../../../../../../../../../constants";
import { DataGridAddRecordButton } from "components/DataGridAddRecordButton";
import { GridHeader } from "components/GridHeader";
import { getUserName } from "helpers/miscelaneous";

export type SectionFieldsProps = {
  fields: SchemaField[];
  fieldTypes: FieldType[];
  schemaSectionId?: string;
  sectionName?: string;
  loading?: boolean;
  onSchemaFieldAdd: (newSchemaField: AddSchemaFieldInput) => void;
  onSchemaFieldStatusChange: (
    row: SchemaField,
    newStatus: SchemaFieldStatus
  ) => void;
  onSchemaFieldUpdate: (fieldData: EditSchemaFieldInput) => void;
};

export const SectionFields: React.FC<SectionFieldsProps> = ({
  fields,
  fieldTypes,
  sectionName,
  schemaSectionId,
  loading,
  onSchemaFieldAdd,
  onSchemaFieldStatusChange,
  onSchemaFieldUpdate,
}) => {
  const { t } = useTranslation();
  const { authenticatedUser } = useContext(GlobalContext);
  const { visibleRowsCount, gridApiRef } = useDataGridVisibleRows();
  const statusOptions =
    useActiveRemovedStatusOptions() as StatusOption<SchemaFieldStatus>[];

  const [rows, setRows] = useState<SchemaField[]>(sortFields(fields));
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>(
    computeGridRowModes(fields)
  );
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>();

  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [
      {
        field: "status",
        operator: "isAnyOf",
        value: [SchemaFieldStatus.Active],
      },
    ],
    logicOperator: GridLogicOperator.And,
    quickFilterLogicOperator: GridLogicOperator.And,
    quickFilterValues: [],
  });

  const handleExportToExcel = () => {
    const columns = [
      { header: t("common.labels.name"), key: "name", width: 20 },
      { header: t("common.labels.status"), key: "status", width: 20 },
      {
        header: t("AdminConsole.Products.labels.displayText"),
        key: "displayText",
        width: 20,
      },
      {
        header: t("AdminConsole.Products.labels.fieldType"),
        key: "fieldType",
        width: 20,
      },
      {
        header: t("AdminConsole.Products.labels.displayOrder"),
        key: "displayOrder",
        width: 20,
      },
      {
        header: t("common.labels.required"),
        key: "isRequired",
        width: 20,
      },
      { header: t("common.labels.dateCreated"), key: "dateCreated", width: 20 },
      { header: t("common.labels.createdBy"), key: "creator", width: 20 },
    ];

    const rowsToExport = rows
      .filter((field) => (selectionModel || []).indexOf(field.id) >= 0)
      .map((field) => ({
        ...field,
        fieldType: field.fieldType?.description || "",
        isRequired: !!field.isRequired,
        dateCreated: field.dateCreated ? new Date(field.dateCreated) : "",
        creator: getUserName(field.creator),
      }));

    exportToExcel(
      `${sectionName}-${t("AdminConsole.Products.labels.sectionFields")}`,
      columns,
      rowsToExport
    );
  };

  const handleSchemaFieldStatusChange = useCallback(
    (row: SchemaField, newStatus: SchemaFieldStatus) => {
      if (row.id === temporaryRowId) {
        setRows((curRows) =>
          curRows.map((row) => {
            if (row.id === temporaryRowId) {
              return {
                ...row,
                status: newStatus,
              };
            }

            return row;
          })
        );
      } else {
        onSchemaFieldStatusChange(row, newStatus);
      }
    },
    [onSchemaFieldStatusChange]
  );

  const handleSchemaFieldTypeChange = useCallback(
    (row: SchemaField, event: SelectChangeEvent<unknown>) => {
      if (row.id === temporaryRowId) {
        setRows((curRows) =>
          curRows.map((row) => {
            if (row.id === temporaryRowId) {
              return {
                ...row,
                fieldTypeId: event.target.value as string,
              };
            }

            return row;
          })
        );
      } else {
        onSchemaFieldUpdate({
          id: row.id,
          name: row.name,
          displayText: row.displayText,
          displayOrder: row.displayOrder,
          schemaSectionId: schemaSectionId!,
          isRequired: row.isRequired,
          fieldTypeId: event.target.value as string,
        });
      }
    },
    [schemaSectionId, onSchemaFieldUpdate]
  );

  const handleDeleteRow = useCallback(
    (rowId: GridRowId) => {
      if (rowId === temporaryRowId) {
        // just remove the temporary row from local rows
        setRows((curRows) => curRows.filter((row) => row.id !== rowId));
      } else {
        // call BE to delete row
        onSchemaFieldStatusChange(
          fields.find((schemaField) => schemaField.id === rowId)!,
          SchemaFieldStatus.Removed
        );
      }
    },
    [onSchemaFieldStatusChange, fields]
  );

  /**
   * This function does not do the actual save because the data inside the row is not commited until it gets out of EditMode. Thus,
   * we're closing the editMode here, and process the add/edit inside processRowUpdate
   */
  const handleRowSaveClick = useCallback((rowId: GridRowId) => {
    setRowModesModel((curModel) => ({
      ...curModel,
      [rowId]: { mode: GridRowModes.View },
    }));
  }, []);

  const handleRowEditStart = (
    _: GridRowParams,
    event: MuiEvent<React.SyntheticEvent>
  ) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (_, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowChangesCommited = useCallback(
    (newRow: GridRowModel<SchemaField>, oldRow: GridRowModel<SchemaField>) => {
      if (newRow.id === temporaryRowId) {
        if (
          newRow.name &&
          newRow.displayText &&
          newRow.displayOrder !== null &&
          newRow.displayOrder !== undefined &&
          newRow.fieldTypeId
        ) {
          onSchemaFieldAdd({
            name: newRow.name,
            displayText: newRow.displayText,
            displayOrder: newRow.displayOrder,
            fieldTypeId: newRow.fieldTypeId,
            isRequired: newRow.isRequired,
            schemaSectionId: schemaSectionId!,
          });
        } else {
          setRowModesModel((prevData) => ({
            ...prevData,
            [temporaryRowId]: {
              mode: GridRowModes.Edit,
              fieldToFocus: "name",
            },
          }));
        }
      } else if (
        newRow.name !== oldRow.name ||
        newRow.displayText !== oldRow.displayText
      ) {
        onSchemaFieldUpdate({
          id: newRow.id,
          name: newRow.name,
          displayText: newRow.displayText,
          displayOrder: newRow.displayOrder,
          schemaSectionId: schemaSectionId!,
          isRequired: newRow.isRequired,
          fieldTypeId: newRow.fieldTypeId,
        });
      }

      return newRow;
    },
    [onSchemaFieldAdd, onSchemaFieldUpdate, schemaSectionId]
  );

  const handleAddTemporaryRecord = () => {
    setRows((currentRecords) => [
      ...currentRecords,
      {
        creator: authenticatedUser || ({} as User),
        creatorId: authenticatedUser?.id ?? "",
        dateCreated: moment(new Date().toString()).format(dateISOFormat),
        name: "",
        displayOrder: rows.length + 1 || 1,
        id: temporaryRowId,
        schemaSectionId: schemaSectionId!,
        status: SchemaFieldStatus.Active,
        fieldType: {} as FieldType,
        displayText: "",
        fieldTypeId: "",
        isInternal: false,
        isRequired: true,
      },
    ]);

    setTimeout(() => {
      setRowModesModel((prevData) => ({
        ...prevData,
        [temporaryRowId]: {
          mode: GridRowModes.Edit,
          fieldToFocus: "name",
        },
      }));
    });
  };

  const handleRowOrderChange: GridEventListener<"rowOrderChange"> = (
    params
  ) => {
    const field = params.row;
    const newIndex = params.targetIndex + 1;

    if (newIndex !== field.displayOrder) {
      onSchemaFieldUpdate({
        id: field.id,
        name: field.name,
        displayText: field.displayText,
        displayOrder: newIndex,
        schemaSectionId: schemaSectionId!,
        isRequired: field.isRequired,
        fieldTypeId: field.fieldTypeId,
      });
    }
  };

  const handleIsRequiredChange = useCallback(
    (rowId: GridRowId) => {
      if (rowId === temporaryRowId) {
        setRows((curRows) =>
          curRows.map((row) => {
            if (row.id === temporaryRowId) {
              return {
                ...row,
                isRequired: !row.isRequired,
              };
            }

            return row;
          })
        );
      } else {
        const field = rows.find((row) => row.id === rowId);

        if (field) {
          onSchemaFieldUpdate({
            id: field.id,
            name: field.name,
            displayText: field.displayText,
            displayOrder: field.displayOrder,
            schemaSectionId: schemaSectionId!,
            isRequired: !field.isRequired,
            fieldTypeId: field.fieldTypeId,
          });
        }
      }
    },
    [rows, onSchemaFieldUpdate, schemaSectionId]
  );

  const columns = useColumns(
    statusOptions,
    handleSchemaFieldStatusChange,
    handleSchemaFieldTypeChange,
    rowModesModel,
    handleRowSaveClick,
    handleDeleteRow,
    fieldTypes,
    handleIsRequiredChange
  );

  useEffect(() => {
    setRows(sortFields(fields));
    setRowModesModel(computeGridRowModes(fields));
  }, [fields]);

  const tempRecordAdded = rowsContainTemporaryRecord(rows);

  return (
    <Box>
      <GridHeader
        title={t("AdminConsole.Products.labels.sectionFields")}
        visibleRowsCount={visibleRowsCount || 0}
        selectedCount={selectionModel?.length || 0}
        onExportToExcel={handleExportToExcel}
        containerStyle={{ px: 0 }}
      />
      <Box sx={{ maxHeight: 600, width: "100%", overflowY: "auto" }}>
        <StyledDataGrid
          apiRef={gridApiRef}
          rows={rows}
          columns={columns}
          getRowId={(rowData: SchemaField) => rowData.id}
          onRowSelectionModelChange={setSelectionModel}
          loading={loading}
          filterMode="client"
          filterModel={filterModel}
          onFilterModelChange={setFilterModel}
          rowModesModel={rowModesModel}
          onRowEditStart={handleRowEditStart}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={handleRowChangesCommited}
          onRowOrderChange={handleRowOrderChange}
          // experimentalFeatures={{ newEditingApi: true }}
          getCellClassName={(params: GridCellParams<any, SchemaField, any>) => {
            return params.row.status === SchemaFieldStatus.Removed
              ? "greyed-out"
              : "";
          }}
          localeText={{
            noRowsLabel: schemaSectionId
              ? enUS.components.MuiDataGrid.defaultProps.localeText.noRowsLabel
              : t("Grid.labels.sectionSelectionNeeded"),
          }}
          checkboxSelection
          disableRowSelectionOnClick
          autoHeight
          hideFooter
          rowReordering
        />
        <Tooltip
          title={
            schemaSectionId
              ? ""
              : t("AdminConsole.Products.labels.sectionSelectionNeeded")
          }
          placement="top-end"
        >
          <>
            <DataGridAddRecordButton
              onClick={handleAddTemporaryRecord}
              disabled={!schemaSectionId || tempRecordAdded}
              ml={0}
            />
          </>
        </Tooltip>
      </Box>
    </Box>
  );
};
