import { useCallback, useContext, useEffect, useState } from "react";
import { ClaimWidgetContext } from "../../ClaimWidget/ClaimWidget.context";
import { useGraphMutation } from "hooks/useGraphMutation";
import {
  GenerateSendClaimDetailsResponsePreviewMutation,
  GenerateSendClaimDetailsResponsePreviewMutationVariables,
  SendClaimDetailsResponsePreview,
} from "generated/graphql";
import { debounce } from "@mui/material";
import { eventDebounceDuration } from "../../../../../../constants";
import { generateSendClaimDetailsResponsePreviewMutation } from "./RespondRegardingDetailedClaimAction.query";

export const useSendResponseRegardingDetailedClaimModal = (
  detailedClaimId: string,
  details: string
) => {
  const [claimPreview, setClaimPreview] =
    useState<SendClaimDetailsResponsePreview>();

  const { claim, contract } = useContext(ClaimWidgetContext);

  const [generateClaimPreview] = useGraphMutation<
    GenerateSendClaimDetailsResponsePreviewMutation,
    GenerateSendClaimDetailsResponsePreviewMutationVariables
  >(generateSendClaimDetailsResponsePreviewMutation, {}, null);

  const loadPreview = debounce(
    useCallback(async () => {
      const { data } = await generateClaimPreview({
        variables: {
          input: {
            claimId: claim!.id,
            detailedClaimId,
            details,
          },
        },
      });

      if (data) {
        setClaimPreview(data.generateSendClaimDetailsResponsePreview);
      }
    }, [generateClaimPreview, claim, detailedClaimId, details]),
    eventDebounceDuration
  );

  useEffect(() => {
    loadPreview();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  return {
    claimPreview,
    contract,
  };
};
