import { ActionsDialogProps } from "components/ActionsDialog";
import { RecordProductItemExtraData } from "containers/Projects/components/RecordProductItemForm";
import { VariationActionType } from "generated/graphql";
import { RecordAction } from "../components/RecordAction";
import { useRecordWorkToCommenceInstructionModal } from "./useRecordWorkToCommenceInstructionModal";
import { EnhancedAttachment } from "containers/Projects/components/Attachments/Attachments.decl";
import { InstructWorkToCommenceSummary } from "./InstructWorkToCommenceSummary";

export type RecordWorkToCommenceInstructionModalProps = {
  remarks: string;
  attachments?: EnhancedAttachment[];
  noAgreement?: boolean;
  onPrimaryClick: (extraData: RecordProductItemExtraData) => void;
} & Omit<ActionsDialogProps, "onPrimaryClick">;

export const RecordWorkToCommenceInstructionModal: React.FC<
  RecordWorkToCommenceInstructionModalProps
> = ({ remarks, attachments = [], noAgreement, ...restDialogProps }) => {
  const { variationPreview } = useRecordWorkToCommenceInstructionModal(remarks);

  return (
    <RecordAction
      actionType={VariationActionType.InstructWorkToCommenceOnVariation}
      variationPreview={variationPreview}
      summaryEl={
        <InstructWorkToCommenceSummary
          text={remarks}
          noAgreement={noAgreement}
          attachments={attachments}
        />
      }
      {...restDialogProps}
    />
  );
};
