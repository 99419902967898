import {
  FormControl,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { FormLabel } from "components/FormLabel";
import { FormPublicApi } from "decl";
import {
  AddDailyDiaryDelayRecordInput,
  CompanyLookupCollection,
  DailyDiaryDelayRecord,
  DailyDiaryPresetSection,
  EditDailyDiaryDelayRecordInput,
} from "generated/graphql";
import { validateData } from "helpers/validators";
import {
  ChangeEventHandler,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Textarea } from "components/TextArea";
import { DelayOptionalColumn } from "../DelayTable/DelayTable";
import { delayRecordToEditInput } from "../DelaySection.utils";
import moment from "moment";
import { dailyDiaryTimeFormat } from "../../../DailyDiary.constants";
import { FormErrorLabel } from "components/FormErrorLabel";
import { addDefaultMinutes00 } from "../../../utilts";
import { TimePicker } from "components/TimePicker";
import { useDelayRecordFormValidators } from "./useDelayRecordFormValidators";

export type DelayLookups = {
  Shift: CompanyLookupCollection;
  DelayType: CompanyLookupCollection;
  Area: CompanyLookupCollection;
  Discipline: CompanyLookupCollection;
  Team: CompanyLookupCollection;
};

export type DelayRecordFormProps = {
  delayLookups: DelayLookups;
  optionalColumns: DelayOptionalColumn[];
  section: DailyDiaryPresetSection;
  delayRecord?: DailyDiaryDelayRecord;
  apiRef?: React.Ref<FormPublicApi>;
  onChange: (
    delayRecord: AddDailyDiaryDelayRecordInput | EditDailyDiaryDelayRecordInput
  ) => void;
};

export const defaultFormData: EditDailyDiaryDelayRecordInput = {
  id: "",
  areaOptionId: undefined,
  attachments: [],
  description: "",
  disciplineOptionId: undefined,
  teamOptionId: undefined,
  shiftOptionId: undefined,
  delayTypeOptionId: "",
  duration: null,
};

export const DelayRecordForm: React.FC<DelayRecordFormProps> = ({
  delayRecord,
  optionalColumns,
  delayLookups,
  section,
  apiRef,
  onChange,
}) => {
  const { t } = useTranslation();

  const firstFieldRef = useRef<any>(null);

  const [formData, setFormData] = useState<
    AddDailyDiaryDelayRecordInput | EditDailyDiaryDelayRecordInput
  >(delayRecord ? delayRecordToEditInput(delayRecord) : defaultFormData);
  const [formDataErrors, setFormDataErrors] = useState<{
    [key: string]: string;
  }>({});

  const dataValidators = useDelayRecordFormValidators(
    section,
    formData,
    delayLookups
  );

  const validateForm = useCallback(
    (
      formData: AddDailyDiaryDelayRecordInput | EditDailyDiaryDelayRecordInput
    ) => {
      const validationResult = validateData(formData, dataValidators);

      if (validationResult.valid) {
        setFormDataErrors({});
        return true;
      }
      setFormDataErrors(validationResult.errors);

      return false;
    },
    [dataValidators]
  );

  const handleDescriptionChange: ChangeEventHandler<HTMLTextAreaElement> = (
    event
  ) => {
    setFormData((curData) => ({
      ...curData,
      description: event.target.value,
    }));
  };

  const handleSelectChange = (event: SelectChangeEvent<string | null>) => {
    setFormData((curData) => ({
      ...curData,
      [event.target.name]: event.target.value ?? "",
    }));

    setFormDataErrors((crtFormDataErrs) => {
      const { [event.target.name]: _, ...rest } = crtFormDataErrs;

      return rest;
    });
  };

  const handleDurationValueChange = (value: any) => {
    setFormData((crtFormData) => ({
      ...crtFormData,
      duration: value,
    }));

    setFormDataErrors((crtFormDataErrs) => {
      const { duration: _, ...rest } = crtFormDataErrs;

      return rest;
    });
  };

  const resetForm = useCallback(() => {
    setFormData(defaultFormData);
    firstFieldRef.current.focus();
  }, []);

  const handleHoursInputChange = (evt: InputEvent) => {
    addDefaultMinutes00(evt, !formData.duration, handleDurationValueChange);
  };

  useImperativeHandle(
    apiRef,
    () => ({
      validate: () => validateForm(formData),
      reset: resetForm,
    }),
    [resetForm, formData, validateForm]
  );

  useEffect(() => {
    const {
      duration,
      areaOptionId,
      disciplineOptionId,
      teamOptionId,
      shiftOptionId,
      ...restData
    } = formData;

    onChange({
      ...restData,
      ...(areaOptionId ? { areaOptionId } : {}),
      ...(disciplineOptionId ? { disciplineOptionId } : {}),
      ...(teamOptionId ? { teamOptionId } : {}),
      ...(shiftOptionId ? { shiftOptionId } : {}),
      ...(duration
        ? {
            duration: moment(duration).format(dailyDiaryTimeFormat),
          }
        : {}),
    });
  }, [onChange, formData]);

  useEffect(() => {
    firstFieldRef.current.focus();
  }, []);

  const isDescriptionRequired = section.fields.find(
    (field) => field.name === "Description"
  )?.isRequired;
  const isShiftRequired = section.fields.find(
    (field) => field.name === "Shift"
  )?.isRequired;
  const isTypeRequired = section.fields.find(
    (field) => field.name === "DelayType"
  )?.isRequired;
  const isAreaRequired = section.fields.find(
    (field) => field.name === "Area"
  )?.isRequired;
  const isDisciplineRequired = section.fields.find(
    (field) => field.name === "Discipline"
  )?.isRequired;
  const isTeamRequired = section.fields.find(
    (field) => field.name === "Team"
  )?.isRequired;
  const isDurationRequired = section.fields.find(
    (field) => field.name === "Duration"
  )?.isRequired;

  return (
    <Grid container spacing={5}>
      <Grid item xs={12}>
        <FormControl variant="standard" sx={{ minWidth: 120 }} fullWidth>
          <FormLabel
            label={t("Projects.DailyDiaries.Delay.description")}
            required={isDescriptionRequired}
          />
          <Textarea
            value={formData.description ?? ""}
            onChange={handleDescriptionChange}
            ref={firstFieldRef}
            error={!!formDataErrors.description}
          />
          {!!formDataErrors.description && (
            <FormErrorLabel
              dataTestId="description-err-msg"
              errorMessage={formDataErrors.description}
            />
          )}
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl variant="standard" sx={{ minWidth: 120 }} fullWidth>
          <FormLabel
            label={t("Projects.DailyDiaries.Delay.delayType")}
            required={isTypeRequired}
          />
          <Select
            labelId="delayType-select-label"
            id="delayType-select"
            value={formData.delayTypeOptionId ?? ""}
            name="delayTypeOptionId"
            onChange={handleSelectChange}
            variant="outlined"
            error={!!formDataErrors.delayTypeOptionId}
            required={isTypeRequired}
            size="small"
          >
            {delayLookups.DelayType.options.items.map((lkpOption) => (
              <MenuItem key={lkpOption.id} value={lkpOption.id}>
                {lkpOption.value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      {optionalColumns.includes(DelayOptionalColumn.Shift) && (
        <Grid item md={6} xs={12}>
          <FormControl variant="standard" sx={{ minWidth: 120 }} fullWidth>
            <FormLabel
              label={t("Projects.DailyDiaries.Delay.shift")}
              required={isShiftRequired}
            />
            <Select
              labelId="shift-select-label"
              id="shift-select"
              value={formData.shiftOptionId ?? ""}
              name="shiftOptionId"
              onChange={handleSelectChange}
              variant="outlined"
              error={!!formDataErrors.shiftOptionId}
              size="small"
              required={isShiftRequired}
            >
              {delayLookups.Shift.options.items.map((lkpOption) => (
                <MenuItem key={lkpOption.id} value={lkpOption.id}>
                  {lkpOption.value}
                </MenuItem>
              ))}
            </Select>
            {!!formDataErrors.shiftOptionId && (
              <FormErrorLabel
                dataTestId="shiftOptionId-error-msg"
                errorMessage={formDataErrors.shiftOptionId}
              />
            )}
          </FormControl>
        </Grid>
      )}
      {optionalColumns.includes(DelayOptionalColumn.Area) && (
        <Grid item md={6} xs={12}>
          <FormControl variant="standard" sx={{ minWidth: 120 }} fullWidth>
            <FormLabel
              label={t("Projects.DailyDiaries.Delay.area")}
              required={isAreaRequired}
            />
            <Select
              labelId="area-select-label"
              id="area-select"
              value={formData.areaOptionId ?? ""}
              name="areaOptionId"
              onChange={handleSelectChange}
              variant="outlined"
              error={!!formDataErrors.areaOptionId}
              size="small"
              required={isAreaRequired}
            >
              {delayLookups.Area.options.items.map((lkpOption) => (
                <MenuItem key={lkpOption.id} value={lkpOption.id}>
                  {lkpOption.value}
                </MenuItem>
              ))}
            </Select>
            {!!formDataErrors.areaOptionId && (
              <FormErrorLabel
                dataTestId="areaOptionId-error-msg"
                errorMessage={formDataErrors.areaOptionId}
              />
            )}
          </FormControl>
        </Grid>
      )}
      {optionalColumns.includes(DelayOptionalColumn.Discipline) && (
        <Grid item md={6} xs={12}>
          <FormControl variant="standard" sx={{ minWidth: 120 }} fullWidth>
            <FormLabel
              label={t("Projects.DailyDiaries.Delay.discipline")}
              required={isDisciplineRequired}
            />
            <Select
              labelId="discipline-select-label"
              id="discipline-select"
              value={formData.disciplineOptionId ?? ""}
              name="disciplineOptionId"
              onChange={handleSelectChange}
              variant="outlined"
              error={!!formDataErrors.disciplineOptionId}
              size="small"
              required={isDisciplineRequired}
            >
              {delayLookups.Discipline.options.items.map((lkpOption) => (
                <MenuItem key={lkpOption.id} value={lkpOption.id}>
                  {lkpOption.value}
                </MenuItem>
              ))}
            </Select>
            {!!formDataErrors.disciplineOptionId && (
              <FormErrorLabel
                dataTestId="disciplineOptionId-error-msg"
                errorMessage={formDataErrors.disciplineOptionId}
              />
            )}
          </FormControl>
        </Grid>
      )}
      {optionalColumns.includes(DelayOptionalColumn.Team) && (
        <Grid item md={6} xs={12}>
          <FormControl variant="standard" sx={{ minWidth: 120 }} fullWidth>
            <FormLabel
              label={t("Projects.DailyDiaries.Delay.team")}
              required={isTeamRequired}
            />
            <Select
              labelId="team-select-label"
              id="team-select"
              name="teamOptionId"
              value={formData.teamOptionId ?? ""}
              onChange={handleSelectChange}
              variant="outlined"
              error={!!formDataErrors.teamOptionId}
              size="small"
              required={isTeamRequired}
            >
              {delayLookups.Team.options.items.map((lkpOption) => (
                <MenuItem key={lkpOption.id} value={lkpOption.id}>
                  {lkpOption.value}
                </MenuItem>
              ))}
            </Select>
            {!!formDataErrors.teamOptionId && (
              <FormErrorLabel
                dataTestId="teamOptionId-error-msg"
                errorMessage={formDataErrors.teamOptionId}
              />
            )}
          </FormControl>
        </Grid>
      )}
      <Grid item md={6}>
        <FormLabel
          label={t("Projects.DailyDiaries.Delay.duration")}
          required={isDurationRequired}
        />
        <TimePicker
          label="hh:mm"
          value={formData.duration}
          ampm={false}
          onChange={handleDurationValueChange}
          slotProps={{
            textField: {
              size: "small",
              fullWidth: true,
              onInput: handleHoursInputChange,
            },
          }}
        />
      </Grid>
    </Grid>
  );
};
