import { Checkbox, Typography } from "@mui/material";
import {
  GridColDef,
  GridEditInputCell,
  GridRenderCellParams,
  GridRenderEditCellParams,
  GridRowId,
  GridRowModesModel,
} from "@mui/x-data-grid-pro";
import { StyledLink } from "components/StyledLink";
import { ProductSchema, ProductSchemaStatus } from "generated/graphql";
import {
  getCommonActions,
  statusComparatorFunction,
  useDateCreatedColumnConfig,
} from "helpers/dataGrid.helpers";
import { getUserName } from "helpers/miscelaneous";
import {
  StatusTag,
  StatusOption,
} from "../../../../../../../../components/StatusTag/StatusTag";
import { NewAppPaths } from "helpers/paths/paths";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";

export const useColumns = (
  statusOptions: StatusOption<ProductSchemaStatus>[],
  productId: string,
  rowModesModel: GridRowModesModel,
  onStatusChange: (row: ProductSchema, newStatus: ProductSchemaStatus) => void,
  handleSaveRow: (rowId: GridRowId) => void,
  handleDeleteRow: (rowId: GridRowId) => void,
  handleIsDefaultChange: (rowId: GridRowId) => void
): GridColDef<ProductSchema>[] => {
  const { t } = useTranslation();
  const dateCreatedColConfig = useDateCreatedColumnConfig();

  const columns = useMemo(
    () =>
      [
        {
          field: "name",
          headerName: t("common.labels.name"),
          flex: 0.125,
          minWidth: 190,
          resizable: true,
          editable: true,
          renderCell: (
            params: GridRenderCellParams<any, ProductSchema, any>
          ) => {
            return (
              <StyledLink
                to={NewAppPaths.authorized.AdminConsole.children.System.children.Products.children.ProductDetails.children.SchemaDetails.pathConstructor(
                  productId,
                  params.row.id
                )}
                onClick={(evt) => {
                  evt.stopPropagation();
                }}
              >
                <Typography variant="body2">{params.row.name}</Typography>
              </StyledLink>
            );
          },
          renderEditCell: (
            params: GridRenderEditCellParams<any, ProductSchema>
          ) => {
            return <GridEditInputCell {...params} error={!params.row.name} />;
          },
        },
        {
          field: "status",
          headerName: t("common.labels.status"),
          flex: 0.125,
          minWidth: 90,
          resizable: true,
          sortComparator: statusComparatorFunction,
          renderCell: (
            params: GridRenderCellParams<any, ProductSchema, any>
          ) => {
            return (
              <StatusTag
                status={params.row.status}
                options={statusOptions}
                disablePortal={false}
                onChange={(newStatus: StatusOption<ProductSchemaStatus>) =>
                  onStatusChange(params.row, newStatus.id)
                }
              />
            );
          },
        },
        {
          field: "isDefault",
          headerName: t("AdminConsole.Products.labels.isDefault"),
          flex: 0.075,
          minWidth: 90,
          resizable: true,
          renderCell: (
            params: GridRenderCellParams<any, ProductSchema, any>
          ) => {
            return (
              <Checkbox
                checked={params.row.isDefault}
                onClick={(evt) => {
                  evt.stopPropagation();
                }}
                onChange={() => {
                  handleIsDefaultChange(params.row.id);
                }}
              />
            );
          },
        },
        dateCreatedColConfig,
        {
          field: "creator",
          headerName: t("common.labels.createdBy"),
          flex: 0.125,
          minWidth: 190,
          resizable: true,
          valueGetter: (params) => getUserName(params.row.creator),
        },
        {
          field: "actions",
          flex: 0.05,
          type: "actions",
          getActions: (cellParams) =>
            getCommonActions(
              cellParams,
              rowModesModel,
              handleSaveRow,
              handleDeleteRow
            ),
        },
      ] as GridColDef<ProductSchema>[],
    [
      t,
      statusOptions,
      productId,
      rowModesModel,
      dateCreatedColConfig,
      onStatusChange,
      handleSaveRow,
      handleDeleteRow,
      handleIsDefaultChange,
    ]
  );

  return columns;
};
