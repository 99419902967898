import { Color, css, styled, useTheme } from "@mui/material";
import { DataGridPro, DataGridProProps, enUS } from "@mui/x-data-grid-pro";

const LocalDataGrid = styled(DataGridPro)<{ clickable?: boolean }>(
  ({ theme, clickable = false }) => css`
    && {
      .MuiDataGrid-columnHeaders {
        background: ${theme.palette.grey[300]};
      }
      .MuiDataGrid-cell.greyed-out {
        color: ${theme.palette.action.disabled};
      }

      .MuiDataGrid-row {
        min-height: 52; // Note: default from MUI. Re-setting it for grids with height 'auto'
        ${clickable &&
        `&:hover {
          cursor: pointer;
        }`}
        .visible-on-hover {
          visibility: hidden;
        }

        .Mui-error {
          background-color: rgb(126, 10, 15, 0.1); // TODO use theme values
          color: #750f0f; // TODO use theme values
          height: 100%;
        }
      }

      .MuiDataGrid-row:hover {
        .MuiDataGrid-cell:not(.greyed-out) {
          .visible-on-hover {
            visibility: visible;
          }
        }
      }
    }
  `
);

const LocalNewDataGrid = styled(DataGridPro)<{ clickable?: boolean }>(
  ({ theme, clickable = false }) => css`
    && {
      &.MuiDataGrid-root {
        border-radius: 8px;
        border-color: ${theme.palette.grey[100]};
        background: white;

        .MuiDataGrid-cell.selected {
          background: ${(theme.palette.secondary as Partial<Color>)[100]};
        }

        .MuiDataGrid-columnHeaders {
          background: white;
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;

          .MuiDataGrid-columnHeaderTitle {
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            color: ${theme.palette.grey[600]};
          }

          .MuiDataGrid-columnSeparator {
            opacity: 0 !important;
          }
        }

        .MuiDataGrid-row {
          .visible-on-hover {
            visibility: hidden;
          }
        }

        .MuiDataGrid-row:hover {
          background: ${theme.palette.grey[200]};
          cursor: ${clickable ? "pointer" : "default"};

          .MuiDataGrid-cell:not(.greyed-out) {
            .visible-on-hover {
              visibility: visible;
            }
          }
        }
        .MuiDataGrid-row.Mui-selected {
          background: ${(theme.palette.secondary as Partial<Color>)[100]};
        }

        .MuiDataGrid-cellContent {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: ${theme.palette.grey[600]};
        }

        .MuiDataGrid-cell--withRenderer {
          overflow: visible;
        }
      }
    }
  `
);

export const StyledDataGrid = (
  props: DataGridProProps<any> & React.RefAttributes<HTMLDivElement>
) => {
  return (
    <LocalDataGrid
      {...props}
      clickable={!!props.onRowClick}
      localeText={{
        ...enUS.components.MuiDataGrid.defaultProps.localeText,
        ...props.localeText,
      }}
    />
  );
};

export const NewStyledDataGrid = (
  props: DataGridProProps<any> &
    React.RefAttributes<HTMLDivElement> & {
      disableSelectAll?: boolean;
      error?: boolean;
    }
) => {
  const theme = useTheme();

  return (
    <LocalNewDataGrid
      {...props}
      clickable={!!props.onRowClick}
      localeText={{
        ...enUS.components.MuiDataGrid.defaultProps.localeText,
        ...props.localeText,
      }}
      {...(props.disableSelectAll
        ? {
            sx: {
              "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
                {
                  display: "none",
                },
              "&.MuiDataGrid-root": {
                borderColor: props.error
                  ? `${theme.palette.error.main} !important`
                  : undefined,
              },
            },
          }
        : {})}
    />
  );
};

const StyledDailyDiarySectionDataGrid = styled(DataGridPro)<{
  clickable?: boolean;
  stickyHeader?: boolean;
}>(
  ({ theme, clickable = false, stickyHeader = false }) => css`
    && {
      &.MuiDataGrid-root {
        border: none;
        background: white;

        ${stickyHeader &&
        css`
          .MuiDataGrid-main {
            overflow: unset;
          }
        `}

        .MuiDataGrid-cell {
          min-height: 36px !important;
          box-sizing: border-box;
          padding: ${theme.spacing(1)} 10px;
          justify-content: flex-start;
          align-items: flex-start;

          .MuiButtonBase-root.MuiCheckbox-root {
            width: 36px;
            height: 36px;
            box-sizing: border-box;
            padding-top: 0;
            padding-bottom: 0;
            margin-top: -8px;
            margin-bottom: -8px;
            margin-left: -3px;
          }

          & > * {
            box-sizing: border-box;
          }

          &.selected {
            background: ${(theme.palette.secondary as Partial<Color>)[100]};
          }
        }

        .MuiDataGrid-columnHeaders {
          background: white;
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;

          ${stickyHeader &&
          css`
            position: sticky;
            top: 0;
            background: white;
            z-index: 2;
          `}

          .MuiDataGrid-columnHeaderTitle {
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            color: ${theme.palette.grey[600]};
          }

          .MuiDataGrid-columnSeparator {
            opacity: 0 !important;
          }
        }

        .MuiDataGrid-row {
          .visible-on-hover {
            visibility: hidden;
          }

          .MuiDataGrid-cell:focus {
            outline: none;
          }

          .MuiDataGrid-cell--withRenderer {
            overflow: visible;
            outline: none;
          }
        }

        .MuiDataGrid-row:hover {
          background: transparent;
          cursor: ${clickable ? "pointer" : "default"};

          .MuiDataGrid-cell:not(.greyed-out) {
            .visible-on-hover {
              visibility: visible;
            }
          }
        }
        .MuiDataGrid-row.Mui-selected {
          background: ${(theme.palette.secondary as Partial<Color>)[100]};
        }

        .MuiDataGrid-cellContent {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: ${theme.palette.grey[600]};
        }
      }
    }
  `
);

export const DailyDiarySectionDataGrid = (
  props: DataGridProProps<any> &
    React.RefAttributes<HTMLDivElement> & { stickyHeader?: boolean }
) => {
  return (
    <StyledDailyDiarySectionDataGrid
      {...props}
      clickable={!!props.onRowClick}
      stickyHeader={props.stickyHeader}
      getRowHeight={() => "auto"}
      localeText={{
        ...enUS.components.MuiDataGrid.defaultProps.localeText,
        ...props.localeText,
      }}
    />
  );
};
