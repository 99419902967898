import {
  AddEffectOptionInput,
  AddEffectOptionMutation,
  AddEffectOptionMutationVariables,
  ChangeEffectOptionStatusMutation,
  ChangeEffectOptionStatusMutationVariables,
  EffectOption,
  EffectOptionStatus,
} from "generated/graphql";
import {
  addEffectOptionMutation,
  changeEffectOptionStatusMutation,
} from "../../Effect.query";
import { useTranslation } from "react-i18next";
import { useGraphMutation } from "hooks/useGraphMutation";
import { OnMutationDone } from "decl";

export type useEffectPresetOptionsArgs = {
  onAdd: OnMutationDone;
  onStatusChange: OnMutationDone;
};

export const useEffectPresetOptions = ({
  onStatusChange,
  onAdd,
}: useEffectPresetOptionsArgs) => {
  const { t } = useTranslation();

  const [
    doChangeEffectOptionStatus,
    { loading: changeEffectOptionStatusLoading },
  ] = useGraphMutation<
    ChangeEffectOptionStatusMutation,
    ChangeEffectOptionStatusMutationVariables
  >(
    changeEffectOptionStatusMutation,
    {
      update: onStatusChange,
    },
    t("common.successMessages.entityUpdated", {
      entity: t("AdminConsole.Effect.labels.effectPresetOption"),
    })
  );

  const [doAddEffectOption, { loading: addEffectOptionLoading }] =
    useGraphMutation<AddEffectOptionMutation, AddEffectOptionMutationVariables>(
      addEffectOptionMutation,
      {
        update: onAdd,
      },
      t("common.successMessages.entityCreated", {
        entity: t("AdminConsole.Effect.labels.effectPresetOption"),
      })
    );

  const changeEffectPresetOptionStatus = async (
    effectOption: EffectOption,
    newStatus: EffectOptionStatus
  ) => {
    await doChangeEffectOptionStatus({
      variables: {
        id: effectOption.id,
        status: newStatus,
      },
    });
  };

  const addEffectPresetOption = async (input: AddEffectOptionInput) => {
    await doAddEffectOption({
      variables: {
        input,
      },
    });
  };

  return {
    loading: changeEffectOptionStatusLoading || addEffectOptionLoading,
    changeEffectOptionStatus: changeEffectPresetOptionStatus,
    addEffectOption: addEffectPresetOption,
  };
};
