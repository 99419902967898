import { Box, Button, Typography, useTheme } from "@mui/material";
import { CenteredLoadingIndicator } from "components/CenteredLoadingIndicator";
import { dateISOFormat } from "../../../../../../../constants";
import { DaysLate } from "containers/Projects/components/DaysLate";
import {
  CompEventItem,
  CompEventActionType,
  ProductType,
  CompEventHistoryItem,
  ClaimHistoryItem,
  VariationHistoryItem,
} from "generated/graphql";
import moment from "moment";
import { CaretRight } from "phosphor-react";
import { useCallback, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useCompEventHistory } from "../../../CompEventHistoryModal/useCompEventHistory";
import { HistoryModal } from "containers/Projects/components/HistoryModal/HistoryModal";
import { CEHistoryItemDetails } from "../../../CompEventHistoryModal/CEHistoryItemDetails";
import { CompEventWidgetContext } from "../../CompEventWidget.context";

export type CEWidgetLastStepSectionProps = {
  compEvent: CompEventItem;
  previewMode?: boolean;
};

export const actionTypeToLastActionTextMapping: Record<
  CompEventActionType,
  string
> = {
  [CompEventActionType.NotifyCompEvent]:
    "Projects.CompEvents.LastAction.NotifyCompEvent",
  [CompEventActionType.AbandonInstruction]:
    "Projects.CompEvents.LastAction.AbandonInstruction",
  [CompEventActionType.AcceptQuotation]:
    "Projects.CompEvents.LastAction.AcceptQuotation",
  [CompEventActionType.ConfirmCompEvent]:
    "Projects.CompEvents.LastAction.ConfirmCompEvent",
  [CompEventActionType.NotifyCompEventRequestQuotation]:
    "Projects.CompEvents.LastAction.NotifyCompEventRequestQuotation",
  [CompEventActionType.NotifyOwnAssessment]:
    "Projects.CompEvents.LastAction.NotifyOwnAssessment",
  [CompEventActionType.RejectCompEvent]:
    "Projects.CompEvents.LastAction.RejectCompEvent",
  [CompEventActionType.RequestMoreTime]:
    "Projects.CompEvents.LastAction.RequestMoreTime",
  [CompEventActionType.RequestRevisedQuotation]:
    "Projects.CompEvents.LastAction.RequestRevisedQuotation",
  [CompEventActionType.SubmitOwnAssessment]:
    "Projects.CompEvents.LastAction.SubmitOwnAssessment",
  [CompEventActionType.SubmitQuotation]:
    "Projects.CompEvents.LastAction.SubmitQuotation",
  [CompEventActionType.SubmitRevisedQuotation]:
    "Projects.CompEvents.LastAction.SubmitRevisedQuotation",
  [CompEventActionType.ProposedInstructionChangedDecision]:
    "Projects.CompEvents.LastAction.ProposedInstructionChangedDecision",
  [CompEventActionType.DenyRequestMoreTime]:
    "Projects.CompEvents.LastAction.DenyRequestMoreTime",
  [CompEventActionType.GrantRequestMoreTime]:
    "Projects.CompEvents.LastAction.GrantRequestMoreTime",
  [CompEventActionType.NotifySilenceCompEventNotice]:
    "Projects.CompEvents.LastAction.NotifySilenceCompEventNotice",
  [CompEventActionType.NotifySilenceOwnAssessment]:
    "Projects.CompEvents.LastAction.NotifySilenceOwnAssessment",
  [CompEventActionType.NotifySilenceQuotation]:
    "Projects.CompEvents.LastAction.NotifySilenceQuotation",
  [CompEventActionType.CompEventDeemedConfirmed]:
    "Projects.CompEvents.LastAction.CompEventDeemedConfirmed",
  [CompEventActionType.QuotationDeemedAccepted]:
    "Projects.CompEvents.LastAction.QuotationDeemedAccepted",
};

export const CEWidgetLastStepSection: React.FC<
  CEWidgetLastStepSectionProps
> = ({ compEvent, previewMode = false }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const [historyModalVisible, setHistoryModalVisible] = useState(false);
  const { contract } = useContext(CompEventWidgetContext);

  const { compEventHistoryItems, loading: compEventHistoryItemsLoading } =
    useCompEventHistory(compEvent.id);

  const toggleHistoryModal = useCallback(
    () => setHistoryModalVisible((state) => !state),
    []
  );

  return (
    <>
      {historyModalVisible && (
        <HistoryModal
          open={historyModalVisible}
          title={compEvent.number}
          historyItems={compEventHistoryItems}
          onClose={toggleHistoryModal}
          productType={ProductType.CompEvents}
          contract={contract}
          renderHistoryItemDetails={(
            historyItem:
              | CompEventHistoryItem
              | ClaimHistoryItem
              | VariationHistoryItem
          ) => (
            <CEHistoryItemDetails
              historyItem={historyItem as CompEventHistoryItem}
            />
          )}
        />
      )}
      <Box
        display="flex"
        flexDirection={previewMode ? "row" : "column"}
        alignItems={previewMode ? "center" : "flex-start"}
        width="100%"
      >
        <Box display="flex" flexDirection="column" flex={3}>
          <Typography variant="p2" fontWeight={600}>
            {t("Projects.CompEvents.lastAction")}
          </Typography>
          <Typography variant="p2" mt={0.5} data-testid="last-action">
            {t(
              actionTypeToLastActionTextMapping[compEvent.lastAction.actionType]
            )}
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          mt={previewMode ? 0 : 2}
          flex={1}
        >
          <Typography variant="p2" fontWeight={600}>
            {t("common.labels.dateSent")}
          </Typography>
          <Box
            display="flex"
            alignItems="center"
            mt={0.5}
            data-testid="date-sent"
          >
            <Typography variant="p2">
              {moment(compEvent.lastAction.date).format(dateISOFormat)}
            </Typography>
            {compEvent.lastAction.daysLate ? (
              <DaysLate daysLate={compEvent.lastAction.daysLate} ml={1.5} />
            ) : null}
          </Box>
        </Box>
        {!previewMode && (
          <Box mt={3}>
            {compEventHistoryItemsLoading ? (
              <CenteredLoadingIndicator size={20} />
            ) : (
              <Button
                variant="text"
                sx={{ padding: 0 }}
                onClick={toggleHistoryModal}
                data-testid="view-full-history-btn"
              >
                <Typography
                  variant="p2"
                  color="grey.800"
                  fontWeight={600}
                  mr={0.25}
                >
                  {t("Projects.CompEvents.viewFullHistory", {
                    itemsNo: compEventHistoryItems.length,
                  })}
                </Typography>
                <CaretRight color={theme.palette.grey[800]} size={18} />
              </Button>
            )}
          </Box>
        )}
      </Box>
    </>
  );
};
