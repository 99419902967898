import { useCallback, useContext, useEffect, useState } from "react";
import { ClaimWidgetContext } from "../../ClaimWidget/ClaimWidget.context";
import { useGraphMutation } from "hooks/useGraphMutation";
import {
  GenerateSendClaimNoticeLapsedNoticePreviewMutation,
  GenerateSendClaimNoticeLapsedNoticePreviewMutationVariables,
  SendClaimNoticeLapsedNoticePreview,
} from "generated/graphql";
import { debounce } from "@mui/material";
import { eventDebounceDuration } from "../../../../../../constants";
import { generateSendClaimNoticeLapsedNoticePreviewMutation } from "./NotifyClaimNoticeValidityHasLapsedAction.query";

export const useSendNoticeOfClaimValidityHasLapsedModal = (remarks: string) => {
  const [claimPreview, setClaimPreview] =
    useState<SendClaimNoticeLapsedNoticePreview>();

  const { claim, contract } = useContext(ClaimWidgetContext);

  const [generateClaimPreview] = useGraphMutation<
    GenerateSendClaimNoticeLapsedNoticePreviewMutation,
    GenerateSendClaimNoticeLapsedNoticePreviewMutationVariables
  >(generateSendClaimNoticeLapsedNoticePreviewMutation, {}, null);

  const loadPreview = debounce(
    useCallback(async () => {
      const { data } = await generateClaimPreview({
        variables: {
          input: {
            claimId: claim!.id,
            remarks,
          },
        },
      });

      if (data) {
        setClaimPreview(data.generateSendClaimNoticeLapsedNoticePreview);
      }
    }, [generateClaimPreview, claim, remarks]),
    eventDebounceDuration
  );

  useEffect(() => {
    loadPreview();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  return {
    claimPreview,
    contract,
  };
};
