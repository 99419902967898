import { css, styled } from "@mui/material";
import ReactFlagsSelect from "react-flags-select";

export const NewStyledCountriesSelect = styled(ReactFlagsSelect)<{
  hasErrors?: boolean;
}>(
  ({ theme, hasErrors }) => css`
    && {
      button {
        height: 56px;
        border: 1px solid ${theme.palette.grey[200]};
        border-radius: 8px;
        padding-left: ${theme.spacing(2)};
        padding-top: 0;
        padding-bottom: 0;
        transition: border-width 0.125s ease-in, border-color 0.05s ease-in;

        & > span {
          padding-left: 0;
          color: transparent;

          & span {
            color: ${theme.palette.common.black};
          }
        }

        svg {
          padding-right: ${theme.spacing(1)};
        }

        &:hover {
          border-color: black;
        }

        ${hasErrors
          ? css`
              border-color: ${theme.palette.error.main};
            `
          : ""}

        &[aria-expanded="true"] {
          border-color: ${theme.palette.primary.main};
        }
      }

      ul input {
        border: none;
        border: 1px solid #ccc;
      }
    }
  `
);

// TODO: remove when not used anymore
export const OldStyledCountriesSelect = styled(ReactFlagsSelect)<{
  hasErrors?: boolean;
}>(
  ({ theme, hasErrors }) => css`
    && {
      button {
        border: none;
        border-bottom: 1px solid rgb(118, 118, 118);
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        padding-left: 0;
        padding-top: 0;
        padding-bottom: 0;
        transition: border-bottom-width 0.125s ease-in,
          border-bottom-color 0.05s ease-in;

        & > span {
          padding-left: 0;
          color: transparent;

          & span {
            color: ${theme.palette.common.black};
          }
        }

        svg {
          padding-right: ${theme.spacing(1)};
        }

        &:hover {
          border-bottom-color: black;
        }

        ${hasErrors
          ? css`
              border-bottom-color: ${theme.palette.error.main};
            `
          : ""}

        &[aria-expanded="true"] {
          border-bottom-color: ${theme.palette.primary.main};
        }
      }

      ul input {
        border: none;
        border-bottom: 1px solid #ccc;
      }
    }
  `
);
