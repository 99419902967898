import { Typography, useTheme } from "@mui/material";
import {
  dateISOFormat,
  emptyRichTextHTML,
} from "../../../../../../../constants";
import { RichTextArea } from "components/RichTextArea/RichTextArea";
import { CompEventHistoryNoticeOfSilenceCompEventNoticeAnswers } from "generated/graphql";
import { useTranslation } from "react-i18next";
import moment from "moment";

export const NotifyOfSilenceCEHistorySummaryCommon: React.FC<
  CompEventHistoryNoticeOfSilenceCompEventNoticeAnswers
> = ({ remarks, deemedAcceptedDate }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const computedValue =
    remarks && remarks !== emptyRichTextHTML
      ? remarks
      : t("common.labels.none");

  return (
    <>
      {/* TODO: extra this title into a common cmp */}
      <Typography variant="p2" color="grey.800" fontWeight={600} mb={0.5}>
        {t("common.labels.remarks")}
      </Typography>
      <RichTextArea
        readOnly
        content={computedValue}
        color={theme.palette.grey[600]}
      />
      <Typography
        variant="p2"
        color="grey.800"
        fontWeight={600}
        mb={0.5}
        mt={2}
      >
        {t("Projects.CompEvents.deemedAcceptedDate")}
      </Typography>
      <Typography variant="p1" color="grey.600" mt={0.5}>
        {moment(deemedAcceptedDate).format(dateISOFormat)}
      </Typography>
    </>
  );
};
