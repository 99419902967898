import { ActionsDialogProps } from "components/ActionsDialog";
import { RecordProductItemExtraData } from "containers/Projects/components/RecordProductItemForm";
import { VariationActionType } from "generated/graphql";
import { RecordAction } from "../components/RecordAction";
import { useRecordObjectionToInstructionOfVariationModal } from "./useRecordObjectionToInstructionOfVariationModal";
import { useTranslation } from "react-i18next";
import { ReasonsAttachmentsSummary } from "containers/Projects/components/ActionModal/ReasonsAttachmentsSummary";
import { EnhancedAttachment } from "containers/Projects/components/Attachments/Attachments.decl";

export type RecordObjectionToInstructionOfVariationModalProps = {
  reasons: string;
  attachments?: EnhancedAttachment[];
  onPrimaryClick: (extraData: RecordProductItemExtraData) => void;
} & Omit<ActionsDialogProps, "onPrimaryClick">;

export const RecordObjectionToInstructionOfVariationModal: React.FC<
  RecordObjectionToInstructionOfVariationModalProps
> = ({ reasons, attachments = [], ...restDialogProps }) => {
  const { t } = useTranslation();
  const { variationPreview } =
    useRecordObjectionToInstructionOfVariationModal(reasons);
  return (
    <RecordAction
      actionType={VariationActionType.ObjectToInstructionOfVariation}
      variationPreview={variationPreview}
      summaryEl={
        <ReasonsAttachmentsSummary
          text={reasons}
          label={t("common.labels.reasons")}
          attachments={attachments}
        />
      }
      {...restDialogProps}
    />
  );
};
