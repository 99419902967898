import {
  Box,
  CircularProgress,
  styled,
  Typography,
  useTheme,
} from "@mui/material";
import { ChangeEventHandler, useState, useEffect } from "react";
import { ExplorerEntity } from "../Explorer.decl";
import { ListItem } from "./ListItem";
import { SearchBox } from "../../../../../components/SearchBox";
import { Status } from "decl";

export type FlyoutProps<T extends ExplorerEntity> = {
  items: T[];
  selectedEntityId?: string;
  leftHeader: string;
  rightHeader?: string;
  loading?: boolean;
  onSelectionChange: (item: T) => void;
};

const FlyoutContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  min-width: 450px;
`;

export const Flyout = <T extends ExplorerEntity>({
  leftHeader,
  rightHeader,
  items,
  selectedEntityId,
  loading,
  onSelectionChange,
}: FlyoutProps<T>) => {
  const theme = useTheme();

  const [searchQuery, setSearchQuery] = useState("");
  const [localItems, setLocalItems] = useState(items ?? []);

  const handleSearchBoxChange: ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = (evt) => {
    setSearchQuery(evt.target.value);
    setLocalItems(
      items.filter((item) =>
        item.listItemLeftText
          .toLowerCase()
          .includes((evt.target.value || "").toLowerCase())
      )
    );
  };

  useEffect(() => {
    setLocalItems(items ?? []);
  }, [items]);

  return (
    <FlyoutContainer
      display="flex"
      flexDirection="column"
      pb={1}
      data-testid="explorer-flyout"
    >
      <Box px={2} pt={2}>
        <SearchBox
          placeholder="Search"
          value={searchQuery}
          onChange={handleSearchBoxChange}
        />
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        px={2}
        py={2}
      >
        <Typography
          variant="caption3"
          textTransform="uppercase"
          color={theme.palette.grey[500]}
        >
          {leftHeader}
        </Typography>
        {rightHeader && (
          <Typography
            variant="caption3"
            textTransform="uppercase"
            color={theme.palette.grey[500]}
          >
            {rightHeader}
          </Typography>
        )}
      </Box>
      <Box overflow="auto" maxHeight="300px">
        {loading && (
          <Box display="flex" justifyContent="center" py={2}>
            <CircularProgress size="20px" />
          </Box>
        )}
        {localItems.map((item) => (
          <ListItem
            key={item.id}
            status={item.status as any as Status}
            mainText={item.listItemLeftText}
            rightText={item.listItemRightText}
            selected={item.id === selectedEntityId}
            onSelect={(evt) => {
              evt.stopPropagation();
              onSelectionChange(item);
            }}
          />
        ))}
      </Box>
    </FlyoutContainer>
  );
};
