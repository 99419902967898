import {
  GenerateSendVariationAgreementNoticePreviewMutation,
  GenerateSendVariationAgreementNoticePreviewMutationVariables,
  SendVariationAgreementNoticePreview,
} from "generated/graphql";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useCallback, useContext, useEffect, useState } from "react";
import { debounce } from "@mui/material";
import { eventDebounceDuration } from "../../../../../../constants";
import { VariationWidgetContext } from "../../VariationWidget/VariationWidget.context";
import { generateSendVariationAgreementNoticePreviewMutation } from "./NotifyAgreementOfVariationAction.query";

export const useSendNotifyAgreementOfVariationModal = (
  draftVariationAgreementId: string
) => {
  const { variation, contract } = useContext(VariationWidgetContext);
  const [variationPreview, setVariationPreview] =
    useState<SendVariationAgreementNoticePreview>();

  const [generatePreview] = useGraphMutation<
    GenerateSendVariationAgreementNoticePreviewMutation,
    GenerateSendVariationAgreementNoticePreviewMutationVariables
  >(generateSendVariationAgreementNoticePreviewMutation, {}, null);

  const loadPreview = debounce(
    useCallback(async () => {
      const { data } = await generatePreview({
        variables: {
          input: {
            variationId: variation!.id,
            draftVariationAgreementId,
          },
        },
      });

      if (data) {
        setVariationPreview(data.generateSendVariationAgreementNoticePreview);
      }
    }, [generatePreview, variation, draftVariationAgreementId]),
    eventDebounceDuration
  );

  useEffect(() => {
    loadPreview();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  return {
    variationPreview,
    contract,
  };
};
