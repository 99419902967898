import { Box, Typography, Tooltip, Button } from "@mui/material";
import { InfoLiteIcon } from "components/Icons/InfoLiteIcon";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AuthPageHeader } from "../components/AuthPageHeader";
import ReactInputVerificationCode from "react-input-verification-code";
import { ResetPasswordFooter } from "../components/ResetPasswordFooter";
import { StyledCodeVerificationContainer } from "./SetNewPassword.styled";

const securityCodeLength = 6;

export const SecurityCodeValidationView: React.FC<{
  initialSecurityCode?: string;
  onEnterSecurityCode: (code: string) => void;
  onResendCode: () => void;
}> = ({ initialSecurityCode, onEnterSecurityCode, onResendCode }) => {
  const { t } = useTranslation();
  const [securityCode, setSecurityCode] = useState<string>(
    initialSecurityCode ?? ""
  );

  const handleSecurityCodeChange = (newSecurityCode: string) => {
    if (newSecurityCode.length === securityCodeLength) {
      setSecurityCode(newSecurityCode);
    }
  };

  const handleSendSecurityCode = () => {
    onEnterSecurityCode(securityCode);
  };

  useEffect(() => {
    setSecurityCode(initialSecurityCode ?? "");
  }, [initialSecurityCode]);

  return (
    <Box display="flex" flexDirection="column" maxWidth="420px">
      <AuthPageHeader
        title={t("ResetPassword.resetYourPassword")}
        subtitle={t("ResetPassword.checkInbox2")}
      />
      <Box mt={7} display="flex" alignItems="center">
        <Typography variant="p1" fontWeight={600} mr={1}>
          {t("ResetPassword.securityCode")}
        </Typography>
        <Tooltip
          title={
            <Box p={1}>
              <Typography variant="p2">
                {t("ResetPassword.infoTooltip")}
              </Typography>
            </Box>
          }
          arrow
        >
          <Box>
            <InfoLiteIcon />
          </Box>
        </Tooltip>
      </Box>
      <StyledCodeVerificationContainer mt={1.5}>
        <ReactInputVerificationCode
          length={securityCodeLength}
          type="text"
          value={securityCode}
          onChange={handleSecurityCodeChange}
          placeholder=""
          autoFocus
        />
      </StyledCodeVerificationContainer>
      <Box mt={2}>
        <Button variant="text" onClick={onResendCode} sx={{ padding: 0 }}>
          <Typography variant="p2" fontWeight={600} color="primary">
            {t("ResetPassword.resendCode")}
          </Typography>
        </Button>
      </Box>
      <Box mt={6}>
        <Button
          variant="contained"
          onClick={handleSendSecurityCode}
          size="large"
          fullWidth
        >
          <Typography variant="p2" fontWeight={700}>
            {t("ResetPassword.enterSecurityCode")}
          </Typography>
        </Button>
      </Box>
      <Box mt={3}>
        <ResetPasswordFooter />
      </Box>
    </Box>
  );
};
