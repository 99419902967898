import { Box, Dialog, DialogProps, Grid, Typography } from "@mui/material";
import { HeaderContainer } from "containers/Projects/components/FullScreenProductItemPreview";
import { DailyDiaryRecordSummary } from "./DailyDiaryRecordSummary";
import { Attachment } from "generated/graphql";
import { FileGridItemNewReadOnly } from "containers/Projects/components/Attachments/components/FileGridItem/FileGridItemNewReadOnly";
import { useMemo, useRef, useState } from "react";
import { AttachmentEnlargedView } from "./AttachmentEnlargedView";
import { FileMIMEType } from "containers/Projects/components/Attachments/Attachments.decl";
import { useArrowsNavigation } from "../../../../../../hooks/useArrowsNavigation";
import { stringToFileMIMEType } from "containers/Projects/components/Attachments/Attachments.utils";
import { useAttachments } from "containers/Projects/components/Attachments/hooks/useAttachments";

type LocalAttachmentExtras = {
  mimeTypeEnum: FileMIMEType;
  previewUrl?: string;
};

export type DailyDiaryRecordAttachmentsModalProps = {
  recordJSON: Record<string, string>;
  attachments: Attachment[];
} & DialogProps;

export const DailyDiaryRecordAttachmentsModal: React.FC<
  DailyDiaryRecordAttachmentsModalProps
> = ({ open, title, attachments, recordJSON, onClose, ...restProps }) => {
  const [selectedAttachment, setSelectedAttachment] = useState<Attachment>();
  const attachmentsExtrasRef = useRef<Map<string, LocalAttachmentExtras>>(
    new Map()
  );

  const { downloadAttachment } = useAttachments(attachments);

  const imageAttachments = useMemo(
    () =>
      attachments.filter(
        (attach) => stringToFileMIMEType(attach.mimeType) === FileMIMEType.Img
      ),
    [attachments]
  );

  const selectedAttachmentExtras = useMemo(
    () =>
      selectedAttachment
        ? attachmentsExtrasRef.current.get(selectedAttachment.id)
        : null,
    [selectedAttachment]
  );

  const handleSeePreviousAttachment = () => {
    if (!selectedAttachment) {
      // this should never happen though, because we're in the enlarged view, which means there is a selected attachment
      return;
    }

    const crtAttachmentIndex = imageAttachments.findIndex(
      (crtAttch) => crtAttch.id === selectedAttachment.id
    );
    setSelectedAttachment(
      crtAttachmentIndex === 0
        ? imageAttachments.at(-1)
        : imageAttachments.at(crtAttachmentIndex - 1)
    );
  };
  const handleSeeNextAttachment = () => {
    if (!selectedAttachment) {
      // this should never happen though, because we're in the enlarged view, which means there is a selected attachment
      return;
    }

    const crtAttachmentIndex = imageAttachments.findIndex(
      (crtAttch) => crtAttch.id === selectedAttachment.id
    );
    setSelectedAttachment(
      crtAttachmentIndex === imageAttachments.length - 1
        ? imageAttachments.at(0)
        : imageAttachments.at(crtAttachmentIndex + 1)
    );
  };

  const handleImageClick = () => {
    downloadAttachment(selectedAttachment!);
  };

  const handleGridAttachmentClick = (attachment: Attachment) => {
    if (stringToFileMIMEType(attachment.mimeType) === FileMIMEType.Img) {
      setSelectedAttachment(attachment);
    } else {
      downloadAttachment(attachment!);
    }
  };

  useArrowsNavigation(handleSeePreviousAttachment, handleSeeNextAttachment);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="attachments-dialog"
      aria-describedby="attachments-dialog"
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "1344px", // custom width to match 4 item tiles per row
          },
        },
      }}
      {...restProps}
    >
      <Box
        display="flex"
        flexDirection="column"
        boxSizing="border-box"
        px={6}
        pb={5}
      >
        <HeaderContainer sx={{ paddingBottom: 4 }}>
          <Typography variant="h3" color="grey.900">
            {title}
          </Typography>
        </HeaderContainer>

        <Box width="100%" sx={{ background: "white" }}>
          <DailyDiaryRecordSummary recordJSON={recordJSON} />
          {selectedAttachment && selectedAttachmentExtras ? (
            <AttachmentEnlargedView
              attachment={selectedAttachment}
              hasMore={imageAttachments.length > 1}
              mimeType={selectedAttachmentExtras.mimeTypeEnum}
              previewUrl={selectedAttachmentExtras.previewUrl}
              onBack={() => setSelectedAttachment(undefined)}
              onPrevious={handleSeePreviousAttachment}
              onNext={handleSeeNextAttachment}
              onClick={handleImageClick}
            />
          ) : (
            <Grid
              container
              spacing={3}
              mt={1}
              display="grid"
              gridTemplateColumns="repeat(auto-fit, 314px)"
            >
              {attachments.map((attachment) => (
                <Grid item key={attachment.id}>
                  <FileGridItemNewReadOnly
                    file={attachment}
                    onClick={() => handleGridAttachmentClick(attachment)}
                    onLoaded={(mimeType, previewUrl) => {
                      attachmentsExtrasRef.current.set(attachment.id, {
                        mimeTypeEnum: mimeType,
                        previewUrl,
                      });
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          )}
        </Box>
      </Box>
    </Dialog>
  );
};
