import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { StatusOption, StatusTag } from "components/StatusTag/StatusTag";
import { DocumentTemplate, DocumentTemplateStatus } from "generated/graphql";
import {
  statusComparatorFunction,
  useDateCreatedColumnConfig,
} from "helpers/dataGrid.helpers";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { getUserName } from "helpers/miscelaneous";

export const useColumns = (
  statusOptions: StatusOption<DocumentTemplateStatus>[],
  onStatusChange: (
    row: DocumentTemplate,
    newStatus: DocumentTemplateStatus
  ) => void
): GridColDef<DocumentTemplate>[] => {
  const { t } = useTranslation();
  const dateCreatedColConfig = useDateCreatedColumnConfig();
  const columns = useMemo(
    () =>
      [
        {
          field: "name",
          headerName: t("common.labels.name"),
          flex: 0.15,
          minWidth: 190,
          resizable: true,
        },
        {
          field: "status",
          headerName: t("common.labels.status"),
          flex: 0.125,
          minWidth: 90,
          resizable: true,
          sortComparator: statusComparatorFunction,
          renderCell: (
            params: GridRenderCellParams<any, DocumentTemplate, any>
          ) => {
            return (
              <StatusTag
                status={params.row.status}
                options={statusOptions}
                onChange={(newStatus: StatusOption<DocumentTemplateStatus>) =>
                  onStatusChange(params.row, newStatus.id)
                }
              />
            );
          },
        },
        dateCreatedColConfig,
        {
          field: "creator",
          headerName: t("common.labels.createdBy"),
          flex: 0.125,
          width: 130,
          resizable: true,
          valueGetter: (params) => {
            return getUserName(params.row.creator);
          },
        },
      ] as GridColDef<DocumentTemplate>[],
    [dateCreatedColConfig, t, statusOptions, onStatusChange]
  );
  return columns;
};
