import {
  ClaimActionType,
  CompEventActionType,
  VariationActionType,
} from "generated/graphql";
import { HistoryItemStatus } from "./HistoryListItem";
import {
  VariationActionTypeAll,
  VariationActionTypeFIDIC17White,
  VariationActionTypeFIDIC99RedYellow,
} from "../Variations/Variations.types";
import {
  ClaimActionTypeAll,
  ClaimActionTypeFIDIC99RedYellow,
} from "../Claims/types";

// CE
export const ceActionTypesToItemStatusMapping: Record<
  CompEventActionType,
  HistoryItemStatus
> = {
  [CompEventActionType.AbandonInstruction]: HistoryItemStatus.Failure,
  [CompEventActionType.AcceptQuotation]: HistoryItemStatus.Success,
  [CompEventActionType.ConfirmCompEvent]: HistoryItemStatus.Success,
  [CompEventActionType.NotifyCompEvent]: HistoryItemStatus.Success,
  [CompEventActionType.NotifyCompEventRequestQuotation]:
    HistoryItemStatus.Success,
  [CompEventActionType.NotifyOwnAssessment]: HistoryItemStatus.Success,
  [CompEventActionType.RejectCompEvent]: HistoryItemStatus.Failure,
  [CompEventActionType.RequestMoreTime]: HistoryItemStatus.RequestMoreTime,
  [CompEventActionType.RequestRevisedQuotation]: HistoryItemStatus.Success,
  [CompEventActionType.SubmitOwnAssessment]: HistoryItemStatus.Success,
  [CompEventActionType.SubmitQuotation]: HistoryItemStatus.Success,
  [CompEventActionType.SubmitRevisedQuotation]: HistoryItemStatus.Success,
  [CompEventActionType.DenyRequestMoreTime]: HistoryItemStatus.Failure,
  [CompEventActionType.GrantRequestMoreTime]: HistoryItemStatus.Success,
  [CompEventActionType.ProposedInstructionChangedDecision]:
    HistoryItemStatus.Success,
  [CompEventActionType.NotifySilenceCompEventNotice]: HistoryItemStatus.Failure,
  [CompEventActionType.NotifySilenceOwnAssessment]: HistoryItemStatus.Failure,
  [CompEventActionType.NotifySilenceQuotation]: HistoryItemStatus.Failure,
  [CompEventActionType.CompEventDeemedConfirmed]: HistoryItemStatus.Success,
  [CompEventActionType.QuotationDeemedAccepted]: HistoryItemStatus.Success,
};

export const ceActionTypeToHistoryItemTextMapping: Record<
  CompEventActionType,
  string
> = {
  [CompEventActionType.NotifyCompEvent]:
    "Projects.CompEvents.ActionHistory.NotifyCompEvent",
  [CompEventActionType.AbandonInstruction]:
    "Projects.CompEvents.ActionHistory.AbandonInstruction",
  [CompEventActionType.AcceptQuotation]:
    "Projects.CompEvents.ActionHistory.AcceptQuotation",
  [CompEventActionType.ConfirmCompEvent]:
    "Projects.CompEvents.ActionHistory.ConfirmCompEvent",
  [CompEventActionType.NotifyCompEventRequestQuotation]:
    "Projects.CompEvents.ActionHistory.NotifyCompEventRequestQuotation",
  [CompEventActionType.NotifyOwnAssessment]:
    "Projects.CompEvents.ActionHistory.NotifyOwnAssessment",
  [CompEventActionType.RejectCompEvent]:
    "Projects.CompEvents.ActionHistory.RejectCompEvent",
  [CompEventActionType.RequestMoreTime]:
    "Projects.CompEvents.ActionHistory.RequestMoreTime",
  [CompEventActionType.RequestRevisedQuotation]:
    "Projects.CompEvents.ActionHistory.RequestRevisedQuotation",
  [CompEventActionType.SubmitOwnAssessment]:
    "Projects.CompEvents.ActionHistory.SubmitOwnAssessment",
  [CompEventActionType.SubmitQuotation]:
    "Projects.CompEvents.ActionHistory.SubmitQuotation",
  [CompEventActionType.SubmitRevisedQuotation]:
    "Projects.CompEvents.ActionHistory.SubmitRevisedQuotation",
  [CompEventActionType.ProposedInstructionChangedDecision]:
    "Projects.CompEvents.ActionHistory.ProposedInstructionChangedDecision",
  [CompEventActionType.DenyRequestMoreTime]:
    "Projects.CompEvents.ActionHistory.DenyRequestMoreTime",
  [CompEventActionType.GrantRequestMoreTime]:
    "Projects.CompEvents.ActionHistory.GrantRequestMoreTime",
  [CompEventActionType.NotifySilenceCompEventNotice]:
    "Projects.CompEvents.ActionHistory.NotifySilenceCompEventNotice",
  [CompEventActionType.NotifySilenceOwnAssessment]:
    "Projects.CompEvents.ActionHistory.NotifySilenceOwnAssessment",
  [CompEventActionType.NotifySilenceQuotation]:
    "Projects.CompEvents.ActionHistory.NotifySilenceQuotation",
  [CompEventActionType.CompEventDeemedConfirmed]:
    "Projects.CompEvents.ActionHistory.CompEventDeemedConfirmed",
  [CompEventActionType.QuotationDeemedAccepted]:
    "Projects.CompEvents.ActionHistory.QuotationDeemedAccepted",
};

// Claims
export const claimActionTypeToHistoryItemTextMapping: Record<
  ClaimActionTypeAll,
  string
> = {
  [ClaimActionType.NotifyClaim]: "Projects.Claims.ActionHistory.NotifyClaim",
  [ClaimActionTypeFIDIC99RedYellow.NotifyClaim99RY]:
    "Projects.Claims.ActionHistory.NotifyClaim99RY",
  [ClaimActionType.SubmitInterimDetailedClaim]:
    "Projects.Claims.ActionHistory.SubmitInterimDetailedClaim",
  [ClaimActionTypeFIDIC99RedYellow.SubmitInterimDetailedClaim99RY]:
    "Projects.Claims.ActionHistory.SubmitInterimDetailedClaim99RY",
  [ClaimActionType.SubmitFinalDetailedClaim]:
    "Projects.Claims.ActionHistory.SubmitFinalDetailedClaim",
  [ClaimActionTypeFIDIC99RedYellow.SubmitFinalDetailedClaim99RY]:
    "Projects.Claims.ActionHistory.SubmitFinalDetailedClaim99RY",
  [ClaimActionType.NotifyAgreementClaim]:
    "Projects.Claims.ActionHistory.NotifyAgreementClaim",
  [ClaimActionTypeFIDIC99RedYellow.NotifyAgreementClaim99RY]:
    "Projects.Claims.ActionHistory.NotifyAgreementClaim99RY",
  [ClaimActionType.NotifyAgreementClaimNotReached]:
    "Projects.Claims.ActionHistory.NotifyAgreementClaimNotReached",
  [ClaimActionTypeFIDIC99RedYellow.NotifyAgreementClaimNotReached99RY]:
    "Projects.Claims.ActionHistory.NotifyAgreementClaimNotReached99RY",
  [ClaimActionType.NotifyDeterminationClaim]:
    "Projects.Claims.ActionHistory.NotifyDeterminationClaim",
  [ClaimActionTypeFIDIC99RedYellow.NotifyDeterminationClaim99RY]:
    "Projects.Claims.ActionHistory.NotifyDeterminationClaim99RY",
  [ClaimActionType.ProvideFurtherParticulars]:
    "Projects.Claims.ActionHistory.ProvideFurtherParticulars",
  [ClaimActionTypeFIDIC99RedYellow.ProvideFurtherParticulars99RY]:
    "Projects.Claims.ActionHistory.ProvideFurtherParticulars99RY",
  [ClaimActionType.RequestFurtherParticulars]:
    "Projects.Claims.ActionHistory.RequestFurtherParticulars",
  [ClaimActionTypeFIDIC99RedYellow.RequestFurtherParticulars99RY]:
    "Projects.Claims.ActionHistory.RequestFurtherParticulars99RY",
  [ClaimActionType.NotifyClaimNoticeInvalid]:
    "Projects.Claims.ActionHistory.NotifyClaimNoticeInvalid",
  [ClaimActionTypeFIDIC99RedYellow.NotifyClaimNoticeInvalid99RY]:
    "Projects.Claims.ActionHistory.NotifyClaimNoticeInvalid99RY",
  [ClaimActionType.NotifyClaimNoticeValidityHasLapsed]:
    "Projects.Claims.ActionHistory.NotifyClaimNoticeValidityHasLapsed",
  [ClaimActionTypeFIDIC99RedYellow.NotifyClaimNoticeValidityHasLapsed99RY]:
    "Projects.Claims.ActionHistory.NotifyClaimNoticeValidityHasLapsed99RY",
  [ClaimActionType.RespondRegardingDetailedClaim]:
    "Projects.Claims.ActionHistory.RespondRegardingDetailedClaim",
  [ClaimActionTypeFIDIC99RedYellow.RespondRegardingDetailedClaim99RY]:
    "Projects.Claims.ActionHistory.RespondRegardingDetailedClaim99RY",
  [ClaimActionType.NotifyErrorFoundAgreement]:
    "Projects.Claims.ActionHistory.NotifyErrorFoundAgreement",
  [ClaimActionType.DisagreeWithNoticeErrorAgreement]:
    "Projects.Claims.ActionHistory.DisagreeWithNoticeErrorAgreement",
  [ClaimActionType.NotifyErrorFoundDetermination]:
    "Projects.Claims.ActionHistory.NotifyErrorFoundDetermination",
  [ClaimActionType.DisagreeWithNoticeErrorDetermination]:
    "Projects.Claims.ActionHistory.DisagreeWithNoticeErrorDetermination",
} as any; // TODO

export const claimActionTypesToItemStatusMapping: Record<
  ClaimActionType,
  HistoryItemStatus
> = {
  [ClaimActionType.NotifyClaim]: HistoryItemStatus.Success,
  [ClaimActionType.SubmitInterimDetailedClaim]: HistoryItemStatus.Success,
  [ClaimActionType.SubmitFinalDetailedClaim]: HistoryItemStatus.Success,
  [ClaimActionType.NotifyAgreementClaim]: HistoryItemStatus.Success,
  [ClaimActionType.NotifyAgreementClaimNotReached]:
    HistoryItemStatus.NoAgreement,
  [ClaimActionType.NotifyDeterminationClaim]: HistoryItemStatus.Success,
  [ClaimActionType.RequestFurtherParticulars]: HistoryItemStatus.MoreDetails,
  [ClaimActionType.ProvideFurtherParticulars]: HistoryItemStatus.Success,
  [ClaimActionType.NotifyClaimNoticeInvalid]: HistoryItemStatus.Failure,
  [ClaimActionType.NotifyClaimNoticeValidityHasLapsed]:
    HistoryItemStatus.Failure,
  [ClaimActionType.RespondRegardingDetailedClaim]:
    HistoryItemStatus.MoreDetails,
  [ClaimActionType.NotifyErrorFoundAgreement]: HistoryItemStatus.Failure,
  [ClaimActionType.DisagreeWithNoticeErrorAgreement]: HistoryItemStatus.Success,
  [ClaimActionType.NotifyErrorFoundDetermination]: HistoryItemStatus.Failure,
  [ClaimActionType.DisagreeWithNoticeErrorDetermination]:
    HistoryItemStatus.Success,
} as any;

// Variations
export const variationActionTypeToHistoryItemTextMapping: Record<
  VariationActionTypeAll,
  string
> = {
  [VariationActionType.SubmitProposalForVariation]:
    "Projects.Variations.ActionHistory.SubmitProposalForVariation",
  [VariationActionType.ConsentToProposalForProposedVariation]:
    "Projects.Variations.ActionHistory.ConsentToProposalForProposedVariation",
  [VariationActionTypeFIDIC99RedYellow.ConsentToProposalForProposedVariation99RY]:
    "Projects.Variations.ActionHistory.ConsentToProposalForProposedVariation99RY",
  [VariationActionType.SubmitProposalForProposedVariation]:
    "Projects.Variations.ActionHistory.SubmitProposalForProposedVariation",
  [VariationActionType.GiveReasonsWhyProposalCannotBeGivenForProposedVariation]:
    "Projects.Variations.ActionHistory.GiveReasonsWhyProposalCannotBeGivenForProposedVariation",
  [VariationActionType.NotifyAgreementVariation]:
    "Projects.Variations.ActionHistory.NotifyAgreementVariation",
  [VariationActionType.NotifyAgreementVariationNotReached]:
    "Projects.Variations.ActionHistory.NotifyAgreementVariationNotReached",
  [VariationActionType.RequestRevisedProposalForProposedVariation]:
    "Projects.Variations.ActionHistory.RequestRevisedProposalForProposedVariation",
  [VariationActionType.NotifyDeterminationVariation]:
    "Projects.Variations.ActionHistory.NotifyDeterminationVariation",
  [VariationActionType.AbandonProposedVariation]:
    "Projects.Variations.ActionHistory.AbandonProposedVariation",
  [VariationActionTypeFIDIC99RedYellow.AbandonProposedVariation99RY]:
    "Projects.Variations.ActionHistory.AbandonProposedVariation99RY",
  [VariationActionType.RevokeInstructionOfVariation]:
    "Projects.Variations.ActionHistory.RevokeInstructionOfVariation",
  [VariationActionTypeFIDIC17White.RevokeInstructionOfVariationFIDIC17White]:
    "Projects.Variations.ActionHistory.RevokeInstructionOfVariationFIDIC17White",
  [VariationActionTypeFIDIC17White.AbandonProposedVariationFIDIC17White]:
    "Projects.Variations.ActionHistory.AbandonProposedVariationFIDIC17White",
  [VariationActionTypeFIDIC17White.ConsentToProposalForProposedVariationFIDIC17White]:
    "Projects.Variations.ActionHistory.ConsentToProposalForProposedVariationFIDIC17White",
  [VariationActionTypeFIDIC17White.ConfirmInstructionOfVariationFIDIC17White]:
    "Projects.Variations.ActionHistory.ConfirmInstructionOfVariationFIDIC17White",
  [VariationActionType.ObjectToInstructionOfVariation]:
    "Projects.Variations.ActionHistory.ObjectToInstructionOfVariation",
  [VariationActionType.ConfirmInstructionOfVariation]:
    "Projects.Variations.ActionHistory.ConfirmInstructionOfVariation",
  [VariationActionType.InstructWorkToCommenceOnVariation]:
    "Projects.Variations.ActionHistory.InstructWorkToCommenceOnVariation",
} as any; // TODO

export const variationActionTypesToItemStatusMapping: Record<
  VariationActionTypeAll,
  HistoryItemStatus
> = {
  [VariationActionType.SubmitProposalForVariation]: HistoryItemStatus.Success,
  [VariationActionType.SubmitProposalForProposedVariation]:
    HistoryItemStatus.Success,
  [VariationActionType.ConsentToProposalForProposedVariation]:
    HistoryItemStatus.Success,
  [VariationActionType.GiveReasonsWhyProposalCannotBeGivenForProposedVariation]:
    HistoryItemStatus.Failure,
  [VariationActionType.NotifyAgreementVariation]: HistoryItemStatus.Success,
  [VariationActionType.NotifyAgreementVariationNotReached]:
    HistoryItemStatus.NoAgreement,
  [VariationActionType.RequestRevisedProposalForProposedVariation]:
    HistoryItemStatus.Success,
  [VariationActionType.NotifyDeterminationVariation]: HistoryItemStatus.Success,
  [VariationActionType.AbandonProposedVariation]: HistoryItemStatus.Failure,
  [VariationActionType.RevokeInstructionOfVariation]: HistoryItemStatus.Failure,
  [VariationActionType.ObjectToInstructionOfVariation]:
    HistoryItemStatus.Failure,
  [VariationActionType.ConfirmInstructionOfVariation]:
    HistoryItemStatus.Success,
  [VariationActionType.InstructWorkToCommenceOnVariation]:
    HistoryItemStatus.Success,
} as any;
