// TODO: localize these error messages
export enum FIELD_VALIDATION_ERRORS {
  InvalidField = "Invalid field",
  RequiredField = "Required field",
  PasswordsNotMatching = "Passwords are not matching",
  NotANumber = "Field must be a number",
  PositiveNumber = "Field must be a positive number",
  PositiveNumberOrZero = "Field must be a positive number or 0",
  NonEmptyArray = "Please add at least one record",
  InvalidPastDate = "Value must be a valid date in the past",
  OptionNotValid = "The option you had selected is no longer available. Please choose a different option.",
}

export const temporaryRowId = "temp";

export const eventDebounceDuration = 200;
export const supportMail = "support@ccom.cloud";

/**
 * length: 8-80 chars
 * at least 1 number
 * at least 1 special character
 * at least 1 uppercase letter
 * at least 1 lowercase letter
 */
export const defaultCognitoPasswordRequirementsRegex =
  /^(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])(?=.*\d)(?=.*[A-Z])(?=.*[a-z])[A-Za-z\d!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]{8,80}$/;

export const emptyRichTextHTML = '<p class="editor-paragraph"><br></p>';

export const dateISOFormat = "YYYY-MM-DD"; // TODO: reuse across all app
export const dateTimeISOFormat = "YYYY-MM-DD HH:mm:ss";
export const AWSDateTimeFormat = "YYYY-MM-DDThh:mm:ss.sssZ";

export const tooltipEnterDelay = 750;

export enum CommonTabs {
  All = "all",
  Drafts = "drafts",
}

export const putIntoEffectEnumValue = "PUT_INTO_EFFECT";
export const provideQuotationEnumValue = "PROVIDE_QUOTATION";

export const snackbarAutoHideDuration = 5000;

export const EFFECT_SECTION_NAME = "Effect";
