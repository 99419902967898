import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { FormPublicApi } from "decl";
import {
  AddCompanyLookupCollectionInput,
  Company,
  CompanyLookupCollection,
  CompanyLookupCollectionUsage,
} from "generated/graphql";
import { validateData } from "helpers/validators";
import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { UsageToTranslation } from "../CustomSimpleLookups.constants";
import { dataValidators } from "./CustomLookupCollectionForm.constants";

export type CustomLookupCollectionFormProps = {
  lookupCollection?: CompanyLookupCollection;
  apiRef: React.Ref<FormPublicApi>;
  companies: Company[];
  onChange?: (
    lookupCollectionData:
      | AddCompanyLookupCollectionInput
      | CompanyLookupCollection
  ) => void;
};

export const defaultLookupCollection: AddCompanyLookupCollectionInput = {
  name: "",
  optionsLockedForUsers: true,
  optionsCharLengthLimit: "" as any,
  usage: CompanyLookupCollectionUsage.ContractBindings,
  companyId: "",
};

export const CustomLookupCollectionForm: React.FC<
  CustomLookupCollectionFormProps
> = ({ lookupCollection, companies, apiRef, onChange }) => {
  const { t } = useTranslation();

  const [formData, setFormData] = useState<
    AddCompanyLookupCollectionInput | CompanyLookupCollection
  >(lookupCollection || defaultLookupCollection);
  const [formDataErrors, setFormDataErrors] = useState<{
    [key: string]: string;
  }>({});

  const handleTextFieldChange: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = (evt) => {
    setFormData((curData) => ({
      ...curData,
      [evt.target.name]: evt.target.value,
    }));

    setFormDataErrors((curFormDataErrs) => {
      const { [evt.target.name]: _, ...rest } = curFormDataErrs;

      return rest;
    });
  };

  const handleCheckboxChange = (
    evt: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setFormData((curData) => ({
      ...curData,
      [evt.target.name]: checked,
    }));
  };

  const handleCompanyChange = (event: SelectChangeEvent<string | null>) => {
    setFormData((curData) => ({
      ...curData,
      companyId: event.target.value ?? "",
    }));
  };

  const handleUsageChange = (
    event: SelectChangeEvent<CompanyLookupCollectionUsage | null>
  ) => {
    setFormData((curData) => ({
      ...curData,
      usage: event.target.value as CompanyLookupCollectionUsage,
    }));
  };

  const validateForm = useCallback(
    (formData: AddCompanyLookupCollectionInput | CompanyLookupCollection) => {
      const validationResult = validateData(formData, dataValidators);

      if (validationResult.valid) {
        setFormDataErrors({});
        return true;
      }
      setFormDataErrors(validationResult.errors);
      return false;
    },
    []
  );

  const reset = useCallback(() => {
    setFormData(defaultLookupCollection);
  }, []);

  useEffect(() => {
    onChange?.({
      ...formData,
      optionsCharLengthLimit: formData.optionsCharLengthLimit || undefined,
    });
  }, [formData, onChange]);

  useEffect(() => {
    setFormData(lookupCollection || defaultLookupCollection);
  }, [lookupCollection]);

  useImperativeHandle(
    apiRef,
    () => ({
      validate: () => validateForm(formData),
      reset,
    }),
    [validateForm, formData, reset]
  );

  return (
    <>
      <Grid container spacing={6}>
        <Grid item md={6} xs={12}>
          <TextField
            fullWidth
            name="name"
            value={formData.name}
            onChange={handleTextFieldChange}
            type="text"
            label={t("common.labels.name")}
            variant="standard"
            error={!!formDataErrors.name}
            helperText={formDataErrors.name}
            InputLabelProps={{ shrink: true }}
            required
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <FormControlLabel
            label={
              <Typography variant="caption">
                {`${t("AdminConsole.Lookups.optionsLockedForUsers")}`}
              </Typography>
            }
            labelPlacement="end"
            control={
              <Checkbox
                inputProps={{
                  "aria-label": `${t(
                    "AdminConsole.Lookups.optionsLockedForUsers"
                  )}`,
                }}
                name="optionsLockedForUsers"
                onChange={handleCheckboxChange}
                checked={!!formData.optionsLockedForUsers}
                required
              />
            }
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            fullWidth
            type="number"
            name="optionsCharLengthLimit"
            value={formData.optionsCharLengthLimit}
            onChange={handleTextFieldChange}
            label={t("AdminConsole.Lookups.optionsCharLengthLimit")}
            variant="standard"
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <FormControl variant="standard" sx={{ minWidth: 120 }} fullWidth>
            <>
              <InputLabel id="company-select-label" shrink>
                <Typography
                  color={formDataErrors.companyId ? "error" : "inherit"}
                >{`${t("common.labels.company")} *`}</Typography>
              </InputLabel>
              <Select
                labelId="company-select-label"
                value={formData.companyId}
                onChange={handleCompanyChange}
                label={t("common.labels.company")}
                error={!!formDataErrors.companyId}
                required
              >
                {companies.map((company) => (
                  <MenuItem key={company.id} value={company.id}>
                    {company.tradingName}
                  </MenuItem>
                ))}
              </Select>
              {!!formDataErrors.companyId && (
                <Typography variant="caption" color="error" mt={0.5}>
                  {formDataErrors.companyId}
                </Typography>
              )}
            </>
          </FormControl>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormControl variant="standard" sx={{ minWidth: 120 }} fullWidth>
            <>
              <InputLabel id="usage-select-label" shrink>
                <Typography
                  color={formDataErrors.usage ? "error" : "inherit"}
                >{`${t("AdminConsole.Lookups.usage")} *`}</Typography>
              </InputLabel>
              <Select
                labelId="usage-select-label"
                value={formData.usage}
                onChange={handleUsageChange}
                label={t("AdminConsole.Lookups.usage")}
                error={!!formDataErrors.usage}
                required
              >
                {Object.entries(UsageToTranslation).map(([key, value]) => (
                  <MenuItem key={key} value={key}>
                    {t(value)}
                  </MenuItem>
                ))}
              </Select>
              {!!formDataErrors.usage && (
                <Typography variant="caption" color="error" mt={0.5}>
                  {formDataErrors.usage}
                </Typography>
              )}
            </>
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
};
