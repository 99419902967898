import { Box, Typography } from "@mui/material";
import { NewEntityHeader } from "components/NewEntityHeader";
import { PageContentContainer } from "components/PageContentContainer";
import { FormPublicApi } from "decl";
import {
  AddAuthorizationWorkflowInput,
  EditAuthorizationWorkflowInput,
} from "generated/graphql";
import { useNavigateBack } from "hooks/useNavigateBack";
import { useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useNewAuthorizationWorkflow } from "./useNewAuthorizationWorkflow";
import { AuthorizationWorkflowForm } from "../components/AuthorizationWorkflowForm/AuthorizationWorkflowForm";
import { useAuthorizationWorkflowUserCandidates } from "hooks/useAuthorizationWorkflowUserCandidates";
import { useProjectLite } from "containers/AdminConsole/hooks/useProjectLite";

export const NewAuthorizationWorkflow = () => {
  const { t } = useTranslation();
  const navigateBack = useNavigateBack();
  const { projectId } = useParams();
  const formRef = useRef<FormPublicApi>(null);
  const formDataRef = useRef<AddAuthorizationWorkflowInput>();

  const { addAuthorizationWorkflow, loading: addAuthorizationWorkflowLoading } =
    useNewAuthorizationWorkflow();

  const { userCandidates, loading: userCandidatesLoading } =
    useAuthorizationWorkflowUserCandidates(projectId!);

  const { projectDataLite, loading: projectDataLoading } = useProjectLite(
    projectId!
  );

  const handleAddAuthorizationWorkflow = useCallback(async () => {
    if (!formDataRef.current || !formRef.current?.validate()) {
      return;
    }

    const { errors } = await addAuthorizationWorkflow({
      variables: {
        input: formDataRef.current,
      },
    });

    if (!errors) {
      navigateBack();
    }
  }, [addAuthorizationWorkflow, navigateBack]);

  const handleFormChange = useCallback(
    (
      authWorkflow:
        | AddAuthorizationWorkflowInput
        | EditAuthorizationWorkflowInput
    ) => {
      formDataRef.current = authWorkflow as AddAuthorizationWorkflowInput;
    },
    [formDataRef]
  );

  return (
    <Box display="flex" flexDirection="column" position="relative">
      <NewEntityHeader
        onAdd={handleAddAuthorizationWorkflow}
        title={
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            ml={3}
            mr={1}
          >
            <Typography variant="subtitle2">
              {projectDataLite?.friendlyName}
            </Typography>
            <Typography variant="h5">
              {t("common.labels.newEntity", {
                entity: t(
                  "AdminConsole.AuthorizationWorkflows.authorizationWorkflow"
                ).toLowerCase(),
              })}
            </Typography>
          </Box>
        }
        generalLoading={
          addAuthorizationWorkflowLoading ||
          userCandidatesLoading ||
          projectDataLoading
        }
        addActionLoading={addAuthorizationWorkflowLoading}
      />
      <PageContentContainer>
        <AuthorizationWorkflowForm
          projectId={projectId!}
          onChange={handleFormChange}
          apiRef={formRef}
          users={userCandidates}
          disabled={addAuthorizationWorkflowLoading || userCandidatesLoading}
        />
      </PageContentContainer>
    </Box>
  );
};
