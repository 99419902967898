import { useCallback, useContext, useEffect, useState } from "react";
import {
  GenerateRecordVariationDeterminationNoticePreviewMutation,
  GenerateRecordVariationDeterminationNoticePreviewMutationVariables,
  RecordVariationDeterminationNoticePreview,
} from "generated/graphql";
import { useGraphMutation } from "hooks/useGraphMutation";
import { debounce } from "@mui/material";
import { eventDebounceDuration } from "../../../../../../constants";
import { VariationWidgetContext } from "../../VariationWidget/VariationWidget.context";
import { generateRecordVariationDeterminationNoticePreviewMutation } from "./NotifyDeterminationOfVariationAction.query";

export const useRecordDeterminationOfVariationNoticeModal = (
  draftVariationDeterminationId: string
) => {
  const { variation, contract } = useContext(VariationWidgetContext);

  const [variationPreview, setVariationPreview] =
    useState<RecordVariationDeterminationNoticePreview>();

  const [generatePreview] = useGraphMutation<
    GenerateRecordVariationDeterminationNoticePreviewMutation,
    GenerateRecordVariationDeterminationNoticePreviewMutationVariables
  >(generateRecordVariationDeterminationNoticePreviewMutation, {}, null);

  const loadPreview = debounce(
    useCallback(async () => {
      const { data } = await generatePreview({
        variables: {
          input: {
            variationId: variation!.id,
            draftVariationDeterminationId,
          },
        },
      });

      if (data) {
        setVariationPreview(
          data.generateRecordVariationDeterminationNoticePreview
        );
      }
    }, [generatePreview, variation, draftVariationDeterminationId]),
    eventDebounceDuration
  );

  useEffect(() => {
    loadPreview();
    // eslint-disable-next-line
  }, []);

  return {
    variationPreview,
    contract,
  };
};
