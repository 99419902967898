import { GridColDef } from "@mui/x-data-grid-pro";
import { useTranslation } from "react-i18next";
import { CreatedByColumnRenderer } from "./CreatedByColumnRenderer";
import { getUserName } from "helpers/miscelaneous";
import { User } from "generated/graphql";

export const useCreatedByColumn = <
  T extends { creator: User }
>(): GridColDef<T> => {
  const { t } = useTranslation();

  return {
    field: "creator",
    headerName: t("common.labels.createdBy"),
    width: 230,
    resizable: true,
    renderCell: (params) => (
      <CreatedByColumnRenderer createdBy={getUserName(params.row.creator)} />
    ),
  };
};
