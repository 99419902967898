import {
  GridColDef,
  GridRenderCellParams,
  GridRowId,
  GridValueGetterParams,
} from "@mui/x-data-grid-pro";
import {
  CompanyLookupCollection,
  DailyDiaryWorkRecord,
} from "generated/graphql";
import { useMemo } from "react";
import { useDailyDiaryStatus } from "../../../hooks/useDailyDiaryStatus";
import { DescriptionColumnRenderer } from "../../DescriptionColumnRenderer";
import { useTranslation } from "react-i18next";
import { useAttachmentsColumn } from "../../AttachmentsColumn";
import { useKebabButtonColumn } from "../../KebabButtonColumn";
import { useCreatedByColumn } from "../../CreatedByColumn";

export const useColumns = ({
  workLookups,
  withShiftColumn,
  withDescriptionColumn,
  withAreaColumn,
  withDisciplineColumn,
  withTeamColumn,
  withActivityColumn,
  withAttachments,
  onEdit,
  onRemove,
  onAttachmentsClick,
}: {
  onEdit?: (rowId: GridRowId) => void;
  onRemove?: (rowId: GridRowId) => void;
  onAttachmentsClick: (rowId: GridRowId) => void;
  withAttachments?: boolean;
  workLookups: CompanyLookupCollection[];
  withShiftColumn?: boolean;
  withDescriptionColumn?: boolean;
  withAreaColumn?: boolean;
  withDisciplineColumn?: boolean;
  withTeamColumn?: boolean;
  withActivityColumn?: boolean;
}) => {
  const { t } = useTranslation();
  const { isDraftOrEmptyDailyDiary } = useDailyDiaryStatus();
  const attachmentsCol = useAttachmentsColumn(onAttachmentsClick);
  const kebabButtonColumn = useKebabButtonColumn<DailyDiaryWorkRecord>(
    onEdit,
    onRemove
  );
  const createdByColumn = useCreatedByColumn<DailyDiaryWorkRecord>();

  return useMemo((): GridColDef<DailyDiaryWorkRecord>[] => {
    return [
      ...(withDescriptionColumn
        ? [
            {
              field: "description",
              headerName: t("common.labels.description"),
              flex: 0.2,
              resizable: true,
              renderCell: (
                params: GridRenderCellParams<any, DailyDiaryWorkRecord, any>
              ) => (
                <DescriptionColumnRenderer
                  description={params.row.description ?? undefined}
                />
              ),
            },
          ]
        : []),
      ...(withShiftColumn
        ? [
            {
              field: "shift",
              headerName: t("Projects.DailyDiaries.Work.shift"),
              width: 95,
              resizable: true,
              valueGetter: (
                params: GridValueGetterParams<DailyDiaryWorkRecord>
              ) => {
                return workLookups
                  .find((lkp) => lkp.id === params.row.shiftLookupId)
                  ?.options.items.find(
                    (option) => option.id === params.row.shiftOptionId
                  )?.value;
              },
            },
          ]
        : []),
      ...(withAreaColumn
        ? [
            {
              field: "area",
              headerName: t("Projects.DailyDiaries.Work.area"),
              flex: 0.1,
              resizable: true,
              valueGetter: (
                params: GridValueGetterParams<DailyDiaryWorkRecord>
              ) => {
                return workLookups
                  .find((lkp) => lkp.id === params.row.areaLookupId)
                  ?.options.items.find(
                    (option) => option.id === params.row.areaOptionId
                  )?.value;
              },
            },
          ]
        : []),
      ...(withDisciplineColumn
        ? [
            {
              field: "discipline",
              headerName: t("Projects.DailyDiaries.Work.discipline"),
              flex: 0.1,
              resizable: true,
              valueGetter: (
                params: GridValueGetterParams<DailyDiaryWorkRecord>
              ) => {
                return workLookups
                  .find((lkp) => lkp.id === params.row.disciplineLookupId)
                  ?.options.items.find(
                    (option) => option.id === params.row.disciplineOptionId
                  )?.value;
              },
            },
          ]
        : []),
      ...(withTeamColumn
        ? [
            {
              field: "team",
              headerName: t("Projects.DailyDiaries.Work.team"),
              flex: 0.1,
              resizable: true,
              valueGetter: (
                params: GridValueGetterParams<DailyDiaryWorkRecord>
              ) =>
                workLookups
                  .find((lkp) => lkp.id === params.row.teamLookupId)
                  ?.options.items.find(
                    (option) => option.id === params.row.teamOptionId
                  )?.value,
            },
          ]
        : []),
      ...(withActivityColumn
        ? [
            {
              field: "activity",
              headerName: t("Projects.DailyDiaries.Work.activity"),
              flex: 0.1,
              resizable: true,
              valueGetter: (
                params: GridValueGetterParams<DailyDiaryWorkRecord>
              ) =>
                workLookups
                  .find((lkp) => lkp.id === params.row.activityLookupId)
                  ?.options.items.find(
                    (option) => option.id === params.row.activityOptionId
                  )?.value,
            },
          ]
        : []),
      {
        field: "workType",
        headerName: t("Projects.DailyDiaries.Work.workType"),
        flex: 0.1,
        resizable: true,
        valueGetter: (params: GridValueGetterParams<DailyDiaryWorkRecord>) =>
          workLookups
            .find((lkp) => lkp.id === params.row.workTypeLookupId)
            ?.options.items.find(
              (option) => option.id === params.row.workTypeOptionId
            )?.value,
      },
      {
        field: "amount",
        headerName: t("Projects.DailyDiaries.Work.amount"),
        flex: 0.05,
        resizable: true,
      },
      {
        field: "unitOfMeasurement",
        headerName: t("Projects.DailyDiaries.Work.unitOfMeasurement"),
        flex: 0.05,
        resizable: true,
        maxWidth: 50,
        valueGetter: (params: GridValueGetterParams<DailyDiaryWorkRecord>) =>
          workLookups
            .find((lkp) => lkp.id === params.row.unitOfMeasurementLookupId)
            ?.options.items.find(
              (option) => option.id === params.row.unitOfMeasurementOptionId
            )?.value,
      },
      createdByColumn,
      ...(withAttachments ? [attachmentsCol] : []),
      ...(isDraftOrEmptyDailyDiary && (onRemove || onEdit)
        ? [kebabButtonColumn]
        : []),
    ];
  }, [
    t,
    workLookups,
    withShiftColumn,
    withDescriptionColumn,
    withAreaColumn,
    withDisciplineColumn,
    withTeamColumn,
    withActivityColumn,
    withAttachments,
    createdByColumn,
    kebabButtonColumn,
    isDraftOrEmptyDailyDiary,
    attachmentsCol,
    onEdit,
    onRemove,
  ]);
};
