import { gridRowCountSelector, useGridApiRef } from "@mui/x-data-grid-pro";
import { useEffect, useState } from "react";

export const useDataGridVisibleRows = () => {
  const [visibleRowsCount, setVisibleRowsCount] = useState<number>();
  const dataGridApiRef = useGridApiRef();

  useEffect(() => {
    dataGridApiRef.current.subscribeEvent("stateChange", () => {
      const count = gridRowCountSelector(dataGridApiRef.current.state);
      setVisibleRowsCount(count);
    });

    return () => {
      // TODO ADI B. remove subscription
      // dataGridApiRef.current.unstable_eventManager.removeListener('stateChange');
    };
  }, [dataGridApiRef]);

  return {
    gridApiRef: dataGridApiRef,
    visibleRowsCount,
  };
};
