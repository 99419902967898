import { useCallback, useContext, useEffect, useState } from "react";
import { ClaimWidgetContext } from "../../ClaimWidget/ClaimWidget.context";
import {
  GenerateRecordClaimErrorDeterminationDisagreementPreviewMutation,
  GenerateRecordClaimErrorDeterminationDisagreementPreviewMutationVariables,
  RecordClaimErrorDeterminationDisagreementPreview,
} from "generated/graphql";
import { useGraphMutation } from "hooks/useGraphMutation";
import { debounce } from "@mui/material";
import { eventDebounceDuration } from "../../../../../../constants";
import { generateRecordClaimErrorDeterminationDisagreementPreviewMutation } from "./DisagreeWithNoticeOfErrorInDeterminationAction.query";

export const useRecordDisagreementRegardingNoticeOfErrorInDeterminationModal = (
  remarks: string
) => {
  const { claim } = useContext(ClaimWidgetContext);

  const [claimPreview, setClaimPreview] =
    useState<RecordClaimErrorDeterminationDisagreementPreview>();

  const [generateClaimPreview] = useGraphMutation<
    GenerateRecordClaimErrorDeterminationDisagreementPreviewMutation,
    GenerateRecordClaimErrorDeterminationDisagreementPreviewMutationVariables
  >(generateRecordClaimErrorDeterminationDisagreementPreviewMutation, {}, null);

  const loadPreview = debounce(
    useCallback(async () => {
      const { data } = await generateClaimPreview({
        variables: {
          input: {
            claimId: claim!.id,
            remarks,
          },
        },
      });

      if (data) {
        setClaimPreview(
          data.generateRecordClaimErrorDeterminationDisagreementPreview
        );
      }
    }, [generateClaimPreview, claim, remarks]),
    eventDebounceDuration
  );

  useEffect(() => {
    loadPreview();
    // eslint-disable-next-line
  }, []);

  return {
    claimPreview,
  };
};
