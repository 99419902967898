import {
  ClaimAgreementNoticePromptQuery,
  ClaimAgreementNoticePromptQueryVariables,
  DraftClaimAgreement,
  RecordClaimAgreementNoticeMutation,
  RecordClaimAgreementNoticeMutationVariables,
  SendClaimAgreementNoticeMutation,
  SendClaimAgreementNoticeMutationVariables,
} from "generated/graphql";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useGraphQuery } from "hooks/useGraphQuery";
import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ClaimWidgetContext } from "../../ClaimWidget/ClaimWidget.context";
import {
  claimAgreementNoticePromptQuery,
  recordClaimAgreementNoticeMutation,
  sendClaimAgreementNoticeMutation,
} from "./NotifyAgreementClaimAction.query";
import { OnMutationDone } from "decl";

export const useNotifyAgreementClaimAction = (
  triggersAuthWorkflow?: boolean
) => {
  const { t } = useTranslation();
  const { onChange, claim, isFIDIC99RedYellow } =
    useContext(ClaimWidgetContext);

  const handleOnUpdate: OnMutationDone = (cache) => {
    cache.evict({
      id: "ROOT_QUERY",
      fieldName: "claimItems",
    });
    cache.evict({
      id: "ROOT_QUERY",
      fieldName: "claimHistoryItems",
      args: { claimId: claim!.id },
    });
    cache.evict({
      id: "ROOT_QUERY",
      fieldName: "claimDetailsExplorerItems",
      args: { claimId: claim!.id },
    });
    cache.evict({
      id: "ROOT_QUERY",
      fieldName: "claimAction",
    });
    cache.gc();
    onChange?.();
  };

  const { data: promptData, loading } = useGraphQuery<
    ClaimAgreementNoticePromptQuery,
    ClaimAgreementNoticePromptQueryVariables
  >(claimAgreementNoticePromptQuery, {
    variables: {
      input: {
        claimId: claim!.id,
      },
    },
    skip: !claim,
  });

  const [
    sendClaimAgreementNotice,
    { loading: sendClaimAgreementNoticeLoading },
  ] = useGraphMutation<
    SendClaimAgreementNoticeMutation,
    SendClaimAgreementNoticeMutationVariables
  >(
    sendClaimAgreementNoticeMutation,
    {
      update: handleOnUpdate,
    },
    triggersAuthWorkflow
      ? t("common.successMessages.actionInitiatedForAuthorization")
      : t("common.successMessages.entitySent", {
          entity: t(
            `Projects.Claims.claimAgreement${isFIDIC99RedYellow ? "99RY" : ""}`
          ),
        })
  );

  const [
    recordClaimAgreementNotice,
    { loading: recordClaimAgreementNoticeLoading },
  ] = useGraphMutation<
    RecordClaimAgreementNoticeMutation,
    RecordClaimAgreementNoticeMutationVariables
  >(
    recordClaimAgreementNoticeMutation,
    {
      update: handleOnUpdate,
    },
    triggersAuthWorkflow
      ? t("common.successMessages.actionInitiatedForAuthorization")
      : t("common.successMessages.entityRecorded", {
          entity: t(
            `Projects.Claims.claimAgreement${isFIDIC99RedYellow ? "99RY" : ""}`
          ),
        })
  );

  const draftClaimAgreements = useMemo(
    () =>
      (promptData?.claimAgreementNoticePrompt.draftClaimAgreements ??
        []) as DraftClaimAgreement[],
    [promptData]
  );

  return {
    draftClaimAgreements,
    loading,
    actionLoading:
      sendClaimAgreementNoticeLoading || recordClaimAgreementNoticeLoading,
    sendClaimAgreementNotice,
    recordClaimAgreementNotice,
  };
};
