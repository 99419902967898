import { CompEventWidgetContext } from "containers/Projects/components/CompEvents/CompEventWidget/CompEventWidget.context";
import { OnMutationDone } from "decl";
import {
  CompEventQuotationPromptQuery,
  CompEventQuotationPromptQueryVariables,
  DraftQuotation,
  RecordCompEventQuotationMutation,
  RecordCompEventQuotationMutationVariables,
  SendCompEventQuotationMutation,
  SendCompEventQuotationMutationVariables,
} from "generated/graphql";
import { recordCompEventQuotationMutation } from "graphql/mutations/recordCompEventQuotation";
import { sendCompEventQuotationMutation } from "graphql/mutations/sendCompEventQuotation";
import { compEventQuotationPromptQuery } from "graphql/queries/compEventQuotationPrompt.query";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useGraphQuery } from "hooks/useGraphQuery";
import { useCallback, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";

export const useSubmitQuotationCEAction = () => {
  const { t } = useTranslation();
  const { onChange, compEvent } = useContext(CompEventWidgetContext);

  const { data, loading } = useGraphQuery<
    CompEventQuotationPromptQuery,
    CompEventQuotationPromptQueryVariables
  >(compEventQuotationPromptQuery, {
    variables: {
      input: {
        compEventId: compEvent!.id,
      },
    },
  });

  const onUpdated: OnMutationDone = useCallback(
    (cache) => {
      cache.evict({ id: "ROOT_QUERY", fieldName: "compEventItems" });
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "compEventQuotationAssessments",
      });
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "compEventQuotationPrompt",
        args: { input: { compEventId: compEvent!.id } },
      });
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "compEventHistoryItems",
        args: { compEventId: compEvent!.id },
      });
      cache.gc();
      onChange?.();
    },
    [onChange, compEvent]
  );

  const [sendCEQuotation, { loading: sendCEQuotationLoading }] =
    useGraphMutation<
      SendCompEventQuotationMutation,
      SendCompEventQuotationMutationVariables
    >(
      sendCompEventQuotationMutation,
      {
        update: onUpdated,
      },
      t("common.successMessages.entitySent", {
        entity: t("Projects.CompEvents.compEventQuotation"),
      })
    );

  const [recordCEQuotation, { loading: recordCEQuotationLoading }] =
    useGraphMutation<
      RecordCompEventQuotationMutation,
      RecordCompEventQuotationMutationVariables
    >(
      recordCompEventQuotationMutation,
      {
        update: onUpdated,
      },
      t("common.successMessages.entityRecorded", {
        entity: t("Projects.CompEvents.compEventQuotation"),
      })
    );

  const draftQuotations = useMemo(
    () =>
      (data?.compEventQuotationPrompt.draftQuotations as DraftQuotation[]) ??
      [],
    [data]
  );

  return {
    draftQuotations,
    loading,
    actionLoading: sendCEQuotationLoading || recordCEQuotationLoading,
    sendCEQuotation,
    recordCEQuotation,
  };
};
