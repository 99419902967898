import {
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import { FormLabel } from "components/FormLabel";
import { AWSDateTimeFormat } from "../../../constants";
import { ContractBindingType, ProductType } from "generated/graphql";
import {
  DataValidators,
  validateData,
  ValidatorType,
} from "helpers/validators";
import moment from "moment";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

export type RecordProductItemExtraData = {
  dateSent: string;
  givenById: string;
  number: string;
};

export type LocalRecordProductItemExtraData = Omit<
  RecordProductItemExtraData,
  "dateSent"
> & {
  dateSent: Date | null;
};

export type RecordProductItemFormProps = {
  bindingTypes: ContractBindingType[];
  productType: ProductType;
  onChange: (recordEWExtraData: RecordProductItemExtraData) => void;
  onValidationChange: (isFormValid: boolean) => void;
};

const dataValidators: DataValidators = {
  dateSent: {
    validators: [ValidatorType.Required, ValidatorType.ValidDateMaxDateToday],
  },
  number: {
    validators: [ValidatorType.Required],
  },
  givenById: {
    validators: [ValidatorType.Required],
  },
};

const defaultFormData = {
  dateSent: null,
  number: "",
  givenById: "",
};

export const RecordProductItemForm: React.FC<RecordProductItemFormProps> = ({
  bindingTypes,
  productType,
  onChange,
  onValidationChange,
}) => {
  const { t } = useTranslation();

  const [formData, setFormData] =
    useState<LocalRecordProductItemExtraData>(defaultFormData);
  const [formDataErrors, setFormDataErrors] = useState<{
    [key: string]: string;
  }>({});

  const handleTextFieldChange: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = (evt) => {
    setFormData((curData) => ({
      ...curData,
      [evt.target.name]: evt.target.value,
    }));

    setFormDataErrors((curFormDataErrs) => {
      const { [evt.target.name]: _, ...rest } = curFormDataErrs;

      return rest;
    });

    onChange({
      ...formData,
      [evt.target.name]: evt.target.value,
      dateSent: formData.dateSent
        ? moment(formData.dateSent).format(AWSDateTimeFormat)
        : "",
    });
    onValidationChange(
      validateForm({
        ...formData,
        dateSent: formData.dateSent
          ? moment(formData.dateSent).format(AWSDateTimeFormat)
          : "",
        [evt.target.name]: evt.target.value,
      })
    );
  };

  const handleDatetimeChange = (date: Date | null) => {
    setFormData((curData) => ({
      ...curData,
      dateSent: date,
    }));

    setFormDataErrors((curFormDataErrs) => {
      const { dateSent: _, ...rest } = curFormDataErrs;

      return rest;
    });

    onChange({
      ...formData,
      dateSent: date ? moment(date).format(AWSDateTimeFormat) : "",
    });

    onValidationChange(
      validateForm({
        ...formData,
        dateSent: date ? moment(date).format(AWSDateTimeFormat) : "",
      })
    );
  };

  const handleBindingTypeChange = (event: SelectChangeEvent<string>) => {
    setFormData((curData) => ({
      ...curData,
      givenById: event.target.value,
    }));

    setFormDataErrors((curFormDataErrs) => {
      const { givenById: _, ...rest } = curFormDataErrs;

      return rest;
    });

    onChange({
      ...formData,
      givenById: event.target.value,
      dateSent: formData.dateSent
        ? moment(formData.dateSent).format(AWSDateTimeFormat)
        : "",
    });

    onValidationChange(
      validateForm({
        ...formData,
        dateSent: formData.dateSent
          ? moment(formData.dateSent).format(AWSDateTimeFormat)
          : "",
        givenById: event.target.value,
      })
    );
  };

  const validateForm = useCallback((formData: RecordProductItemExtraData) => {
    const validationResult = validateData(formData, dataValidators);

    if (validationResult.valid) {
      setFormDataErrors({});
      return true;
    }
    setFormDataErrors(validationResult.errors);

    return false;
  }, []);

  const productName =
    productType === ProductType.EarlyWarnings
      ? t("Projects.EarlyWarnings.earlyWarning")
      : productType === ProductType.Instructions
      ? t("Projects.Instructions.instruction")
      : undefined;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <FormLabel
          label={t("Projects.EarlyWarnings.RecordEWInstDialog.sentDate")}
          required
        />
        <DateTimePicker
          value={formData.dateSent}
          onChange={handleDatetimeChange}
          format={"yyyy-MM-dd HH:mm:ss"}
          maxDate={new Date() as any}
          data-testid="record-extra-info-sent-date"
          slotProps={{
            popper: {
              placement: "bottom",
            },
            textField: {
              error: !!formDataErrors.dateSent,
              helperText: formDataErrors.dateSent ?? undefined,
              required: true,
              size: "small",
              fullWidth: true,
            },
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <FormLabel
          label={
            productName
              ? t("Projects.EarlyWarnings.RecordEWInstDialog.ewNumber", {
                  productName,
                })
              : t("common.labels.number")
          }
          required
        />
        <TextField
          fullWidth
          name="number"
          value={formData.number ?? ""}
          data-testid="record-extra-info-number"
          onChange={handleTextFieldChange}
          type="text"
          placeholder={t(
            "Projects.EarlyWarnings.RecordEWInstDialog.enterNumber"
          )}
          error={!!formDataErrors.number}
          helperText={formDataErrors.number}
          size="small"
          required
        />
      </Grid>
      <Grid item xs={12}>
        <FormLabel label={t("common.labels.givenBy")} required />
        <Select
          data-testid="record-extra-given-by"
          value={formData.givenById}
          onChange={handleBindingTypeChange}
          placeholder={t("common.labels.suspensionSelect")}
          error={!!formDataErrors.givenById}
          sx={{
            width: "100%",
            "& .MuiSelect-select .notranslate::after": t(
              "common.labels.suspensionSelect"
            )
              ? {
                  content: `"${t("common.labels.suspensionSelect")}"`,
                  opacity: 0.42,
                }
              : {},
          }}
          size="small"
          required
        >
          {bindingTypes.map((bindingType) => (
            <MenuItem
              key={bindingType.id}
              value={bindingType.id}
              data-testid={`ce-given-by-${bindingType.description}-option`}
            >
              {bindingType.description}
            </MenuItem>
          ))}
        </Select>
        {!!formDataErrors.givenById && (
          <Typography variant="caption" color="error" mt={0.5}>
            {formDataErrors.givenById}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};
