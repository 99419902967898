import {
  GridFilterModel,
  GridLogicOperator,
  GridRowId,
  GridRowSelectionModel,
} from "@mui/x-data-grid-pro";
import { DailyDiarySectionDataGrid } from "components/StyledDataGrid";
import { AttachmentStatus, DailyDiaryGeneralRecord } from "generated/graphql";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDailyDiaryStatus } from "../../../hooks/useDailyDiaryStatus";
import { NoRowsOverlay } from "../../NoRowsOverlay";
import { useColumns } from "./GeneralTable.constants";
import { DailyDiaryRecordAttachmentsModal } from "../../DailyDiaryRecordAttachmentsModal/DailyDiaryRecordAttachmentsModal";
import { toRecordJSON } from "./GeneralTable.utils";
import { useDiaryTableSorting } from "../../../hooks/useDiaryTableSorting";

export type GeneralTableProps = {
  records: DailyDiaryGeneralRecord[];
  loading?: boolean;
  hideAttachments?: boolean;
  onEdit?: (record: GridRowId) => void;
  onRemove?: (record: GridRowId) => void;
  onSelectionChange?: (selectedRecordIds?: GridRowId[]) => void;
};

export const GeneralTable: React.FC<GeneralTableProps> = ({
  hideAttachments,
  records,
  loading,
  onEdit,
  onRemove,
  onSelectionChange,
}) => {
  const { t } = useTranslation();

  const { isDraftOrEmptyDailyDiary } = useDailyDiaryStatus();
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>();
  const selectionEnabled = !!onSelectionChange;
  const editable = !!onEdit || !!onRemove;

  const { sortingModel, setSortingModel, rows } = useDiaryTableSorting(records);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
    logicOperator: GridLogicOperator.And,
    quickFilterLogicOperator: GridLogicOperator.And,
    quickFilterValues: [],
  });

  const [selectedRow, setSelectedRow] = useState<DailyDiaryGeneralRecord>();

  const handleAttachmentsClick = (gridRowId: GridRowId) => {
    setSelectedRow(rows.find((record) => record.id === gridRowId));
  };

  const handleGridRowSelectionModelChange = useCallback(
    (selectionModel: GridRowSelectionModel) => {
      setSelectionModel(selectionModel);
      onSelectionChange?.(selectionModel);
    },
    [onSelectionChange]
  );

  const columns = useColumns({
    withAttachments: !hideAttachments,
    onEdit,
    onRemove,
    onAttachmentsClick: handleAttachmentsClick,
  });

  const selectedRowActiveAttachments = useMemo(
    () =>
      (selectedRow?.attachments ?? []).filter(
        (attach) => attach.status === AttachmentStatus.Active
      ),
    [selectedRow]
  );

  return (
    <>
      {selectedRow && (
        <DailyDiaryRecordAttachmentsModal
          open={!!selectedRow}
          attachments={selectedRowActiveAttachments}
          onClose={() => setSelectedRow(undefined)}
          recordJSON={toRecordJSON(selectedRow, t)}
          title={t("Projects.DailyDiaries.General.general")}
        />
      )}
      <DailyDiarySectionDataGrid
        rows={rows}
        columns={columns}
        getRowId={(rowData: DailyDiaryGeneralRecord) => rowData.id}
        loading={loading}
        sortingMode="client"
        sortModel={sortingModel}
        onSortModelChange={setSortingModel}
        filterMode="client"
        filterModel={filterModel}
        slots={{
          noRowsOverlay: () => (
            <NoRowsOverlay
              title={
                isDraftOrEmptyDailyDiary
                  ? undefined
                  : t("Grid.labels.noRecords")
              }
              hideSubtitle={!isDraftOrEmptyDailyDiary || !editable}
              recordName={t("Projects.DailyDiaries.sections.general")}
            />
          ),
        }}
        onFilterModelChange={setFilterModel}
        rowSelectionModel={selectionEnabled ? selectionModel : undefined}
        checkboxSelection={selectionEnabled}
        onRowSelectionModelChange={handleGridRowSelectionModelChange}
        rowSelection={selectionEnabled}
        disableRowSelectionOnClick
        hideFooter
        autoHeight
        stickyHeader
      />
    </>
  );
};
