import { ActionsDialogProps } from "components/ActionsDialog";
import { ClaimActionType } from "generated/graphql";
import { SendAction } from "../components/SendAction";
import { EnhancedAttachment } from "containers/Projects/components/Attachments/Attachments.decl";
import { ReasonsAttachmentsSummary } from "../../../ActionModal/ReasonsAttachmentsSummary";
import { useTranslation } from "react-i18next";
import { useSendNoticeOfClaimValidityHasLapsedModal } from "./useSendNoticeOfClaimValidityHasLapsedModal";

export type SendNoticeOfClaimValidityHasLapsedModalProps = {
  remarks: string;
  attachments?: EnhancedAttachment[];
} & ActionsDialogProps;

export const SendNoticeOfClaimValidityHasLapsedModal: React.FC<
  SendNoticeOfClaimValidityHasLapsedModalProps
> = ({ remarks, attachments = [], ...restDialogProps }) => {
  const { claimPreview } = useSendNoticeOfClaimValidityHasLapsedModal(remarks);
  const { t } = useTranslation();

  return (
    <SendAction
      claimPreview={claimPreview}
      actionType={ClaimActionType.NotifyClaimNoticeValidityHasLapsed}
      summaryEl={
        <ReasonsAttachmentsSummary
          text={remarks}
          label={t("common.labels.remarks")}
          attachments={attachments}
        />
      }
      {...restDialogProps}
    />
  );
};
