import { gql } from "@apollo/client";
import { creatorFields } from "graphql/fragments/creator.fragment";
import { itemStatusOptionFields } from "graphql/fragments/itemStatusOption.fragment";
import { itemDataFields } from "graphql/fragments/itemData.fragment";

export const eventItemFields = gql`
  ${itemStatusOptionFields}
  ${creatorFields}
  ${itemDataFields}

  fragment EventItemFields on EventItem {
    id
    title
    number
    productInstanceId
    productInstance {
      contract {
        valueCurrency
      }
    }
    ownerId
    statusOptionId
    data {
      ...ItemDataFields
    }
    dateCreated
    dateModified
    creatorId
    creator {
      ...CreatorFields
    }
    statusOption {
      ...ItemStatusOptionFields
    }
    owner {
      ...CreatorFields
    }
  }
`;
