import { Box, Grid, Typography } from "@mui/material";
import {
  useCallback,
  useContext,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { ClaimWidgetContext } from "../../ClaimWidget/ClaimWidget.context";
import { DetailedClaim } from "generated/graphql";
import { ClaimActionFormProps } from "../ClaimActionView";
import { RecordProductItemExtraData } from "containers/Projects/components/RecordProductItemForm";
import { FormLabel } from "components/FormLabel";
import { useTranslation } from "react-i18next";
import { RichTextArea } from "components/RichTextArea/RichTextArea";
import {
  DataValidators,
  ValidatorType,
  validateData,
} from "helpers/validators";
import { useRespondRegardingDetailedClaimAction } from "./useRespondRegardingDetailedClaimAction";
import { SendResponseRegardingDetailedClaimModal } from "./SendResponseRegardingDetailedClaimModal";
import { RecordResponseRegardingDetailedClaimModal } from "./RecordResponseRegardingDetailedClaimModal";
import {
  ClaimResolveType,
  DetailedClaimsAgreementsDeterminationsTable,
} from "../components/DetailedClaimsAgreementsDeterminationsTable/DetailedClaimsAgreementsDeterminationsTable";
import { DetailedClaimAgreementDeterminationReadOnlyModal } from "../../DetailedClaimAgreementDeterminationReadOnlyModal/DetailedClaimAgreementDeterminationReadOnlyModal";

const dataValidators: DataValidators = {
  details: {
    validators: [ValidatorType.RequiredRichText],
  },
  detailedClaim: {
    validators: [ValidatorType.Required],
  },
};

type FormDataType = {
  details: string;
  detailedClaim?: DetailedClaim;
  // attachments?: AttachmentInput[];
};

const defaultFormData: FormDataType = {
  details: "",
  detailedClaim: undefined,
  // attachments: [],
};

export const RespondRegardingDetailedClaimAction: React.FC<
  ClaimActionFormProps
> = ({ apiRef, onClose }) => {
  const { t } = useTranslation();
  const { claim, contract, isFIDIC99RedYellow } =
    useContext(ClaimWidgetContext);

  const [formData, setFormData] = useState<FormDataType>(defaultFormData);
  const [formDataErrors, setFormDataErrors] = useState<{
    [key: string]: string;
  }>({});
  const [sendModalVisibility, setSendModalVisibility] = useState(false);
  const [recordModalVisibility, setRecordModalVisibility] = useState(false);
  const [clickedDetailedClaim, setClickedDetailedClaim] =
    useState<DetailedClaim>();

  const {
    sendResponseRegardingDetailedClaim,
    recordResponseRegardingDetailedClaim,
    actionLoading,
    detailedClaims,
    detailedClaimsLoading,
  } = useRespondRegardingDetailedClaimAction();

  const toggleSendModalVisibility = () => {
    setSendModalVisibility((state) => !state);
  };

  const toggleRecordModalVisibility = () => {
    setRecordModalVisibility((state) => !state);
  };

  const handleSendNoticeThatClaimNoticeValidityHasLapsed = async () => {
    if (formData.detailedClaim) {
      await sendResponseRegardingDetailedClaim({
        variables: {
          input: {
            claimId: claim?.id!,
            details: formData.details,
            detailedClaimId: formData.detailedClaim.id,
            // attachments: formData.attachments,
          },
        },
      });
    }
    toggleSendModalVisibility();
    onClose();
  };

  const handleRecordNoticeThatClaimNoticeValidityHasLapsed = async (
    extraData: RecordProductItemExtraData
  ) => {
    if (formData.detailedClaim) {
      await recordResponseRegardingDetailedClaim({
        variables: {
          input: {
            claimId: claim?.id!,
            details: formData.details,
            detailedClaimId: formData.detailedClaim.id,
            // attachments: formData.attachments,
            dateSent: extraData.dateSent,
            givenById: extraData.givenById,
            number: extraData.number,
          },
        },
      });
    }
    toggleRecordModalVisibility();
    onClose();
  };

  const handleTextFieldChange = (details: string) => {
    setFormData((curData) => ({
      ...curData,
      details,
    }));

    setFormDataErrors((curFormDataErrs) => {
      const { details: _, ...rest } = curFormDataErrs;

      return rest;
    });
  };

  const validateForm = useCallback((formData: FormDataType) => {
    const result = validateData(formData, dataValidators);

    if (result.valid) {
      setFormDataErrors({});
    } else {
      setFormDataErrors(result.errors);
    }

    return result.valid;
  }, []);

  const isFormValid = useMemo(
    () => !!formData.details && formData.detailedClaim,
    [formData]
  );

  // const handleAttachmentsUpdated = async (
  //   attachmentsUpdated: AttachmentInput[]
  // ) => {
  //   setFormData((crtFormData) => ({
  //     ...crtFormData,
  //     attachments: attachmentsUpdated,
  //   }));
  // };

  const handleDetailedClaimSelectionChange = useCallback(
    (selectedDetailedClaim?: ClaimResolveType) => {
      setFormData((crtFormData) => ({
        ...crtFormData,
        detailedClaim: selectedDetailedClaim as DetailedClaim | undefined,
      }));

      setFormDataErrors((curFormDataErrs) => {
        const { detailedClaim: _, ...rest } = curFormDataErrs;

        return rest;
      });
    },
    []
  );

  const handleDetailedClaimClick = (clickedDetailedClaim: ClaimResolveType) => {
    setClickedDetailedClaim(clickedDetailedClaim as DetailedClaim);
  };

  const handleDetailedClaimModalClose = () => {
    setClickedDetailedClaim(undefined);
  };

  // const {
  //   allAttachments,
  //   addAttachments,
  //   removeAttachment,
  //   updateAttachment,
  //   downloadAttachment,
  //   unloadLocalAttachments,
  // } = useAttachments([], handleAttachmentsUpdated); // always starting from empty attachments.

  // const handleBeforeAbort = useCallback(() => {
  //   // flow of notifying a Claim's notice validity being outdated aborted. Remove all allegedly added attachments from the server
  //   unloadLocalAttachments();
  // }, [unloadLocalAttachments]);

  // const {
  //   imageAttachmentPreviewModalVisible,
  //   imagePreviewData,
  //   previewUrl,
  //   handleAttachmentClick,
  //   closeModal: closeImagePreviewModal,
  // } = useImagePreviewModal(downloadAttachment);

  useImperativeHandle(
    apiRef,
    () => ({
      validate: () => validateForm(formData),
      record: toggleRecordModalVisibility,
      send: toggleSendModalVisibility,
      onBeforeAbort: () => {},
      // onBeforeAbort: handleBeforeAbort,
    }),
    [formData, validateForm]
  );

  return (
    <>
      {clickedDetailedClaim && (
        <DetailedClaimAgreementDeterminationReadOnlyModal
          open={!!clickedDetailedClaim}
          onClose={handleDetailedClaimModalClose}
          item={clickedDetailedClaim}
          contract={contract}
        />
      )}

      {/* <PhotoAttachmentPreviewModal
        open={imageAttachmentPreviewModalVisible}
        attachment={imagePreviewData?.attachment}
        creatorName={imagePreviewData?.creatorName}
        creatorCompany={imagePreviewData?.creatorCompany}
        previewUrl={previewUrl}
        onClose={closeImagePreviewModal}
        onDownload={downloadAttachment}
      /> */}
      {isFormValid && sendModalVisibility && formData.detailedClaim && (
        <SendResponseRegardingDetailedClaimModal
          open={sendModalVisibility}
          details={formData.details}
          detailedClaim={formData.detailedClaim}
          // attachments={allAttachments as EnhancedAttachment[]}
          onPrimaryClick={handleSendNoticeThatClaimNoticeValidityHasLapsed}
          onSecondaryClick={toggleSendModalVisibility}
          onClose={toggleSendModalVisibility}
          primaryBtnLoading={actionLoading}
        />
      )}
      {isFormValid && recordModalVisibility && formData.detailedClaim && (
        <RecordResponseRegardingDetailedClaimModal
          open={recordModalVisibility}
          details={formData.details}
          detailedClaim={formData.detailedClaim}
          // attachments={allAttachments as EnhancedAttachment[]}
          onPrimaryClick={handleRecordNoticeThatClaimNoticeValidityHasLapsed}
          onSecondaryClick={toggleRecordModalVisibility}
          onClose={toggleRecordModalVisibility}
          primaryBtnLoading={actionLoading}
        />
      )}
      <Box display="flex" flexDirection="column">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DetailedClaimsAgreementsDeterminationsTable
              contractCurrency={contract.valueCurrency}
              items={detailedClaims}
              type="DetailedClaim"
              loading={detailedClaimsLoading}
              hasError={!!formDataErrors.detailedClaim}
              onSelectionChange={handleDetailedClaimSelectionChange}
              onRowClick={handleDetailedClaimClick}
            />
            {!!formDataErrors.detailedClaim && (
              <Typography variant="caption" color="error" mt={0.5}>
                {t(
                  `Projects.Claims.ActionModal.noDetailedClaimSelected${
                    isFIDIC99RedYellow ? "99RY" : ""
                  }`
                )}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <FormLabel
              label={t(
                `Projects.Claims.ActionModal.responseRegardingDetailedClaimLabel${
                  isFIDIC99RedYellow ? "99RY" : ""
                }`
              )}
              required
            />
            <RichTextArea
              content={formData.details}
              error={!!formDataErrors.details}
              onChange={handleTextFieldChange}
            />
            {!!formDataErrors.details && (
              <Typography variant="caption" color="error" mt={0.5}>
                {formDataErrors.details}
              </Typography>
            )}
          </Grid>
          {/* <Grid item xs={12}>
            <Attachments
              editMode
              showTabs={false}
              dencity={AttachmentsDencity.Compact}
              defaultLayout={AttachmentsLayout.List}
              attachments={allAttachments}
              onAttachmentsAdd={addAttachments}
              onAttachmentRemove={removeAttachment}
              onAttachmentUpdate={updateAttachment}
              onAttachmentClick={handleAttachmentClick}
            />
          </Grid> */}
        </Grid>
      </Box>
    </>
  );
};
