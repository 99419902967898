import { Box, css, styled } from "@mui/material";
import ReactFlagsSelect from "react-flags-select";

export const StyledCountriesSelect = styled(ReactFlagsSelect, {
  shouldForwardProp: (prop) => prop !== "hasErrors",
})<{
  hasErrors?: boolean;
}>(
  ({ theme, hasErrors }) => css`
    && {
      button {
        border: 1px solid ${theme.palette.grey[200]};
        padding-left: 14px;
        padding-right: 14px;
        padding-top: 0;
        padding-bottom: 0;
        transition: border-color 0.05s ease-in;
        height: 56px;
        border-radius: 8px;

        & > span {
          padding-left: 0;
          color: transparent;

          & span {
            color: ${theme.palette.common.black};
          }
        }

        svg {
          padding-right: ${theme.spacing(1)};
        }

        &:hover {
          border-color: black;
        }

        ${hasErrors
          ? css`
              border-color: ${theme.palette.error.main};
            `
          : ""}

        &[aria-expanded="true"] {
          border-color: ${theme.palette.primary.main};
        }
      }

      ul input {
        border: 1px solid ${theme.palette.grey[200]};
      }
    }
  `
);

export const CountriesSelectAdornment = styled(ReactFlagsSelect)(
  ({ theme }) => css`
    && {
      button {
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};
        height: 56px;
        border: none;
        border-right: 1px solid ${theme.palette.grey[200]};
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        margin-top: 5px;

        & > span {
          padding-left: 0;
          color: transparent;

          & span {
            color: ${theme.palette.common.black};
          }
        }

        svg {
          padding-right: ${theme.spacing(1)};
        }

        &:hover {
          border-color: ${theme.palette.grey[500]};
        }
      }

      ul {
        min-width: 220px;
      }

      ul input {
        border: 1px solid ${theme.palette.grey[200]};
      }
    }
  `
);

export const HeaderContainer = styled(Box)(
  ({ theme }) => css`
    position: sticky;
    top: 0;
    padding-top: ${theme.spacing(3)};
    padding-bottom: ${theme.spacing(2)};
    background: ${theme.palette.common.white};
    z-index: 3;
  `
);
