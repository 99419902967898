import { Box, CircularProgress, Typography } from "@mui/material";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  defaultConfig,
  DynamicConfig,
  dynamicConfigUrl,
} from "../config/config";

export enum ConfigLoadingState {
  Loading = "loading",
  Ready = "ready",
  Error = "error",
}

interface DynamicConfigContext {
  config: DynamicConfig;
  configLoadingState: ConfigLoadingState;
}

const ConfigContext = React.createContext<DynamicConfigContext>({
  config: defaultConfig,
  configLoadingState: ConfigLoadingState.Loading,
});

export const useConfig = () => useContext(ConfigContext);

export const ConfigContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [configState, setConfigState] = useState(defaultConfig);
  const [configLoadingState, setConfigLoadingState] =
    useState<ConfigLoadingState>(ConfigLoadingState.Loading);

  const fetchConfigFile = useCallback(async () => {
    try {
      const resp = await fetch(dynamicConfigUrl);
      const respData = (await resp.json()) as DynamicConfig;
      setConfigState(respData);

      setConfigLoadingState(ConfigLoadingState.Ready);
    } catch (err) {
      // probably running locally - try to use process env variables
      if (
        process.env.REACT_APP_AUTH_BASE_URL &&
        process.env.REACT_APP_GRAPHQL_URL &&
        process.env.REACT_APP_API_KEY &&
        process.env.REACT_APP_INTERCOM_APP_ID
      ) {
        const localConfig = {
          REACT_APP_AUTH_BASE_URL: process.env.REACT_APP_AUTH_BASE_URL,
          REACT_APP_GRAPHQL_URL: process.env.REACT_APP_GRAPHQL_URL,
          REACT_APP_API_KEY: process.env.REACT_APP_API_KEY,
          REACT_APP_INTERCOM_APP_ID: process.env.REACT_APP_INTERCOM_APP_ID,
          REACT_APP_VERSION: "5.0.0",
        };
        setConfigState(localConfig);
        setConfigLoadingState(ConfigLoadingState.Ready);
      } else {
        setConfigLoadingState(ConfigLoadingState.Error);
      }
    }
  }, []);

  useEffect(() => {
    fetchConfigFile();
  }, [fetchConfigFile]);

  const configContextValue = useMemo(() => {
    return {
      config: configState,
      configLoadingState,
    };
  }, [configState, configLoadingState]);

  if (configLoadingState === ConfigLoadingState.Loading) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100%"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (configLoadingState === ConfigLoadingState.Error) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100%"
      >
        <Typography variant="p2" color="error">
          Error while fetching global config
        </Typography>
      </Box>
    );
  }

  return (
    <ConfigContext.Provider value={configContextValue}>
      {children}
    </ConfigContext.Provider>
  );
};
