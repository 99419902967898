import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { Company, CompanyStatus } from "generated/graphql";
import { getUserName } from "helpers/miscelaneous";
import {
  StatusOption,
  StatusTag,
} from "../../../../components/StatusTag/StatusTag";
import {
  statusComparatorFunction,
  useDateCreatedColumnConfig,
} from "helpers/dataGrid.helpers";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";

export const useColumns = (
  companyStatusOptions: StatusOption<CompanyStatus>[],
  onStatusChange: (row: Company, newStatus: CompanyStatus) => void
): GridColDef<Company>[] => {
  const dateCreatedColConfig = useDateCreatedColumnConfig();
  const { t } = useTranslation();

  const columns = useMemo(
    () =>
      [
        {
          field: "tradingName",
          headerName: t("AdminConsole.Companies.labels.tradingName"),
          flex: 0.15,
          minWidth: 190,
          resizable: true,
        },
        {
          field: "status",
          headerName: t("common.labels.status"),
          flex: 0.125,
          minWidth: 90,
          resizable: true,
          sortComparator: statusComparatorFunction,
          renderCell: (params: GridRenderCellParams<any, Company, any>) => {
            return (
              <StatusTag
                status={params.row.status}
                options={companyStatusOptions}
                onChange={(newStatus: StatusOption<CompanyStatus>) =>
                  onStatusChange(params.row, newStatus.id)
                }
              />
            );
          },
        },
        {
          field: "city",
          headerName: t("AdminConsole.Companies.labels.city"),
          flex: 0.15,
          width: 120,
          resizable: true,
          valueGetter: (params) => params.row.physicalAddress?.city,
        },
        {
          field: "country",
          headerName: t("common.labels.country"),
          flex: 0.15,
          width: 120,
          resizable: true,
          valueGetter: (params) => params.row.physicalAddress?.country,
        },
        dateCreatedColConfig,
        {
          field: "creator",
          headerName: t("common.labels.createdBy"),
          flex: 0.125,
          width: 130,
          resizable: true,
          valueGetter: (params) => getUserName(params.row.creator),
        },
      ] as GridColDef<Company>[],
    [companyStatusOptions, onStatusChange, t, dateCreatedColConfig]
  );

  return columns;
};
