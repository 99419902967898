import { FormControl, Grid, Typography } from "@mui/material";
import { FormLabel } from "components/FormLabel";
import { FormPublicApi } from "decl";
import {
  AddDailyDiaryGeneralRecordInput,
  DailyDiaryGeneralRecord,
  DailyDiaryPresetSection,
  EditDailyDiaryGeneralRecordInput,
} from "generated/graphql";
import { validateData } from "helpers/validators";
import {
  ChangeEventHandler,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Textarea } from "components/TextArea";
import { generalRecordToEditInput } from "../GeneralSection.utils";
import { useGeneralRecordFormValidators } from "./useGeneralRecordFormValidators";

export type GeneralRecordFormProps = {
  generalRecord?: DailyDiaryGeneralRecord;
  apiRef?: React.Ref<FormPublicApi>;
  section: DailyDiaryPresetSection;
  onChange: (
    generalRecord:
      | AddDailyDiaryGeneralRecordInput
      | EditDailyDiaryGeneralRecordInput
  ) => void;
};

export const defaultFormData: EditDailyDiaryGeneralRecordInput = {
  id: "",
  description: "",
  attachments: [],
};

export const GeneralRecordForm: React.FC<GeneralRecordFormProps> = ({
  generalRecord,
  apiRef,
  section,
  onChange,
}) => {
  const { t } = useTranslation();

  const firstFieldRef = useRef<any>(null);

  const [formData, setFormData] = useState<
    AddDailyDiaryGeneralRecordInput | EditDailyDiaryGeneralRecordInput
  >(generalRecord ? generalRecordToEditInput(generalRecord) : defaultFormData);
  const [formDataErrors, setFormDataErrors] = useState<{
    [key: string]: string;
  }>({});

  const dataValidators = useGeneralRecordFormValidators(section);

  const validateForm = useCallback(
    (
      formData:
        | AddDailyDiaryGeneralRecordInput
        | EditDailyDiaryGeneralRecordInput
    ) => {
      const validationResult = validateData(formData, dataValidators);

      if (validationResult.valid) {
        setFormDataErrors({});
        return true;
      }
      setFormDataErrors(validationResult.errors);

      return false;
    },
    [dataValidators]
  );

  const handleDescriptionChange: ChangeEventHandler<HTMLTextAreaElement> = (
    event
  ) => {
    setFormData((curData) => ({
      ...curData,
      description: event.target.value,
    }));
  };

  const resetForm = useCallback(() => {
    setFormData(defaultFormData);
    firstFieldRef.current.focus();
  }, []);

  useImperativeHandle(
    apiRef,
    () => ({
      validate: () => validateForm(formData),
      reset: resetForm,
    }),
    [resetForm, formData, validateForm]
  );

  useEffect(() => {
    onChange(formData);
  }, [onChange, formData]);

  useEffect(() => {
    firstFieldRef.current.focus();
  }, []);

  const isDescriptionRequired = section.fields.find(
    (field) => field.name === "Description"
  )?.isRequired;

  return (
    <Grid container spacing={5}>
      <Grid item xs={12}>
        <FormControl variant="standard" sx={{ minWidth: 120 }} fullWidth>
          <FormLabel
            label={t("Projects.DailyDiaries.General.description")}
            required={isDescriptionRequired}
          />
          <Textarea
            value={formData.description ?? ""}
            ref={firstFieldRef}
            required={isDescriptionRequired}
            onChange={handleDescriptionChange}
          />
          {!!formDataErrors.description && (
            <Typography variant="caption" color="error" mt={0.5}>
              {formDataErrors.description}
            </Typography>
          )}
        </FormControl>
      </Grid>
    </Grid>
  );
};
