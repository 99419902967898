import { Box, Skeleton, Typography, useTheme } from "@mui/material";
import { ItemTag } from "components/ItemTag";
import {
  StatusOptionNew,
  StatusTagNew,
} from "components/StatusTag/StatusTagNew";
import { ChangeProductItemStatusModal } from "containers/Projects/components/ChangeProductItemStatusModal/ChangeProductItemStatusModal";
import { ProductItemHeaderContainer } from "containers/Projects/components/HeaderContainer";
import { ItemStatusOption, ProductType } from "generated/graphql";
import { useState } from "react";
import { HeaderBackButton } from "./HeaderBackButton";
import { RecordedMark } from "./RecordedMark";
import { OverflowTooltip } from "components/OverflowTooltip";

export type EWInstructionDetailsHeaderProps = {
  title: string;
  productType: ProductType.EarlyWarnings | ProductType.Instructions;
  statusOptions: ItemStatusOption[];
  statusOptionId: string;
  titleSuffix?: string;
  dataLoading?: boolean;
  isRecorded?: boolean;
  onStatusChange?: (
    newStatus: ItemStatusOption,
    reasonId: string,
    remarks?: string
  ) => void;
  onBack: () => void;
};

export const EWInstructionDetailsHeader: React.FC<
  EWInstructionDetailsHeaderProps
> = ({
  title,
  titleSuffix,
  dataLoading,
  productType,
  statusOptionId,
  statusOptions,
  isRecorded,
  onStatusChange,
  onBack,
}) => {
  const theme = useTheme();

  const [changeStatusModalOpen, setChangeStatusModalOpen] = useState(false);
  const [newStatus, setNewStatus] = useState<ItemStatusOption>();

  const triggerStatusChangeFlow = (
    newSelectedOption: StatusOptionNew<string>
  ) => {
    setNewStatus(
      statusOptions.find((option) => option.id === newSelectedOption.id)!
    );

    setChangeStatusModalOpen(true);
  };

  const handleStatusChange = (reasonId: string, remarks?: string) => {
    onStatusChange?.(newStatus!, reasonId, remarks);
    setChangeStatusModalOpen(false);
    setNewStatus(undefined);
  };

  return (
    <>
      <ChangeProductItemStatusModal
        open={changeStatusModalOpen}
        oldStatusName={
          statusOptions.find((option) => option.id === statusOptionId)
            ?.description || ""
        }
        newStatusName={newStatus?.description || ""}
        reasons={newStatus?.optionReasons.items || []}
        onClose={() => setChangeStatusModalOpen(false)}
        onSecondaryClick={() => setChangeStatusModalOpen(false)}
        onPrimaryClick={handleStatusChange}
      />
      <ProductItemHeaderContainer>
        <>
          <HeaderBackButton onBack={onBack} disabled={dataLoading} />
          {dataLoading ? (
            <Box ml={2}>
              <Skeleton variant="rectangular" animation="pulse" width={500} />
            </Box>
          ) : (
            <Box display="flex" alignItems="center" ml={2} flex={1}>
              <ItemTag type={productType} />
              <Box display="flex" alignItems="center" mx={2}>
                <OverflowTooltip
                  title={title}
                  typographyProps={{
                    variant: "h3",
                    color: theme.palette.grey[800],
                    maxWidth: "600px",
                  }}
                />
                {titleSuffix && (
                  <Typography
                    variant="h3"
                    fontWeight={400}
                    ml={0.75}
                    color={theme.palette.grey[800]}
                  >
                    {titleSuffix}
                  </Typography>
                )}
              </Box>
              <StatusTagNew
                size="small"
                hideArrow
                placement="bottom-start"
                selectedOptionId={statusOptionId}
                options={statusOptions.map((statusOption) => ({
                  id: statusOption.id,
                  label: statusOption.description,
                  style: statusOption.style,
                }))}
                disabled={!onStatusChange}
                onChange={triggerStatusChangeFlow}
              />
              {isRecorded && <RecordedMark />}
              {/* NOTE: uncomment when implementing Copy functionality. `isHeaderStuck` is coming from ProductItemHeaderContainer if children are a function */}
              {/* <Box ml="auto" mr={isHeaderStuck ? 3 : 0}>
                  <IconButton
                    onClick={() => {}}
                    style={{
                      background: "transparent",
                      border: "1px solid grey",
                      borderRadius: "8px",
                      borderColor: theme.palette.grey[300],
                    }}
                    size="medium"
                  >
                    <Copy size={24} />
                  </IconButton>
                </Box> */}
            </Box>
          )}
        </>
      </ProductItemHeaderContainer>
    </>
  );
};
