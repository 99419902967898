import { Box, Collapse, useTheme } from "@mui/material";
import {
  GridCellParams,
  GridEventListener,
  GridFilterModel,
  GridLogicOperator,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowParams,
  GridRowSelectionModel,
  MuiEvent,
} from "@mui/x-data-grid-pro";
import { CollapsibleHeader } from "components/CollapsibleHeader";
import { StatusOption } from "components/StatusTag/StatusTag";
import { useActiveRemovedStatusOptions } from "components/StatusTag/useActiveRemovedStatusOptions";
import { StyledDataGrid } from "components/StyledDataGrid";
import { temporaryRowId } from "../../../../../../../constants";
import { computeGridRowModes } from "helpers/dataGrid.helpers";
import { exportToExcel } from "helpers/exportToExcel";
import { useDataGridVisibleRows } from "hooks/useDataGridVisibleRows";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  AddProductUserRoleInput,
  EditProductUserRoleInput,
  ProductUserRole,
  ProductUserRoleStatus,
  Project,
  User,
} from "generated/graphql";
import {
  sortRoles,
  rowsContainTemporaryRecord,
  productUserRolesToLocalProductUserRoles,
  getUserRoleAttributeIds,
} from "./UserRoles.utils";
import { useColumns, IdNameType } from "./UserRoles.constants";
import { DataGridAddRecordButton } from "components/DataGridAddRecordButton";
import { Keyhole } from "phosphor-react";
import { GlobalContext } from "state-management/globalContext/Global.context";
import { getUserName } from "helpers/miscelaneous";

export type LocalProductUserRole = {
  id: string;
  project?: {
    id: string;
    name: string;
  };
  contract?: {
    id: string;
    name: string;
  };
  product?: {
    id: string;
    name: string;
  };
  productInstance?: {
    id: string;
    name: string;
  };
  role?: {
    id: string;
    name: string;
  };
  status: ProductUserRoleStatus;
  dateCreated: string;
  creator: User;
  changesCommitted?: boolean;
};

const emptyUserRole = {
  id: temporaryRowId,
  project: {
    id: "",
    name: "",
  },
  contract: {
    id: "",
    name: "",
  },
  product: {
    id: "",
    name: "",
  },
  productInstance: {
    id: "",
    name: "",
  },
  role: {
    id: "",
    name: "",
  },
  dateCreated: "",
  creator: {} as User,
  status: ProductUserRoleStatus.Active,
};

export type LocalProductUserRoleLite = {
  productInstanceId: string;
  productRoleId: string;
};

export type UserRolesProps = {
  roles: ProductUserRole[];
  projects: Project[];
  user: User;
  loading?: boolean;
  onRoleStatusChange: (
    userRole: EditProductUserRoleInput,
    newStatus: ProductUserRoleStatus
  ) => Promise<boolean>;
  onRoleAdd: (newUserRole: AddProductUserRoleInput) => Promise<boolean>;
  onRoleEdit: (updatedUserRole: EditProductUserRoleInput) => Promise<boolean>;
};

export const UserRoles: React.FC<UserRolesProps> = ({
  roles,
  projects,
  user,
  loading,
  onRoleStatusChange,
  onRoleAdd,
  onRoleEdit,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { authenticatedUser, refetchAuthenticatedUser } =
    useContext(GlobalContext);
  const { visibleRowsCount, gridApiRef } = useDataGridVisibleRows();
  const statusOptions =
    useActiveRemovedStatusOptions() as StatusOption<ProductUserRoleStatus>[];
  const addUpdateInProgress = useRef<boolean>(false);

  const [rows, setRows] = useState<LocalProductUserRole[]>(
    sortRoles(productUserRolesToLocalProductUserRoles(roles ?? []))
  );
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>(
    computeGridRowModes(rows)
  );
  const [showSections, setShowSections] = useState(true);
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>();

  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [
      {
        field: "status",
        operator: "isAnyOf",
        value: [ProductUserRoleStatus.Active],
      },
    ],
    logicOperator: GridLogicOperator.And,
    quickFilterLogicOperator: GridLogicOperator.And,
    quickFilterValues: [],
  });

  const handleExportToExcel = () => {
    const columns = [
      { header: t("common.labels.project"), key: "project", width: 20 },
      { header: t("common.labels.contract"), key: "contract", width: 20 },
      { header: t("common.labels.product"), key: "product", width: 20 },
      {
        header: t("common.labels.productInstance"),
        key: "productInstance",
        width: 20,
      },
      { header: t("common.labels.role"), key: "role", width: 20 },
      { header: t("common.labels.status"), key: "status", width: 20 },
      { header: t("common.labels.dateCreated"), key: "dateCreated", width: 20 },
      { header: t("common.labels.createdBy"), key: "createdBy", width: 20 },
    ];

    const rowsToExport = rows
      .filter(
        (productUserRole) =>
          (selectionModel || []).indexOf(productUserRole.id) >= 0
      )
      .map((productUserRole) => ({
        ...productUserRole,
        project: productUserRole.project?.name,
        contract: productUserRole.contract?.name,
        product: productUserRole.product?.name,
        productInstance: productUserRole.productInstance?.name,
        role: productUserRole.role?.name,
        dateCreated: new Date(productUserRole.dateCreated),
        createdBy: getUserName(productUserRole.creator),
      }));

    exportToExcel(
      `${getUserName(user)}-${t("common.labels.roles")}`,
      columns,
      rowsToExport
    );
  };

  const handleProjectChange = useCallback(
    (row: LocalProductUserRole, newProject: IdNameType) => {
      setRows((curRows) =>
        curRows.map((crtRow) => {
          if (crtRow.id === row.id) {
            return {
              ...crtRow,
              project: newProject,
              contract: { id: "", name: "" },
              product: { id: "", name: "" },
              productInstance: { id: "", name: "" },
              role: { id: "", name: "" },
              changesCommitted: false,
            };
          }
          return crtRow;
        })
      );
    },
    []
  );

  const handleContractChange = useCallback(
    (row: LocalProductUserRole, newContract: IdNameType) => {
      setRows((curRows) =>
        curRows.map((crtRow) => {
          if (crtRow.id === row.id) {
            return {
              ...crtRow,
              contract: newContract,
              product: { id: "", name: "" },
              productInstance: { id: "", name: "" },
              role: { id: "", name: "" },
              changesCommitted: false,
            };
          }
          return crtRow;
        })
      );
    },
    []
  );

  const handleProductChange = useCallback(
    (row: LocalProductUserRole, newProduct: IdNameType) => {
      setRows((curRows) =>
        curRows.map((crtRow) => {
          if (crtRow.id === row.id) {
            return {
              ...crtRow,
              product: newProduct,
              productInstance: { id: "", name: "" },
              role: { id: "", name: "" },
              changesCommitted: false,
            };
          }
          return crtRow;
        })
      );
    },
    []
  );

  const handleProductInstanceChange = useCallback(
    (row: LocalProductUserRole, newProductInstance: IdNameType) => {
      setRows((curRows) =>
        curRows.map((crtRow) => {
          if (crtRow.id === row.id) {
            return {
              ...crtRow,
              productInstance: newProductInstance,
              role: { id: "", name: "" },
              changesCommitted: false,
            };
          }
          return crtRow;
        })
      );
    },
    []
  );

  const handleRoleChange = useCallback(
    (row: LocalProductUserRole, newRole: IdNameType) => {
      setRows((curRows) =>
        curRows.map((crtRow) => {
          if (crtRow.id === row.id) {
            return {
              ...crtRow,
              role: newRole,
              changesCommitted: false,
            };
          }
          return crtRow;
        })
      );
    },
    []
  );

  const removeInProgressRole = () => {
    // removing the in-progress row
    setRows((curRows) => curRows.filter((row) => row.id !== temporaryRowId));
  };

  const handleStatusChange = useCallback(
    async (row: LocalProductUserRole, newStatus: ProductUserRoleStatus) => {
      if (row.id === temporaryRowId) {
        if (newStatus === ProductUserRoleStatus.Removed) {
          removeInProgressRole();
        } else {
          setRows((curRows) =>
            curRows.map((row) => {
              return {
                ...row,
                status: newStatus,
                changesCommitted: false,
              };
            })
          );
        }
      } else {
        const success = await onRoleStatusChange(
          {
            id: row.id,
            userId: user.id,
            productInstanceId: row.productInstance!.id!,
            productRoleId: row.role!.id,
          },
          newStatus
        );

        if (success && user.id === authenticatedUser!.id) {
          refetchAuthenticatedUser();
        }
      }
    },
    [onRoleStatusChange, user, authenticatedUser, refetchAuthenticatedUser]
  );

  /**
   * This function does not do the actual save because the data inside the row is not commited until it gets out of EditMode. Thus,
   * we're closing the editMode here, and process the add/edit inside processRowUpdate
   */
  const handleRowSaveClick = useCallback((rowId: GridRowId) => {
    setRowModesModel((curModel) => ({
      ...curModel,
      [rowId]: { mode: GridRowModes.View },
    }));
  }, []);

  const handleDeleteRow = useCallback(
    async (rowId: GridRowId) => {
      if (rowId === temporaryRowId) {
        removeInProgressRole();
      } else {
        // call BE to delete row
        const crtRow = rows.find((role) => role.id === rowId)!;
        const success = await onRoleStatusChange(
          {
            id: crtRow.id,
            userId: user.id,
            productInstanceId: crtRow.productInstance!.id!,
            productRoleId: crtRow.role!.id,
          },
          ProductUserRoleStatus.Removed
        );

        if (success && user.id === authenticatedUser!.id) {
          refetchAuthenticatedUser();
        }
      }
    },
    [
      onRoleStatusChange,
      rows,
      user,
      authenticatedUser,
      refetchAuthenticatedUser,
    ]
  );

  // TODO: export these functions in generic hook
  const handleRowEditStart = (
    _: GridRowParams,
    event: MuiEvent<React.SyntheticEvent>
  ) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (_, event) => {
    event.defaultMuiPrevented = true;
  };

  const isRowValid = (row: GridRowModel<LocalProductUserRole>) => {
    const { productInstanceId, productRoleId } = getUserRoleAttributeIds(row);

    return !!productInstanceId && !!productRoleId;
  };

  const handleRowChangesCommitted = useCallback(
    async (
      newRow: GridRowModel<LocalProductUserRole>,
      _: GridRowModel<LocalProductUserRole>
    ) => {
      const updatedRow = rows.find((row) => row.id === newRow.id)!;

      if (!isRowValid(updatedRow)) {
        setRowModesModel((prevData) => ({
          ...prevData,
          [updatedRow.id]: {
            mode: GridRowModes.Edit,
            fieldToFocus: "productRole",
          },
        }));

        return updatedRow;
      }

      if (addUpdateInProgress.current) {
        // debounce wasn't an option because we need to return `newRow` so that grid gets out of select mode
        console.log(
          "Row already in progress. Ignoring this change...",
          updatedRow
        );
        return updatedRow;
      }

      const { productInstanceId, productRoleId } =
        getUserRoleAttributeIds(updatedRow);

      if (updatedRow.id === temporaryRowId) {
        addUpdateInProgress.current = true;
        const success = await onRoleAdd({
          productInstanceId,
          productRoleId,
          userId: user.id,
        });
        addUpdateInProgress.current = false;
        if (success) {
          if (user.id === authenticatedUser!.id) {
            refetchAuthenticatedUser();
          }
        } else {
          // remove temporary row
          setRows((curRows) =>
            curRows.filter((curRow) => curRow.id !== temporaryRowId)
          );
        }
      } else if (updatedRow.changesCommitted) {
        return updatedRow;
      } else {
        addUpdateInProgress.current = true;
        const success = await onRoleEdit({
          id: updatedRow.id,
          productInstanceId,
          productRoleId,
          userId: user.id,
        });
        addUpdateInProgress.current = false;

        if (success) {
          setRows((curRows) =>
            curRows.map((crtRow) => {
              if (crtRow.id === updatedRow.id) {
                return {
                  ...crtRow,
                  changesCommitted: true,
                };
              }
              return crtRow;
            })
          );
          if (user.id === authenticatedUser!.id) {
            refetchAuthenticatedUser();
          }
        } else {
          setRowModesModel((prevData) => ({
            ...prevData,
            [updatedRow.id]: {
              mode: GridRowModes.Edit,
              fieldToFocus: "product",
            },
          }));
        }
      }

      return updatedRow;
    },
    [
      onRoleAdd,
      onRoleEdit,
      user,
      rows,
      authenticatedUser,
      refetchAuthenticatedUser,
    ]
  );

  const handleAddTemporaryRecord = () => {
    setRows((currentRecords) => {
      return [...currentRecords, emptyUserRole];
    });

    setTimeout(() => {
      setRowModesModel((prevData) => ({
        ...prevData,
        [temporaryRowId]: {
          mode: GridRowModes.Edit,
          fieldToFocus: "role",
        },
      }));
    });
  };

  const columns = useColumns({
    rowModesModel,
    projects,
    statusOptions,
    onProjectChange: handleProjectChange,
    onContractChange: handleContractChange,
    onProductChange: handleProductChange,
    onProductInstanceChange: handleProductInstanceChange,
    onRoleChange: handleRoleChange,
    onStatusChange: handleStatusChange,
    handleDeleteRow,
    handleSaveRow: handleRowSaveClick,
  });

  useEffect(() => {
    const localUserRoles = productUserRolesToLocalProductUserRoles(roles ?? []);

    setRows(sortRoles(localUserRoles));
    setRowModesModel(computeGridRowModes(localUserRoles));
  }, [roles]);

  return (
    <Box>
      <CollapsibleHeader
        title={t("common.labels.roles")}
        visibleRowsCount={visibleRowsCount || 0}
        selectedCount={selectionModel?.length || 0}
        onExportToExcel={handleExportToExcel}
        icon={
          <Keyhole size={22} weight="fill" color={theme.palette.primary.main} />
        }
        onToggleCollapse={() => setShowSections((state) => !state)}
        collapsed={!showSections}
      />
      <Collapse in={showSections}>
        <Box sx={{ maxHeight: 600, width: "100%", overflowY: "auto" }}>
          <StyledDataGrid
            apiRef={gridApiRef}
            rows={rows}
            columns={columns}
            getRowId={(rowData: LocalProductUserRole) => rowData.id}
            onRowSelectionModelChange={setSelectionModel}
            loading={loading}
            filterMode="client"
            filterModel={filterModel}
            onFilterModelChange={setFilterModel}
            rowModesModel={rowModesModel}
            onRowEditStart={handleRowEditStart}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={handleRowChangesCommitted}
            // experimentalFeatures={{ newEditingApi: true }}
            getCellClassName={(
              params: GridCellParams<any, LocalProductUserRole, any>
            ) => {
              return params.row.status === ProductUserRoleStatus.Removed
                ? "greyed-out"
                : "";
            }}
            checkboxSelection
            disableRowSelectionOnClick
            autoHeight
            hideFooter
          />
          <DataGridAddRecordButton
            onClick={handleAddTemporaryRecord}
            disabled={rowsContainTemporaryRecord(rows)}
          />
        </Box>
      </Collapse>
    </Box>
  );
};
