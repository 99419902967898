import {
  Box,
  BoxProps,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { ProductRole } from "generated/graphql";
import { useMemo } from "react";
import {
  acceptedRoleNames,
  defaultProductInstanceRole,
} from "./ProductInstanceRolesMapper";
import { LocalInviteUserRoleInput } from "./ProjectRolesContainer";

export type ProductInstanceRoleSelectorProps = {
  productInstanceName: string;
  productInstanceId: string;
  selectedRoleId?: string;
  roles: ProductRole[];
  onChange: (newPIRoleMapping: LocalInviteUserRoleInput) => void;
} & Omit<BoxProps, "onChange">;

export const ProductInstanceRoleSelector: React.FC<
  ProductInstanceRoleSelectorProps
> = ({
  productInstanceName,
  roles,
  selectedRoleId = "",
  productInstanceId,
  onChange,
  ...restBoxProps
}) => {
  const productRolesLite = useMemo(() => {
    const rolesLite = roles.map((role) => ({
      id: role.id,
      name: role.name,
    }));

    return [defaultProductInstanceRole, ...rolesLite].filter(
      (role) => acceptedRoleNames.indexOf(role.name.toLowerCase()) >= 0
    );
  }, [roles]);

  const handleRoleChange = (event: SelectChangeEvent<string>) => {
    onChange({
      productInstanceId,
      productRoleId: event.target.value ?? "",
    });
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      ml={12}
      mr={4}
      {...restBoxProps}
    >
      <Box flex={1}>
        <Typography fontSize="15px" fontWeight={500}>
          {productInstanceName}
        </Typography>
      </Box>
      <Box width="445px" display="flex" justifyContent="center">
        <Select
          id="product-instance-role-select"
          value={selectedRoleId}
          onChange={handleRoleChange}
          sx={{ minWidth: "150px" }}
          size="small"
          displayEmpty
        >
          {productRolesLite.map((role) => (
            <MenuItem key={role.id} value={role.id}>
              {role.name}
            </MenuItem>
          ))}
        </Select>
      </Box>
    </Box>
  );
};
