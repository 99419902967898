import {
  Box,
  ClickAwayListener,
  Fade,
  List,
  Popper,
  PopperPlacementType,
  Typography,
} from "@mui/material";
import { useMemo, useState } from "react";
import { SelectedIcon } from "../Icons/SelectedIcon";
import {
  Arrow,
  PopperContentContainer,
  StyledChip,
  StyledListItem,
} from "./StatusTag.styled";
import { UnselectedIcon } from "../Icons/UnselectedIcon";

export type StatusOption<T> = {
  id: T;
  label: string;
  color: string;
};

export type StatusTagProps<T> = {
  options: StatusOption<T>[];
  status: string;
  disabled?: boolean;
  disablePortal?: boolean;
  placement?: PopperPlacementType;
  onChange?: (newStatus: StatusOption<T>) => void;
};

export const StatusTag = <T extends string>({
  options,
  status,
  disabled,
  disablePortal = false,
  placement = "right-start",
  onChange,
}: StatusTagProps<T>) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [arrowRef, setArrowRef] = useState<HTMLElement | null>(null);
  const [popperRef, setPopperRef] = useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (!popperRef?.contains((event as any).target)) {
      setAnchorEl(event.currentTarget);
    }
    event.stopPropagation();
  };

  const open = Boolean(anchorEl);
  const id = open ? "transition-popper" : undefined;

  const handleClickAway = () => {
    setAnchorEl(null);
  };

  const handleItemClick = (newStatus: StatusOption<T>) => {
    onChange?.(newStatus);
    setAnchorEl(null);
  };

  const selectedStatus = useMemo(
    () => options.find((curStatus) => curStatus.id === status),
    [options, status]
  );

  return (
    <Box onClick={disabled ? undefined : handleClick}>
      <StyledChip
        variant="filled"
        bgColor={selectedStatus?.color!}
        label={
          <Typography
            variant="caption"
            fontWeight={500}
            textTransform="uppercase"
          >
            {selectedStatus?.label}
          </Typography>
        }
      />
      {open && (
        <Popper
          id={id}
          open={open}
          anchorEl={anchorEl}
          transition
          placement={placement}
          disablePortal={disablePortal}
          ref={setPopperRef}
          sx={{ zIndex: 1300 }}
          modifiers={[
            {
              name: "flip",
              enabled: true,
              options: {
                altBoundary: true,
                rootBoundary: "document",
                padding: 8,
              },
            },
            {
              name: "offset",
              options: {
                offset: [0, 20],
              },
            },
            {
              name: "arrow",
              enabled: true,
              options: {
                element: arrowRef,
              },
            },
          ]}
        >
          {({ TransitionProps }) => (
            <ClickAwayListener onClickAway={handleClickAway}>
              <Fade {...TransitionProps} timeout={350}>
                <PopperContentContainer>
                  <Arrow ref={setArrowRef} placement={placement} />
                  <List>
                    {Object.values(options).map((curStatus) => {
                      return (
                        <StyledListItem
                          key={curStatus.id}
                          onClick={() => handleItemClick(curStatus)}
                        >
                          {status === curStatus.id ? (
                            <SelectedIcon color={curStatus.color} />
                          ) : (
                            <UnselectedIcon color={curStatus.color} />
                          )}
                          <Typography
                            variant="body2"
                            ml={1}
                            color={curStatus.color}
                          >
                            {curStatus.label}
                          </Typography>
                        </StyledListItem>
                      );
                    })}
                  </List>
                </PopperContentContainer>
              </Fade>
            </ClickAwayListener>
          )}
        </Popper>
      )}
    </Box>
  );
};
