import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { OldStyledCountriesSelect } from "components/CountriesSelect";
import {
  AddCompanyInput,
  AddressInput,
  Company,
  CompanyType,
} from "generated/graphql";
import { LogoPlaceholder, StyledUploadBtn } from "./CompanyForm.styled";
import { CompanyFormSection } from "./CompanyFormSection";
import {
  companyDataValidators,
  CompanyTypeMapping,
  defaultCompanyData,
} from "./CompanyForm.constants";
import { UploadIcon } from "../../../../../../components/Icons/UploadIcon";
import { CustomLabel } from "react-flags-select/build/types";
import { validateData } from "helpers/validators";
import { Overlay } from "components/Overlay";
import {
  countriesData,
  getCountryCodeByName,
} from "helpers/countries/countries";
import { useTranslation } from "react-i18next";
import { cleanseBase64Image } from "helpers/miscelaneous";

export type CompanyFormProps = {
  company?: Company;
  disabled?: boolean;
  onChange?: (companyData: AddCompanyInput | Company) => void;
  onLastFieldTabPressed?: () => void;
};

export type CompanyFormPublicApi = {
  validate: () => boolean;
};

export const CompanyForm = React.forwardRef(
  (
    { company, onChange, disabled, onLastFieldTabPressed }: CompanyFormProps,
    ref: React.Ref<CompanyFormPublicApi>
  ) => {
    const { t } = useTranslation();
    const [formData, setFormData] = useState<AddCompanyInput | Company>(
      company || defaultCompanyData
    );
    const [selectedPhysicalCountryCode, setSelectedPhysicalCountryCode] =
      useState("");
    const [selectedPostalCountryCode, setSelectedPostalCountryCode] =
      useState("");
    const [sameAddresses, setSameAddresses] = useState(false);

    const [formDataErrors, setFormDataErrors] = useState<{
      [key: string]: string;
    }>({});

    const handleCountryChange = (
      code: string,
      path: "physicalAddress" | "postalAddress"
    ) => {
      if (path === "physicalAddress") {
        setSelectedPhysicalCountryCode(code);
      } else {
        setSelectedPostalCountryCode(code);
      }
      setFormData((curFormData) => ({
        ...curFormData,
        [path]: {
          ...(curFormData[path] as AddressInput),
          country: (countriesData[code] as CustomLabel).primary,
        },
      }));
    };

    const handleTextFieldChange: React.ChangeEventHandler<
      HTMLTextAreaElement | HTMLInputElement
    > = (evt) => {
      const path = evt.target.name.split(".");

      if (path.length === 2) {
        // handling just 2 levels of depth. Use lodash `get` otherwhise
        setFormData((curData) => ({
          ...curData,
          [path[0]]: {
            ...(curData[
              path[0] as keyof AddCompanyInput
            ] as Partial<AddCompanyInput>),
            [path[1]]: evt.target.value,
          },
        }));
      } else {
        setFormData((curData) => ({
          ...curData,
          [evt.target.name]: evt.target.value,
        }));
      }

      setFormDataErrors((curFormDataErrs) => {
        const { [evt.target.name]: _, ...rest } = curFormDataErrs;

        return rest;
      });
    };

    const handlePhotoUpload = (photoBase64: string) => {
      setFormData((curData) => ({
        ...curData,
        logo: photoBase64,
      }));
    };

    const handleCompanyTypeChange = (
      event: SelectChangeEvent<CompanyType | null>
    ) => {
      setFormData((curData) => ({
        ...curData,
        type: event.target.value as CompanyType,
      }));
    };

    const handleLastFieldKeyDown: React.KeyboardEventHandler<HTMLDivElement> = (
      evt
    ) => {
      if (evt.key === "Tab" && onLastFieldTabPressed) {
        evt.stopPropagation();
        evt.preventDefault();

        onLastFieldTabPressed();
      }
    };

    const validateForm = useCallback((formData: AddCompanyInput | Company) => {
      const validationResult = validateData(formData, companyDataValidators);

      if (validationResult.valid) {
        setFormDataErrors({});
        return true;
      }
      setFormDataErrors(validationResult.errors);
      return false;
    }, []);

    const handleSameAddressesChange = (
      _: React.ChangeEvent<HTMLInputElement>,
      checked: boolean
    ) => {
      setSameAddresses(checked);
    };

    useEffect(() => {
      onChange?.({
        ...formData,
        postalAddress: sameAddresses
          ? formData.physicalAddress
          : formData.postalAddress,
        logo: cleanseBase64Image(formData.logo),
      });
    }, [formData, onChange, sameAddresses]);

    useEffect(() => {
      if (company) {
        setFormData(company);
        if (company.physicalAddress?.country) {
          setSelectedPhysicalCountryCode(
            getCountryCodeByName(company.physicalAddress.country) || ""
          );
        }
        if (company.postalAddress?.country) {
          setSelectedPostalCountryCode(
            getCountryCodeByName(company.postalAddress.country) || ""
          );
        }
        validateForm(company as Company);
      }
    }, [company, validateForm]);

    useImperativeHandle(
      ref,
      () => ({
        validate: () => validateForm(formData),
      }),
      [validateForm, formData]
    );

    return (
      <>
        <>
          {disabled && <Overlay withBackground />}
          <CompanyFormSection
            title={t("AdminConsole.Companies.labels.companyDetails")}
          >
            <Grid container spacing={6}>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  name="registeredName"
                  value={formData.registeredName}
                  onChange={handleTextFieldChange}
                  type="text"
                  label={t("AdminConsole.Companies.labels.registeredName")}
                  variant="standard"
                  error={!!formDataErrors.registeredName}
                  helperText={formDataErrors.registeredName}
                  InputLabelProps={{ shrink: true }}
                  required
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  name="tradingName"
                  value={formData.tradingName}
                  onChange={handleTextFieldChange}
                  type="text"
                  label={t("AdminConsole.Companies.labels.tradingName")}
                  variant="standard"
                  error={!!formDataErrors.tradingName}
                  helperText={formDataErrors.tradingName}
                  InputLabelProps={{ shrink: true }}
                  required
                />
              </Grid>
              <Grid item md={3} xs={6}>
                <FormControl
                  variant="standard"
                  sx={{ minWidth: 120 }}
                  fullWidth
                >
                  <>
                    <InputLabel id="company-type-select-label" shrink>
                      <Typography
                        color={formDataErrors.type ? "error" : "inherit"}
                      >{`${t(
                        "AdminConsole.Companies.labels.companyType"
                      )} *`}</Typography>
                    </InputLabel>
                    <Select
                      labelId="company-type-select-label"
                      id="company-type-select"
                      value={formData.type}
                      onChange={handleCompanyTypeChange}
                      error={!!formDataErrors.type}
                    >
                      {Object.entries(CompanyTypeMapping).map(
                        ([key, value]) => (
                          <MenuItem key={key} value={key}>
                            {value}
                          </MenuItem>
                        )
                      )}
                    </Select>
                    {!!formDataErrors.type && (
                      <Typography variant="caption" color="error" mt={0.5}>
                        {formDataErrors.type}
                      </Typography>
                    )}
                  </>
                </FormControl>
              </Grid>
              <Grid item md={3} xs={6}>
                <TextField
                  fullWidth
                  name="registrationNumber"
                  value={formData.registrationNumber}
                  onChange={handleTextFieldChange}
                  type="text"
                  label={t("AdminConsole.Companies.labels.registrationNumber")}
                  variant="standard"
                  error={!!formDataErrors.registrationNumber}
                  helperText={formDataErrors.registrationNumber}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item md={3} xs={6}>
                <TextField
                  fullWidth
                  name="vatRegistrationNumber"
                  value={formData.vatRegistrationNumber}
                  onChange={handleTextFieldChange}
                  type="text"
                  label={t(
                    "AdminConsole.Companies.labels.vatRegistrationNumber"
                  )}
                  variant="standard"
                  error={!!formDataErrors.vatRegistrationNumber}
                  helperText={formDataErrors.vatRegistrationNumber}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid
                item
                md={3}
                xs={6}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <>
                  {formData.logo ? (
                    <img
                      style={{ maxHeight: "48px" }}
                      alt="company logo"
                      src={formData.logo || ""}
                    />
                  ) : (
                    <LogoPlaceholder>
                      <Typography variant="caption" textAlign="center">
                        {t("AdminConsole.Companies.labels.companyLogo")}
                      </Typography>
                    </LogoPlaceholder>
                  )}
                  <StyledUploadBtn
                    caption={
                      <Box display="flex" alignItems="center">
                        <UploadIcon />
                      </Box>
                    }
                    onPhotoUpload={handlePhotoUpload}
                  />
                </>
              </Grid>
            </Grid>
          </CompanyFormSection>
          <CompanyFormSection
            title={t("AdminConsole.Companies.labels.physicalAddress")}
            mt={8}
          >
            <Grid container spacing={6}>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  name="physicalAddress.line1"
                  value={formData.physicalAddress?.line1}
                  onChange={handleTextFieldChange}
                  type="text"
                  label={t("AdminConsole.Companies.labels.addressLine1")}
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  name="physicalAddress.line2"
                  value={formData.physicalAddress?.line2}
                  onChange={handleTextFieldChange}
                  type="text"
                  label={t("AdminConsole.Companies.labels.addressLine2")}
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  name="physicalAddress.city"
                  value={formData.physicalAddress?.city}
                  onChange={handleTextFieldChange}
                  type="text"
                  label={t("AdminConsole.Companies.labels.city")}
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  name="physicalAddress.provinceState"
                  value={formData.physicalAddress?.provinceState}
                  onChange={handleTextFieldChange}
                  type="text"
                  label={t("AdminConsole.Companies.labels.provinceOrState")}
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <FormControl fullWidth>
                  <>
                    <FormLabel id="physicaladdress-country-select">
                      <Typography variant="caption">
                        {t("AdminConsole.Companies.labels.country")}
                      </Typography>
                    </FormLabel>
                    <OldStyledCountriesSelect
                      selected={selectedPhysicalCountryCode}
                      onSelect={(countryCode: string) =>
                        handleCountryChange(countryCode, "physicalAddress")
                      }
                      customLabels={countriesData}
                      placeholder={t("common.labels.selectCountry")}
                      showSecondarySelectedLabel
                      showSelectedLabel
                      showSecondaryOptionLabel
                      showOptionLabel
                      searchable
                    />
                  </>
                </FormControl>
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  name="physicalAddress.code"
                  value={formData.physicalAddress?.code}
                  onChange={handleTextFieldChange}
                  type="text"
                  label={t("AdminConsole.Companies.labels.code")}
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                  onKeyDown={sameAddresses ? handleLastFieldKeyDown : undefined}
                />
              </Grid>
            </Grid>
          </CompanyFormSection>

          <Box mt={8}>
            <FormControlLabel
              label={
                <Typography variant="caption">
                  {t("AdminConsole.Companies.labels.sameAddress")}
                </Typography>
              }
              labelPlacement="end"
              control={
                <Checkbox
                  inputProps={{
                    "aria-label": "Agreement",
                  }}
                  onChange={handleSameAddressesChange}
                  checked={sameAddresses}
                />
              }
            />
          </Box>
          <CompanyFormSection
            title={t("AdminConsole.Companies.labels.postalAddress")}
            disabled={sameAddresses}
            mt={0}
          >
            {/* TODO ADI B. extract it into an address form if encountered again. To keep the validation 1 level of depth, I decided to duplicate the code here. */}
            <Grid container spacing={6}>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  name="postalAddress.line1"
                  value={
                    sameAddresses
                      ? formData.physicalAddress?.line1
                      : formData.postalAddress?.line1
                  }
                  onChange={handleTextFieldChange}
                  type="text"
                  label={t("AdminConsole.Companies.labels.addressLine1")}
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  name="postalAddress.line2"
                  value={
                    sameAddresses
                      ? formData.physicalAddress?.line2
                      : formData.postalAddress?.line2
                  }
                  onChange={handleTextFieldChange}
                  type="text"
                  label={t("AdminConsole.Companies.labels.addressLine2")}
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  name="postalAddress.city"
                  value={
                    sameAddresses
                      ? formData.physicalAddress?.city
                      : formData.postalAddress?.city
                  }
                  onChange={handleTextFieldChange}
                  type="text"
                  label={t("AdminConsole.Companies.labels.city")}
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  name="postalAddress.provinceState"
                  value={
                    sameAddresses
                      ? formData.physicalAddress?.provinceState
                      : formData.postalAddress?.provinceState
                  }
                  onChange={handleTextFieldChange}
                  type="text"
                  label={t("AdminConsole.Companies.labels.provinceOrState")}
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <FormControl fullWidth>
                  <>
                    <FormLabel id="postaladdress-country-select">
                      <Typography variant="caption">
                        {t("AdminConsole.Companies.labels.country")}
                      </Typography>
                    </FormLabel>
                    <OldStyledCountriesSelect
                      selected={
                        sameAddresses
                          ? selectedPhysicalCountryCode
                          : selectedPostalCountryCode
                      }
                      onSelect={(countryCode: string) =>
                        handleCountryChange(countryCode, "postalAddress")
                      }
                      placeholder={t("common.labels.selectCountry")}
                      customLabels={countriesData}
                      showSecondarySelectedLabel
                      showSelectedLabel
                      showSecondaryOptionLabel
                      showOptionLabel
                      searchable
                    />
                  </>
                </FormControl>
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  name="postalAddress.code"
                  value={
                    sameAddresses
                      ? formData.physicalAddress?.code
                      : formData.postalAddress?.code
                  }
                  onChange={handleTextFieldChange}
                  type="text"
                  label={t("AdminConsole.Companies.labels.code")}
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                  onKeyDown={handleLastFieldKeyDown}
                />
              </Grid>
            </Grid>
          </CompanyFormSection>
        </>
      </>
    );
  }
);
