import { CompEventWidgetContext } from "containers/Projects/components/CompEvents/CompEventWidget/CompEventWidget.context";
import { OnMutationDone } from "decl";
import {
  CompEventActionType,
  CompEventNoticeOfSilenceCompEventNoticePromptQuery,
  CompEventNoticeOfSilenceCompEventNoticePromptQueryVariables,
  CompEventNoticeOfSilenceOwnAssessmentPromptQuery,
  CompEventNoticeOfSilenceOwnAssessmentPromptQueryVariables,
  CompEventNoticeOfSilenceQuotationPromptQuery,
  CompEventNoticeOfSilenceQuotationPromptQueryVariables,
  RecordCompEventNoticeOfSilenceCompEventNoticeMutation,
  RecordCompEventNoticeOfSilenceCompEventNoticeMutationVariables,
  RecordCompEventNoticeOfSilenceOwnAssessmentMutation,
  RecordCompEventNoticeOfSilenceOwnAssessmentMutationVariables,
  RecordCompEventNoticeOfSilenceQuotationMutation,
  RecordCompEventNoticeOfSilenceQuotationMutationVariables,
  SendCompEventNoticeOfSilenceCompEventNoticeMutation,
  SendCompEventNoticeOfSilenceCompEventNoticeMutationVariables,
  SendCompEventNoticeOfSilenceOwnAssessmentMutation,
  SendCompEventNoticeOfSilenceOwnAssessmentMutationVariables,
  SendCompEventNoticeOfSilenceQuotationMutation,
  SendCompEventNoticeOfSilenceQuotationMutationVariables,
} from "generated/graphql";
import { recordCompEventNoticeOfSilenceCompEventNoticeMutation } from "graphql/mutations/recordCompEventNoticeOfSilenceCompEventNotice";
import { recordCompEventNoticeOfSilenceOwnAssessmentMutation } from "graphql/mutations/recordCompEventNoticeOfSilenceOwnAssessment";
import { recordCompEventNoticeOfSilenceQuotationMutation } from "graphql/mutations/recordCompEventNoticeOfSilenceQuotation";
import { sendCompEventNoticeOfSilenceCompEventNoticeMutation } from "graphql/mutations/sendCompEventNoticeOfSilenceCompEventNotice";
import { sendCompEventNoticeOfSilenceOwnAssessmentMutation } from "graphql/mutations/sendCompEventNoticeOfSilenceOwnAssessment";
import { sendCompEventNoticeOfSilenceQuotationMutation } from "graphql/mutations/sendCompEventNoticeOfSilenceQuotation";
import { compEventNoticeOfSilenceCompEventNoticePromptQuery } from "graphql/queries/compEventNoticeOfSilenceCompEventNoticePrompt.query";
import { compEventNoticeOfSilenceOwnAssessmentPromptQuery } from "graphql/queries/compEventNoticeOfSilenceOwnAssessmentPrompt.query";
import { compEventNoticeOfSilenceQuotationPromptQuery } from "graphql/queries/compEventNoticeOfSilenceQuotationPrompt.query";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useGraphQuery } from "hooks/useGraphQuery";
import { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";

export const useNotifyOfSilenceCEAction = (type: CompEventActionType) => {
  const { t } = useTranslation();
  const { onChange, compEvent } = useContext(CompEventWidgetContext);

  const onUpdated: OnMutationDone = useCallback(
    (cache) => {
      cache.evict({ id: "ROOT_QUERY", fieldName: "compEventItems" });
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "compEventHistoryItems",
        args: { compEventId: compEvent?.id },
      });
      cache.gc();
      onChange?.();
    },
    [onChange, compEvent]
  );

  const {
    data: notifyOfSilenceCENotice,
    loading: notifyOfSilenceCENoticeLoading,
  } = useGraphQuery<
    CompEventNoticeOfSilenceCompEventNoticePromptQuery,
    CompEventNoticeOfSilenceCompEventNoticePromptQueryVariables
  >(compEventNoticeOfSilenceCompEventNoticePromptQuery, {
    variables: { input: { compEventId: compEvent!.id } },
    skip: type !== CompEventActionType.NotifySilenceCompEventNotice,
  });

  const {
    data: notifyOfSilenceCEQuotation,
    loading: notifyOfSilenceCEQuotationLoading,
  } = useGraphQuery<
    CompEventNoticeOfSilenceQuotationPromptQuery,
    CompEventNoticeOfSilenceQuotationPromptQueryVariables
  >(compEventNoticeOfSilenceQuotationPromptQuery, {
    variables: { input: { compEventId: compEvent!.id } },
    skip: type !== CompEventActionType.NotifySilenceQuotation,
  });

  const {
    data: notifyOfSilenceOwnAssessment,
    loading: notifyOfSilenceOwnAssessmentLoading,
  } = useGraphQuery<
    CompEventNoticeOfSilenceOwnAssessmentPromptQuery,
    CompEventNoticeOfSilenceOwnAssessmentPromptQueryVariables
  >(compEventNoticeOfSilenceOwnAssessmentPromptQuery, {
    variables: { input: { compEventId: compEvent!.id } },
    skip: type !== CompEventActionType.NotifySilenceOwnAssessment,
  });

  const [
    sendCENoticeOfSilenceCENotice,
    { loading: sendCENoticeOfSilenceCENoticeLoading },
  ] = useGraphMutation<
    SendCompEventNoticeOfSilenceCompEventNoticeMutation,
    SendCompEventNoticeOfSilenceCompEventNoticeMutationVariables
  >(
    sendCompEventNoticeOfSilenceCompEventNoticeMutation,
    {
      update: onUpdated,
    },
    t("common.successMessages.entitySent", {
      entity: t("Projects.CompEvents.compEventNoticeOfSilenceCENotification"),
    })
  );

  const [
    recordCENoticeOfSilenceCENotice,
    { loading: recordCENoticeOfSilenceCENoticeLoading },
  ] = useGraphMutation<
    RecordCompEventNoticeOfSilenceCompEventNoticeMutation,
    RecordCompEventNoticeOfSilenceCompEventNoticeMutationVariables
  >(
    recordCompEventNoticeOfSilenceCompEventNoticeMutation,
    {
      update: onUpdated,
    },
    t("common.successMessages.entityRecorded", {
      entity: t("Projects.CompEvents.compEventNoticeOfSilenceCENotification"),
    })
  );

  const [
    sendCENoticeOfSilenceCEQuotation,
    { loading: sendCENoticeOfSilenceCEQuotationLoading },
  ] = useGraphMutation<
    SendCompEventNoticeOfSilenceQuotationMutation,
    SendCompEventNoticeOfSilenceQuotationMutationVariables
  >(
    sendCompEventNoticeOfSilenceQuotationMutation,
    {
      update: onUpdated,
    },
    t("common.successMessages.entitySent", {
      entity: t("Projects.CompEvents.compEventNoticeOfSilenceCEQuotation"),
    })
  );

  const [
    recordCENoticeOfSilenceCEQuotation,
    { loading: recordCENoticeOfSilenceCEQuotationLoading },
  ] = useGraphMutation<
    RecordCompEventNoticeOfSilenceQuotationMutation,
    RecordCompEventNoticeOfSilenceQuotationMutationVariables
  >(
    recordCompEventNoticeOfSilenceQuotationMutation,
    {
      update: onUpdated,
    },
    t("common.successMessages.entityRecorded", {
      entity: t("Projects.CompEvents.compEventNoticeOfSilenceCEQuotation"),
    })
  );

  const [
    sendCENoticeOfSilenceCEOwnAssessment,
    { loading: sendCENoticeOfSilenceCEOwnAssessmentLoading },
  ] = useGraphMutation<
    SendCompEventNoticeOfSilenceOwnAssessmentMutation,
    SendCompEventNoticeOfSilenceOwnAssessmentMutationVariables
  >(
    sendCompEventNoticeOfSilenceOwnAssessmentMutation,
    {
      update: onUpdated,
    },
    t("common.successMessages.entitySent", {
      entity: t("Projects.CompEvents.compEventNoticeOfSilenceCEOwnAssessment"),
    })
  );

  const [
    recordCENoticeOfSilenceCEOwnAssessment,
    { loading: recordCENoticeOfSilenceCEOwnAssessmentLoading },
  ] = useGraphMutation<
    RecordCompEventNoticeOfSilenceOwnAssessmentMutation,
    RecordCompEventNoticeOfSilenceOwnAssessmentMutationVariables
  >(
    recordCompEventNoticeOfSilenceOwnAssessmentMutation,
    {
      update: onUpdated,
    },
    t("common.successMessages.entityRecorded", {
      entity: t("Projects.CompEvents.compEventNoticeOfSilenceCEOwnAssessment"),
    })
  );

  return {
    notifyOfSilenceCENoticePrompt:
      notifyOfSilenceCENotice?.compEventNoticeOfSilenceCompEventNoticePrompt,
    notifyOfSilenceCEQuotationPrompt:
      notifyOfSilenceCEQuotation?.compEventNoticeOfSilenceQuotationPrompt,
    notifyOfSilenceOwnAssessmentPrompt:
      notifyOfSilenceOwnAssessment?.compEventNoticeOfSilenceOwnAssessmentPrompt,
    sendCENoticeOfSilenceCENotice,
    recordCENoticeOfSilenceCENotice,
    sendCENoticeOfSilenceCEQuotation,
    recordCENoticeOfSilenceCEQuotation,
    sendCENoticeOfSilenceCEOwnAssessment,
    recordCENoticeOfSilenceCEOwnAssessment,
    loading:
      notifyOfSilenceCENoticeLoading ||
      notifyOfSilenceCEQuotationLoading ||
      notifyOfSilenceOwnAssessmentLoading,
    actionLoading:
      sendCENoticeOfSilenceCENoticeLoading ||
      recordCENoticeOfSilenceCENoticeLoading ||
      sendCENoticeOfSilenceCEQuotationLoading ||
      recordCENoticeOfSilenceCEQuotationLoading ||
      sendCENoticeOfSilenceCEOwnAssessmentLoading ||
      recordCENoticeOfSilenceCEOwnAssessmentLoading,
  };
};
