import { gql } from "@apollo/client";
import { compEventItemFields } from "graphql/fragments/compEventItem.fragment";
import { instructionItemBaseFields } from "graphql/fragments/instructionItem.fragment";
import { attachmentFields } from "../EarlyWarningDetails/EarlyWarningDetails.query";
import { productItemProductInstanceFields } from "../RiskDetails/RiskDetails.query";
import { authorizationWorkflowAuditFields } from "graphql/fragments/authorizationWorkflowAudit.fragment";
import { claimItemFields } from "graphql/fragments/claimItem.fragment";
import { variationItemFields } from "graphql/fragments/variationItem.fragment";
import { creatorFields } from "graphql/fragments/creator.fragment";
import { authorizationWorkflowActionMappingFields } from "graphql/fragments/authorizationWorkflowActionMapping.fragment";
import { authorizationWorkflowAuditTrailFields } from "graphql/fragments/authorizationWorkflowAuditTrail.fragment";
import { notificationRecipientFields } from "graphql/fragments/notificationRecipient.fragment";

const localAuthWorkflowAuditFields = gql`
  ${creatorFields}
  ${authorizationWorkflowActionMappingFields}
  ${authorizationWorkflowAuditTrailFields}

  fragment LocalAuthWorkflowAuditFields on AuthorizationWorkflowAudit {
    id
    initiatedById
    initiatedBy {
      ...CreatorFields
    }
    regardingProductItemId
    regardingProductType
    regardingItemHistoryId
    actionId
    action {
      ...AuthorizationWorkflowActionMappingFields
    }
    workflowId
    workflow {
      ...AuthorizationWorkflowFields
    }
    status
    dateCreated
    auditTrails {
      ...AuthorizationWorkflowAuditTrailFields
    }
  }
`;

export const instructionItemExtendedQuery = gql`
  ${instructionItemBaseFields}
  ${productItemProductInstanceFields}
  ${attachmentFields}
  ${compEventItemFields}
  ${authorizationWorkflowAuditFields}
  ${claimItemFields}
  ${variationItemFields}
  ${localAuthWorkflowAuditFields}
  ${notificationRecipientFields}

  query instructionItemExtended($id: ID!) {
    instructionItem(id: $id) {
      ...InstructionItemBaseFields
      productInstance {
        ...ProductInstanceFields
      }
      attachments {
        ...AttachmentFields
      }
      compEvent {
        ...CompEventItemFields
        ongoingAuthorizationAudit {
          ...LocalAuthWorkflowAuditFields
        }
      }
      claim {
        ...ClaimItemFields
        ongoingAuthorizationAudit {
          ...LocalAuthWorkflowAuditFields
        }
      }
      variation {
        ...VariationItemFields
        ongoingAuthorizationAudit {
          ...LocalAuthWorkflowAuditFields
        }
      }
      authorizationWorkflowAudit {
        ...AuthorizationWorkflowAuditFields # TODO: take only ID here and fetch authAudit only where necessary. Same in EW
      }
      notificationRecipients {
        ...NotificationRecipientFields
      }
    }
  }
`;
