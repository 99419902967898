import {
  ExtendedOptionStyles,
  StatusOptionNew,
} from "components/StatusTag/StatusTagNew";
import { ClaimStatus } from "generated/graphql";

export const claimStatusOptions: StatusOptionNew<string>[] = [
  {
    id: ClaimStatus.Accepted,
    label: ClaimStatus.Accepted,
    style: ExtendedOptionStyles.Green,
  },
  {
    id: ClaimStatus.Final,
    label: ClaimStatus.Final,
    style: ExtendedOptionStyles.Purple,
  },
  {
    id: ClaimStatus.Interim,
    label: ClaimStatus.Interim,
    style: ExtendedOptionStyles.Blue,
  },
  {
    id: ClaimStatus.NotNotified,
    label: ClaimStatus.NotNotified,
    style: ExtendedOptionStyles.Black,
  },
  {
    id: ClaimStatus.Notified,
    label: ClaimStatus.Notified,
    style: ExtendedOptionStyles.Grey,
  },
  {
    id: ClaimStatus.Rejected,
    label: ClaimStatus.Rejected,
    style: ExtendedOptionStyles.Red,
  },
];
