import { User } from "generated/graphql";

/**
 * BE is expecting just the image in base64 format, without the "data:image..." prefix
 * @param base64ImgStr
 * @returns The image data as base64, without prefix
 */
export const cleanseBase64Image = (base64ImgStr?: string | null) => {
  return base64ImgStr ? base64ImgStr.split(";base64,")[1] : undefined;
};

export const formatNumber = (
  nr: number,
  separator: string = " ",
  fraction: string = "."
) => {
  let str = nr.toLocaleString("en-US");
  str = str.replace(/\./, fraction);
  str = str.replace(/,/g, separator);

  return str;
};

export const omitTypename = (key: string, value: any) =>
  key === "__typename" ? undefined : value;

/* eslint-disable */
// TODO: check if still used
export const formatBytes = (a: number, b = 2) => {
  if (!+a) return "0 Bytes";
  const c = 0 > b ? 0 : b,
    d = Math.floor(Math.log(a) / Math.log(1024));
  return `${parseFloat((a / Math.pow(1024, d)).toFixed(c))} ${
    ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d]
  }`;
};
/* eslint-enable */

export const getUserName = (user?: User | null): string =>
  `${user?.firstname ?? ""} ${user?.surname ?? ""}`;

export const getUserNameOrEmail = (user?: User | null): string => {
  if (user) {
    const username = getUserName(user).trim();
    return username || user.email;
  }

  return "";
};

export const padTo2Digits = (num: number) => {
  return num.toString().padStart(2, "0");
};

export const minutesToHoursAndMinutes = (totalMinutes: number) => {
  const minutes = totalMinutes % 60;
  const hours = Math.floor(totalMinutes / 60);

  return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}`;
};

export const noop = () => {};
