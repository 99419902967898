import { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useGraphQuery } from "hooks/useGraphQuery";
import {
  RecordVariationObjectionVariationMutation,
  RecordVariationObjectionVariationMutationVariables,
  SendVariationObjectionVariationMutation,
  SendVariationObjectionVariationMutationVariables,
  VariationObjectionVariationPromptQuery,
  VariationObjectionVariationPromptQueryVariables,
} from "generated/graphql";
import { OnMutationDone } from "decl";
import { useGraphMutation } from "hooks/useGraphMutation";
import { VariationWidgetContext } from "../../VariationWidget/VariationWidget.context";
import {
  recordVariationObjectionVariationMutation,
  sendVariationObjectionVariationMutation,
  variationObjectionVariationPromptQuery,
} from "./ObjectToInstructionOfVariationAction.query";

export const useObjectToInstructionOfVariationAction = () => {
  const { t } = useTranslation();
  const { onChange, variation } = useContext(VariationWidgetContext);

  const { data: promptData, loading } = useGraphQuery<
    VariationObjectionVariationPromptQuery,
    VariationObjectionVariationPromptQueryVariables
  >(variationObjectionVariationPromptQuery, {
    variables: {
      input: {
        variationId: variation?.id!,
      },
    },
  });

  const onUpdated: OnMutationDone = useCallback(
    (cache) => {
      cache.evict({ id: "ROOT_QUERY", fieldName: "variationItems" });
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "variationProposalExplorerItems",
        args: { variationId: variation!.id },
      });
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "variationObjectionVariationPrompt",
        args: { input: { variationId: variation!.id } },
      });
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "variationAction",
      });
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "variationHistoryItems",
        args: { variationId: variation!.id },
      });
      cache.gc();
      onChange?.();
    },
    [onChange, variation]
  );

  const [sendVariationObjection, { loading: sendVariationObjectionLoading }] =
    useGraphMutation<
      SendVariationObjectionVariationMutation,
      SendVariationObjectionVariationMutationVariables
    >(
      sendVariationObjectionVariationMutation,
      { update: onUpdated },
      t("common.successMessages.entitySent", {
        entity: t("Projects.Variations.variationObjection"),
      })
    );

  const [
    recordVariationObjection,
    { loading: recordVariationObjectionLoading },
  ] = useGraphMutation<
    RecordVariationObjectionVariationMutation,
    RecordVariationObjectionVariationMutationVariables
  >(
    recordVariationObjectionVariationMutation,
    { update: onUpdated },
    t("common.successMessages.entityRecorded", {
      entity: t("Projects.Variations.variationObjection"),
    })
  );

  return {
    defaultText:
      promptData?.variationObjectionVariationPrompt.defaultText ?? "",
    loading,
    sendVariationObjection,
    recordVariationObjection,
    actionLoading:
      sendVariationObjectionLoading || recordVariationObjectionLoading,
  };
};
