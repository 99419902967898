import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { CenteredLoadingIndicator } from "components/CenteredLoadingIndicator";
import { CompEventWidgetContext } from "containers/Projects/components/CompEvents/CompEventWidget/CompEventWidget.context";
import {
  CompEventItem,
  CompEventAction as CompEventActionType,
  CompEventActionType as CEActionType,
  CompEventStatus,
  CompEventQuotation,
  DraftQuotation,
  ProductType,
} from "generated/graphql";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { CEWidgetNextStepSection } from "../../../CompEventWidget/components/CEWidgetNextStepSection/CEWidgetNextStepSection";
import { AcceptQuotationCEAction } from "../AcceptQuotationCEAction/AcceptQuotationCEAction";
import { useAcceptQuotationCEAction } from "../AcceptQuotationCEAction/useAcceptQuotationCEAction";
import { ConfirmCEAction } from "../ConfirmCEAction/ConfirmCEAction";
import { NotifyCEAction } from "../NotifyCEAction/NotifyCEAction";
import { NotifyOwnAssessmentCEAction } from "../NotifyOwnAssessmentCEAction/NotifyOwnAssessmentCEAction";
import { RejectCEAction } from "../RejectCEAction/RejectCEAction";
import { RequestRevisedQuotationCEAction } from "../RequestRevisedQuotationCEAction/RequestRevisedQuotationCEAction";
import { SubmitOwnAssessmentCEAction } from "../SubmitOwnAssessmentCEAction/SubmitOwnAssessmentCEAction";
import { QuotationsTable } from "../SubmitQuotationCEAction/QuotationsTable/QuotationsTable";
import { SubmitQuotationCEAction } from "../SubmitQuotationCEAction/SubmitQuotationCEAction";
import { compEventActionTypeTranslations } from "./CompEventAction.constants";
import { AbandonInstructionCEAction } from "../AbandonInstructionCEAction/AbandonInstructionCEAction";
import { NotifyAndRequestQuotationCEAction } from "../NotifyAndRequestQuotationCEAction/NotifyAndRequestQuotationCEAction";
import { RequestForMoreTimeCEAction } from "../RequestForMoreTimeCEAction/RequestForMoreTimeCEAction";
import { GrantMoreTimeToRespondCEAction } from "../GrantMoreTimeToRespondCEAction/GrantMoreTimeToRespondCEAction";
import { DenyMoreTimeToRespondCEAction } from "../DenyMoreTimeToRespondCEAction/DenyMoreTimeToRespondCEAction";
import { NotifyOfSilenceCEAction } from "../NotifyOfSilenceCEAction/NotifyOfSilenceCEAction";
import { AuthorizationRequiredPill } from "../../../../../../../components/Authorization/AuthorizationRequiredPill";
import { AuthorizationProgressPreview } from "../../../../../../../components/Authorization/AuthorizationProgressPreview";
import { useCEQuotationAssessmentModal } from "../../../CEQuotationAssessmentModal/useCEQuotationAssessmentModal";
import { CEQuotationAssessmentReadOnlyModal } from "../../../CEQuotationAssessmentReadOnlyModal/CEQuotationAssessmentReadOnlyModal";
import { WidgetHeader } from "containers/Projects/components/Widget/WidgetHeader";
import {
  CEWidgetSectionType,
  WidgetSection,
} from "containers/Projects/components/Widget/WidgetSection";
import { ActionModalFooter } from "containers/Projects/components/ActionModal/ActionModalFooter";

export type CompEventActionViewProps = {
  compEvent?: CompEventItem;
  compEventActions: CompEventActionType[];
  onCancel: () => void; // Note: this callback is called even when modal auto-closes after a successful action
};

export type CEFormPublicApi = {
  validate: () => boolean;
  record: () => void;
  send: () => void;
};

export type CEActionFormProps = {
  onClose: () => void;
  apiRef?: React.Ref<CEFormPublicApi>;
};

export const CompEventActionView: React.FC<CompEventActionViewProps> = ({
  compEvent,
  compEventActions,
  onCancel,
}) => {
  const { t } = useTranslation();
  const { contract, recordSupported } = useContext(CompEventWidgetContext);

  const [actionAwaitingAuthorization, setActionAwaitingAuthorization] =
    useState(false);
  const [selectedAction, setSelectedAction] = useState<CEActionType>();
  const [selectedQuotation, setSelectedQuotation] =
    useState<CompEventQuotation>();
  const formAPIRef = useRef<CEFormPublicApi | null>(null);

  const selectedActionFull = useMemo(
    () => compEventActions.find((action) => action.type === selectedAction),
    [compEventActions, selectedAction]
  );

  const { quotations, loading: quotationsLoading } = useAcceptQuotationCEAction(
    !compEvent || compEvent?.status !== CompEventStatus.Quoted
  );
  const {
    modalVisibility: quotationModalVisibility,
    toggleModalVisibility: toggleQuotationModalVisibility,
  } = useCEQuotationAssessmentModal();

  const handleQuotationClick = (
    quotation: DraftQuotation | CompEventQuotation
  ) => {
    setSelectedQuotation(quotation as CompEventQuotation);
    toggleQuotationModalVisibility();
  };

  const handleActionChange = (event: SelectChangeEvent<CEActionType>) => {
    setSelectedAction(event.target.value as CEActionType);
  };

  const renderCompEventActionForm = useCallback(
    (actionType: CEActionType) => {
      const ongoingAuthorizationProductOutputName =
        compEvent?.ongoingAuthorizationAudit?.action.productOutputAction.name;

      if (
        !!ongoingAuthorizationProductOutputName &&
        selectedActionFull?.actionMapping?.productOutputAction.name ===
          ongoingAuthorizationProductOutputName
      ) {
        // action already awaiting authorization. UseEffect will sort out the error display
        return;
      }

      switch (actionType) {
        case CEActionType.NotifyCompEvent:
          return <NotifyCEAction apiRef={formAPIRef} onClose={onCancel} />;
        case CEActionType.NotifyCompEventRequestQuotation:
          return (
            <NotifyAndRequestQuotationCEAction
              onClose={onCancel}
              apiRef={formAPIRef}
            />
          );
        case CEActionType.ConfirmCompEvent:
          return (
            <ConfirmCEAction
              apiRef={formAPIRef}
              onClose={onCancel}
              triggersAuthWorkflow={!!selectedActionFull?.actionMapping}
            />
          );
        case CEActionType.RejectCompEvent:
          return <RejectCEAction apiRef={formAPIRef} onClose={onCancel} />;
        case CEActionType.RequestMoreTime:
          return (
            <RequestForMoreTimeCEAction
              apiRef={formAPIRef}
              onClose={onCancel}
            />
          );
        case CEActionType.GrantRequestMoreTime:
          return (
            <GrantMoreTimeToRespondCEAction
              onClose={onCancel}
              apiRef={formAPIRef}
            />
          );
        case CEActionType.DenyRequestMoreTime:
          return (
            <DenyMoreTimeToRespondCEAction
              onClose={onCancel}
              apiRef={formAPIRef}
            />
          );
        case CEActionType.AbandonInstruction:
          return (
            <AbandonInstructionCEAction
              onClose={onCancel}
              apiRef={formAPIRef}
            />
          );
        case CEActionType.SubmitQuotation:
        case CEActionType.SubmitRevisedQuotation:
          return (
            <SubmitQuotationCEAction apiRef={formAPIRef} onClose={onCancel} />
          );
        case CEActionType.SubmitOwnAssessment:
          return (
            <SubmitOwnAssessmentCEAction
              apiRef={formAPIRef}
              onClose={onCancel}
              triggersAuthWorkflow={!!selectedActionFull?.actionMapping}
            />
          );
        case CEActionType.AcceptQuotation:
          return (
            <AcceptQuotationCEAction
              apiRef={formAPIRef}
              onClose={onCancel}
              triggersAuthWorkflow={!!selectedActionFull?.actionMapping}
            />
          );
        case CEActionType.NotifyOwnAssessment:
          return (
            <NotifyOwnAssessmentCEAction
              apiRef={formAPIRef}
              onClose={onCancel}
            />
          );
        case CEActionType.NotifySilenceCompEventNotice:
        case CEActionType.NotifySilenceQuotation:
        case CEActionType.NotifySilenceOwnAssessment:
          return (
            <NotifyOfSilenceCEAction
              type={actionType}
              apiRef={formAPIRef}
              onClose={onCancel}
            />
          );
        case CEActionType.RequestRevisedQuotation:
          return (
            <RequestRevisedQuotationCEAction
              apiRef={formAPIRef}
              onClose={onCancel}
            />
          );
        default:
          return null;
      }
    },
    [onCancel, selectedActionFull, compEvent?.ongoingAuthorizationAudit]
  );

  const handleRecord = () => {
    if (formAPIRef.current?.validate()) {
      formAPIRef.current?.record();
    }
  };

  const handleSend = () => {
    if (formAPIRef.current?.validate()) {
      formAPIRef.current?.send();
    }
  };

  useEffect(() => {
    // TODO: this is subject to being removed as BE probably updated the actionList such that it does not allow users to re-do the same action again
    // TODO: verify this in this patch
    if (selectedActionFull) {
      const ongoingAuthorizationProductOutputName =
        compEvent?.ongoingAuthorizationAudit?.action.productOutputAction.name;

      setActionAwaitingAuthorization(
        !!ongoingAuthorizationProductOutputName &&
          selectedActionFull.actionMapping?.productOutputAction.name ===
            ongoingAuthorizationProductOutputName
      );
    }
  }, [selectedActionFull, compEvent?.ongoingAuthorizationAudit]);

  return (
    <Box display="flex" flexDirection="column" pt={1}>
      {compEvent && (
        <Box mb={3}>
          <WidgetHeader
            productItem={compEvent}
            productType={ProductType.CompEvents}
          />
        </Box>
      )}
      {compEvent?.ongoingAuthorizationAudit && (
        <Box mb={2}>
          <AuthorizationProgressPreview
            authorizationWorkflow={
              compEvent?.ongoingAuthorizationAudit.workflow
            }
            authorizationWorkflowAudit={
              compEvent?.ongoingAuthorizationAudit ?? undefined
            }
          />
        </Box>
      )}
      <WidgetSection type={CEWidgetSectionType.NextStep}>
        <CEWidgetNextStepSection compEvent={compEvent} previewMode />
      </WidgetSection>
      {compEvent?.status === CompEventStatus.Quoted &&
        (!selectedAction ||
          [
            CEActionType.AcceptQuotation,
            CEActionType.NotifySilenceQuotation,
            CEActionType.NotifySilenceOwnAssessment,
          ].indexOf(selectedAction) < 0) && (
          <Box mt={2}>
            {quotationsLoading ? (
              <CenteredLoadingIndicator />
            ) : (
              <>
                {quotationModalVisibility && selectedQuotation && (
                  <CEQuotationAssessmentReadOnlyModal
                    open={quotationModalVisibility}
                    onClose={toggleQuotationModalVisibility}
                    quotationAssessment={selectedQuotation}
                    contract={contract}
                  />
                )}
                <QuotationsTable
                  quotations={quotations}
                  contractCurrency={contract.valueCurrency ?? ""}
                  onRowClick={handleQuotationClick}
                />
              </>
            )}
          </Box>
        )}
      <WidgetSection type={CEWidgetSectionType.ActionSelection} mt={2}>
        <Typography variant="p1" color="grey.800" fontWeight={600} mb={1.25}>
          {t("Projects.CompEvents.ActionModal.actionsAvailable")}
        </Typography>
        <FormControl fullWidth>
          {!selectedAction && (
            <InputLabel shrink={false} sx={{ top: "-5px" }}>
              {t("Projects.CompEvents.ActionModal.actionPlaceholder")}
            </InputLabel>
          )}
          <Select
            labelId="actions-available-label"
            id="actions-available-id"
            value={selectedAction ?? ""}
            onChange={handleActionChange}
            data-testid="ce-action-dropdown"
            fullWidth
            size="small"
            sx={{ backgroundColor: "white" }}
            required
            SelectDisplayProps={{
              style: { display: "flex", alignItems: "center" },
            }}
          >
            {compEventActions.map((compEventActionType) => (
              <MenuItem
                key={compEventActionType.type}
                value={compEventActionType.type}
                data-testid={`ce-action-${compEventActionType.type}-option`}
              >
                {t(compEventActionTypeTranslations[compEventActionType.type])}
                {compEventActionType.actionMapping ? (
                  <Box ml={1}>
                    <AuthorizationRequiredPill />
                  </Box>
                ) : null}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </WidgetSection>
      {selectedAction && !actionAwaitingAuthorization && (
        <Box mt={2}>{renderCompEventActionForm(selectedAction)}</Box>
      )}
      {/* TODO: maybe this is not needed anymore, if user cannot select the same action twice */}
      {actionAwaitingAuthorization && (
        <Box mt={1}>
          <Typography variant="caption" color="error" mt={0.5}>
            {t("Home.Authorizations.actionAwaitingAuthorization")}
          </Typography>
        </Box>
      )}
      {/* TODO `authorizationWorkflow` should be present if requiresAuthorization is true - delete extra check after BE is fixed */}
      {selectedActionFull &&
        selectedActionFull.actionMapping &&
        !actionAwaitingAuthorization && (
          <Box mt={3}>
            <AuthorizationProgressPreview
              authorizationWorkflow={selectedActionFull.actionMapping.workflow}
            />
          </Box>
        )}
      <Box display="flex" justifyContent="flex-end" mt={3}>
        <ActionModalFooter
          sendDisabled={actionAwaitingAuthorization}
          recordDisabled={actionAwaitingAuthorization}
          recordSupported={recordSupported}
          onCancel={onCancel}
          onRecord={handleRecord}
          onSend={handleSend}
        />
      </Box>
    </Box>
  );
};
