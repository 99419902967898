import { Box, Typography, useTheme } from "@mui/material";
import { NewThemeStyledLink } from "components/NewThemeStyledLink";
import {
  ActionAuthorizedDetails,
  ActionPreventedDetails,
  AttachmentAddedDetails,
  AttachmentDescriptionAddedDetails,
  AttachmentRemovedDetails,
  AuthorizationRequiredDetails,
  Notification,
  ProductType,
  RiskEditedDetails,
  RiskOwnerChangedDetails,
  RiskStatusChangedDetails,
} from "generated/graphql";
import { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { getEventType, EventType } from "./Notifications.utils";
import {
  isFIDIC17WhiteContractType,
  isFIDIC99RedYellowContractType,
} from "containers/Projects/Projects.utils";
import { getUserName } from "helpers/miscelaneous";

export const NotificationTextContent: React.FC<{
  notification: Notification;
  onClick: () => void;
}> = ({ notification, onClick }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const notificationType = useMemo(
    () => getEventType(notification),
    [notification]
  );
  const creatorName = useMemo(
    () => getUserName(notification.creator),
    [notification]
  );
  const productItemType = useMemo(() => {
    switch (notification.productType) {
      case ProductType.EarlyWarnings:
        return t("common.labels.ew");
      case ProductType.Instructions:
        return t("common.labels.instruction");
      case ProductType.Events:
        return t("common.labels.event");
      case ProductType.RisksRegister:
      default:
        return t("common.labels.risk");
    }
  }, [notification, t]);
  const transComponentsConfig = useMemo(
    () => ({
      bold: <Typography variant="p2" fontWeight={600} />,
      boldClickableLink: (
        <NewThemeStyledLink colorTone="darker" onClick={onClick}>
          <Typography variant="p2" fontWeight={600}></Typography>
        </NewThemeStyledLink>
      ),
    }),
    [onClick]
  );
  const TextContent = useMemo(() => {
    const productItemName = notification.details.title;
    const isFIDIC17White = isFIDIC17WhiteContractType(notification.contract);
    const isFIDIC99RedYellow = isFIDIC99RedYellowContractType(
      notification.contract
    );
    const claimNotifications99RYSpecific = [
      EventType.ClaimNoticeSent,
      EventType.ClaimNoticeRecorded,
      EventType.ClaimNoticeInvalidNoticeSent,
      EventType.ClaimNoticeInvalidNoticeRecorded,
      EventType.ClaimInterimClaimDetailsSent,
      EventType.ClaimInterimClaimDetailsRecorded,
      EventType.ClaimFinalClaimDetailsSent,
      EventType.ClaimFinalClaimDetailsRecorded,
      EventType.ClaimNoticeLapsedNoticeSent,
      EventType.ClaimNoticeLapsedNoticeRecorded,
      EventType.ClaimFurtherParticularsRequestSent,
      EventType.ClaimFurtherParticularsRequestRecorded,
      EventType.ClaimFurtherParticularsSubmissionSent,
      EventType.ClaimFurtherParticularsSubmissionRecorded,
      EventType.ClaimDetailsResponseSent,
      EventType.ClaimDetailsResponseRecorded,
    ];

    const variationNotifications99RYSpecific = [
      EventType.VariationProposalConsentForProposedVariationSent,
      EventType.VariationProposalConsentForProposedVariationRecorded,
      EventType.VariationProposalAbandonmentSent,
      EventType.VariationProposalAbandonmentRecorded,
    ];

    const variationNotifications17WhiteSpecific = [
      EventType.VariationConfirmationSent,
      EventType.VariationConfirmationRecorded,
      EventType.VariationProposalConsentForProposedVariationSent,
      EventType.VariationProposalConsentForProposedVariationRecorded,
      EventType.VariationProposalAbandonmentSent,
      EventType.VariationProposalAbandonmentRecorded,
      EventType.VariationRevocationSent,
      EventType.VariationRevocationRecorded,
    ];

    switch (notificationType) {
      case EventType.ActionAuthorized:
        return (
          <Trans
            i18nKey="Notifications.ActionAuthorized"
            values={{
              action: t(
                `Notifications.authorizationActionType.${
                  (notification.details as ActionAuthorizedDetails).actionName
                }`
              ),
              productItemName,
            }}
            components={transComponentsConfig}
          />
        );
      case EventType.ActionPrevented:
        return (
          <Trans
            i18nKey={`Notifications.ActionPrevented${
              [ProductType.DailyDiary].includes(notification.productType)
                ? ""
                : [
                    ProductType.CompEvents,
                    ProductType.Claims,
                    ProductType.Variations,
                    ProductType.EarlyWarnings,
                    ProductType.Instructions,
                  ].includes(notification.productType)
                ? "Authorizable"
                : ""
            }`}
            values={{
              action: t(
                `Notifications.authorizationActionType.${
                  (notification.details as ActionPreventedDetails).actionName
                }`
              ),
              productItemName,
            }}
            components={transComponentsConfig}
          />
        );
      case EventType.AuthorizationRequired:
        return (
          <Trans
            i18nKey={`Notifications.AuthorizationRequired${
              [ProductType.DailyDiary].includes(notification.productType) // Only Diaries exist before being authorised. (EWs/Instructions are created after approving phase)
                ? "Clickable"
                : [
                    ProductType.CompEvents,
                    ProductType.Claims,
                    ProductType.Variations,
                    ProductType.EarlyWarnings,
                    ProductType.Instructions,
                  ].includes(notification.productType)
                ? "Authorizable"
                : ""
            }`}
            values={{
              action: t(
                `Notifications.authorizationActionType.${
                  (notification.details as AuthorizationRequiredDetails)
                    .actionName
                }`
              ),
              productItemName,
              initiatorName: (
                notification.details as AuthorizationRequiredDetails
              ).initiatorName,
            }}
            components={transComponentsConfig}
          />
        );
      case EventType.RiskAdded:
      case EventType.EventAdded:
        return (
          <Trans
            i18nKey="Notifications.productItemCreated"
            values={{
              user: creatorName,
              productItem: productItemType,
              productItemName,
            }}
            components={transComponentsConfig}
          />
        );
      case EventType.RiskEdited:
      case EventType.EventEdited:
      case EventType.InstructionEdited:
      case EventType.EwEdited:
        return (
          <Trans
            i18nKey="Notifications.productItemUpdated"
            values={{
              user: creatorName,
              productItem: productItemType,
              productItemName,
              fields: (notification.details as RiskEditedDetails).fieldChanges
                .map((fieldChange) => fieldChange.fieldName)
                .join(", "),
            }}
            components={transComponentsConfig}
          />
        );
      case EventType.RiskOwnerChanged:
      case EventType.EventOwnerChanged:
      case EventType.EwOwnerChanged:
      case EventType.InstructionOwnerChanged:
        return (
          <Trans
            i18nKey="Notifications.ownerChanged"
            values={{
              user: creatorName,
              productItem: productItemType,
              productItemName,
              oldOwner: (notification.details as RiskOwnerChangedDetails)
                .oldOwner,
              newOwner: (notification.details as RiskOwnerChangedDetails)
                .newOwner,
            }}
            components={transComponentsConfig}
          />
        );
      case EventType.RiskStatusChanged:
      case EventType.EwStatusChanged:
      case EventType.EventStatusChanged:
      case EventType.InstructionStatusChanged:
        return (
          <Trans
            i18nKey="Notifications.statusChanged"
            values={{
              user: creatorName,
              productItem: productItemType,
              productItemName,
              oldStatus: (notification.details as RiskStatusChangedDetails)
                .oldStatus,
              newStatus: (notification.details as RiskStatusChangedDetails)
                .newStatus,
            }}
            components={transComponentsConfig}
          />
        );
      case EventType.AttachmentAdded:
        return (
          <Trans
            i18nKey="Notifications.attachmentsAdded"
            values={{
              user: creatorName,
              productItem: productItemType,
              productItemName,
              attachment: (notification.details as AttachmentAddedDetails)
                .attachments[0].fileName,
            }}
            components={transComponentsConfig}
          />
        );
      case EventType.AttachmentDescriptionAdded:
      case EventType.AttachmentDescriptionEdited:
        return (
          <Trans
            i18nKey="Notifications.attachmentDescriptionEdited"
            values={{
              user: creatorName,
              productItem: productItemType,
              productItemName,
              attachment: (
                notification.details as AttachmentDescriptionAddedDetails
              ).attachmentChanges?.[0].fileName, // attachment description updates cannot happen in bulk
            }}
            components={transComponentsConfig}
          />
        );
      case EventType.AttachmentRemoved:
        return (
          <Trans
            i18nKey="Notifications.attachmentRemoved"
            values={{
              user: creatorName,
              productItem: productItemType,
              productItemName,
              attachments: (
                notification.details as AttachmentRemovedDetails
              ).attachments
                ?.map((attach) => attach.fileName)
                .join(", "),
            }}
            components={transComponentsConfig}
          />
        );
      case EventType.CompEventConfirmationRecorded:
      case EventType.CompEventConfirmationSent:
      case EventType.CompEventNoticeRecorded:
      case EventType.CompEventNoticeRequestQuotationRecorded:
      case EventType.CompEventNoticeRequestQuotationSent:
      case EventType.CompEventNoticeSent:
      case EventType.CompEventOwnAssessmentNoticeRecorded:
      case EventType.CompEventOwnAssessmentNoticeSent:
      case EventType.CompEventOwnAssessmentRecorded:
      case EventType.CompEventOwnAssessmentSent:
      case EventType.CompEventQuotationAcceptanceRecorded:
      case EventType.CompEventQuotationAcceptanceSent:
      case EventType.CompEventQuotationRecorded:
      case EventType.CompEventQuotationRevisionRequestRecorded:
      case EventType.CompEventQuotationRevisionRequestSent:
      case EventType.CompEventQuotationSent:
      case EventType.CompEventRejectionRecorded:
      case EventType.CompEventRejectionSent:
      case EventType.CompEventRequestForMoreTimeSent:
      case EventType.CompEventRequestForMoreTimeRecorded:
      case EventType.CompEventNoticeProposedInstructionSent:
      case EventType.CompEventNoticeProposedInstructionRecorded:
      case EventType.CompEventAbandonInstructionSent:
      case EventType.CompEventAbandonInstructionRecorded:
      case EventType.CompEventGrantingOfMoreTimeSent:
      case EventType.CompEventGrantingOfMoreTimeRecorded:
      case EventType.CompEventDenyingOfMoreTimeSent:
      case EventType.CompEventDenyingOfMoreTimeRecorded:
      case EventType.CompEventNoticeOfSilenceCompEventNoticeSent:
      case EventType.CompEventNoticeOfSilenceCompEventNoticeRecorded:
      case EventType.CompEventNoticeOfSilenceOwnAssessmentSent:
      case EventType.CompEventNoticeOfSilenceOwnAssessmentRecorded:
      case EventType.CompEventNoticeOfSilenceQuotationSent:
      case EventType.CompEventNoticeOfSilenceQuotationRecorded:
        return (
          <Trans
            i18nKey={`Notifications.CE.${notificationType}`}
            values={{
              user: creatorName,
              productItemName,
            }}
            components={transComponentsConfig}
          />
        );
      case EventType.ClaimAgreementNoticeRecorded:
      case EventType.ClaimAgreementNoticeSent:
      case EventType.ClaimDetailsResponseRecorded:
      case EventType.ClaimDetailsResponseSent:
      case EventType.ClaimDeterminationNoticeRecorded:
      case EventType.ClaimDeterminationNoticeSent:
      case EventType.ClaimErrorAgreementDisagreementRecorded:
      case EventType.ClaimErrorAgreementDisagreementSent:
      case EventType.ClaimErrorAgreementNoticeRecorded:
      case EventType.ClaimErrorAgreementNoticeSent:
      case EventType.ClaimErrorDeterminationDisagreementRecorded:
      case EventType.ClaimErrorDeterminationDisagreementSent:
      case EventType.ClaimErrorDeterminationNoticeRecorded:
      case EventType.ClaimErrorDeterminationNoticeSent:
      case EventType.ClaimFinalClaimDetailsRecorded:
      case EventType.ClaimFinalClaimDetailsSent:
      case EventType.ClaimFurtherParticularsRequestRecorded:
      case EventType.ClaimFurtherParticularsRequestSent:
      case EventType.ClaimFurtherParticularsSubmissionRecorded:
      case EventType.ClaimFurtherParticularsSubmissionSent:
      case EventType.ClaimInterimClaimDetailsRecorded:
      case EventType.ClaimInterimClaimDetailsSent:
      case EventType.ClaimNoAgreementNoticeRecorded:
      case EventType.ClaimNoAgreementNoticeSent:
      case EventType.ClaimNoticeInvalidNoticeRecorded:
      case EventType.ClaimNoticeInvalidNoticeSent:
      case EventType.ClaimNoticeLapsedNoticeRecorded:
      case EventType.ClaimNoticeLapsedNoticeSent:
      case EventType.ClaimNoticeRecorded:
      case EventType.ClaimNoticeSent:
        return (
          <Trans
            i18nKey={`Notifications.Claims.${notificationType}${
              claimNotifications99RYSpecific.includes(notificationType) &&
              isFIDIC99RedYellow
                ? "99RY"
                : ""
            }`}
            values={{
              user: creatorName,
              productItemName,
            }}
            components={transComponentsConfig}
          />
        );
      case EventType.VariationConfirmationSent:
      case EventType.VariationConfirmationRecorded:
      case EventType.VariationProposalConsentForProposedVariationSent:
      case EventType.VariationProposalConsentForProposedVariationRecorded:
      case EventType.VariationProposalAbandonmentSent:
      case EventType.VariationProposalAbandonmentRecorded:
      case EventType.VariationRevocationSent:
      case EventType.VariationRevocationRecorded:
      case EventType.VariationProposalForProposedVariationSent:
      case EventType.VariationProposalForProposedVariationRecorded:
      case EventType.VariationObjectionProposedVariationSent:
      case EventType.VariationObjectionProposedVariationRecorded:
      case EventType.VariationNoAgreementNoticeSent:
      case EventType.VariationNoAgreementNoticeRecorded:
      case EventType.VariationRevisedProposalForProposedVariationRequestSent:
      case EventType.VariationRevisedProposalForProposedVariationRequestRecorded:
      case EventType.VariationAgreementNoticeSent:
      case EventType.VariationAgreementNoticeRecorded:
      case EventType.VariationDeterminationNoticeSent:
      case EventType.VariationDeterminationNoticeRecorded:
      case EventType.VariationProposalForVariationSent:
      case EventType.VariationProposalForVariationRecorded:
      case EventType.VariationObjectionVariationSent:
      case EventType.VariationObjectionVariationRecorded:
      case EventType.VariationInstructionCommenceWorkSent:
      case EventType.VariationInstructionCommenceWorkRecorded:
        return (
          <Trans
            i18nKey={`Notifications.Variations.${notificationType}${
              variationNotifications99RYSpecific.includes(notificationType) &&
              isFIDIC99RedYellow
                ? "99RY"
                : ""
            }${
              variationNotifications17WhiteSpecific.includes(
                notificationType
              ) && isFIDIC17White
                ? "FIDIC17White"
                : ""
            }`}
            values={{
              user: creatorName,
              productItemName,
            }}
            components={transComponentsConfig}
          />
        );
      case EventType.CommentReplied:
        return (
          <Trans
            i18nKey="Notifications.commentReplied"
            values={{
              user: creatorName,
              productItemName,
            }}
            components={transComponentsConfig}
          />
        );
      case EventType.NewCommentAdded:
        return (
          <Trans
            i18nKey="Notifications.commentAdded"
            values={{
              user: creatorName,
              productItemName,
            }}
            components={transComponentsConfig}
          />
        );
      case EventType.UserMentioned:
        return (
          <Trans
            i18nKey="Notifications.userMentioned"
            values={{
              user: creatorName,
              productItemName,
            }}
            components={transComponentsConfig}
          />
        );
      case EventType.EwRecorded:
        return (
          <Trans
            i18nKey="Notifications.ewRecorded"
            values={{
              user: creatorName,
              productItemName,
            }}
            components={transComponentsConfig}
          />
        );
      case EventType.InstructionRecorded:
        return (
          <Trans
            i18nKey="Notifications.instructionRecorded"
            values={{
              user: creatorName,
              productItemName,
            }}
            components={transComponentsConfig}
          />
        );
      case EventType.EwSent:
        return (
          <Trans
            i18nKey="Notifications.ewSent"
            values={{
              user: creatorName,
              productItemName,
            }}
            components={transComponentsConfig}
          />
        );
      case EventType.InstructionSent:
        return (
          <Trans
            i18nKey="Notifications.instructionSent"
            values={{
              user: creatorName,
              productItemName,
            }}
            components={transComponentsConfig}
          />
        );
      default:
        return (
          <Typography variant="p2" color="grey.900">
            {productItemName}
          </Typography>
        );
    }
  }, [
    t,
    creatorName,
    notification,
    notificationType,
    productItemType,
    transComponentsConfig,
  ]);

  return (
    <Box
      fontSize={theme.typography.p2.fontSize}
      color={theme.palette.grey[900]}
      sx={{ wordBreak: "break-word" }}
    >
      {TextContent}
    </Box>
  );
};
