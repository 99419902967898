import { gql } from "@apollo/client";
import { authorizationWorkflowFields } from "graphql/fragments/authorizationWorkflow.fragment";
import { authorizationWorkflowActionMappingFields } from "graphql/fragments/authorizationWorkflowActionMapping.fragment";
import { authorizationWorkflowAuditTrailFields } from "graphql/fragments/authorizationWorkflowAuditTrail.fragment";
import {
  claimLastActionFields,
  claimNextStepFields,
} from "graphql/fragments/claimItem.fragment";
import { compEventItemFields } from "graphql/fragments/compEventItem.fragment";
import { creatorFields } from "graphql/fragments/creator.fragment";
import { earlyWarningItemFields } from "graphql/fragments/earlyWarningItem.fragment";
import { instructionItemFields } from "graphql/fragments/instructionItem.fragment";
import { itemDataFields } from "graphql/fragments/itemData.fragment";
import { productLiteFields } from "graphql/fragments/productLite.fragment";
import {
  voLastActionFields,
  voNextStepFields,
} from "graphql/fragments/variationItem.fragment";

const localDailyDiaryItemFields = gql`
  ${productLiteFields}

  fragment LocalDailyDiaryItemFields on DailyDiaryItem {
    id
    date
    number
    dateCreated
    dateModified
    creatorId
    sentById
    productInstance {
      id
      description
      numberingFormat
      soloModeSupported
      status
      contract {
        id
        name
        friendlyName
        number
        status
        dateCreated
        project {
          id
          name
          friendlyName
          status
        }
      }
      product {
        ...ProductLiteFields
      }
    }
  }
`;

const localAuthorizationWorkflowAuditFields = gql`
  ${creatorFields}
  ${authorizationWorkflowActionMappingFields}
  ${compEventItemFields}
  ${earlyWarningItemFields}
  ${instructionItemFields}
  ${authorizationWorkflowAuditTrailFields}
  ${localDailyDiaryItemFields}
  ${claimNextStepFields}
  ${claimLastActionFields}
  ${voNextStepFields}
  ${voLastActionFields}
  ${itemDataFields}
  ${authorizationWorkflowFields}

  fragment LocalAuthorizationWorkflowAuditFields on AuthorizationWorkflowAudit {
    id
    initiatedById
    initiatedBy {
      ...CreatorFields
    }
    regardingProductItemId
    regardingProductType
    regardingItemHistoryId
    actionId
    workflowId
    workflow {
      ...AuthorizationWorkflowFields
    }
    action {
      ...AuthorizationWorkflowActionMappingFields
    }
    status
    dateCreated
    dateInitiated
    regardingProductItem {
      ... on CompEventItem {
        ...CompEventItemFields
      }
      ... on EarlyWarningItem {
        ...EarlyWarningItemFields
      }
      ... on InstructionItem {
        ...InstructionItemFields
      }
      ... on DailyDiaryItem {
        ...LocalDailyDiaryItemFields
      }
      ... on ClaimItem {
        __typename
        id
        number
        productInstanceId
        regardingId
        advanceWarningId
        dateCreated
        dateModified
        creatorId
        notifiedByPartyId
        creator {
          ...CreatorFields
        }
        productInstance {
          id
          description
          numberingFormat
          status
          product {
            id
            name
          }
          contract {
            id
            name
            friendlyName
            status
            timeZone
            valueCurrency
            project {
              id
              name
              friendlyName
              status
            }
          }
        }
        advanceWarning {
          id
          title
          dateSent
          number
          title
          productInstance {
            id
            product {
              id
            }
          }
        }
        regardingEvent {
          id
          title
          productInstanceId
          number
          data {
            ...ItemDataFields
          }
          productInstance {
            id
            product {
              id
            }
          }
        }
        regardingInstruction {
          id
          title
          number
          productInstanceId
          data {
            ...ItemDataFields
          }
          productInstance {
            id
            product {
              id
            }
          }
        }
        notifiedByParty {
          id
          description
          contractTypeId
          status
        }
        claimNextStep: nextStep {
          ...ClaimNextStepFields
        }
        claimLastAction: lastAction {
          ...ClaimLastActionFields
        }
        claimStatus: status
        claimRegardingType: regardingType
      }
      ... on VariationItem {
        __typename
        id
        number
        productInstanceId
        regardingId
        type
        priceSought
        priceGranted
        timeSought
        timeGranted
        dateCreated
        dateModified
        creatorId
        creator {
          ...CreatorFields
        }
        productInstance {
          id
          description
          numberingFormat
          status
          product {
            id
            name
          }
          contract {
            id
            name
            friendlyName
            status
            timeZone
            valueCurrency
            project {
              id
              name
              friendlyName
              status
            }
          }
        }
        regardingInstruction {
          id
          title
          number
          productInstanceId
          data {
            ...ItemDataFields
          }
          productInstance {
            id
            product {
              id
            }
          }
        }
        voNextStep: nextStep {
          ...VONextStepFields
        }
        voLastAction: lastAction {
          ...VOLastActionFields
        }
        voStatus: status
      }
    }
    auditTrails {
      ...AuthorizationWorkflowAuditTrailFields
    }
  }
`;

export const authAuditDetailsQuery = gql`
  ${localAuthorizationWorkflowAuditFields}

  query authAuditDetails($id: ID!) {
    authorizationWorkflowAudit(id: $id) {
      ...LocalAuthorizationWorkflowAuditFields
    }
  }
`;

export const authAuditDetailsLiteQuery = gql`
  ${authorizationWorkflowFields}
  ${authorizationWorkflowAuditTrailFields}
  ${creatorFields}

  query authAuditDetailsLite($id: ID!) {
    authorizationWorkflowAudit(id: $id) {
      id
      initiatedById
      initiatedBy {
        ...CreatorFields
      }
      regardingProductItemId
      regardingProductType
      regardingItemHistoryId
      actionId
      status
      dateCreated
      dateInitiated
      workflowId
      workflow {
        ...AuthorizationWorkflowFields
      }
      auditTrails {
        ...AuthorizationWorkflowAuditTrailFields
      }
    }
  }
`;
