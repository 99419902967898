import { Box } from "@mui/material";
import {
  NewEntityHeader,
  NewEntityHeaderFontSize,
} from "components/NewEntityHeader";
import { PageContentContainer } from "components/PageContentContainer";
import { PageContentHeaderSize } from "components/PageContentHeader/PageContentHeader";
import { FormPublicApi } from "decl";
import {
  AddContractBindingInput,
  AddContractBindingMutation,
  AddContractBindingMutationVariables,
  ContractLiteQuery,
  ContractLiteQueryVariables,
  EditContractBindingInput,
} from "generated/graphql";
import { contractLiteQuery } from "graphql/queries/contractLite.query";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useGraphQuery } from "hooks/useGraphQuery";
import { useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { ContractBindingForm } from "../../components/ContractBindingForm/ContractBindingForm";
import { useContractBindingForm } from "../../components/ContractBindingForm/useContractBindingForm";
import { addContractBindingMutation } from "./NewContractBinding.query";

export const NewContractBinding = () => {
  const { t } = useTranslation();
  const contractBindingData = useRef<AddContractBindingInput>();
  const ref = useRef<FormPublicApi>(null);
  const { contractId } = useParams();
  const navigate = useNavigate();

  const { data: contractLiteData, loading: getContractLiteDataLoading } =
    useGraphQuery<ContractLiteQuery, ContractLiteQueryVariables>(
      contractLiteQuery,
      {
        variables: { id: contractId! },
      }
    );

  const {
    companies,
    companyUsers,
    setSelectedCompanyId,
    loading: formDataLoading,
    usersLoading,
    bindingTypes,
  } = useContractBindingForm(contractLiteData?.contract.contractTypeId);

  const [addNewContractBinding, { loading: addNewContractBindingLoading }] =
    useGraphMutation<
      AddContractBindingMutation,
      AddContractBindingMutationVariables
    >(
      addContractBindingMutation,
      {
        update: (cache) => {
          cache.evict({ id: "ROOT_QUERY", fieldName: "contract" });
          cache.evict({ id: "ROOT_QUERY", fieldName: "productInstance" });
          cache.gc();
        },
      },
      t("common.successMessages.entityCreated", {
        entity: t("common.labels.binding"),
      })
    );

  const handleAddContractBinding = useCallback(async () => {
    if (ref.current?.validate()) {
      const { errors } = await addNewContractBinding({
        variables: {
          input: contractBindingData.current!,
        },
      });

      if (!errors) {
        navigate(-1);
      }
    }
  }, [addNewContractBinding, navigate]);

  const handleFormChange = useCallback(
    (data: AddContractBindingInput | EditContractBindingInput) => {
      if (data.companyId !== contractBindingData.current?.companyId) {
        setSelectedCompanyId(data.companyId);
      }
      contractBindingData.current = data as AddContractBindingInput;
    },
    [setSelectedCompanyId]
  );

  return (
    <Box display="flex" flexDirection="column" position="relative">
      <NewEntityHeader
        onAdd={handleAddContractBinding}
        size={PageContentHeaderSize.Normal}
        entityName={t("common.labels.binding").toLowerCase()}
        fontSize={NewEntityHeaderFontSize.Large}
        generalLoading={
          addNewContractBindingLoading || getContractLiteDataLoading
        }
        addActionLoading={addNewContractBindingLoading}
      />
      <PageContentContainer>
        <ContractBindingForm
          onChange={handleFormChange}
          apiRef={ref}
          contractId={contractId!}
          companies={companies || []}
          bindingTypes={bindingTypes || []}
          companyUsers={companyUsers || []}
          usersSelectDisabled={usersLoading}
          disabled={
            formDataLoading ||
            addNewContractBindingLoading ||
            getContractLiteDataLoading
          }
        />
      </PageContentContainer>
    </Box>
  );
};
