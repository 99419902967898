import {
  ExtendedOptionStyles,
  StatusOptionNew,
} from "components/StatusTag/StatusTagNew";
import { VariationStatus } from "generated/graphql";

export const variationStatusOptions: StatusOptionNew<string>[] = [
  {
    id: VariationStatus.Abandoned,
    label: VariationStatus.Abandoned,
    style: ExtendedOptionStyles.Grey,
  },
  {
    id: VariationStatus.Accepted,
    label: VariationStatus.Accepted,
    style: ExtendedOptionStyles.Green,
  },
  {
    id: VariationStatus.Instructed,
    label: VariationStatus.Instructed,
    style: ExtendedOptionStyles.Blue,
  },
  {
    id: VariationStatus.None,
    label: VariationStatus.None,
    style: ExtendedOptionStyles.Black,
  },
  {
    id: VariationStatus.Priced,
    label: VariationStatus.Priced,
    style: ExtendedOptionStyles.Amber,
  },
  {
    id: VariationStatus.Proposed,
    label: VariationStatus.Proposed,
    style: ExtendedOptionStyles.Blue,
  },
  {
    id: VariationStatus.Rejected,
    label: VariationStatus.Rejected,
    style: ExtendedOptionStyles.Red,
  },
  {
    id: VariationStatus.Revoked,
    label: VariationStatus.Revoked,
    style: ExtendedOptionStyles.Red,
  },
];
