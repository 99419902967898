import { Box, IconButton, Theme, Tooltip, Typography } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { InvitationIcon } from "components/Icons/InvitationIcon";
import { CheckIcon } from "components/Icons/CheckIcon";
import { UnregisteredIcon } from "components/Icons/UnregisteredIcon";
import { User, UserStatus } from "generated/graphql";
import { TFunction } from "i18next";
import moment from "moment";
import {
  StatusTag,
  StatusOption,
} from "../../../../../../../../components/StatusTag/StatusTag";
import { getUserName } from "helpers/miscelaneous";
import { dateISOFormat } from "../../../../../../../../constants";
import { statusComparatorFunction } from "helpers/dataGrid.helpers";

export const getColumns = (
  theme: Theme,
  companyStatusOptions: StatusOption<UserStatus>[],
  onStatusChange: (row: User, newStatus: UserStatus) => void,
  onResendRegisterInvitation: (row: User) => void,
  t: TFunction
): GridColDef<User>[] => {
  return [
    {
      field: "name",
      headerName: t("common.labels.name"),
      flex: 0.125,
      minWidth: 190,
      resizable: true,
      valueGetter: (params) => getUserName(params.row),
    },
    {
      field: "email",
      headerName: t("common.labels.email"),
      flex: 0.15,
      minWidth: 90,
      resizable: true,
    },
    {
      field: "registered",
      headerName: t("AdminConsole.Users.labels.registered"),
      flex: 0.075,
      minWidth: 90,
      resizable: true,
      renderCell: (params: GridRenderCellParams<any, User, any>) => {
        return params.row.registered ? (
          <CheckIcon
            color={
              params.row.status === UserStatus.Removed
                ? theme.palette.grey[700]
                : theme.palette.primary.main
            }
          />
        ) : (
          <UnregisteredIcon />
        );
      },
    },
    {
      field: "status",
      headerName: t("common.labels.status"),
      flex: 0.125,
      minWidth: 90,
      resizable: true,
      sortComparator: statusComparatorFunction,
      renderCell: (params: GridRenderCellParams<any, User, any>) => {
        return (
          <StatusTag
            status={params.row.status}
            options={companyStatusOptions}
            disabled={!params.row.registered}
            disablePortal={false}
            onChange={(newStatus: StatusOption<UserStatus>) =>
              onStatusChange(params.row, newStatus.id)
            }
          />
        );
      },
    },
    {
      field: "dateInvited",
      headerName: t("AdminConsole.Users.labels.dateInvited"),
      flex: 0.125,
      width: 120,
      resizable: true,
      renderCell: (params: GridRenderCellParams<any, User, any>) => {
        const rawValue = params.row.dateInvited;

        return (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="body2" width="90px">
              {moment(rawValue).format(dateISOFormat)}
            </Typography>
            {!params.row.registered && (
              <Tooltip
                title={t("AdminConsole.Users.labels.resendInvite")! as string}
                arrow
                placement="right"
              >
                <IconButton
                  onClick={(evt) => {
                    evt.stopPropagation();
                    onResendRegisterInvitation(params.row);
                  }}
                >
                  <InvitationIcon />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        );
      },
    },
    {
      field: "invitedBy",
      headerName: t("common.labels.invitedBy"),
      flex: 0.125,
      width: 130,
      resizable: true,
      valueGetter: (params) => getUserName(params.row.invitedBy),
    },
  ];
};
