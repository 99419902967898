import {
  GenerateSendVariationRevocationPreviewMutation,
  GenerateSendVariationRevocationPreviewMutationVariables,
  SendVariationRevocationPreview,
} from "generated/graphql";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useCallback, useContext, useEffect, useState } from "react";
import { debounce } from "@mui/material";
import { eventDebounceDuration } from "../../../../../../constants";
import { VariationWidgetContext } from "../../VariationWidget/VariationWidget.context";
import { generateSendVariationRevocationPreviewMutation } from "./CancelInstructionOfVariationAction.query";

export const useSendInstructionOfVariationCancelationModal = (
  remarks: string
) => {
  const { variation } = useContext(VariationWidgetContext);
  const [variationPreview, setVariationPreview] =
    useState<SendVariationRevocationPreview>();

  const [generatePreview] = useGraphMutation<
    GenerateSendVariationRevocationPreviewMutation,
    GenerateSendVariationRevocationPreviewMutationVariables
  >(generateSendVariationRevocationPreviewMutation, {}, null);

  const loadPreview = debounce(
    useCallback(async () => {
      const { data } = await generatePreview({
        variables: {
          input: {
            variationId: variation!.id,
            remarks,
          },
        },
      });

      if (data) {
        setVariationPreview(data.generateSendVariationRevocationPreview);
      }
    }, [generatePreview, variation, remarks]),
    eventDebounceDuration
  );

  useEffect(() => {
    loadPreview();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  return {
    variationPreview,
  };
};
