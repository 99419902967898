import {
  Box,
  BoxProps,
  Button,
  CircularProgress,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import { LiteCheckIcon } from "components/Icons/LiteCheckIcon";
import {
  ActionPreventedDetails,
  AuthorizationRequiredDetails,
  ClaimItemQuery,
  ClaimItemQueryVariables,
  ClaimRegardingType,
  CompEventItemQuery,
  CompEventItemQueryVariables,
  CompEventRegardingType,
  Notification,
  ProductType,
  VariationItemQuery,
  VariationItemQueryVariables,
} from "generated/graphql";
import { getProductItemDetailsPath } from "helpers/paths/pathConstructors";
import { useGraphLazyQueryLite } from "hooks/useGraphLazyQueryLite";
import { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { useLocation, useNavigate } from "react-router-dom";
import { NotificationListItem } from "./NotificationListItem";
import { NotificationsPopperContentContainer } from "./Notifications.styled";
import { NewAppPaths } from "helpers/paths/paths";
import {
  claimItemQuery,
  compEventItemQuery,
  variationItemQuery,
} from "./Notifications.query";
import { useAuthStore } from "state-management/store/authStore";

export type NotificationsPopperContentProps = {
  notifications: Notification[];
  loading?: boolean;
  hasMore: boolean;
  newUnloadedNotifications?: number;
  onStartFresh: () => void;
  onCloseNotificationsPopup: () => void;
  onReadUnreadNotification: (
    notificationId: string,
    action: "read" | "unread"
  ) => void;
  onMarkAllNotificationsAsRead: () => void;
  onLoadMoreNotifications: () => void;
};

export const LoadingIndicator: React.FC<BoxProps> = (props) => (
  <Box display="flex" justifyContent="center" pb={3} {...props}>
    <CircularProgress size="20px" />
  </Box>
);

export const NotificationsPopperContent = forwardRef(
  (
    {
      notifications,
      loading,
      hasMore,
      newUnloadedNotifications,
      onStartFresh,
      onCloseNotificationsPopup,
      onReadUnreadNotification,
      onMarkAllNotificationsAsRead,
      onLoadMoreNotifications,
    }: NotificationsPopperContentProps,
    ref
  ) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const setAuthModalState = useAuthStore((state) => state.setAuthModalState);

    const [fetchCompEvent] = useGraphLazyQueryLite<
      CompEventItemQuery,
      CompEventItemQueryVariables
    >(compEventItemQuery);

    const [fetchClaim] = useGraphLazyQueryLite<
      ClaimItemQuery,
      ClaimItemQueryVariables
    >(claimItemQuery);

    const [fetchVariation] = useGraphLazyQueryLite<
      VariationItemQuery,
      VariationItemQueryVariables
    >(variationItemQuery);

    const handleProductInstanceItemNavigation = (link: string) => {
      if (pathname === link) {
        // already on the right route. Forcing a page refresh to trigger data refresh
        navigate(NewAppPaths.authorized.Home, { replace: true });
        setTimeout(() => {
          navigate(link, { replace: true });
        });
      } else {
        navigate(link);
      }
    };

    const handleCompEventNotificationClick = async (
      notification: Notification
    ) => {
      // Note: ideally BE should provide this information inside the Notification type
      const { data } = await fetchCompEvent({ id: notification.itemId });
      if (!data.compEventItem) {
        return;
      }

      const regardingItemProductInstanceId =
        data.compEventItem.regardingEvent?.productInstanceId ||
        data.compEventItem.regardingInstruction?.productInstanceId;
      const regardingType = data.compEventItem.regardingType;

      handleProductInstanceItemNavigation(
        `${
          NewAppPaths.authorized.Projects.path
        }/product-instances/${regardingItemProductInstanceId}/${
          regardingType === CompEventRegardingType.Event
            ? "events"
            : "instructions"
        }/${data.compEventItem.regardingId}`
      );
    };

    const handleClaimNotificationClick = async (notification: Notification) => {
      // Note: ideally BE should provide this information inside the Notification type
      const { data } = await fetchClaim({ id: notification.itemId });
      if (!data.claimItem) {
        return;
      }

      const regardingItemProductInstanceId =
        data.claimItem.regardingEvent?.productInstanceId ||
        data.claimItem.regardingInstruction?.productInstanceId;
      const regardingType = data.claimItem.regardingType;
      handleProductInstanceItemNavigation(
        `${
          NewAppPaths.authorized.Projects.path
        }/product-instances/${regardingItemProductInstanceId}/${
          regardingType === ClaimRegardingType.Event ? "events" : "instructions"
        }/${data.claimItem.regardingId}`
      );
    };

    const handleVariationNotificationClick = async (
      notification: Notification
    ) => {
      // Note: ideally BE should provide this information inside the Notification type
      const { data } = await fetchVariation({ id: notification.itemId });
      if (!data.variationItem) {
        return;
      }

      const regardingItemProductInstanceId =
        data.variationItem.regardingInstruction?.productInstanceId;
      handleProductInstanceItemNavigation(
        `${NewAppPaths.authorized.Projects.path}/product-instances/${regardingItemProductInstanceId}/instructions/${data.variationItem.regardingId}`
      );
    };

    const handleAuthorizationNotificationClick = (
      notification: Notification
    ) => {
      const authAuditId = (notification.details as AuthorizationRequiredDetails)
        .authorizationAuditId;

      if (authAuditId) {
        if (
          (notification.details as AuthorizationRequiredDetails).__typename ===
          "AuthorizationRequiredDetails"
        ) {
          setAuthModalState({
            authAuditId,
            type: "Any",
          });
        } else if (
          (notification.details as ActionPreventedDetails).__typename ===
          "ActionPreventedDetails"
        ) {
          setAuthModalState({
            authAuditId,
            type: "AuditTrail",
          });
        }
      }
      onCloseNotificationsPopup();
    };

    const handleNotificationClick = async (notification: Notification) => {
      onReadUnreadNotification(notification.id, "read");

      if (
        ((notification.details as ActionPreventedDetails).__typename ===
          "ActionPreventedDetails" ||
          (notification.details as AuthorizationRequiredDetails).__typename ===
            "AuthorizationRequiredDetails") &&
        notification.productType !== ProductType.DailyDiary
      ) {
        return handleAuthorizationNotificationClick(notification);
      }

      if (notification.productType === ProductType.CompEvents) {
        handleCompEventNotificationClick(notification);
      } else if (notification.productType === ProductType.Claims) {
        handleClaimNotificationClick(notification);
      } else if (notification.productType === ProductType.Variations) {
        handleVariationNotificationClick(notification);
      } else {
        let productItemDetailsUrl: string = "";
        if (notification.productType === ProductType.DailyDiary) {
          const diaryNumber = notification.number;
          const diaryDate = diaryNumber.substring(diaryNumber.length - 8); // take last 8 digits (i.e. 20240608)
          const year = diaryDate.substring(0, 4);
          const month = diaryDate.substring(4, 6);
          const day = diaryDate.substring(6);
          const formattedDate = `${year}-${month}-${day}`;

          productItemDetailsUrl =
            NewAppPaths.authorized.Projects.children.DailyDiaryDetails.pathConstructor(
              notification.productInstanceId,
              formattedDate,
              notification.itemId,
              notification.versionId ?? undefined
            );
        } else {
          productItemDetailsUrl = getProductItemDetailsPath(
            notification.productInstanceId,
            notification.itemId,
            notification.productType
          );
        }
        navigate(productItemDetailsUrl);
      }
      onCloseNotificationsPopup();
    };

    return (
      <NotificationsPopperContentContainer
        ref={ref}
        maxHeight={800}
        overflow="auto"
        id="scrollable-container"
      >
        <Box
          display="flex"
          alignItems="space-between"
          flexDirection="column"
          p={3}
          position="sticky"
          top={0}
          sx={{ background: "white", width: "100%", boxSizing: "border-box" }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            flex={1}
          >
            <Typography variant="h3" color="grey.800">
              {t("Notifications.labels.notifications")}
            </Typography>
            <Button variant="text" onClick={onMarkAllNotificationsAsRead}>
              <LiteCheckIcon />
              <Typography variant="p2" fontWeight={700} ml={1} color="grey.800">
                {t("Notifications.labels.markAllAsRead")}
              </Typography>
            </Button>
          </Box>
          {newUnloadedNotifications ? (
            <Box display="flex" justifyContent="flex-end">
              <Button variant="text" onClick={onStartFresh}>
                <Typography variant="p3" color="grey.600">
                  {/* {newUnloadedNotifications} new notifications. Click here to
                  load them. */}
                  {t("Notifications.labels.newNotifications", {
                    noOfNewNotifications: newUnloadedNotifications,
                  })}
                </Typography>
              </Button>
            </Box>
          ) : null}
        </Box>
        <InfiniteScroll
          dataLength={notifications.length * 10}
          next={onLoadMoreNotifications}
          style={{ overflow: "hidden" }}
          hasMore={hasMore}
          endMessage={
            loading ? null : (
              <Typography
                variant="p2"
                textAlign="center"
                width="100%"
                fontWeight={600}
                color="grey.500"
                pb={2}
                sx={{ paddingBottom: theme.spacing(3), display: "block" }}
              >
                {t("Notifications.labels.noMoreNotifications")}
              </Typography>
            )
          }
          loader={<LoadingIndicator />}
          scrollableTarget="scrollable-container"
        >
          {notifications?.length ? (
            <Grid container spacing={1} px={3} pb={3}>
              {notifications.map((notification) => (
                <Grid item xs={12} key={notification.id}>
                  <NotificationListItem
                    notification={notification}
                    onReadUnreadNotification={(action) =>
                      onReadUnreadNotification(notification.id, action)
                    }
                    onClick={() => {
                      handleNotificationClick(notification);
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          ) : null}
          {loading && <LoadingIndicator />}
        </InfiniteScroll>
      </NotificationsPopperContentContainer>
    );
  }
);
