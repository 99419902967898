import { Box } from "@mui/material";
import {
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  AddDraftVariationAgreementInput,
  AttachmentInput,
  DraftVariationAgreement,
  EditDraftVariationAgreementInput,
  VariationAgreementNoticePrompt,
  VariationProposal,
} from "generated/graphql";
import { RecordProductItemExtraData } from "containers/Projects/components/RecordProductItemForm";
import { CenteredLoadingIndicator } from "components/CenteredLoadingIndicator";
import { TableHeader } from "containers/Projects/components/ActionModal/TableHeader";
import { VariationActionFormProps } from "../VariationActionView";
import { VariationWidgetContext } from "../../VariationWidget/VariationWidget.context";
import { useVariationProposalAgreementDeterminationModal } from "../../VariationProposalAgreementDeterminationModal/useVariationProposalAgreementDeterminationModal";
import { useNotifyAgreementOfVariationAction } from "./useNotifyAgreementOfVariationAction";
import {
  ModalType,
  VariationProposalAgreementDeterminationFormDataType,
  VariationProposalAgreementDeterminationModal,
} from "../../VariationProposalAgreementDeterminationModal/VariationProposalAgreementDeterminationModal";
import {
  VOProposalsAgreementsDeterminationsTable,
  VariationResolveType,
} from "../components/VOProposalsAgreementsDeterminationsTable/VOProposalsAgreementsDeterminationsTable";
import { SendNotifyAgreementOfVariationModal } from "./SendNotifyAgreementOfVariationModal";
import { RecordNotifyAgreementOfVariationModal } from "./RecordNotifyAgreementOfVariationModal";

const defaultFormData: VariationAgreementNoticePrompt = {
  draftVariationAgreements: [], // Note: even though prompt accepts an array of VariationAgreements, engineers will be able to add only one at a time
};

export const NotifyAgreementOfVariationAction: React.FC<
  VariationActionFormProps & {
    consentedProposal?: VariationProposal;
    finalProposal?: VariationProposal;
    triggersAuthWorkflow?: boolean;
  }
> = ({
  apiRef,
  consentedProposal,
  finalProposal,
  onClose,
  triggersAuthWorkflow,
}) => {
  const { variation, contract } = useContext(VariationWidgetContext);

  const [formData, setFormData] =
    useState<VariationAgreementNoticePrompt>(defaultFormData);
  const [sendModalVisibility, setSendModalVisibility] = useState(false);
  const [recordModalVisibility, setRecordModalVisibility] = useState(false);
  const [selectedDraftVariationId, setSelectedDraftVariationAgreementId] =
    useState<string>();

  const updatedAttachmentsRef = useRef<AttachmentInput[]>();

  const {
    modalVisibility: VOAgreementModalVisibility,
    toggleModalVisibility: toggleVOAgreementModalVisibility,
    loading: VOAgreementLoading,
    addDraftVOAgreement,
    editDraftVOAgreement,
    removeDraftVOAgreement,
  } = useVariationProposalAgreementDeterminationModal();

  const draftVariationAgreementIds = useMemo(
    () =>
      formData.draftVariationAgreements.map(
        (draftAgreement) => draftAgreement.id
      ),
    [formData]
  );

  const {
    sendVariationAgreement,
    recordVariationAgreement,
    draftVariationAgreements: promptDraftVariationAgreements,
    loading: promptDraftVariationAgreementsLoading,
    actionLoading,
  } = useNotifyAgreementOfVariationAction(triggersAuthWorkflow);

  const toggleSendModalVisibility = () => {
    setSendModalVisibility((state) => !state);
  };

  const toggleRecordModalVisibility = () => {
    setRecordModalVisibility((state) => !state);
  };

  const handleSendVariationAgreementNotice = async () => {
    await sendVariationAgreement({
      variables: {
        input: {
          variationId: variation?.id!,
          draftVariationAgreementId: draftVariationAgreementIds[0],
        },
      },
    });
    toggleSendModalVisibility();
    onClose();
  };

  const handleRecordVariationAgreementNotice = async (
    extraData: RecordProductItemExtraData
  ) => {
    await recordVariationAgreement({
      variables: {
        input: {
          variationId: variation?.id!,
          draftVariationAgreementId: draftVariationAgreementIds[0],
          dateSent: extraData.dateSent,
          givenById: extraData.givenById,
          number: extraData.number,
        },
      },
    });

    toggleRecordModalVisibility();
    onClose();
  };

  const isFormValid = useMemo(
    () => !!formData.draftVariationAgreements.length,
    [formData]
  );

  const handleCreateEditDraftVariationAgreement = useCallback(
    async (
      data: VariationProposalAgreementDeterminationFormDataType,
      keepModalOpen?: boolean
    ) => {
      const draftVariationAgreementData = data as
        | AddDraftVariationAgreementInput
        | EditDraftVariationAgreementInput;

      if (formData.draftVariationAgreements.length) {
        // edit mode
        const { data } = await editDraftVOAgreement({
          variables: {
            input:
              draftVariationAgreementData as EditDraftVariationAgreementInput,
          },
        });

        if (data) {
          setFormData((crtFormData) => ({
            ...crtFormData,
            draftVariationAgreements: [
              data.editDraftVariationAgreement as DraftVariationAgreement,
            ],
          }));
        }
      } else {
        // create new draft VO agreement
        const { data } = await addDraftVOAgreement({
          variables: { input: draftVariationAgreementData },
        });

        if (data) {
          setFormData((crtFormData) => ({
            ...crtFormData,
            draftVariationAgreements: [
              data.addDraftVariationAgreement as DraftVariationAgreement,
            ],
          }));
        }
      }

      if (!keepModalOpen) {
        toggleVOAgreementModalVisibility();
      }
    },
    [
      formData,
      addDraftVOAgreement,
      editDraftVOAgreement,
      toggleVOAgreementModalVisibility,
    ]
  );

  const handleAttachmentsChange = useCallback(
    (formData: VariationProposalAgreementDeterminationFormDataType) => {
      updatedAttachmentsRef.current = formData.attachments;
      const isEditMode = !!selectedDraftVariationId;

      if (isEditMode) {
        // update live the attachments if form is valid
        handleCreateEditDraftVariationAgreement(formData, true);
      }
    },
    [handleCreateEditDraftVariationAgreement, selectedDraftVariationId]
  );

  const handleVariationAgreementModalClose = () => {
    toggleVOAgreementModalVisibility();
    setSelectedDraftVariationAgreementId(undefined);
  };

  const handleVariationAgreementRowClick = (
    draftVariationAgreement: VariationResolveType
  ) => {
    setSelectedDraftVariationAgreementId(
      (draftVariationAgreement as DraftVariationAgreement).id
    );
    toggleVOAgreementModalVisibility();
  };

  const handleDeleteDraftVariationAgreement = useCallback(
    (variationAgreementId: string) => {
      removeDraftVOAgreement({ variables: { id: variationAgreementId } });
    },
    [removeDraftVOAgreement]
  );

  const handleNewVariationAgreementClick = () => {
    setSelectedDraftVariationAgreementId(undefined);
    toggleVOAgreementModalVisibility();
  };

  const selectedDraftVariationAgreement = useMemo(
    () =>
      formData.draftVariationAgreements.find(
        (draftVariationAgreement) =>
          draftVariationAgreement.id === selectedDraftVariationId
      ),
    [formData, selectedDraftVariationId]
  );

  useImperativeHandle(
    apiRef,
    () => ({
      validate: () => isFormValid,
      record: toggleRecordModalVisibility,
      send: toggleSendModalVisibility,
    }),
    [isFormValid]
  );

  useEffect(() => {
    if (promptDraftVariationAgreements) {
      setFormData({
        draftVariationAgreements: promptDraftVariationAgreements,
      });
    }
  }, [promptDraftVariationAgreements]);

  return !selectedDraftVariationId &&
    (VOAgreementLoading || promptDraftVariationAgreementsLoading) ? (
    <CenteredLoadingIndicator />
  ) : (
    <>
      {VOAgreementModalVisibility && (
        <VariationProposalAgreementDeterminationModal
          open={VOAgreementModalVisibility}
          type={ModalType.DraftVOAgreement}
          draftAgreement={selectedDraftVariationAgreement}
          contractCurrency={contract.valueCurrency ?? ""}
          contractSections={contract.sections}
          consentedProposal={consentedProposal}
          finalProposal={finalProposal}
          maxWidth="lg"
          onAttachmentsChange={handleAttachmentsChange}
          onPrimaryClick={handleCreateEditDraftVariationAgreement}
          onClose={handleVariationAgreementModalClose}
          onSecondaryClick={handleVariationAgreementModalClose}
        />
      )}
      {isFormValid && sendModalVisibility && (
        <SendNotifyAgreementOfVariationModal
          open={sendModalVisibility}
          draftVariationAgreements={formData.draftVariationAgreements}
          onPrimaryClick={handleSendVariationAgreementNotice}
          onSecondaryClick={toggleSendModalVisibility}
          onClose={toggleSendModalVisibility}
          primaryBtnLoading={actionLoading}
        />
      )}
      {isFormValid && recordModalVisibility && (
        <RecordNotifyAgreementOfVariationModal
          open={recordModalVisibility}
          draftVariationAgreements={formData.draftVariationAgreements}
          onPrimaryClick={handleRecordVariationAgreementNotice}
          onSecondaryClick={toggleRecordModalVisibility}
          onClose={toggleRecordModalVisibility}
          primaryBtnLoading={actionLoading}
        />
      )}
      <Box display="flex" flexDirection="column">
        <TableHeader
          type="VariationAgreement"
          onAdd={handleNewVariationAgreementClick}
          disabled={!!formData.draftVariationAgreements.length}
        />
        <VOProposalsAgreementsDeterminationsTable
          contractCurrency={contract.valueCurrency ?? ""}
          loading={VOAgreementLoading}
          items={formData.draftVariationAgreements}
          type="VariationAgreement"
          onRowClick={handleVariationAgreementRowClick}
          onDelete={handleDeleteDraftVariationAgreement}
        />
      </Box>
    </>
  );
};
