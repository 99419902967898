import {
  AddDraftEventItemMutation,
  AddDraftEventItemMutationVariables,
  AddEventItemMutation,
  AddEventItemMutationVariables,
  DraftEventItemQuery,
  DraftEventItemQueryVariables,
  EditDraftEventItemMutation,
  EditDraftEventItemMutationVariables,
} from "generated/graphql";
import { addDraftEventItemMutation } from "graphql/mutations/addDraftEventItem";
import { addEventItemMutation } from "graphql/mutations/addEventItem";
import { editDraftEventItemMutation } from "graphql/mutations/editDraftEventItem";
import { draftEventItemQuery } from "graphql/queries/draftEventItem.query";
import { useGraphLazyQuery } from "hooks/useGraphLazyQuery";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useTranslation } from "react-i18next";

export const useNewEvent = () => {
  const { t } = useTranslation();

  const [
    fetchDraftEventData,
    {
      data: draftEventData,
      loading: fetchDraftEventDataLoading,
      refetch: refetchDraftEventData,
    },
  ] = useGraphLazyQuery<DraftEventItemQuery, DraftEventItemQueryVariables>(
    draftEventItemQuery
  );

  const [addEventItem, { loading: addEventItemLoading }] = useGraphMutation<
    AddEventItemMutation,
    AddEventItemMutationVariables
  >(
    addEventItemMutation,
    {
      // update: (cache) => {
      //   cache.evict({ id: "ROOT_QUERY", fieldName: "eventItems" });
      //   cache.evict({ id: "ROOT_QUERY", fieldName: "draftEventItems" });
      //   cache.gc();
      // },
    },
    t("common.successMessages.entityCreated", {
      entity: t("Projects.Events.event"),
    })
  );

  const [addEventItemDraft, { loading: addEventItemDraftLoading }] =
    useGraphMutation<
      AddDraftEventItemMutation,
      AddDraftEventItemMutationVariables
    >(
      addDraftEventItemMutation,
      {
        // update: (cache) => {
        //   cache.evict({ id: "ROOT_QUERY", fieldName: "draftEventItems" });
        //   cache.gc();
        // },
      },
      t("common.successMessages.entityCreated", {
        entity: t("Projects.Events.draftEvent"),
      })
    );

  const [editEventItemDraft, { loading: editEventItemDraftLoading }] =
    useGraphMutation<
      EditDraftEventItemMutation,
      EditDraftEventItemMutationVariables
    >(
      editDraftEventItemMutation,
      {
        update: (_cache) => {
          refetchDraftEventData();

          //   cache.evict({ id: "ROOT_QUERY", fieldName: "draftEventItems" });
          //   cache.gc();
        },
      },
      t("common.successMessages.entityUpdated", {
        entity: t("Projects.Events.draftEvent"),
      })
    );

  return {
    draftEventData,
    fetchDraftEventData,
    fetchDraftEventDataLoading,
    addEventItem,
    addEventItemLoading,
    addEventItemDraft,
    addEventItemDraftLoading,
    editEventItemDraft,
    editEventItemDraftLoading,
  };
};
