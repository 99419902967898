import { useCallback, useContext, useEffect, useState } from "react";
import { ClaimWidgetContext } from "../../ClaimWidget/ClaimWidget.context";
import {
  GenerateRecordClaimFurtherParticularsSubmissionPreviewMutation,
  GenerateRecordClaimFurtherParticularsSubmissionPreviewMutationVariables,
  RecordClaimFurtherParticularsSubmissionPreview,
} from "generated/graphql";
import { useGraphMutation } from "hooks/useGraphMutation";
import { debounce } from "@mui/material";
import { eventDebounceDuration } from "../../../../../../constants";
import { generateRecordClaimFurtherParticularsSubmissionPreviewMutation } from "./ProvideFurtherParticularsAction.query";

export const useRecordFurtherParticularsSubmissionModal = (details: string) => {
  const { claim, contract } = useContext(ClaimWidgetContext);

  const [claimPreview, setClaimPreview] =
    useState<RecordClaimFurtherParticularsSubmissionPreview>();

  const [generateClaimPreview] = useGraphMutation<
    GenerateRecordClaimFurtherParticularsSubmissionPreviewMutation,
    GenerateRecordClaimFurtherParticularsSubmissionPreviewMutationVariables
  >(generateRecordClaimFurtherParticularsSubmissionPreviewMutation, {}, null);

  const loadPreview = debounce(
    useCallback(async () => {
      const { data } = await generateClaimPreview({
        variables: {
          input: {
            claimId: claim!.id,
            details,
          },
        },
      });

      if (data) {
        setClaimPreview(
          data.generateRecordClaimFurtherParticularsSubmissionPreview
        );
      }
    }, [generateClaimPreview, claim, details]),
    eventDebounceDuration
  );

  useEffect(() => {
    loadPreview();
    // eslint-disable-next-line
  }, []);

  return {
    claimPreview,
    contract,
  };
};
