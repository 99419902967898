import { SchemaField } from "generated/graphql";
import { temporaryRowId } from "../../../../../../../../../constants";

export const sortFields = (fields: SchemaField[]): SchemaField[] => {
  const fieldsCopy = JSON.parse(JSON.stringify(fields)) as SchemaField[];

  return fieldsCopy.sort(
    (field1, field2) => field1.displayOrder - field2.displayOrder
  );
};

export const rowsContainTemporaryRecord = (rows: SchemaField[]): boolean => {
  return !!rows.find((row) => row.id === temporaryRowId);
};
