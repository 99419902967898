import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import {
  ProductType,
  VariationAction,
  VariationActionType,
  VariationItem,
  VariationProposal,
  VariationStatus,
} from "generated/graphql";
import {
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { WidgetHeader } from "containers/Projects/components/Widget/WidgetHeader";
import {
  CEWidgetSectionType,
  WidgetSection,
} from "containers/Projects/components/Widget/WidgetSection";
import { AuthorizationProgressPreview } from "components/Authorization/AuthorizationProgressPreview";
import { AuthorizationRequiredPill } from "components/Authorization/AuthorizationRequiredPill";
import { ActionModalFooter } from "containers/Projects/components/ActionModal/ActionModalFooter";
import { VariationWidgetContext } from "../VariationWidget/VariationWidget.context";
import { VariationWidgetNextStepSection } from "../VariationWidget/components/VariationWidgetNextStepSection";
import { variationActionTypeTranslations } from "../VariationWidget/VariationWidget.constants";
import { SubmitProposalForProposedVariationAction } from "./SubmitProposalForProposedVariationAction/SubmitProposalForProposedVariationAction";
import { ObjectionToProposedVariationAction } from "./ObjectionToProposedVariationAction/ObjectionToProposedVariationAction";
import { NotifyAgreementOfVariationAction } from "./NotifyAgreementOfVariationAction/NotifyAgreementOfVariationAction";
import { useVariationConsentedOrFinalProposal } from "../hooks/useVariationConsentedOrFinalProposal";
import { useBasicModal } from "components/BasicModal/useBasicModal";
import { CenteredLoadingIndicator } from "components/CenteredLoadingIndicator";
import { VariationProposalAgreementDeterminationReadOnlyModal } from "../VariationProposalAgreementDeterminationReadOnlyModal/VariationProposalAgreementDeterminationReadOnlyModal";
import {
  VOProposalsAgreementsDeterminationsTable,
  VariationResolveType,
} from "./components/VOProposalsAgreementsDeterminationsTable/VOProposalsAgreementsDeterminationsTable";
import { ConsentToProposalForProposedVariationAction } from "./ConsentToProposalForProposedVariationAction/ConsentToProposalForProposedVariationAction";
import { NotifyNoVariationAgreementAction } from "./NotifyNoVariationAgreementAction/NotifyNoVariationAgreementAction";
import { RequestRevisedProposalAction } from "./RequestRevisedProposalAction/RequestRevisedProposalAction";
import { useVariationProposalExplorerItems } from "../hooks/useVariationProposalExplorerItems";
import { AbandonProposedVariationAction } from "./AbandonProposedVariationAction/AbandonProposedVariationAction";
import { NotifyDeterminationOfVariationAction } from "./NotifyDeterminationOfVariationAction/NotifyDeterminationOfVariationAction";
import { SubmitProposalForVariationAction } from "./SubmitProposalForVariationAction/SubmitProposalForVariationAction";
import { CancelInstructionOfVariationAction } from "./CancelInstructionOfVariationAction/CancelInstructionOfVariationAction";
import { ObjectToInstructionOfVariationAction } from "./ObjectToInstructionOfVariationAction/ObjectToInstructionOfVariationAction";
import { ConfirmInstructionOfVariationAction } from "./ConfirmInstructionOfVariationAction/ConfirmInstructionOfVariationAction";
import { InstructWorkToCommenceAction } from "./InstructWorkToCommenceAction/InstructWorkToCommenceAction";
import {
  variationActionTypeFIDIC17WhiteTypeMapping,
  variationActionTypeFIDIC99RedYellowTypeMapping,
} from "../Variations.types";

export type VariationActionViewProps = {
  variation?: VariationItem;
  variationActions: VariationAction[];
  apiRef?: React.Ref<{ onBeforeClose: () => void }>;
  onCancel: () => void; // Note: this callback is called even when modal auto-closes after a successful action
};

// Note: same as ClaimFormPublicApi
export type VariationFormPublicApi = {
  validate: () => boolean;
  record: () => void;
  send: () => void;
  onBeforeAbort?: () => void;
};

// Note: same as for CompEvent & Claims
export type VariationActionFormProps = {
  onClose: () => void;
  apiRef?: React.Ref<VariationFormPublicApi>;
};

export const VariationActionView: React.FC<VariationActionViewProps> = ({
  variation,
  variationActions,
  apiRef,
  onCancel,
}) => {
  const { t } = useTranslation();
  const { recordSupported, contract, isFIDIC17White, isFIDIC99RedYellow } =
    useContext(VariationWidgetContext);

  const [actionAwaitingAuthorization, setActionAwaitingAuthorization] =
    useState(false);
  const [selectedAction, setSelectedAction] = useState<VariationActionType>();
  const formAPIRef = useRef<VariationFormPublicApi | null>(null);

  const [selectedVOProposal, setSelectedVOProposal] =
    useState<VariationProposal>();

  const selectedActionFull = useMemo(
    () => variationActions.find((action) => action.type === selectedAction),
    [variationActions, selectedAction]
  );

  const { consentedProposal, finalProposal } =
    useVariationConsentedOrFinalProposal();

  const { variationProposals, loading: proposalsLoading } =
    useVariationProposalExplorerItems();

  // const { finalAgreement, loading: finalAgreementLoading } =
  //   useFinalClaimAgreement();
  // const { finalDetermination, loading: finalDeterminationLoading } =
  //   useFinalDetermination();

  const {
    modalVisibility: VOProposalModalVisibility,
    toggleModalVisibility: toggleVOProposalModalVisibility,
  } = useBasicModal();

  // const {
  //   modalVisibility: finalAgreementDeterminationModalVisibility,
  //   toggleModalVisibility: toggleFinalAgreementDeterminationModalVisibility,
  // } = useBasicModal();

  const handleProposalClick = (voItem: VariationResolveType) => {
    setSelectedVOProposal(voItem as VariationProposal);
    toggleVOProposalModalVisibility();
  };

  const handleActionChange = (
    event: SelectChangeEvent<VariationActionType>
  ) => {
    setSelectedAction(event.target.value as VariationActionType);
  };

  const handleCancel = () => {
    formAPIRef.current?.onBeforeAbort?.();
    onCancel();
  };

  const renderVariationActionForm = useCallback(
    (actionType: VariationActionType) => {
      const ongoingAuthorizationProductOutputName =
        variation?.ongoingAuthorizationAudit?.action.productOutputAction.name;

      if (
        !!ongoingAuthorizationProductOutputName &&
        selectedActionFull?.actionMapping?.productOutputAction.name ===
          ongoingAuthorizationProductOutputName
      ) {
        // action already awaiting authorization. UseEffect will sort out the error display
        return;
      }

      switch (actionType) {
        case VariationActionType.SubmitProposalForProposedVariation:
          return (
            <SubmitProposalForProposedVariationAction
              apiRef={formAPIRef}
              onClose={onCancel}
            />
          );
        case VariationActionType.GiveReasonsWhyProposalCannotBeGivenForProposedVariation:
          return (
            <ObjectionToProposedVariationAction
              apiRef={formAPIRef}
              onClose={onCancel}
            />
          );
        case VariationActionType.NotifyAgreementVariation:
          return (
            <NotifyAgreementOfVariationAction
              apiRef={formAPIRef}
              consentedProposal={consentedProposal}
              finalProposal={finalProposal}
              onClose={onCancel}
              triggersAuthWorkflow={!!selectedActionFull?.actionMapping}
            />
          );
        case VariationActionType.ConsentToProposalForProposedVariation:
          return (
            <ConsentToProposalForProposedVariationAction
              apiRef={formAPIRef}
              onClose={onCancel}
              triggersAuthWorkflow={!!selectedActionFull?.actionMapping}
            />
          );
        case VariationActionType.NotifyAgreementVariationNotReached:
          return (
            <NotifyNoVariationAgreementAction
              apiRef={formAPIRef}
              onClose={onCancel}
            />
          );
        case VariationActionType.RequestRevisedProposalForProposedVariation:
          return (
            <RequestRevisedProposalAction
              apiRef={formAPIRef}
              onClose={onCancel}
            />
          );
        case VariationActionType.AbandonProposedVariation:
          return (
            <AbandonProposedVariationAction
              apiRef={formAPIRef}
              onClose={onCancel}
            />
          );
        case VariationActionType.NotifyDeterminationVariation:
          return (
            <NotifyDeterminationOfVariationAction
              apiRef={formAPIRef}
              consentedProposal={consentedProposal}
              finalProposal={finalProposal}
              onClose={onCancel}
              triggersAuthWorkflow={!!selectedActionFull?.actionMapping}
            />
          );
        case VariationActionType.SubmitProposalForVariation:
          return (
            <SubmitProposalForVariationAction
              apiRef={formAPIRef}
              onClose={onCancel}
            />
          );
        case VariationActionType.RevokeInstructionOfVariation:
          return (
            <CancelInstructionOfVariationAction
              apiRef={formAPIRef}
              onClose={onCancel}
            />
          );
        case VariationActionType.ObjectToInstructionOfVariation:
          return (
            <ObjectToInstructionOfVariationAction
              apiRef={formAPIRef}
              onClose={onCancel}
            />
          );
        case VariationActionType.ConfirmInstructionOfVariation:
          return (
            <ConfirmInstructionOfVariationAction
              apiRef={formAPIRef}
              onClose={onCancel}
            />
          );
        case VariationActionType.InstructWorkToCommenceOnVariation:
          return (
            <InstructWorkToCommenceAction
              apiRef={formAPIRef}
              onClose={onCancel}
              triggersAuthWorkflow={!!selectedActionFull?.actionMapping}
            />
          );
        default:
          return null;
      }
    },
    [
      selectedActionFull,
      variation?.ongoingAuthorizationAudit,
      consentedProposal,
      finalProposal,
      onCancel,
    ]
  );

  const handleRecord = () => {
    if (formAPIRef.current?.validate()) {
      formAPIRef.current?.record();
    }
  };

  const handleSend = () => {
    if (formAPIRef.current?.validate()) {
      formAPIRef.current?.send();
    }
  };

  const handleBeforeClose = useCallback(() => {
    formAPIRef.current?.onBeforeAbort?.();
  }, []);

  useImperativeHandle(
    apiRef,
    () => ({
      onBeforeClose: handleBeforeClose,
    }),
    [handleBeforeClose]
  );

  const showProposals =
    variation?.status === VariationStatus.Priced &&
    (!selectedAction ||
      [
        VariationActionType.ConsentToProposalForProposedVariation,
        VariationActionType.SubmitProposalForProposedVariation, // debatable
      ].indexOf(selectedAction) < 0);

  // TODO: maybe revoked too?
  // const showAgreementOrDetermination =
  //   variation?.status === VariationStatus.Accepted ||
  //   variation?.status === VariationStatus.Rejected;

  useEffect(() => {
    // TODO: this is subject to being removed as BE probably updated the actionList such that it does not allow users to re-do the same action again
    // TODO: verify this in this patch
    if (selectedActionFull) {
      const ongoingAuthorizationProductOutputName =
        variation?.ongoingAuthorizationAudit?.action.productOutputAction.name;

      setActionAwaitingAuthorization(
        !!ongoingAuthorizationProductOutputName &&
          selectedActionFull.actionMapping?.productOutputAction.name ===
            ongoingAuthorizationProductOutputName
      );
    }
  }, [selectedActionFull, variation?.ongoingAuthorizationAudit]);

  return (
    <Box display="flex" flexDirection="column" pt={1}>
      {variation && (
        <Box mb={3}>
          <WidgetHeader
            productItem={variation}
            productType={ProductType.Variations}
          />
        </Box>
      )}
      {variation?.ongoingAuthorizationAudit && (
        <Box mb={2}>
          <AuthorizationProgressPreview
            authorizationWorkflow={
              variation?.ongoingAuthorizationAudit.workflow
            }
            authorizationWorkflowAudit={
              variation?.ongoingAuthorizationAudit ?? undefined
            }
          />
        </Box>
      )}
      <WidgetSection type={CEWidgetSectionType.NextStep}>
        <VariationWidgetNextStepSection
          variation={variation}
          variationActionList={variationActions}
          previewMode
        />
      </WidgetSection>
      {showProposals && (
        <Box mt={2}>
          {proposalsLoading ? (
            <CenteredLoadingIndicator />
          ) : (
            <>
              {VOProposalModalVisibility && selectedVOProposal && (
                <VariationProposalAgreementDeterminationReadOnlyModal
                  open={VOProposalModalVisibility}
                  onClose={toggleVOProposalModalVisibility}
                  item={selectedVOProposal}
                  contract={contract}
                />
              )}
              <VOProposalsAgreementsDeterminationsTable
                items={
                  consentedProposal ? [consentedProposal] : variationProposals
                }
                contractCurrency={contract.valueCurrency ?? ""}
                type="VariationProposal"
                onRowClick={handleProposalClick}
              />
            </>
          )}
        </Box>
      )}
      {/* {showAgreementOrDetermination && !selectedAction && (
        <Box mt={2}>
          {finalAgreementLoading || finalDeterminationLoading ? (
            <CenteredLoadingIndicator />
          ) : (
            <>
              {finalAgreementDeterminationModalVisibility &&
                (finalAgreement || finalDetermination) && (
                  <DetailedClaimAgreementDeterminationReadOnlyModal
                    open={finalAgreementDeterminationModalVisibility}
                    onClose={toggleFinalAgreementDeterminationModalVisibility}
                    item={finalAgreement || finalDetermination}
                  />
                )}
              <DetailedClaimsAgreementsDeterminationsTable
                items={[finalAgreement || finalDetermination]}
                contractCurrency={contract.valueCurrency ?? ""}
                type={finalAgreement ? "ClaimAgreement" : "ClaimDetermination"}
                onRowClick={toggleFinalAgreementDeterminationModalVisibility}
              />
            </>
          )}
        </Box>
      )} */}
      <WidgetSection type={CEWidgetSectionType.ActionSelection} mt={2}>
        <Typography variant="p1" color="grey.800" fontWeight={600} mb={1.25}>
          {t("Projects.Variations.ActionModal.actionsAvailable")}
        </Typography>
        <FormControl fullWidth>
          {!selectedAction && (
            <InputLabel shrink={false} sx={{ top: "-5px" }}>
              {t("Projects.Variations.ActionModal.actionPlaceholder")}
            </InputLabel>
          )}
          <Select
            labelId="actions-available-label"
            id="actions-available-id"
            value={selectedAction ?? ""}
            onChange={handleActionChange}
            data-testid="variation-action-dropdown"
            fullWidth
            size="small"
            sx={{ backgroundColor: "white" }}
            required
            SelectDisplayProps={{
              style: { display: "flex", alignItems: "center" },
            }}
          >
            {variationActions.map((variationActionType) => {
              const label = t(
                variationActionTypeTranslations[
                  isFIDIC17White
                    ? variationActionTypeFIDIC17WhiteTypeMapping(
                        variationActionType.type
                      )
                    : isFIDIC99RedYellow
                    ? variationActionTypeFIDIC99RedYellowTypeMapping(
                        variationActionType.type
                      )
                    : variationActionType.type
                ]
              );

              return (
                <MenuItem
                  key={variationActionType.type}
                  value={variationActionType.type}
                  data-testid={`variation-action-${variationActionType.type}-option`}
                >
                  {label}
                  {variationActionType.actionMapping ? (
                    <Box ml={1}>
                      <AuthorizationRequiredPill />
                    </Box>
                  ) : null}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </WidgetSection>
      {selectedAction && !actionAwaitingAuthorization && (
        <Box mt={2}>{renderVariationActionForm(selectedAction)}</Box>
      )}
      {/* TODO: maybe this is not needed anymore, if user cannot select the same action twice */}
      {actionAwaitingAuthorization && (
        <Box mt={1}>
          <Typography variant="caption" color="error" mt={0.5}>
            {t("Home.Authorizations.actionAwaitingAuthorization")}
          </Typography>
        </Box>
      )}
      {/* TODO `authorizationWorkflow` should be present if requiresAuthorization is true - delete extra check after BE is fixed */}
      {selectedActionFull &&
        selectedActionFull.actionMapping &&
        !actionAwaitingAuthorization && (
          <Box mt={3}>
            <AuthorizationProgressPreview
              authorizationWorkflow={selectedActionFull.actionMapping.workflow}
            />
          </Box>
        )}
      <Box display="flex" justifyContent="flex-end" mt={3}>
        <ActionModalFooter
          sendDisabled={actionAwaitingAuthorization}
          recordDisabled={actionAwaitingAuthorization}
          recordSupported={recordSupported}
          onCancel={handleCancel}
          onRecord={handleRecord}
          onSend={handleSend}
        />
      </Box>
    </Box>
  );
};
