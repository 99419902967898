import { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useGraphQuery } from "hooks/useGraphQuery";
import {
  RecordVariationInstructionCommenceWorkMutation,
  RecordVariationInstructionCommenceWorkMutationVariables,
  SendVariationInstructionCommenceWorkMutation,
  SendVariationInstructionCommenceWorkMutationVariables,
  VariationInstructionCommenceWorkPromptQuery,
  VariationInstructionCommenceWorkPromptQueryVariables,
} from "generated/graphql";
import { OnMutationDone } from "decl";
import { useGraphMutation } from "hooks/useGraphMutation";
import { VariationWidgetContext } from "../../VariationWidget/VariationWidget.context";
import {
  recordVariationInstructionCommenceWorkMutation,
  sendVariationInstructionCommenceWorkMutation,
  variationInstructionCommenceWorkPromptQuery,
} from "./InstructWorkToCommenceAction.query";

export const useInstructWorkToCommenceAction = (
  triggersAuthWorkflow?: boolean
) => {
  const { t } = useTranslation();
  const { onChange, variation } = useContext(VariationWidgetContext);

  const { data: promptData, loading } = useGraphQuery<
    VariationInstructionCommenceWorkPromptQuery,
    VariationInstructionCommenceWorkPromptQueryVariables
  >(variationInstructionCommenceWorkPromptQuery, {
    variables: {
      input: {
        variationId: variation?.id!,
      },
    },
  });

  const onUpdated: OnMutationDone = useCallback(
    (cache) => {
      cache.evict({ id: "ROOT_QUERY", fieldName: "variationItems" });
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "variationProposalExplorerItems",
        args: { variationId: variation!.id },
      });
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "variationInstructionCommenceWorkPrompt",
        args: { input: { variationId: variation!.id } },
      });
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "variationAction",
      });
      cache.evict({
        id: "ROOT_QUERY",
        fieldName: "variationHistoryItems",
        args: { variationId: variation!.id },
      });
      cache.gc();
      onChange?.();
    },
    [onChange, variation]
  );

  const [
    sendCommenceWorkInstruction,
    { loading: sendCommenceWorkInstructionLoading },
  ] = useGraphMutation<
    SendVariationInstructionCommenceWorkMutation,
    SendVariationInstructionCommenceWorkMutationVariables
  >(
    sendVariationInstructionCommenceWorkMutation,
    { update: onUpdated },
    triggersAuthWorkflow
      ? t("common.successMessages.actionInitiatedForAuthorization")
      : t("common.successMessages.entitySent", {
          entity: t("Projects.Variations.instructionCommenceWork"),
        })
  );

  const [
    recordCommenceWorkInstruction,
    { loading: recordCommenceWorkInstructionLoading },
  ] = useGraphMutation<
    RecordVariationInstructionCommenceWorkMutation,
    RecordVariationInstructionCommenceWorkMutationVariables
  >(
    recordVariationInstructionCommenceWorkMutation,
    { update: onUpdated },
    triggersAuthWorkflow
      ? t("common.successMessages.actionInitiatedForAuthorization")
      : t("common.successMessages.entityRecorded", {
          entity: t("Projects.Variations.instructionCommenceWork"),
        })
  );

  return {
    remarks: promptData?.variationInstructionCommenceWorkPrompt.remarks ?? "",
    agreementSubmitted:
      promptData?.variationInstructionCommenceWorkPrompt.agreementSubmitted,
    loading,
    sendCommenceWorkInstruction,
    recordCommenceWorkInstruction,
    actionLoading:
      sendCommenceWorkInstructionLoading ||
      recordCommenceWorkInstructionLoading,
  };
};
