import {
  Box,
  ClickAwayListener,
  Fade,
  List,
  Popper,
  PopperProps,
  Typography,
} from "@mui/material";
import { useMemo, useState } from "react";
import {
  Arrow,
  PopperContentContainer,
  StyledListItem,
} from "./StatusTag.styled";
import { StyledChipNew } from "./StatusTagNew.styled";
import { ItemStatusOptionStyles } from "generated/graphql";
import { UnselectedNewIcon } from "components/Icons/UnselectedNewIcon";
import { SelectedNewIcon } from "components/Icons/SelectedNewIcon";
import { StatusStyles } from "theme/extendedTheme";
import { ArrowDownIcon } from "components/Icons/ArrowDownIcon";
import { useTranslation } from "react-i18next";

export enum ExtendedOptionStyles {
  Amber = "Amber",
  Black = "Black",
  Green = "Green",
  Grey = "Grey",
  Purple = "Purple",
  Blue = "Blue",
  Red = "Red",
}

export type StatusOptionNew<T> = {
  id: T;
  label: string;
  style: ExtendedOptionStyles | ItemStatusOptionStyles;
};

export type StatusTagProps<T> = {
  options: StatusOptionNew<T>[];
  selectedOptionId: string;
  disabled?: boolean;
  disablePortal?: boolean;
  size?: "small" | "large";
  hideArrow?: boolean;
  onChange?: (newSelectedOption: StatusOptionNew<T>) => void;
} & Pick<PopperProps, "placement">;

export const StatusTagNew = <T extends string>({
  options,
  selectedOptionId,
  disabled,
  disablePortal = false,
  size = "large",
  hideArrow,
  placement = "right-start",
  onChange,
}: StatusTagProps<T>) => {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [arrowRef, setArrowRef] = useState<HTMLElement | null>(null);
  const [popperRef, setPopperRef] = useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (!popperRef?.contains((event as any).target)) {
      setAnchorEl(event.currentTarget);
    }
    event.stopPropagation();
  };

  const open = Boolean(anchorEl);
  const id = open ? "transition-popper" : undefined;

  const handleClickAway = () => {
    setAnchorEl(null);
  };

  const handleItemClick = (selectedOption: StatusOptionNew<T>) => {
    onChange?.(selectedOption);
    setAnchorEl(null);
  };

  const selectedStatus = useMemo(
    () => options.find((curStatus) => curStatus.id === selectedOptionId),
    [options, selectedOptionId]
  );

  const selectedStatusStyle = useMemo(() => {
    const selectedOption = options.find(
      (option) => option.id === selectedOptionId
    )!;

    switch (selectedOption?.style) {
      case ExtendedOptionStyles.Amber:
      case ExtendedOptionStyles.Blue:
      case ExtendedOptionStyles.Purple:
      case ExtendedOptionStyles.Red:
      case ExtendedOptionStyles.Green:
        return StatusStyles[selectedOption.style];
      case ExtendedOptionStyles.Black:
      case ExtendedOptionStyles.Grey:
      default:
        return StatusStyles.Grey;
    }
  }, [selectedOptionId, options]);

  return (
    <Box
      onClick={disabled ? undefined : handleClick}
      data-testid="status-trigger"
    >
      <StyledChipNew
        variant="filled"
        bgColor={selectedStatusStyle.bgColor}
        size="small"
        disabled={disabled}
        label={
          <Box display="flex" alignItems="center">
            <Typography
              variant="p3"
              fontWeight={500}
              color={selectedStatusStyle.color}
              px={2}
            >
              {t(selectedStatus?.label ?? "")}
            </Typography>
            {!disabled && <ArrowDownIcon color={selectedStatusStyle.color} />}
          </Box>
        }
      />
      {open && (
        <Popper
          id={id}
          open={open}
          anchorEl={anchorEl}
          transition
          placement={placement}
          disablePortal={disablePortal}
          ref={setPopperRef}
          sx={{ zIndex: 20 }}
          modifiers={[
            {
              name: "flip",
              enabled: true,
              options: {
                altBoundary: true,
                rootBoundary: "document",
                padding: 8,
              },
            },
            {
              name: "offset",
              options: {
                offset: [0, hideArrow ? 0 : 20],
              },
            },
            {
              name: "arrow",
              enabled: true,
              options: {
                element: arrowRef,
              },
            },
          ]}
        >
          {({ TransitionProps }) => (
            <ClickAwayListener onClickAway={handleClickAway}>
              <Fade {...TransitionProps} timeout={350}>
                <PopperContentContainer>
                  {!hideArrow && <Arrow ref={setArrowRef} />}
                  <List>
                    {Object.values(options).map((curStatus) => {
                      const selected = selectedOptionId === curStatus.id;

                      return (
                        <StyledListItem
                          key={curStatus.id}
                          onClick={() => handleItemClick(curStatus)}
                          size={size}
                          selected={selected}
                          data-testid={`${curStatus.label}-status-option`}
                        >
                          {selected ? (
                            <SelectedNewIcon />
                          ) : (
                            <UnselectedNewIcon />
                          )}
                          <Typography variant="p2" ml={1}>
                            {t(curStatus.label)}
                          </Typography>
                        </StyledListItem>
                      );
                    })}
                  </List>
                </PopperContentContainer>
              </Fade>
            </ClickAwayListener>
          )}
        </Popper>
      )}
    </Box>
  );
};
