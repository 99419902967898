import {
  ActiveClaimTypesQuery,
  ActiveClaimTypesQueryVariables,
  ListItem,
} from "generated/graphql";
import { activeClaimTypesQuery } from "graphql/queries/activeClaimTypes.query";
import { useGraphQuery } from "hooks/useGraphQuery";

export const useInstructionProvideQuotationExtraDataForm = (
  contractId: string
) => {
  const { data: activeClaimTypes, loading } = useGraphQuery<
    ActiveClaimTypesQuery,
    ActiveClaimTypesQueryVariables
  >(activeClaimTypesQuery, {
    variables: {
      contractId,
    },
  });

  return {
    activeClaimTypes: (activeClaimTypes?.activeClaimTypes ?? []) as ListItem[],
    loading,
  };
};
