import { Box, Typography, useTheme } from "@mui/material";
import { EffectEstimationType, EffectOption } from "generated/graphql";
import { EffectOptionAnswer } from "../Effect.decl";
import { UnitValueReadOnly } from "./UnitValueReadOnly";
import { Check } from "phosphor-react";

export type EffectOptionFieldReadOnlyProps = {
  effectOption: EffectOption;
  data?: EffectOptionAnswer;
  allowCheck?: boolean;
  contractCurrency: string;
  noOverflow?: boolean;
};

export const EffectOptionFieldReadOnly: React.FC<
  EffectOptionFieldReadOnlyProps
> = ({
  effectOption,
  data,
  contractCurrency,
  allowCheck = true,
  noOverflow = false,
}) => {
  const theme = useTheme();

  return (
    <Box display="flex" alignItems="center">
      {allowCheck && (
        <Box
          width={32}
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
        >
          {data?.selected && (
            <Check size={24} color={theme.palette.success.main} />
          )}
        </Box>
      )}
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        flex={1}
      >
        <Typography
          variant="p1"
          color="grey.600"
          textOverflow={noOverflow ? "ellipsis" : "inherit"}
          overflow={noOverflow ? "hidden" : "inherit"}
          whiteSpace={noOverflow ? "nowrap" : "normal"}
          flex={noOverflow ? 1 : "inherit"}
        >
          {effectOption.label}
        </Typography>
        {effectOption.estimationType !== EffectEstimationType.None &&
          data?.value && (
            <UnitValueReadOnly
              unit={effectOption.unit}
              value={data.value}
              currency={contractCurrency}
              estimationType={effectOption.estimationType}
            />
          )}
      </Box>
    </Box>
  );
};
