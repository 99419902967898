import {
  GridEventListener,
  GridFilterModel,
  GridLogicOperator,
  GridRowParams,
  GridRowSelectionModel,
  GridSortModel,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import moment from "moment";
import { NewStyledDataGrid } from "components/StyledDataGrid";
import {
  DailyDiaryItem,
  DailyDiaryItemMixed,
  EmptyDailyDiaryItem,
} from "generated/graphql";
import { useCallback, useImperativeHandle, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { exportToExcel } from "../../../../../../helpers/exportToExcel";
import { getUserName } from "helpers/miscelaneous";
import { ProductItemsTablePublicAPI } from "containers/Projects/components/ProductItemsView/ProductItemsView.decl";
import { getColumns } from "./DailyDiaries.constants";
import { DailyDiaryExtraTab } from "../../DailyDiariesView.constants";
import { useNavigate, useSearchParams } from "react-router-dom";
import { dateISOFormat } from "../../../../../../constants";
import { dailyDiaryDateFormat } from "containers/Projects/containers/DailyDiary/DailyDiary.constants";
import { NewAppPaths } from "helpers/paths/paths";

export type DailyDiariesTableProps = {
  dailyDiaries: DailyDiaryItem[]; // TODO: mixed dailyDiaryItems
  selectedExtraTab?: DailyDiaryExtraTab;
  loading: boolean;
  apiRef?: React.Ref<ProductItemsTablePublicAPI>;
  selectionModel?: GridRowSelectionModel;
  onLoadMore?: () => void;
  onSelectionModelChange: (newSelectionModel: GridRowSelectionModel) => void;
};

export const DailyDiariesTable: React.FC<DailyDiariesTableProps> = ({
  dailyDiaries,
  loading,
  selectedExtraTab,
  apiRef,
  selectionModel,
  onLoadMore,
  onSelectionModelChange,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const productInstanceId = searchParams.get("productInstanceId");

  const gridApiRef = useGridApiRef();
  const [sortingModel, setSortingModel] = useState<GridSortModel>([
    { field: "date", sort: "desc" },
  ]);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
    logicOperator: GridLogicOperator.And,
    quickFilterLogicOperator: GridLogicOperator.And,
    quickFilterValues: [],
  });

  const handleExportToExcel = useCallback(async () => {
    if (!dailyDiaries || !dailyDiaries.length) {
      console.warn("No daily diaries to export");
      return;
    }

    const columns = [
      {
        header: t("common.labels.date"),
        key: "date",
        width: 20,
      },
      {
        header: "#",
        key: "number",
        width: 20,
      },
      {
        header: t("common.labels.status"),
        key: "status",
        width: 20,
      },
      {
        header: t("Projects.DailyDiaries.revisions"),
        key: "revisions",
        width: 20,
      },
      {
        header: t("common.labels.sentBy"),
        key: "sentBy",
        width: 20,
      },
      {
        header: t("common.labels.dateSent"),
        key: "dateSent",
        width: 20,
      },
    ];

    const rows = dailyDiaries
      .filter(
        (dailyDiary) => (selectionModel || []).indexOf(dailyDiary.id) >= 0
      )
      .map((dailyDiary) => {
        const latestRevisionNumber = dailyDiary.latestRevision
          ? Number(dailyDiary.latestRevision.number)
          : 0;

        return {
          ...dailyDiary,
          date: moment(dailyDiary.date).format(dailyDiaryDateFormat),
          number: dailyDiary.number,
          sentBy: getUserName(dailyDiary.sentBy),
          revisions: latestRevisionNumber || "",
          dateSent: moment(dailyDiary.dateCreated).format(dateISOFormat),
        };
      });

    exportToExcel(t("Projects.DailyDiaries.dailyDiaries"), columns, rows);
  }, [dailyDiaries, selectionModel, t]);

  const handleRowClick: GridEventListener<"rowClick"> | undefined = useCallback(
    (dailyDiary: GridRowParams<DailyDiaryItem>) => {
      const selection = window.getSelection()?.toString();
      if (!selection) {
        navigate(
          NewAppPaths.authorized.Projects.children.DailyDiaryDetails.pathConstructor(
            productInstanceId!,
            dailyDiary.row.date,
            dailyDiary.row.id
          )
        );
      }
    },
    [productInstanceId, navigate]
  );

  const handleClearSelection = useCallback(() => {
    onSelectionModelChange([]);
  }, [onSelectionModelChange]);

  const handleSelectionModelChange = (
    newSelectionModel: GridRowSelectionModel
  ) => {
    onSelectionModelChange(newSelectionModel);
  };

  useImperativeHandle(
    apiRef,
    () => ({
      clearSelection: handleClearSelection,
      exportToExcel: handleExportToExcel,
    }),
    [handleClearSelection, handleExportToExcel]
  );

  const columns = useMemo(
    () => getColumns(t, !!selectedExtraTab),
    [t, selectedExtraTab]
  );

  return (
    <NewStyledDataGrid
      apiRef={gridApiRef}
      rows={dailyDiaries || []}
      columns={columns}
      getRowId={(rowData: DailyDiaryItemMixed) =>
        (rowData as DailyDiaryItem).id || (rowData as EmptyDailyDiaryItem).date
      }
      rowSelectionModel={selectionModel}
      onRowSelectionModelChange={handleSelectionModelChange}
      loading={loading}
      sortingMode="client"
      sortModel={sortingModel}
      onSortModelChange={setSortingModel}
      filterMode="client"
      filterModel={filterModel}
      onFilterModelChange={setFilterModel}
      onRowClick={handleRowClick}
      onRowsScrollEnd={onLoadMore}
      checkboxSelection
      disableRowSelectionOnClick
      hideFooter
    />
  );
};
