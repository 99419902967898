import { Box } from "@mui/material";
import {
  NewEntityHeader,
  NewEntityHeaderFontSize,
} from "components/NewEntityHeader";
import { PageContentContainer } from "components/PageContentContainer";
import { PageContentHeaderSize } from "components/PageContentHeader/PageContentHeader";
import { FormPublicApi } from "decl";
import {
  AddCompanyLookupCollectionInput,
  AddCompanyLookupCollectionMutation,
  AddCompanyLookupCollectionMutationVariables,
  CompanyLookupCollection,
} from "generated/graphql";
import { addCompanyLookupCollectionMutation } from "graphql/mutations/addCompanyLookupCollection";
import { useCompanies } from "hooks/useCompanies";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CustomLookupCollectionForm } from "./CustomLookupCollectionForm/CustomLookupCollectionForm";

export const NewCustomLookupCollection = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const ref = useRef<FormPublicApi>(null);

  const { companies, loading: companiesLoading } = useCompanies();

  const [lookupCollectionData, setLookupCollectionData] =
    useState<AddCompanyLookupCollectionInput>();

  const [newLookupCollection, { loading: newLookupCollectionLoading }] =
    useGraphMutation<
      AddCompanyLookupCollectionMutation,
      AddCompanyLookupCollectionMutationVariables
    >(
      addCompanyLookupCollectionMutation,
      {
        update: (cache) => {
          cache.evict({
            id: "ROOT_QUERY",
            fieldName: "companyLookupCollections",
          });
          cache.gc();
        },
      },
      t("common.successMessages.entityCreated", {
        entity: t("AdminConsole.Lookups.customLookupCollection"),
      })
    );

  const handleAddNewLookupCollection = async () => {
    if (ref.current?.validate()) {
      const { errors } = await newLookupCollection({
        variables: {
          input: lookupCollectionData!,
        },
      });

      if (!errors) {
        navigate(-1);
      }
    }
  };

  const handleLookupCollectionChange = useCallback(
    (data: AddCompanyLookupCollectionInput | CompanyLookupCollection) => {
      setLookupCollectionData(data as AddCompanyLookupCollectionInput);
    },
    []
  );

  return (
    <Box>
      <NewEntityHeader
        onAdd={handleAddNewLookupCollection}
        entityName={t("AdminConsole.Lookups.customLookupCollection")}
        size={PageContentHeaderSize.Normal}
        fontSize={NewEntityHeaderFontSize.Large}
        generalLoading={newLookupCollectionLoading || companiesLoading}
        addActionLoading={newLookupCollectionLoading}
      />
      <PageContentContainer>
        <CustomLookupCollectionForm
          onChange={handleLookupCollectionChange}
          companies={companies}
          apiRef={ref}
        />
      </PageContentContainer>
    </Box>
  );
};
