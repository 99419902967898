import { Box, Breadcrumbs, Link, Typography } from "@mui/material";
import {
  EffectOption,
  EffectPreset,
  EffectPresetQuery,
  EffectPresetQueryVariables,
  EffectPresetStatus,
  User,
} from "generated/graphql";
import { useGraphQuery } from "hooks/useGraphQuery";
import { useParams } from "react-router-dom";
import { EffectPresetOptions } from "./components/EffectPresetOptions/EffectPresetOptions";
import { useEffectPresetOptions } from "./components/EffectPresetOptions/useEffectPresetOptions";
import { EntityDetailsHeader } from "components/EntityDetailsHeader";
import { useActiveRemovedStatusOptions } from "components/StatusTag/useActiveRemovedStatusOptions";
import { StatusOption } from "components/StatusTag/StatusTag";
import { useMemo } from "react";
import { useChangeEffectPresetStatus } from "./components/EffectPresets/useChangeEffectPresetStatus";
import { OnMutationDone } from "decl";
import { effectPresetQuery } from "graphql/queries/effectPreset.query";
import { useNavigateBack } from "hooks/useNavigateBack";
import { useTranslation } from "react-i18next";
import { getUserName } from "helpers/miscelaneous";

export const EffectPresetDetails = () => {
  const { effectPresetId } = useParams();
  const handleNavigateBack = useNavigateBack();
  const { t } = useTranslation();

  const statusOptions =
    useActiveRemovedStatusOptions() as StatusOption<EffectPresetStatus>[];

  const {
    data: effectPreset,
    refetch: refetchEffectPreset,
    loading: getEffectPresetLoading,
  } = useGraphQuery<EffectPresetQuery, EffectPresetQueryVariables>(
    effectPresetQuery,
    { variables: { id: effectPresetId! } }
  );

  const handleEffectPresetChange: OnMutationDone = (cache) => {
    refetchEffectPreset();

    cache.evict({ id: "ROOT_QUERY", fieldName: "effectPresets" });
    cache.gc();
  };

  const { loading, addEffectOption, changeEffectOptionStatus } =
    useEffectPresetOptions({
      onAdd: handleEffectPresetChange,
      onStatusChange: handleEffectPresetChange,
    });

  const { loading: changeEffectPresetStatusLoading, changeEffectPresetStatus } =
    useChangeEffectPresetStatus(handleEffectPresetChange);

  const handleStatusChange = (newStatus: EffectPresetStatus) => {
    changeEffectPresetStatus(
      effectPreset!.effectPreset! as EffectPreset,
      newStatus
    );
  };

  const creatorStr = useMemo(() => {
    if (
      effectPreset?.effectPreset.creator.firstname &&
      effectPreset?.effectPreset.creator.surname
    ) {
      return getUserName(
        effectPreset?.effectPreset.creator as User | undefined
      );
    }

    return "";
  }, [effectPreset]);

  return (
    <Box>
      <EntityDetailsHeader
        loading={loading || changeEffectPresetStatusLoading}
        title={effectPreset?.effectPreset.name || ""}
        subtitle={
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Link key="1" onClick={handleNavigateBack}>
              <Typography variant="body2">
                {t("AdminConsole.Effect.labels.effectPresets")}
              </Typography>
            </Link>
          </Breadcrumbs>
        }
        status={effectPreset?.effectPreset.status}
        statusOptions={statusOptions}
        creator={creatorStr}
        dateCreated={effectPreset?.effectPreset.dateCreated}
        onStatusChange={handleStatusChange}
      />
      <EffectPresetOptions
        effectPresetId={effectPresetId!}
        effectOptions={
          (effectPreset?.effectPreset.options.items as EffectOption[]) || []
        }
        loading={getEffectPresetLoading || loading}
        onStatusChange={changeEffectOptionStatus}
        onAdd={addEffectOption}
      />
    </Box>
  );
};
