import { Box, css, styled, Typography } from "@mui/material";
import { customShadows, unofficialThemeColors } from "theme/extendedTheme";

export const NotificationsPopperContentContainer = styled(Box)(
  ({ theme }) => css`
    background: ${theme.palette.common.white};
    box-shadow: ${customShadows.activeSmall};
    border-radius: 16px;
    border: 1px solid ${theme.palette.grey[100]};
    width: 540px;
  `
);

export const BadgeContainer = styled(Box)(
  () => css`
    position: relative;
    cursor: pointer;
  `
);

export const NotificationsIconContainer = styled(Box)<{ active?: boolean }>(
  ({ theme, active }) => css`
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid ${theme.palette.grey[100]};
    border-radius: 12px;
    box-sizing: border-box;
    background-color: ${active ? theme.palette.grey[100] : "inherit"};
  `
);

export const NotificationText = styled(Typography)(
  () => css`
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 4;
    white-space: break-spaces;
  `
);

export const NotificationContainer = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== "readUnreadBtnHovered" && prop !== "unread",
})<{
  unread?: boolean;
  readUnreadBtnHovered?: boolean;
}>(({ theme, unread = false, readUnreadBtnHovered }) => {
  let borderColor;

  if (readUnreadBtnHovered) {
    borderColor = unread ? theme.palette.info.light : "#E6EFFA";
  } else {
    borderColor = unread
      ? unofficialThemeColors.bluePale
      : theme.palette.grey[100];
  }

  const backgroundColor = unread
    ? unofficialThemeColors.bluePale
    : theme.palette.common.white;

  return css`
    background: ${theme.palette.common.white};
    padding-top: ${theme.spacing(2)};
    padding-bottom: ${theme.spacing(2)};
    padding-left: ${theme.spacing(2)};
    padding-right: ${theme.spacing(2)};
    border: 1px solid;
    border-color: ${borderColor};
    background-color: ${backgroundColor};
    display: flex;
    flex-direction: column;
    border-radius: 12px;
  `;
});
