import { gql } from "@apollo/client";

export const productInstanceUserRolesQuery = gql`
  query productInstanceUserRoles($id: ID!) {
    productInstance(id: $id) {
      id
      description
      contractId
      productId
      productSchemaId
      statusCollectionId
      status
      creatorId
      roles {
        items {
          userId
          productInstanceId
          productRoleId
          status
          dateCreated
          creatorId
          user {
            id
            firstname
            surname
            status
            jobTitle
            company {
              id
              registeredName
              tradingName
            }
          }
          productRole {
            id
            name
            isInternal
            status
          }
        }
      }
    }
  }
`;
