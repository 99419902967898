import { Box } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-pro";
import { ItemTag } from "components/ItemTag";
import {
  ExtendedOptionStyles,
  StatusOptionNew,
} from "components/StatusTag/StatusTagNew";
import { dateISOFormat } from "../../../../constants";
import {
  AuthorizationActionStatus,
  AuthorizationWorkflowAudit,
  AuthorizationWorkflowAuditStatus,
} from "generated/graphql";
import moment from "moment";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { datetimeComparatorFn } from "helpers/dataGrid.helpers";
import { OverflowTooltip } from "components/OverflowTooltip";
import { computeRegardingColumnText } from "./Authorizations.utils";

export const useCommonAuthorizationColumns = () => {
  const { t } = useTranslation();

  return useMemo((): GridColDef<AuthorizationWorkflowAudit>[] => {
    return [
      {
        field: "action",
        headerName: t("Home.Authorizations.action"),
        flex: 0.15,
        resizable: true,
        valueGetter: (params) =>
          t(
            `Home.Authorizations.AuthorizeDenyModal.actions.${params.row.action.productOutputAction.name}`
          ),
      },
      {
        field: "regarding",
        headerName: t("common.labels.regarding"),
        width: 220,
        resizable: true,
        renderCell: (params) => {
          const regardingProductItem = computeRegardingColumnText(params.row);

          return (
            <Box display="flex" alignItems="center" width="100%">
              <ItemTag type={params.row.regardingProductType} />
              <OverflowTooltip
                title={regardingProductItem}
                typographyProps={{
                  variant: "p2",
                  ml: 1,
                }}
              />
            </Box>
          );
        },
      },
      {
        field: "project",
        headerName: t("common.labels.project"),
        flex: 0.15,
        resizable: true,
        valueGetter: (params) =>
          params.row.action.productInstance.contract.project.friendlyName,
      },
      {
        field: "contract",
        headerName: t("common.labels.contract"),
        flex: 0.15,
        resizable: true,
        valueGetter: (params) =>
          params.row.action.productInstance.contract.friendlyName,
      },
      {
        field: "initiated",
        headerName: t("Home.Authorizations.initiated"),
        width: 100,
        resizable: true,
        sortComparator: datetimeComparatorFn,
        valueGetter: (params) => params.row.dateInitiated,
        valueFormatter: (params) => moment(params.value).format(dateISOFormat),
      },
    ];
  }, [t]);
};

export const authorizationAuditStatusOptions: StatusOptionNew<string>[] = [
  {
    id: AuthorizationWorkflowAuditStatus.InProgress,
    label: `Home.Authorizations.status.${AuthorizationWorkflowAuditStatus.InProgress}`,
    style: ExtendedOptionStyles.Amber,
  },
  {
    id: AuthorizationWorkflowAuditStatus.Authorized,
    label: `Home.Authorizations.status.${AuthorizationWorkflowAuditStatus.Authorized}`,
    style: ExtendedOptionStyles.Green,
  },
  {
    id: AuthorizationWorkflowAuditStatus.Denied,
    label: `Home.Authorizations.status.${AuthorizationWorkflowAuditStatus.Denied}`,
    style: ExtendedOptionStyles.Red,
  },
  {
    id: AuthorizationWorkflowAuditStatus.Removed,
    label: `Home.Authorizations.status.${AuthorizationWorkflowAuditStatus.Removed}`,
    style: ExtendedOptionStyles.Grey,
  },
];

export const authorizationAuditTrailStatusOptions: StatusOptionNew<string>[] = [
  {
    id: AuthorizationActionStatus.Pending,
    label: `Home.Authorizations.actionStatus.${AuthorizationActionStatus.Pending}`,
    style: ExtendedOptionStyles.Amber,
  },
  {
    id: AuthorizationActionStatus.Approved,
    label: `Home.Authorizations.actionStatus.${AuthorizationActionStatus.Approved}`,
    style: ExtendedOptionStyles.Green,
  },
  {
    id: AuthorizationActionStatus.Denied,
    label: `Home.Authorizations.actionStatus.${AuthorizationActionStatus.Denied}`,
    style: ExtendedOptionStyles.Red,
  },
];
