import {
  RecordClaimNoAgreementNoticeMutation,
  RecordClaimNoAgreementNoticeMutationVariables,
  SendClaimNoAgreementNoticeMutation,
  SendClaimNoAgreementNoticeMutationVariables,
} from "generated/graphql";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { ClaimWidgetContext } from "../../ClaimWidget/ClaimWidget.context";

import {
  recordClaimNoAgreementNoticeMutation,
  sendClaimNoAgreementNoticeMutation,
} from "./NotifyNoClaimAgreementAction.query";
import { OnMutationDone } from "decl";

export const useNotifyNoClaimAgreementAction = () => {
  const { t } = useTranslation();
  const { onChange, claim } = useContext(ClaimWidgetContext);

  const handleOnUpdate: OnMutationDone = (cache) => {
    cache.evict({
      id: "ROOT_QUERY",
      fieldName: "claimItems",
    });
    cache.evict({
      id: "ROOT_QUERY",
      fieldName: "claimHistoryItems",
      args: { claimId: claim!.id },
    });
    cache.evict({
      id: "ROOT_QUERY",
      fieldName: "claimDetailsExplorerItems",
      args: { claimId: claim!.id },
    });
    cache.evict({
      id: "ROOT_QUERY",
      fieldName: "claimAction",
    });
    cache.gc();
    onChange?.();
  };

  const [
    sendClaimNoAgreementNotice,
    { loading: sendClaimNoAgreementNoticeLoading },
  ] = useGraphMutation<
    SendClaimNoAgreementNoticeMutation,
    SendClaimNoAgreementNoticeMutationVariables
  >(
    sendClaimNoAgreementNoticeMutation,
    {
      update: handleOnUpdate,
    },
    t("common.successMessages.entitySent", {
      entity: t("Projects.Claims.claimNoAgreement"),
    })
  );

  const [
    recordClaimNoAgreementNotice,
    { loading: recordClaimNoAgreementNoticeLoading },
  ] = useGraphMutation<
    RecordClaimNoAgreementNoticeMutation,
    RecordClaimNoAgreementNoticeMutationVariables
  >(
    recordClaimNoAgreementNoticeMutation,
    {
      update: handleOnUpdate,
    },
    t("common.successMessages.entityRecorded", {
      entity: t("Projects.Claims.claimNoAgreement"),
    })
  );

  return {
    actionLoading:
      sendClaimNoAgreementNoticeLoading || recordClaimNoAgreementNoticeLoading,
    sendClaimNoAgreementNotice,
    recordClaimNoAgreementNotice,
  };
};
