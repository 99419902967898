import { TabContext, TabList } from "@mui/lab";
import { Box, Grid, Typography } from "@mui/material";
import { Card } from "components/Card";
import { ArrowFatLineRight, Clock } from "phosphor-react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AuthorizationsRequiredFromYou } from "./AuthorizationsRequiredFromYou/AuthorizationsRequiredFromYou";
import { AuthorizationsInitiatedByYou } from "./AuthorizationInitiatedByYou/AuthorizationInitiatedByYou";
import { StyledTab, StyledTabPanel } from "./Authoriations.styled";

enum AuthorizationTab {
  RequiredFromYou = "1",
  YourRequests = "2",
}

export const Authorizations: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const yourRequestsQueryParam = searchParams.get("yourRequests");

  const handleRequiredFromYouClick = () => {
    navigate({ search: "" });
  };

  const handleYourRequestsClick = () => {
    navigate({ search: `yourRequests=true` });
  };

  const selectedTab = useMemo(
    () =>
      yourRequestsQueryParam === "true"
        ? AuthorizationTab.YourRequests
        : AuthorizationTab.RequiredFromYou,
    [yourRequestsQueryParam]
  );

  return (
    <Card width="100%" height="100%">
      <TabContext value={selectedTab}>
        <Grid spacing={3} container>
          <Grid item xs={12}>
            <Typography variant="h3">
              {t("Home.Authorizations.authorizations")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TabList
              TabIndicatorProps={{
                style: { display: "none" },
              }}
              aria-label="authorization tabs"
            >
              <StyledTab
                value="1"
                data-testid="Required from you"
                icon={<Clock />}
                iconPosition="start"
                label={t("Home.Authorizations.requiredFromYou")}
                onClick={handleRequiredFromYouClick}
              />
              <StyledTab
                value="2"
                icon={<ArrowFatLineRight />}
                data-testid="Your requests"
                iconPosition="start"
                label={t("Home.Authorizations.yourRequests")}
                onClick={handleYourRequestsClick}
              />
            </TabList>
          </Grid>
        </Grid>
        <StyledTabPanel value="1">
          <Box mt={3}>
            <AuthorizationsRequiredFromYou />
          </Box>
        </StyledTabPanel>
        <StyledTabPanel value="2">
          <Box mt={3}>
            <AuthorizationsInitiatedByYou />
          </Box>
        </StyledTabPanel>
      </TabContext>
    </Card>
  );
};
