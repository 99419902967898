import { Box } from "@mui/material";
import {
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  AddDraftVariationProposalInput,
  AttachmentInput,
  DraftVariationProposal,
  EditDraftVariationProposalInput,
} from "generated/graphql";
import { RecordProductItemExtraData } from "containers/Projects/components/RecordProductItemForm";
import { CenteredLoadingIndicator } from "components/CenteredLoadingIndicator";
import { TableHeader } from "containers/Projects/components/ActionModal/TableHeader";
import {
  VariationResolveType,
  VOProposalsAgreementsDeterminationsTable,
} from "../components/VOProposalsAgreementsDeterminationsTable/VOProposalsAgreementsDeterminationsTable";
import { VariationWidgetContext } from "../../VariationWidget/VariationWidget.context";
import { useVariationProposalAgreementDeterminationModal } from "../../VariationProposalAgreementDeterminationModal/useVariationProposalAgreementDeterminationModal";
import { useSubmitProposalForProposedVariationAction } from "./useSubmitProposalForProposedVariationAction";
import {
  ModalType,
  VariationProposalAgreementDeterminationFormDataType,
  VariationProposalAgreementDeterminationModal,
} from "../../VariationProposalAgreementDeterminationModal/VariationProposalAgreementDeterminationModal";
import { SendProposalForProposedVariationModal } from "./SendProposalForProposedVariationModal";
import { RecordProposalForProposedVariationModal } from "./RecordProposalForProposedVariationModal";
import { VariationActionFormProps } from "../VariationActionView";

type FormDataType = {
  draftVOProposals: DraftVariationProposal[];
  preferredProposalId: string;
};

const defaultFormData: FormDataType = {
  draftVOProposals: [],
  preferredProposalId: "",
};

export const SubmitProposalForProposedVariationAction: React.FC<
  VariationActionFormProps
> = ({ apiRef, onClose }) => {
  const { variation, contract } = useContext(VariationWidgetContext);

  const [formData, setFormData] = useState<FormDataType>(defaultFormData);
  const [sendModalVisibility, setSendModalVisibility] = useState(false);
  const [recordModalVisibility, setRecordModalVisibility] = useState(false);
  const [selectedDraftProposalId, setSelectedDraftProposalId] =
    useState<string>();

  const updatedAttachmentsRef = useRef<AttachmentInput[]>();

  const {
    modalVisibility: VOProposalModalVisibility,
    toggleModalVisibility: toggleVOProposalModalVisibility,
    loading: proposalsLoading,
    addDraftVOProposal,
    editDraftVOProposal,
    removeDraftVOProposal,
  } = useVariationProposalAgreementDeterminationModal();

  const draftProposalIds = useMemo(
    () => formData.draftVOProposals.map((draftProposal) => draftProposal.id),
    [formData]
  );

  const {
    sendProposal,
    recordProposal,
    draftVariationProposals: promptDraftProposals,
    loading: promptDraftProposalsLoading,
    actionLoading,
  } = useSubmitProposalForProposedVariationAction();

  const toggleSendModalVisibility = () => {
    setSendModalVisibility((state) => !state);
  };

  const toggleRecordModalVisibility = () => {
    setRecordModalVisibility((state) => !state);
  };

  const handleSendProposalForProposedVariation = async () => {
    await sendProposal({
      variables: {
        input: {
          variationId: variation?.id!,
          proposalIds: draftProposalIds,
          preferredProposalId: draftProposalIds[0], // NOTE: right now taking the first one, but in the future users will select preferred proposal
        },
      },
    });
    toggleSendModalVisibility();
    onClose();
  };

  const handleRecordProposalForProposedVariation = async (
    extraData: RecordProductItemExtraData
  ) => {
    await recordProposal({
      variables: {
        input: {
          variationId: variation?.id!,
          proposalIds: draftProposalIds,
          preferredProposalId: draftProposalIds[0],
          dateSent: extraData.dateSent,
          givenById: extraData.givenById,
          number: extraData.number,
        },
      },
    });

    toggleRecordModalVisibility();
    onClose();
  };

  const isFormValid = useMemo(
    () => !!formData.draftVOProposals.length && !!formData.preferredProposalId,
    [formData]
  );

  const handleCreateEditDraftProposal = useCallback(
    async (
      data: VariationProposalAgreementDeterminationFormDataType,
      keepModalOpen?: boolean
    ) => {
      const draftProposal = data as
        | AddDraftVariationProposalInput
        | EditDraftVariationProposalInput;

      if (formData.draftVOProposals.length) {
        // edit mode
        const { data } = await editDraftVOProposal({
          variables: {
            input: draftProposal as EditDraftVariationProposalInput,
          },
        });

        if (data) {
          setFormData((crtFormData) => ({
            ...crtFormData,
            draftVOProposals: [
              data.editDraftVariationProposal as DraftVariationProposal,
            ],
            preferredProposalId: data.editDraftVariationProposal.id,
          }));
        }
      } else {
        // create new draft proposal
        const { data } = await addDraftVOProposal({
          variables: { input: draftProposal },
        });

        if (data) {
          setFormData((crtFormData) => ({
            ...crtFormData,
            draftVOProposals: [
              data.addDraftVariationProposal as DraftVariationProposal,
            ],
            preferredProposalId: data.addDraftVariationProposal.id,
          }));
        }
      }

      if (!keepModalOpen) {
        toggleVOProposalModalVisibility();
      }
    },
    [
      formData,
      addDraftVOProposal,
      editDraftVOProposal,
      toggleVOProposalModalVisibility,
    ]
  );

  const handleAttachmentsChange = useCallback(
    (proposalFormData: VariationProposalAgreementDeterminationFormDataType) => {
      updatedAttachmentsRef.current = proposalFormData.attachments;
      const isEditMode = !!selectedDraftProposalId;

      if (isEditMode) {
        // update live the attachments if form is valid
        handleCreateEditDraftProposal(proposalFormData, true);
      }
    },
    [handleCreateEditDraftProposal, selectedDraftProposalId]
  );

  const handleVOProposalModalClose = () => {
    toggleVOProposalModalVisibility();
    setSelectedDraftProposalId(undefined);
  };

  const handleVOProposalRowClick = (draftProposal: VariationResolveType) => {
    setSelectedDraftProposalId((draftProposal as DraftVariationProposal).id);
    toggleVOProposalModalVisibility();
  };

  const handleDeleteDraftVOProposal = useCallback(
    (proposalId: string) => {
      removeDraftVOProposal({ variables: { id: proposalId } });
    },
    [removeDraftVOProposal]
  );

  const handleNewVOProposalClick = () => {
    setSelectedDraftProposalId(undefined);
    toggleVOProposalModalVisibility();
  };

  const selectedDraftProposal = useMemo(
    () =>
      formData.draftVOProposals.find(
        (draftProposal) => draftProposal.id === selectedDraftProposalId
      ),
    [formData, selectedDraftProposalId]
  );

  useImperativeHandle(
    apiRef,
    () => ({
      validate: () => isFormValid,
      record: toggleRecordModalVisibility,
      send: toggleSendModalVisibility,
    }),
    [isFormValid]
  );

  useEffect(() => {
    if (promptDraftProposals) {
      setFormData({
        draftVOProposals: promptDraftProposals,
        preferredProposalId: promptDraftProposals[0]?.id ?? "",
      });
    }
  }, [promptDraftProposals]);

  return !selectedDraftProposalId &&
    (proposalsLoading || promptDraftProposalsLoading) ? (
    <CenteredLoadingIndicator />
  ) : (
    <>
      {VOProposalModalVisibility && (
        <VariationProposalAgreementDeterminationModal
          open={VOProposalModalVisibility}
          type={ModalType.DraftVOProposal}
          draftProposal={selectedDraftProposal}
          contractCurrency={contract.valueCurrency ?? ""}
          contractSections={contract.sections}
          onAttachmentsChange={handleAttachmentsChange}
          onPrimaryClick={handleCreateEditDraftProposal}
          onClose={handleVOProposalModalClose}
          onSecondaryClick={handleVOProposalModalClose}
        />
      )}
      {isFormValid && sendModalVisibility && (
        <SendProposalForProposedVariationModal
          open={sendModalVisibility}
          draftProposals={formData.draftVOProposals}
          preferredProposalId={formData.preferredProposalId}
          onPrimaryClick={handleSendProposalForProposedVariation}
          onSecondaryClick={toggleSendModalVisibility}
          onClose={toggleSendModalVisibility}
          primaryBtnLoading={actionLoading}
        />
      )}
      {isFormValid && recordModalVisibility && (
        <RecordProposalForProposedVariationModal
          open={recordModalVisibility}
          draftProposals={formData.draftVOProposals}
          preferredProposalId={formData.preferredProposalId}
          onPrimaryClick={handleRecordProposalForProposedVariation}
          onSecondaryClick={toggleRecordModalVisibility}
          onClose={toggleRecordModalVisibility}
          primaryBtnLoading={actionLoading}
        />
      )}
      <Box display="flex" flexDirection="column">
        <TableHeader
          type="VariationProposal"
          onAdd={handleNewVOProposalClick}
          disabled={!!formData.draftVOProposals.length}
        />
        <VOProposalsAgreementsDeterminationsTable
          contractCurrency={contract.valueCurrency ?? ""}
          loading={proposalsLoading}
          items={formData.draftVOProposals}
          type="VariationProposal"
          onRowClick={handleVOProposalRowClick}
          onDelete={handleDeleteDraftVOProposal}
        />
      </Box>
    </>
  );
};
