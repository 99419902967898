import { gql } from "@apollo/client";
import { companyLiteFields } from "graphql/fragments/companyLite.fragment";

export const companiesQuery = gql`
  ${companyLiteFields}

  query companies {
    companies {
      items {
        ...CompanyLiteFields
        contracts {
          items {
            id
          }
        }
      }
    }
  }
`;
