import { ActionsDialogProps } from "components/ActionsDialog";
import { VariationActionType } from "generated/graphql";
import { useSendWorkToCommenceInstructionModal } from "./useSendWorkToCommenceInstructionModal";
import { EnhancedAttachment } from "containers/Projects/components/Attachments/Attachments.decl";
import { SendAction } from "../components/SendAction";
import { InstructWorkToCommenceSummary } from "./InstructWorkToCommenceSummary";

export type SendWorkToCommenceInstructionModalProps = {
  remarks: string;
  noAgreement?: boolean;
  attachments?: EnhancedAttachment[];
} & ActionsDialogProps;

export const SendWorkToCommenceInstructionModal: React.FC<
  SendWorkToCommenceInstructionModalProps
> = ({ remarks, attachments = [], noAgreement, ...restDialogProps }) => {
  const { variationPreview } = useSendWorkToCommenceInstructionModal(remarks);
  return (
    <SendAction
      variationPreview={variationPreview}
      actionType={VariationActionType.InstructWorkToCommenceOnVariation}
      summaryEl={
        <InstructWorkToCommenceSummary
          text={remarks}
          noAgreement={noAgreement}
          attachments={attachments}
        />
      }
      {...restDialogProps}
    />
  );
};
